// src/api/salesOrderApi.ts

import axios from "axios";
import Cookies from "js-cookie";
const API_BASE_URL = process.env.REACT_APP_COMMERCE_SERVER;

export interface SalesOrderItem {
    sales_order_item_id?: number;
    product_id: number;
    quantity: number;
    unit_price: number;
    notes?: string;
}

export interface SalesOrder {
    sales_order_id?: number;
    assignee_type: string; // 'user' or 'company'
    assignee_id: string; // UUID or Integer as a string
    order_date?: string;
    status?: string;
    total_amount?: number;
    notes?: string;
    items?: SalesOrderItem[];
}

export const fetchSalesOrders = async (params?: any): Promise<{ data: SalesOrder[]; total: number }> => {
    const response = await axios.get(`${API_BASE_URL}/sales-orders`, {
        params,
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return { data: response.data.data, total: response.data.total };
};

export const fetchSalesOrder = async (id: number): Promise<SalesOrder> => {
    const response = await axios.get(`${API_BASE_URL}/sales-orders/${id}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};

export const createSalesOrder = async (orderData: SalesOrder): Promise<SalesOrder> => {
    const response = await axios.post(`${API_BASE_URL}/sales-orders`, orderData, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};

export const updateSalesOrder = async (id: number, orderData: Partial<SalesOrder>): Promise<SalesOrder> => {
    const response = await axios.put(`${API_BASE_URL}/sales-orders/${id}`, orderData, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};

export const deleteSalesOrder = async (id: number): Promise<void> => {
    await axios.delete(`${API_BASE_URL}/sales-orders/${id}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
};
