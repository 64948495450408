import React, { useState } from 'react';
import { Application } from "../../../api/admin/appApi";
import EditApplicationModal from "./EditAppModal";
import { Button } from 'primereact/button';

const createEmptyApplication = (): Application => ({
	id: 0,
	name: "",
	client_id: "",
	client_secret: "",
	redirect_uris: [],
	logo: "",
	description: "",
	created_at: "",
	updated_at: "",
});

const AppButtons: React.FC = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [newApplication, setNewApplication] = useState<Application>(createEmptyApplication());

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const handleCreateApplication = () => {
		handleModalClose();
	};

	return (
		<div className="mb-3">
			<Button
				label="Add Application"
				className="p-button-outlined"
				onClick={handleOpenModal}
			/>
			{isModalOpen && (
				<EditApplicationModal
					isOpen={isModalOpen}
					onClose={handleModalClose}
					app={newApplication}
					onAppUpdated={handleCreateApplication}
				/>
			)}
		</div>
	);
};

export default AppButtons;
