import React, { useState, useEffect } from "react";
import debounce from 'lodash/debounce';

// Prime react
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { ConfirmDialog } from "primereact/confirmdialog"; 

// Api imports
import { createRole, deleteRole, getApplicationsByRole, assignApplicationsToRole, removeApplicationFromRole, getUsersByRole, Role } from "../../../api/admin/rolesApi";
import { getApplications, Application } from "../../../api/admin/appApi";
import { User } from "../../../api/admin/userApi";


interface EditRoleModalProps {
    isOpen: boolean;
    onClose: () => void;
    role: Role;
    onRoleUpdated: () => void;
}

const EditRoleModal: React.FC<EditRoleModalProps> = ({ isOpen, onClose, role, onRoleUpdated }) => {
    const [formData, setFormData] = useState<Partial<Role>>({
        name: role.name,
        guard_name: role.guard_name,
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [activeTab, setActiveTab] = useState<"Details" | "Applications" | "Users">("Details");

    // Application states
    const [applications, setApplications] = useState<Application[]>([]);
    const [assignedApplicationIds, setAssignedApplicationIds] = useState<number[]>([]);
    const [originalAssignedApplicationIds, setOriginalAssignedApplicationIds] = useState<number[]>([]);
    const [isApplicationsLoading, setIsApplicationsLoading] = useState<boolean>(false);

    // Users state
    const [users, setUsers] = useState<User[]>([]);
    const [isUsersLoading, setIsUsersLoading] = useState<boolean>(false);
    const [usersError, setUsersError] = useState<string>("");

    // Delete Confirmation
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    // Load Applications or Users data when switching tabs
    useEffect(() => {
        if (!role) return;
        if (activeTab === "Applications") {
            fetchApplicationsData();
        } else if (activeTab === "Users") {
            fetchUsersData();
        }
        // eslint-disable-next-line
    }, [activeTab]);

    const fetchUsersData = async () => {
        setIsUsersLoading(true);
        try {
            const assignedUsers = await getUsersByRole(role.id);
            setUsers(assignedUsers);
            setUsersError("");
        } catch (err) {
            console.error("Error fetching users:", err);
            setUsersError("Failed to load users.");
        } finally {
            setIsUsersLoading(false);
        }
    };

    const fetchApplicationsData = async () => {
        setIsApplicationsLoading(true);
        try {
            // Fetch all applications
            const { data: allApplications } = await getApplications(1, 1000);
            setApplications(allApplications);

            // Fetch assigned applications for this role
            const assignedAppsResponse = await getApplicationsByRole(role.id);
            const assignedApps = assignedAppsResponse.applications || [];

            const assignedIds = assignedApps.map((app: Application) => app.id);
            setAssignedApplicationIds(assignedIds);
            setOriginalAssignedApplicationIds(assignedIds);
        } catch (err) {
            console.error("Error fetching applications:", err);
            setError("Failed to load applications.");
        } finally {
            setIsApplicationsLoading(false);
        }
    };

    // Checkbox changes for assigned applications
    const handleCheckboxChange = (appId: number, checked: boolean) => {
        if (checked) {
            setAssignedApplicationIds((prev) => [...prev, appId]);
        } else {
            setAssignedApplicationIds((prev) => prev.filter((id) => id !== appId));
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    // Show the delete confirmation dialog
    const handleDeleteClick = () => {
        setShowConfirmDelete(true);
    };

    const onDelete = async () => {
        try {
            await deleteRole(role.id.toString());
            onRoleUpdated();
            onClose();
        } catch (err) {
            console.error("Error deleting role:", err);
            setError("Failed to delete role.");
        }
    };

    const handleConfirmDelete = async () => {
        // Check if there are users assigned to the role
        try {
            const assignedUsers = await getUsersByRole(role.id);
            if (assignedUsers.length > 0) {
                setError("Cannot delete role. There are users assigned to this role.");
                setShowConfirmDelete(false);
                return;
            }
            // No users assigned, proceed with deletion
            setShowConfirmDelete(false);
            await onDelete();
        } catch (err) {
            console.error("Error checking assigned users:", err);
            setError("Failed to delete role.");
            setShowConfirmDelete(false);
        }
    };

    // Handle role creation/update
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            if (role.id === 0) {
                // Create new role
                if (!formData.name) {
                    setError("Role name is required.");
                    setIsSubmitting(false);
                    return;
                }
                const newRole = await createRole(formData.name);
                if (assignedApplicationIds.length > 0) {
                    await assignApplicationsToRole(newRole.id, assignedApplicationIds);
                }
            } else {
                // Update role’s assigned applications
                const applicationsToAssign = assignedApplicationIds.filter((id) => !originalAssignedApplicationIds.includes(id));
                const applicationsToUnassign = originalAssignedApplicationIds.filter((id) => !assignedApplicationIds.includes(id));

                // Assign new applications
                if (applicationsToAssign.length > 0) {
                    await assignApplicationsToRole(role.id, applicationsToAssign);
                }

                // Unassign applications
                for (const appId of applicationsToUnassign) {
                    await removeApplicationFromRole(role.id, appId);
                }
            }
        } catch (err) {
            console.error("Error updating role:", err);
            setError("Failed to update role.");
        } finally {
            setIsSubmitting(false);
        }
    };

	const autoSave = async () => {
		setIsSubmitting(true);
        try {
            if (role.id === 0) {
                // Create new role
                if (!formData.name) {
                    setError("Role name is required.");
                    setIsSubmitting(false);
                    return;
                }
                const newRole = await createRole(formData.name);
                if (assignedApplicationIds.length > 0) {
                    await assignApplicationsToRole(newRole.id, assignedApplicationIds);
                }
            } else {
                // Update role’s assigned applications
                const applicationsToAssign = assignedApplicationIds.filter((id) => !originalAssignedApplicationIds.includes(id));
                const applicationsToUnassign = originalAssignedApplicationIds.filter((id) => !assignedApplicationIds.includes(id));

                // Assign new applications
                if (applicationsToAssign.length > 0) {
                    await assignApplicationsToRole(role.id, applicationsToAssign);
                }

                // Unassign applications
                for (const appId of applicationsToUnassign) {
                    await removeApplicationFromRole(role.id, appId);
                }
            }

        } catch (err) {
            console.error("Error updating role:", err);
            setError("Failed to update role.");
        } finally {
            setIsSubmitting(false);
        }
	};

	const handleAutoSave = debounce(autoSave, 500);

    const header = () => {
        return (
            <div className="flex justify-content-between">
                <div className="">{role.id ? "Edit " + role.name : "Create New Role"}</div>

                <div>
                    <Button
                        icon="pi pi-save p-icon"
                        rounded
                        text
                        aria-label="Save"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        type="button"
                        onClick={handleSubmit}
                        className="p-dialog-header-icon mr-2"
                        style={{ color: "var(--text-color-alternative)" }}
                    />
                </div>
            </div>
        );
    };

    return (
        <Dialog
            visible={isOpen}
            onHide={onClose}
            modal
            dismissableMask
            style={{ width: "80vw", height: "80vh" }}
            closable={true}
            draggable={true}
            maximizable={true}
            header={header}
            headerClassName="pb-0 h-auto bg-primary "
            contentStyle={{ padding: 0 }}
            className="custom-dialog"
			focusOnShow={false}
        >
            {/* Navigation Tabs */}
            <div className="flex gap-3 border-bottom-1 surface-border pb-3 mb-3">
                <Button label="Role Details" className={"p-button-text " + (activeTab === "Details" ? "text-primary" : "text-color-secondary")} onClick={() => setActiveTab("Details")} />
                <Button
                    label="Assigned Applications"
                    className={"p-button-text " + (activeTab === "Applications" ? "text-primary" : "text-color-secondary")}
                    onClick={() => setActiveTab("Applications")}
                />
                <Button label="Assigned Users" className={"p-button-text " + (activeTab === "Users" ? "text-primary" : "text-color-secondary")} onClick={() => setActiveTab("Users")} />
            </div>

            <form onSubmit={handleSubmit} className="flex flex-column gap-2">
                {activeTab === "Details" && (
                    <div className="grid formgrid p-fluid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="name">Role Name</label>
                            <InputText id="name" name="name" value={formData.name || ""} onChange={handleChange} onBlur={handleAutoSave} className="w-full" placeholder="Enter role name" />
                        </div>
                        <div className="field col-12">
                            <label htmlFor="guard_name">Guard Name</label>
                            {/* Guard name is read-only */}
                            <InputText id="guard_name" name="guard_name" value={formData.guard_name || ""} onChange={() => {}} onBlur={handleAutoSave} disabled className="w-full" />
                        </div>
                    </div>
                )}

                {activeTab === "Applications" && (
                    <div className="flex flex-column gap-3">
                        {isApplicationsLoading ? (
                            <p>Loading applications...</p>
                        ) : error ? (
                            <p style={{ color: "red" }}>{error}</p>
                        ) : (
                            <div className="flex flex-column gap-2">
                                {applications.map((app) => (
                                    <div key={app.id} className="field-checkbox">
                                        <Checkbox inputId={`app-${app.id}`} checked={assignedApplicationIds.includes(app.id)} onChange={(e) => handleCheckboxChange(app.id, e.checked!)} onBlur={handleAutoSave} />
                                        <label htmlFor={`app-${app.id}`} style={{ marginLeft: "0.5rem" }}>
                                            {app.name}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}

                {activeTab === "Users" && (
                    <div>
                        {isUsersLoading ? (
                            <p>Loading users...</p>
                        ) : usersError ? (
                            <p style={{ color: "red" }}>{usersError}</p>
                        ) : (
                            <>
                                <p className="font-semibold mb-2">Total Users Assigned: {users.length}</p>
                                <div className="overflow-y-auto" style={{ maxHeight: "200px" }}>
                                    <ul className="list-none p-0 m-0">
                                        {users.map((user) => (
                                            <li key={user.id} className="border-bottom-1 surface-border py-2">
                                                <div style={{ fontWeight: 600 }}>{user.username}</div>
                                                <div style={{ fontSize: "0.9rem", color: "var(--text-color-secondary)" }}>{user.email}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                    </div>
                )}

                {error && <p style={{ color: "red" }}>{error}</p>}
            </form>
        </Dialog>
    );
};

export default EditRoleModal;
