// src/components/icons/PlanIcon.tsx
import React from 'react';
import { MdOutlineViewAgenda } from 'react-icons/md'; // Importing the Material Design icon

interface PlanIconProps {
    className?: string;
}

const PlanIcon: React.FC<PlanIconProps> = ({ className }) => {
    return (
        <MdOutlineViewAgenda
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24} // Default size
            aria-label="Plan Management"
        />
    );
};

export default PlanIcon;
