import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import RoleTable from "../../components/admin/roles/RoleTable";
import LoadingSpinner from '../../components/icons/LoadingSpinner';
import { getAllRoles, Role } from "../../api/admin/rolesApi";
import EditRoleModal from "../../components/admin/roles/EditRoleModal";
import RoleButtons from "../../components/admin/roles/RoleButtons";

const RoleManagementPage: React.FC = () => {
	const [roles, setRoles] = useState<Role[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedRole, setSelectedRole] = useState<Role | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const loadRoles = async () => {
			setIsLoading(true);
			try {
				const rolesData = await getAllRoles();
				setRoles(rolesData);
				setError(null);
			} catch (err) {
				console.error("Error fetching roles:", err);
				setError("Failed to load roles.");
			} finally {
				setIsLoading(false);
			}
		};
		loadRoles();
	}, []);

	const handleEditClick = (role: Role) => {
		setSelectedRole(role);
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setSelectedRole(null);
		setIsModalOpen(false);
	};

	const handleRoleUpdate = async () => {
		// Re-fetch roles after creating or updating a role
		try {
			setIsLoading(true);
			const { data } = await getAllRoles();
			setRoles(data);
			setError(null);
		} catch (err) {
			console.error("Error fetching updated roles:", err);
			setError("Failed to load updated roles.");
		} finally {
			setIsLoading(false);
			handleModalClose();
		}
	};

	return (
		<Layout>
			<div className="flex flex-column gap-3">
				<h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: 0 }}>Role Management</h1>
				{isLoading ? (
					<div className="flex justify-content-center align-items-center" style={{ height: '200px' }}>
						<LoadingSpinner size={60} color="#354396" />
					</div>
				) : (
					<div className="flex flex-column gap-2">
						<RoleButtons />
						<RoleTable roles={roles} onEditClick={handleEditClick} />
					</div>
				)}
				{selectedRole && (
					<EditRoleModal
						isOpen={isModalOpen}
						onClose={handleModalClose}
						role={selectedRole}
						onRoleUpdated={handleRoleUpdate}
					/>
				)}
			</div>
		</Layout>
	);
};

export default RoleManagementPage;
