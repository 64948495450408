import { useEffect, useState } from "react";
import React from "react";
import Cookies from "js-cookie";
import Layout from "../components/Layout";

import { Chart } from "primereact/chart";
import { Calendar } from "primereact/calendar"; // <-- import the Calendar

import { getDashboardData } from "../api/labiq/labiqApi";
import { User } from "../api/admin/userApi";

const options = {
	scales: {
		y: {
			beginAtZero: true,
			ticks: {
				stepSize: 1,
			  },
		},
		
	},
};

const DashboardPage: React.FC = () => {
	const user: User = JSON.parse(Cookies.get("user") || "{}");

	const [chartData, setChartData] = useState({});
	const [chartOptions, setChartOptions] = useState({});

	const [startDate, setStartDate] = useState<Date>(() => {
		const d = new Date();
		d.setDate(d.getDate() - 30);
		return d;
	});
	const [endDate, setEndDate] = useState<Date>(new Date());

	useEffect(() => {
		GetSampleSystemData();
		setChartOptions(options);
	}, [startDate, endDate]);


	const GetSampleSystemData = async () => {
		try {
			// 2) Pass date range to your API call
			const { totals } = await getDashboardData(
				user.company,
				startDate,
				endDate
			);

			const updatedData = {
				labels: ["Samples", "Batches"],
				datasets: [
					{
						label: "Samples",
						data: totals,
						backgroundColor: [
							"rgba(255, 159, 64, 0.2)",
							"rgba(75, 192, 192, 0.2)",
						],
						borderColor: ["rgb(255, 159, 64)", "rgb(75, 192, 192)"],
						borderWidth: 1,
					},
				],
			};

			setChartData(updatedData);
		} catch (error: unknown) {
			console.error(error);
		}
	};


	return (
		<Layout>
			<div className="grid m-4 mt-0">

				<div className="card grid col-6 surface-100 border-round" style={{ marginTop: "1rem" }}>
					<div className="col-12">
						<h3 className="justify-center">Lab IQ</h3>
					</div>
					<div className="col-12" style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
						<div>
							<label>Start Date: </label>
							<Calendar
								value={startDate}
								onChange={(e) => setStartDate(e.value || new Date())}
								showIcon
								dateFormat="yy-mm-dd"
							/>
						</div>
						<div>
							<label>End Date: </label>
							<Calendar
								value={endDate}
								onChange={(e) => setEndDate(e.value || new Date())}
								showIcon
								dateFormat="yy-mm-dd"
							/>
						</div>
					</div>

					<div className="col-12">
						<Chart type="bar" data={chartData} options={chartOptions} />
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default DashboardPage;
