import React, { useState } from "react";
import { uploadFile, FileMetadata } from "../../../../api/fileServiceApi";
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';

interface UploadFileModalProps {
	patientId: number;
	onClose: () => void;
	onFileUploaded: (file: FileMetadata) => void;
}

const UploadFileModal: React.FC<UploadFileModalProps> = ({
	patientId,
	onClose,
	onFileUploaded
}) => {
	const [file, setFile] = useState<File | null>(null);
	const [isUploading, setIsUploading] = useState<boolean>(false);

	const handleFileChange = (event: React.SyntheticEvent) => {
		// If using prime FileUpload you can handle it differently
		// If using a regular <input type="file">, handle like below:
		const target = event.target as HTMLInputElement;
		if (target.files && target.files.length > 0) {
			setFile(target.files[0]);
		}
	};

	const handleUpload = async () => {
		if (!file) return;
		setIsUploading(true);
		try {
			const response = await uploadFile(file, patientId);
			onFileUploaded(response.data);
		} catch (error) {
			// Handle error
			console.error(error);
		} finally {
			setIsUploading(false);
		}
	};

	const footer = (
		<div className="flex justify-content-end gap-2">
			<Button label="Cancel" className="p-button-outlined" onClick={onClose} />
			<Button
				label={isUploading ? "Uploading..." : "Upload"}
				onClick={handleUpload}
				disabled={!file || isUploading}
			/>
		</div>
	);

	return (
		<Dialog
			visible={true}
			onHide={onClose}
			header="Upload File"
			footer={footer}
			modal
			dismissableMask
			style={{ width: '30rem' }}
		>
			<div className="flex flex-column gap-2">
				{/* If you want to use the PrimeReact FileUpload component: */}
				{/* <FileUpload name="file" customUpload onUpload={yourUploadHandler} /> */}
				<input type="file" onChange={handleFileChange} />
			</div>
		</Dialog>
	);
};

export default UploadFileModal;
