import React, { useState } from "react";
import { uploadOmnibeadData, importOmnibeadData, translateOmnibeadData } from "../../../api/labiq/labiqApi";

interface EditUserModalProps {
    isOpen: boolean;
    onClose: () => void;
    onFormSubmission: () => void;
}

const OmnibeadUploadForm: React.FC<EditUserModalProps> = ({ isOpen, onClose, onFormSubmission }) => {
    // State variables
    const [batchID, setBatchID] = useState("");
    const [file, setFile] = useState<File | null>(null);
    const [translationMode, setTranslationMode] = useState("");
    const [message, setMessage] = useState("");
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [disableTranslate, setDisableTranslate] = useState(true);

    const handleSubmit = async (e: { preventDefault: () => void }) => {
        e.preventDefault();

        // Reset messages
        setMessage("");

        // Input validation
        if (!batchID.trim() || !file) {
            alert("Please complete all fields and try again.");
            return;
        }

        const allowedExtensions = ["txt", "zip"];
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
            alert("The file type is not supported. Please upload a .txt or .zip file.");
            return;
        }

        const allowedTypes = ["text/plain", "application/zip", "application/x-zip-compressed"];
        if (!allowedTypes.includes(file.type)) {
            alert("The file type is not supported. Please upload a .txt or .zip file.");
            return;
        }

        // Disable submit button and show message
        setDisableSubmit(true);
        setMessage("Uploading, please wait...");

        // Prepare form data
        const formData = new FormData();
        formData.append("file", file);
        formData.append("omnibead_batchID", batchID);

        try {
            // Upload the file
            const uploadResponse = await uploadOmnibeadData(formData);

            if (uploadResponse && uploadResponse.results) {
                setMessage("Importing, please wait...");
                // Import data
                const importResponse = await importOmnibeadData({ omnibead_batchID: batchID });

                if (importResponse && importResponse.results) {
                    setMessage("Upload complete, you can translate the data now.");
                    setDisableTranslate(false);
                } else {
                    setMessage(importResponse.error.msg || "Error importing data.");
                    setDisableSubmit(false);
                }
            } else {
                setMessage(uploadResponse.error.msg || "Error uploading file.");
                setDisableSubmit(false);
            }
        } catch (error) {
            console.error(error);
            setMessage("An error occurred during upload.");
            setDisableSubmit(false);
        } finally {
            onFormSubmission();
        }
    };

    const handleTranslate = async () => {
        setMessage("Translating, please wait...");
        setDisableTranslate(true);

        const dataToPost = {
            omnibead_batchID: batchID,
            translation_mode: translationMode || "all",
        };

        try {
            const translateResponse = await translateOmnibeadData(dataToPost);

            if (translateResponse && translateResponse.results) {
                setMessage("Translation complete.");
                setDisableSubmit(false);
                setDisableTranslate(true);
                // Clear inputs
                setBatchID("");
                setFile(null);
                setTranslationMode("");
            } else {
                setMessage(translateResponse.error.msg || "Error translating data.");
                setDisableTranslate(false);
            }
        } catch (error) {
            console.error(error);
            setMessage("An error occurred during translation.");
            setDisableTranslate(false);
        }
    };

    // Render modal conditionally based on the isOpen prop
    if (!isOpen) return null; // If not open, don't render anything

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-lg">
                <h5 className="text-xl font-semibold mb-4">Omnibead Upload</h5>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="omnibead-batchID" className="block text-gray-700 font-medium mb-1">
                            Batch ID
                        </label>
                        <input type="text" id="omnibead-batchID" className="w-full border rounded p-2" value={batchID} onChange={(e) => setBatchID(e.target.value)} required />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="omnibead-file" className="block text-gray-700 font-medium mb-1">
                            File
                        </label>
                        <input
                            id="omnibead-file"
                            type="file"
                            accept=".txt,.zip"
                            className="w-full border rounded p-2"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length > 0) {
                                    setFile(e.target.files[0]);
                                } else {
                                    setFile(null);
                                }
                            }}
                            required
                        />
                        <small className="text-gray-600">Please upload a .txt file or a .zip file</small>
                    </div>
                    {message && <div className="mb-4 text-blue-500">{message}</div>}
                    <div className="mb-4">
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50" disabled={disableSubmit}>
                            Submit
                        </button>
                    </div>
                    <div className="mb-4">
                        <p className="font-medium">Translation preference:</p>
                        <div className="flex items-center space-x-4">
                            <div>
                                <input type="radio" id="translation__sampleID" name="translate_mode" value="sampleID" checked={translationMode === "sampleID"} onChange={(e) => setTranslationMode(e.target.value)} className="mr-2" />
                                <label htmlFor="translation__sampleID">via Sample ID</label>
                            </div>
                            <div>
                                <input type="radio" id="translation__batchID" name="translate_mode" value="batchID" checked={translationMode === "batchID"} onChange={(e) => setTranslationMode(e.target.value)} className="mr-2" />
                                <label htmlFor="translation__batchID">via Batch ID</label>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <button type="button" className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 disabled:opacity-50" onClick={handleTranslate} disabled={disableTranslate}>
                            Translate
                        </button>
                        <button type="button" className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default OmnibeadUploadForm;
