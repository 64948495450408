import React, { useState } from 'react';
import Cookies from "js-cookie";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import EditPatientModal from "../../healthcare/patient/EditPatientModal";
import { Patient, PatientInfo } from "../../../api/healthcare/patientApi";

const createEmptyPatient = (): Patient => ({
	id: 0,
	uuid: "",
	username: "",
	email: "",
	password: "",
	password_confirmation: "",
	first_name: "",
	middle_name: "",
	last_name: "",
	phone: "",
	address: "",
	city: "",
	state: "",
	country: "",
	postal_code: "",
	profile_picture: "",
	date_of_birth: undefined,
	gender: "",
	sex: "",
	company: "",
	panel_key: "",
	company_code: "",
	reagents: "",
	preferences: "",
	is_active: false,
	created_at: new Date(),
	updated_at: new Date(),
	total_blood_samples: 0,
	total_gene_samples: 0,
	patient_info: undefined,
});

interface PatientButtonsProps {
	searchPatients: (search: string) => void;
	reloadPatients: () => void;
}

const PatientButtons: React.FC<PatientButtonsProps> = ({ searchPatients, reloadPatients }) => {
	const user = JSON.parse(Cookies.get("user") || "{}");
	
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [newPatient, setNewPatient] = useState<Patient>(createEmptyPatient());
	const [searchTerm, setSearchTerm] = useState("");
	const [isAnon, setIsAnon] = useState(false);
	

	

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchTerm(value);
		searchPatients(value);
	};

	const handleOpenModal = () => {
		setIsAnon(false);
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const handleCreatePatient = () => {
		
		handleModalClose();
	};

	const handleAnonymousUser = () => {
		setIsAnon(true);
		setIsModalOpen(true);
	};

	return (
		<div className="flex align-items-center justify-content-between mb-3 gap-3">
			<div>
				<Button
					label="Create Patient"
					className="p-button-outlined"
					onClick={handleOpenModal}
				/>
				{(user.company === "Mygene" || user.company === "DNAIQ") && (
					<Button
						label="Create Anonymous Patient"
						className="p-button-outlined ml-2"
						onClick={handleAnonymousUser}
					/>
				)}
			</div>
			
			

			<div className="flex align-items-center gap-2">
				<span className="p-input-icon-left">
					<InputText
						type="text"
						placeholder="Search patients..."
						value={searchTerm}
						onChange={handleSearchChange}
						className="w-12rem"
					/>
				</span>
				<Button
					label="Reset"
					className="p-button-outlined"
					onClick={reloadPatients}
				/>
			</div>

			{isModalOpen && (
				<EditPatientModal
					isOpen={isModalOpen}
					onClose={handleModalClose}
					patient={newPatient}
					onPatientUpdated={handleCreatePatient}
					isAnon={isAnon}
				/>
			)}
		</div>
	);
};

export default PatientButtons;
