// src/components/inventory/ProductTable.tsx

import React from "react";
import { useNavigate } from "react-router-dom";
import { Product } from "../../../../api/inventory/productApi";

interface ProductTableProps {
    kits: Product[];
    onEditClick: (kit: Product) => void;
    onDeleteClick: (kit: Product) => void;
}

const ProductTable: React.FC<ProductTableProps> = ({ kits, onEditClick, onDeleteClick }) => {
    const navigate = useNavigate();

    const handleManageStock = (productId: number) => {
        navigate(`/inventory/products/${productId}/stock`);
    };

    if (!kits || kits.length === 0) {
        return <p>No products available.</p>;
    }

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white border">
                <thead>
                    <tr>
                        <th className="py-2 px-3 border-b">Product ID</th>
                        <th className="py-2 px-3 border-b">Name</th>
                        <th className="py-2 px-3 border-b">SKU</th>
                        <th className="py-2 px-3 border-b">Barcode</th>
                        <th className="py-2 px-3 border-b">Unit Price</th>
                        <th className="py-2 px-3 border-b">Unit Cost</th>
                        <th className="py-2 px-3 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {kits.map((kit) => (
                        <tr key={kit.product_id} className="text-center">
                            <td className="py-2 px-3 border-b">{kit.product_id}</td>
                            <td className="py-2 px-3 border-b">{kit.name}</td>
                            <td className="py-2 px-3 border-b">{kit.sku}</td>
                            <td className="py-2 px-3 border-b">{kit.barcode || "-"}</td>
                            <td className="py-2 px-3 border-b">{kit.unit_price !== undefined ? `$${kit.unit_price}` : "-"}</td>
                            <td className="py-2 px-3 border-b">{kit.unit_cost !== undefined ? `$${kit.unit_cost}` : "-"}</td>
                            <td className="py-2 px-3 border-b">
                                <button onClick={() => onEditClick(kit)} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 mr-2">
                                    Edit
                                </button>
                                <button onClick={() => onDeleteClick(kit)} className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 mr-2">
                                    Delete
                                </button>
                                <button onClick={() => handleManageStock(kit.product_id)} className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600">
                                    Manage Stock
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ProductTable;
