import React, { useState, useEffect } from "react";
import axios from "axios";
import { sampleCountOnPatient } from "../../../../api/healthcare/patientApi";
import { createOrUpdateSamplesPanels, fetchPatientAuthSamples, createAuthSamples, removePatientAuthSamples } from "../../../../api/labiq/geneCodesApi";
import { fetchSamples, fetchSample, Sample } from "../../../../api/labiq/labiqApi";
import LoadingSpinner from '../../../icons/LoadingSpinner';

// PrimeReact
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import CreateSampleModal from "./CreateSampleSimpleModal";
import PatientSampleModal from "../../../labiq/sampleManagement/SampleModal";
import ASsignSampleModal from "./AssignSampleModal";

interface SampleTabProps {
	patient: any;
	isSubmitting: boolean;
	setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
	setErrorMessages: React.Dispatch<React.SetStateAction<any[]>>;
	handleAutoSave: () => void;
}

const SampleTab: React.FC<SampleTabProps> = ({ patient, isSubmitting, setIsSubmitting, setErrorMessages, handleAutoSave }) => {
	const [samples, setSamples] = useState<Sample[]>([]);
	const [isCreateSampleModalOpen, setIsCreateSampleModalOpen] = useState<boolean>(false);
	const [isAssignSampleModalOpen, setIsAssignSampleModalOpen] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [sampleModalOpen, setSampleModalOpen] = useState<boolean>(false);
	const [selectedSample, setSelectedSample] = useState<Sample | null>(null);

	useEffect(() => {
		if (patient.id !== 0) {
			fetchPatientSample();
		}
		// eslint-disable-next-line
	}, [patient.id]);

	const fetchPatientSample = async () => {
		setIsLoading(true);
		try {
			const result = await fetchPatientAuthSamples(patient.email);
			let loadedSamples: Sample[] = [];
			for (let i = 0; i < result.length; i++) {
				const tempSample = result[i];
				const detail: Sample = await fetchSample(tempSample.sampleID);
				detail.is_active = tempSample.sample_activation;
				detail.authSampleId = tempSample.idauth_samples;
				loadedSamples.push(detail);

			}
			setSamples(loadedSamples);
		} catch (error: unknown) {
			handleErrors(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleErrors = (error: unknown) => {
		if (axios.isAxiosError(error) && error.response) {
			if (error.response.data?.errors) {
				const errorMessages = Object.entries(error.response.data.errors)
					.flatMap(([_, messages]) => messages as string);
				setErrorMessages(errorMessages);
			} else if (error.response.data?.message) {
				setErrorMessages([error.response.data.message]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} else if (error instanceof Error) {
			setErrorMessages([error.message]);
		} else {
			setErrorMessages(["An unknown error occurred."]);
		}
	};

	const removePatientFromSample = async (sampleId: number, authSampleId: number) => {
		setIsSubmitting(true);
		try {
			const check = await createOrUpdateSamplesPanels(authSampleId, []);
			if (check) {
				await removePatientAuthSamples(authSampleId);
			}
			await sampleCountOnPatient(patient.email, "gene", "decrement");
			const updatedSamples = await fetchPatientAuthSamples(patient.email);
			setSamples(updatedSamples);
		} catch (error: unknown) {
			handleErrors(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	const openSampleModal = (sample: Sample) => {
		setSelectedSample(sample);
		setSampleModalOpen(true);
	};

	const closeSampleModal = async () => {
		setSelectedSample(null);
		setSampleModalOpen(false);
		fetchPatientSample();
	};

	// DataTable body templates
	const sampleIdBody = (rowData: Sample) => rowData.sampleID;
	const labStatusBody = (rowData: Sample) => rowData.sampleOA?.status || "N/A";
	const snpBody = () => "Not yet implemented";
	const actionBody = (rowData: Sample) => (
		<Button
			label={isSubmitting ? "Processing..." : "Remove"}
			className="p-button-text p-button-danger"
			onClick={(e) => {
				e.stopPropagation();
				removePatientFromSample(rowData.id, rowData.authSampleId || 0);
			}}
			disabled={isSubmitting}
		/>
	);

	return (
		<div className="relative">
			{isLoading ? (
				<div className="flex align-items-center justify-content-center" style={{ height: '200px' }}>
					<LoadingSpinner size={60} color="#354396" />
				</div>
			) : (
				<div className="flex flex-column gap-3">
					<div className="flex align-items-center justify-content-end gap-3 mb-2">
						

						<Button
							label="Assign Sample"
							icon="pi pi-plus"
							className="p-button-outlined p-button-success"
							onClick={() => setIsAssignSampleModalOpen(true)}
						/>
						<Button
							label="Create Kit"
							icon="pi pi-plus"
							className="p-button-outlined p-button-success"
							onClick={() => setIsCreateSampleModalOpen(true)}
						/>
					</div>

					{/* DataTable for assigned samples */}
					{samples.length > 0 ? (
						<div className="card surface-0 border-round shadow-2 p-3">
							<DataTable
								value={samples}
								responsiveLayout="scroll"
								className="p-datatable-gridlines p-datatable-sm"
								selectionMode="single"
								onRowClick={(e) => openSampleModal(e.data as Sample)}
							>
								<Column field="sampleID" header="SampleID" body={sampleIdBody} />
								<Column field="logistics" header="Logistics" body={() => "N/A"} />
								<Column field="labStatus" header="Lab Status" body={labStatusBody} />
								<Column field="snps" header="SNP's" body={snpBody} />
								<Column header="Actions" body={actionBody} style={{ width: '6rem' }} />
							</DataTable>
						</div>
					) : (
						<p>No samples found</p>
					)}

					{selectedSample && sampleModalOpen && (
						<PatientSampleModal
							isOpen={sampleModalOpen}
							onClose={closeSampleModal}
							sample={selectedSample}
							patient={patient}
							onFormSubmission={closeSampleModal}
						/>
					)}
					{isCreateSampleModalOpen && (
						<CreateSampleModal
							isOpen={isCreateSampleModalOpen}
							onClose={() => setIsCreateSampleModalOpen(false)}
							setSamples={setSamples}
							patient={patient}
						/>
					)}

					{isAssignSampleModalOpen && (
						<ASsignSampleModal
							isOpen={isAssignSampleModalOpen}
							onClose={() => setIsAssignSampleModalOpen(false)}
							setSamples={setSamples}
							patient={patient}
							fetchPatientSample={fetchPatientSample}
							handleErrors={handleErrors}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default SampleTab;
