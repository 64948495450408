import React, { useState } from 'react';
import { Role } from "../../../api/admin/rolesApi";
import EditRoleModal from "./EditRoleModal";
import { Button } from 'primereact/button';

const createEmptyRole = (): Role => ({
	id: 0,
	guard_name: "api",
	name: "",
	created_at: "",
	updated_at: "",
	pivot: {
		model_type: "",
		model_id: 0,
		role_id: 0,
	},
});

const RoleButtons: React.FC = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [newRole, setNewRole] = useState<Role>(createEmptyRole());

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const handleCreateRole = () => {
		handleModalClose();
	};

	return (
		<div className="flex align-items-center mb-3">
			<Button
				label="Add Role"
				className="p-button-outlined"
				onClick={handleOpenModal}
			/>
			{isModalOpen && (
				<EditRoleModal
					isOpen={isModalOpen}
					onClose={handleModalClose}
					role={newRole}
					onRoleUpdated={handleCreateRole}
				/>
			)}
		</div>
	);
};

export default RoleButtons;
