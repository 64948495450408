// src/api/companyApi.ts

import axios from "axios";
import Cookies from "js-cookie";
const API_BASE_URL = process.env.REACT_APP_GENECODES_API;

export interface Panel {
    id_panels: number;
    name_panel: string;
    desc_long: string;
    desc_short: string;
    red_tag: string;
    green_tag: string;
    modality: string;
    panel_sex: string;
}

export const getPanels = async (): Promise<Panel[]> => {
    const response = await axios.get(`${API_BASE_URL}/panels`, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });

    return response.data;
};

export const getSamplesPanels = async (authUserId: number): Promise<number[]> => {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/samplePanels`,
            {
                authSampleId: authUserId,
            },
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get("apiKey")}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw new Error(`Error creating application: ${error}`);
    }
};

export const createOrUpdateSamplesPanels = async (authUserId: number, panelIds: number[]) => {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/createOrUpdatePanels`,
            {
                authSampleId: authUserId,
                panelIds: panelIds.length > 0 ? panelIds : [],
            },
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get("apiKey")}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw new Error(`Error creating application: ${error}`);
    }
};

/////////////////////////////////////////////////// Auth sample endpoints

export const createAuthSamples = async (userEmail: string, sampleId: string) => {
    try {
        const response = await axios.post(
            `${API_BASE_URL}/authSamples`,
            {
                auth_login: userEmail,
                sampleID: sampleId,
            },
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get("apiKey")}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw new Error(`Error creating application: ${error}`);
    }
};

export const updateAuthSampleDeactivationDate = async (authSampleId: number, date: Date) => {
    try {
        const response = await axios.put(
            `${API_BASE_URL}/authSamples/deactivateDate/${authSampleId}`,
            {
                deactivation_date: date.toISOString().split('T')[0],
            },
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get("apiKey")}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        throw new Error(`Error creating application: ${error}`);
    }
};

export const getAuthSampleDeactivationDate = async (authSampleId: number,) => {
    try {
        const response = await axios.get(
            `${API_BASE_URL}/authSamples/deactivateDate/${authSampleId}`,
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get("apiKey")}`,
                },
            }
        );
        return response.data.data;
    } catch (error) {
        throw new Error(`Error getting sample deactivaiton date: ${error}`);
    }
};

export const fetchPatientAuthSamples = async (patientEmail: string) => {
    const response = await axios.get(`${API_BASE_URL}/authSamples/user/${patientEmail}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data.data;
};

export const removePatientAuthSamples = async (authSampleId: number) => {
    const response = await axios.delete(`${API_BASE_URL}/authSamples/${authSampleId}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};

export const deactivatePatientAuthSamples = async (authSampleId: number) => {
    const response = await axios.put(`${API_BASE_URL}/authSamples/deactivate/${authSampleId}`, {},{
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};

export const activatePatientAuthSamples = async (authSampleId: number) => {
    const response = await axios.put(`${API_BASE_URL}/authSamples/activate/${authSampleId}`, {}, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};
