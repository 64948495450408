import React from "react";
import { User } from "../../../../api/admin/userApi";
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from "primereact/floatlabel";

interface PersonalTabProps {
	updatedUser: User;
	setUpdatedUser: React.Dispatch<React.SetStateAction<User>>;
	handleAutoSave: () => void;
}

const PersonalTab: React.FC<PersonalTabProps> = ({ updatedUser, setUpdatedUser,handleAutoSave }) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUpdatedUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	return (
		<div className="grid p-fluid formgrid">
			{/* Column 1 */}
			<div className="col-12 md:col-4 flex flex-column gap-3">
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="first_name" style={{ display: 'block', marginBottom: '0.5rem' }}>First Name</label>
						<InputText
							id="first_name"
							name="first_name"
							value={updatedUser.first_name || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							className="w-full border rounded"
						/>
					</FloatLabel>
				</div>
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="middle_name">Middle Name</label>
						<InputText
							id="middle_name"
							name="middle_name"
							value={updatedUser.middle_name || ""}
							onChange={handleChange}
							placeholder="Enter middle name"
						/>
					</FloatLabel>
				</div>
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="last_name" style={{ display: 'block', marginBottom: '0.5rem' }}>Last Name</label>
						<InputText
							id="last_name"
							name="last_name"
							value={updatedUser.last_name || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							className="w-full border rounded"
						/>
					</FloatLabel>
				</div>
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="email" style={{ display: 'block', marginBottom: '0.5rem' }}>Email</label>
						<InputText
							type="email"
							id="email"
							name="email"
							value={updatedUser.email || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							className="w-full border rounded"
						/>
					</FloatLabel>
				</div>
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="username" style={{ display: 'block', marginBottom: '0.5rem' }}>Username</label>
						<InputText
							id="username"
							name="username"
							value={updatedUser.username || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							className="w-full border rounded"
						/>
					</FloatLabel>
				</div>
				{updatedUser.id === 0 && (
					<>
						<div className="pt-3" >
							<FloatLabel>
								<label htmlFor="password" style={{ display: 'block', marginBottom: '0.5rem' }}>Password</label>
								<InputText
									type="password"
									id="password"
									name="password"
									value={updatedUser.password || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									className="w-full border rounded"
								/>
							</FloatLabel>
						</div>
						<div className="pt-3">
							<FloatLabel>
								<label htmlFor="password_confirmation" style={{ display: 'block', marginBottom: '0.5rem' }}>Password Confirmation</label>
								<InputText
									type="password"
									id="password_confirmation"
									name="password_confirmation"
									value={updatedUser.password_confirmation || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									className="w-full border rounded"
								/>
							</FloatLabel>
						</div>
					</>
				)}
			</div>

			{/* Column 2 */}
			<div className="col-12 md:col-4 flex flex-column gap-3">
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="address" style={{ display: 'block', marginBottom: '0.5rem' }}>Address</label>
						<InputText
							id="address"
							name="address"
							value={updatedUser.address || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							className="w-full border rounded"
						/>
					</FloatLabel>
				</div>
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="city" style={{ display: 'block', marginBottom: '0.5rem' }}>City</label>
						<InputText
							id="city"
							name="city"
							value={updatedUser.city || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							className="w-full border rounded"
						/>
					</FloatLabel>
				</div>
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="state" style={{ display: 'block', marginBottom: '0.5rem' }}>State</label>
						<InputText
							id="state"
							name="state"
							value={updatedUser.state || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							className="w-full border rounded"
						/>
					</FloatLabel>
				</div>
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="country" style={{ display: 'block', marginBottom: '0.5rem' }}>Country</label>
						<InputText
							id="country"
							name="country"
							value={updatedUser.country || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							className="w-full border rounded"
						/>
					</FloatLabel>
				</div>
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="postal_code" style={{ display: 'block', marginBottom: '0.5rem' }}>Post Code</label>
						<InputText
							id="postal_code"
							name="postal_code"
							value={updatedUser.postal_code || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							className="w-full border rounded"
						/>
					</FloatLabel>
				</div>
			</div>

			{/* Column 3 */}
			<div className="col-12 md:col-4 flex flex-column gap-3">
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="phone" style={{ display: 'block', marginBottom: '0.5rem' }}>Phone Number</label>
						<InputText
							id="phone"
							name="phone"
							value={updatedUser.phone || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							className="w-full border rounded"
						/>
					</FloatLabel>
				</div>
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="date_of_birth" style={{ display: 'block', marginBottom: '0.5rem' }}>Date of Birth</label>
						<InputText
							type="date"
							id="date_of_birth"
							name="date_of_birth"
							value={
								updatedUser.date_of_birth instanceof Date
									? updatedUser.date_of_birth.toISOString().substring(0, 10)
									: (updatedUser.date_of_birth || "")
							}
							onChange={handleChange}
							onBlur={handleAutoSave}
							className="w-full border rounded"
						/>
					</FloatLabel>
				</div>
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="gender" style={{ display: 'block', marginBottom: '0.5rem' }}>Gender</label>
						<InputText
							id="gender"
							name="gender"
							value={updatedUser.gender || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							className="w-full border rounded"
						/>
					</FloatLabel>
				</div>
				<div className="pt-3">
					<FloatLabel>
						<label htmlFor="sex" style={{ display: 'block', marginBottom: '0.5rem' }}>Sex</label>
						<InputText
							id="sex"
							name="sex"
							value={updatedUser.sex || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							className="w-full border rounded"
						/>
					</FloatLabel>
				</div>
			</div>
		</div >
	);
};

export default PersonalTab;
