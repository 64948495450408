import React, { useState } from 'react';
import Cookies from "js-cookie";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';


interface NavButtonsProps {
	createNewAppointment: (slotinfo: any) => void;
}

const NavButtons: React.FC<NavButtonsProps> = ({ createNewAppointment }) => {
	const [searchTerm, setSearchTerm] = useState("");

	return (
		<div className="flex align-items-center justify-content-between mb-3 gap-3">
			<div>
				<Button
					label="New Appointment"
					className="p-button-outlined"
					onClick={() => {createNewAppointment(null)}}
				/>
			</div>
			
			

			<div className="flex align-items-center gap-2">
				<InputText
					placeholder="Search"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
				<Button
					icon="pi pi-search"
					className="p-button-outlined"
				/>
			</div>

		</div>
	);
};

export default NavButtons;
