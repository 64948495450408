import React from "react";
import { Practitioner } from "../../../../api/healthcare/practitionerApi";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

interface AvailabilityTabProps {
	updatedPractitioner: Practitioner;
	setUpdatedPractitioner: React.Dispatch<React.SetStateAction<Practitioner>>;
}

interface BillingInformation {
	account_number: string;
	billing_address: string;
}

interface MalpracticeInsuranceDetails {
	provider: string;
	policy_number: string;
	coverage_amount: string;
}

const AvailabilityTab: React.FC<AvailabilityTabProps> = ({
	updatedPractitioner,
	setUpdatedPractitioner,
}) => {
	const daysOfWeek = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

	// Handler for simple input changes
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setUpdatedPractitioner((prev) => ({
			...prev,
			practitioner: {
				...prev.practitioner,
				[name]: value,
			},
		}));
	};

	// Handler for array inputs (comma-separated)
	const handleArrayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const arrayValues = value.split(",").map((item) => item.trim());
		setUpdatedPractitioner((prev) => ({
			...prev,
			practitioner: {
				...prev.practitioner,
				[name]: arrayValues,
			},
		}));
	};

	// Handler for availability schedule change
	const handleAvailabilityChange = (day: string, field: 'start' | 'end', value: string) => {
		setUpdatedPractitioner((prev) => {
			const prevSchedule = prev.practitioner?.availability_schedule || {};
			const daySchedule = prevSchedule[day] || {};
			const updatedDaySchedule = { ...daySchedule, [field]: value };
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					availability_schedule: {
						...prevSchedule,
						[day]: updatedDaySchedule
					}
				},
			};
		});
	};

	const handleBillingChange = (field: keyof BillingInformation, value: string) => {
		setUpdatedPractitioner((prev) => {
			const prevBilling = prev.practitioner?.billing_information || {};
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					billing_information: { ...prevBilling, [field]: value },
				},
			};
		});
	};

	const handleMalpracticeChange = (field: keyof MalpracticeInsuranceDetails, value: string) => {
		setUpdatedPractitioner((prev) => {
			const prevDetails = prev.practitioner?.malpractice_insurance_details || {};
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					malpractice_insurance_details: { ...prevDetails, [field]: value },
				},
			};
		});
	};

	return (
		<div className="grid formgrid p-fluid">
			{/* Column 1 */}
			<div className="field col-12 md:col-6">
				<label htmlFor="practicing_locations">Practicing Locations</label>
				<InputText
					id="practicing_locations"
					name="practicing_locations"
					value={updatedPractitioner.practitioner?.practicing_locations?.join(", ") || ""}
					onChange={handleArrayChange}
					placeholder="e.g., New York, Los Angeles"
				/>
			</div>

			<div className="field col-12 md:col-6">
				<label htmlFor="consultation_fee">Consultation Fee ($)</label>
				<InputText
					id="consultation_fee"
					name="consultation_fee"
					value={String(updatedPractitioner.practitioner?.consultation_fee ?? "")}
					onChange={handleInputChange}
					placeholder="Enter consultation fee"
				/>
			</div>

			{/* Availability Schedule ( spans entire row ) */}
			<div className="field col-12">
				<label>Availability Schedule</label>
				<div className="grid">
					{daysOfWeek.map((day) => {
						const daySchedule = updatedPractitioner.practitioner?.availability_schedule?.[day] || {};
						return (
							<div key={day} className="col-12 md:col-6 lg:col-4 p-2">
								<div className="font-semibold mb-2">{day}</div>
								<div className="flex gap-2">
									<InputText
										type="time"
										value={daySchedule.start || ""}
										onChange={(e) => handleAvailabilityChange(day, "start", e.target.value)}
										className="w-full"
									/>
									<span>to</span>
									<InputText
										type="time"
										value={daySchedule.end || ""}
										onChange={(e) => handleAvailabilityChange(day, "end", e.target.value)}
										className="w-full"
									/>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			{/* Billing Information */}
			<div className="field col-12">
				<label>Billing Information</label>
				<div className="grid">
					<div className="field col-12 md:col-6">
						<label htmlFor="account_number">Account Number</label>
						<InputText
							id="account_number"
							value={updatedPractitioner.practitioner?.billing_information?.account_number || ""}
							onChange={(e) => handleBillingChange("account_number", e.target.value)}
						/>
					</div>
					<div className="field col-12 md:col-6">
						<label htmlFor="billing_address">Billing Address</label>
						<InputText
							id="billing_address"
							value={updatedPractitioner.practitioner?.billing_information?.billing_address || ""}
							onChange={(e) => handleBillingChange("billing_address", e.target.value)}
						/>
					</div>
				</div>
			</div>

			{/* Malpractice Insurance Details */}
			<div className="field col-12">
				<label>Insurance Details</label>
				<div className="grid">
					<div className="field col-12 md:col-4">
						<label htmlFor="provider">Provider</label>
						<InputText
							id="provider"
							value={updatedPractitioner.practitioner?.malpractice_insurance_details?.provider || ""}
							onChange={(e) => handleMalpracticeChange("provider", e.target.value)}
						/>
					</div>
					<div className="field col-12 md:col-4">
						<label htmlFor="policy_number">Policy Number</label>
						<InputText
							id="policy_number"
							value={updatedPractitioner.practitioner?.malpractice_insurance_details?.policy_number || ""}
							onChange={(e) => handleMalpracticeChange("policy_number", e.target.value)}
						/>
					</div>
					<div className="field col-12 md:col-4">
						<label htmlFor="coverage_amount">Coverage Amount</label>
						<InputText
							id="coverage_amount"
							value={updatedPractitioner.practitioner?.malpractice_insurance_details?.coverage_amount || ""}
							onChange={(e) => handleMalpracticeChange("coverage_amount", e.target.value)}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AvailabilityTab;
