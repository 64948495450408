import React from 'react';
import './LoadingSpinner.css'; 

const LoadingSpinner = ({ size = 50, color = '#3498db' }) => {
  const spinnerStyle = {
    width: size,
    height: size,
    border: `${size / 10}px solid ${color}`,
    borderTop: `${size / 10}px solid transparent`,
  };

  return (
    <div className="spinner-container" aria-busy="true" aria-label="Loading">
      <div className="spinner" style={spinnerStyle}></div>
    </div>
  );
};

export default LoadingSpinner;
