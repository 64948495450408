/**
 * @file This file contains the appointment API functions.
 * @module api/appointmentApi
 * 
 * 
 */

import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";

const API_BASE_URL = process.env.REACT_APP_HEALTHCARE_SERVER;


export interface Appointment {
	id: number;
	patient_id: number;
	practitioner_id: number;
	company: string,
	date?: Date;
	start_time?: Date;
	end_time?: Date;
	status: string;
	created_at: Date;
	updated_at: Date;
	location: string;
	room_number: string;
	category: string;
	title: string;
}



export const fetchAppointments = async (company: string): Promise<{
	data: Appointment[];
}> => {
	const response = await axios.get(
		`${API_BASE_URL}/practitioner/appointments/${company}`,
		{
			headers: {
				Authorization: `Bearer ${Cookies.get("apiKey")}`,
			},
		}
	);

	return {
		data: response.data.data,
	};
};

export const fetchSingleAppointmentById = async (
	id: number
): Promise<{
	data: Appointment;
}> => {
	const response = await axios.get(`${API_BASE_URL}/practitioner/appointments/single/${id}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return {
		data: response.data,
	};
};

export const setNewAppointment = async (appointmentData: Partial<Appointment>): Promise<{
	data: Appointment;
	message: string;
}> => {
	const user = JSON.parse(Cookies.get("user") || "{}");
	const response = await axios.post(`${API_BASE_URL}/practitioner/appointments`, {
		appointmentData,
		user_id: user.uuid,
	}, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return {
		data: response.data.data,
		message: response.data.message,
	};
};

export const updateAppointment = async (appointmentData: Partial<Appointment>): Promise<{
	data: Appointment;
	message: string;
}> => {
	const user = JSON.parse(Cookies.get("user") || "{}");
	const response = await axios.put(`${API_BASE_URL}/practitioner/appointments/${appointmentData.id}`, {
		appointmentData,
		user_id: user.uuid,
	}, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return {
		data: response.data.data,
		message: response.data.message,
	};
};

export const deleteAppointment = async (appointmentId: number) => {
	const response = await axios.delete(`${API_BASE_URL}/practitioner/appointments/${appointmentId}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return response.data;
};
