// ActionButtons.tsx
import React, { useRef } from "react";
import { deleteBatch } from "../../../api/labiq/labiqApi";
import axios from "axios";

import { Button } from "primereact/button";

interface BatchManagementButtonsProps {
	selectedBatches: number[];
	refreshBatches: (isShowAll: boolean) => void;
	isShowAll: boolean;
	editClick: (batch_id: number) => void;
	newBatch: () => void;
}

const BatchManagementButtons: React.FC<BatchManagementButtonsProps> = ({ selectedBatches, refreshBatches, isShowAll, editClick, newBatch }) => {
	const toast = useRef<any>(null);

	const handleDeleteBatch = async () => {
		try{
			for (let i = 0; i < selectedBatches.length; i++) {
				
				await deleteBatch(selectedBatches[i]);

				toast.current?.show({
					severity: "success",
					summary: "Deleted",
					detail: "Batch Deleted",
					life: 3000
				});
			}
			refreshBatches(isShowAll);
		} catch (error) {
			console.error("Error deleting batch:", error);
			if (axios.isAxiosError(error)) {
				alert(`Error deleting batch: ${(error as any).response?.data?.error || (error as any).message}`);
			} else {
				alert(`Error deleting batch: ${error}`);
			}
		}

	};

	const handleViewHistory = () => {
		// Logic to view batch history
	};

	const handleExportToExcel = () => {
		// Logic to export table data to Excel
	};

	return (
		<div className="flex flex-wrap pb-4">
			<Button onClick={newBatch} label="New Batch" icon="pi pi-plus" className="p-button-primary mr-2"/>
			<Button onClick={() => editClick(selectedBatches[0])} disabled={selectedBatches.length !== 1} label="Edit Batch" icon="pi pi-pencil" className="p-button-success mr-2" />
			<Button onClick={handleDeleteBatch} disabled={selectedBatches.length === 0} label="Delete Batch" icon="pi pi-trash" className="p-button-danger" />
			
			{/* These buttons currently dont have a function */}
			{/* <button onClick={handleViewHistory} disabled={selectedBatches.length !== 1} className={`${selectedBatches.length !== 1 ? "bg-gray-300 text-gray-600 cursor-not-allowed" : "bg-purple-500 text-white hover:bg-purple-600"} px-4 py-2 rounded`}>
				View History
			</button>

			<button onClick={handleExportToExcel} className="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-600">
				Export to Excel
			</button> */}

		</div>
	);
};

export default BatchManagementButtons;
