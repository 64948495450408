import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import LoadingSpinner from '../../components/icons/LoadingSpinner';
import { fetchCompanies, Company } from "../../api/admin/companyApi";
import CompanyTable from "../../components/admin/company/CompanyTable";
import EditCompanyModal from "../../components/admin/company/EditCompanyModal";
import ActionButtons from "../../components/admin/company/ActionButtons";

const CompanyManagementPage: React.FC = () => {
	const [companies, setCompanies] = useState<Company[]>([]);
	const [totalCompanies, setTotalCompanies] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const COMPANIES_PER_PAGE = 30;

	useEffect(() => {
		loadCompanies();
		// eslint-disable-next-line
	}, [currentPage]);

	const loadCompanies = async (searchTerm: string = "") => {
		setIsLoading(true);
		try {
			const response = await fetchCompanies(currentPage, COMPANIES_PER_PAGE, searchTerm);
			if (response) {
				setCompanies(response.data);
				setTotalCompanies(response.total);
			}
		} catch (err) {
			console.error(err);
			setError("Failed to load companies.");
		} finally {
			setIsLoading(false);
		}
	};

	const searchCompanies = async (searchTerm: string) => {
		setCurrentPage(1); // Reset to page 1 when searching
		await loadCompanies(searchTerm);
	};

	const handleCreateClick = () => {
		const createEmptyCompany = (): Company => ({
			id: 0,
			name: "",
			address: "",
			email: "",
			phone: "",
			created_at: "",
			updated_at: ""
		});
		setSelectedCompany(createEmptyCompany());
		setIsModalOpen(true);
	};

	const handleEditClick = (company: Company) => {
		setSelectedCompany(company);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setSelectedCompany(null);
		setIsModalOpen(false);
	};

	const handleCompanyUpdate = async () => {
		// Refresh data after creating/updating a company
		await loadCompanies();
	};

	// Convert currentPage (1-based) to 'first' (0-based index for DataTable)
	const first = (currentPage - 1) * COMPANIES_PER_PAGE;

	// Handle page change from DataTable (server-side pagination)
	const onPage = (event: any) => {
		const newPage = event.page !== undefined ? event.page + 1 : 1;
		setCurrentPage(newPage);
	};

	return (
		<Layout>
			<div className="flex flex-column gap-3">
				<h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: 0 }}>Company Management</h1>
				{isLoading ? (
					<div className="flex justify-content-center align-items-center" style={{ height: '200px' }}>
						<LoadingSpinner size={60} color="#354396" />
					</div>
				) : (
					<div className="flex flex-column gap-2">
						<ActionButtons
							createCompany={handleCreateClick}
							searchCompanies={searchCompanies}
							reloadCompanies={() => loadCompanies()}
						/>
						<CompanyTable
							companies={companies}
							onEditClick={handleEditClick}
							first={first}
							rows={COMPANIES_PER_PAGE}
							totalRecords={totalCompanies}
							onPage={onPage}
						/>
					</div>
				)}
				{isModalOpen && (
					<EditCompanyModal
						isOpen={isModalOpen}
						onClose={handleCloseModal}
						company={selectedCompany}
						onCompanyUpdated={handleCompanyUpdate}
					/>
				)}
			</div>
		</Layout>
	);
};

export default CompanyManagementPage;
