// src/components/icons/DashboardIcon.tsx
import React from 'react';
import { MdDashboard } from 'react-icons/md'; // Importing Material Design dashboard icon

interface DashboardIconProps {
    className?: string;
}

const DashboardIcon: React.FC<DashboardIconProps> = ({ className }) => {
    return (
        <MdDashboard
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24} // Default size
            aria-label="Dashboard"
        />
    );
};

export default DashboardIcon;
