import React from 'react';
import { User } from "../../api/admin/userApi";

interface UserProfileCompanyProps {
    user: User;
    isEditing: boolean;
    setUserData: React.Dispatch<React.SetStateAction<User>>;
}

const UserProfileCompany: React.FC<UserProfileCompanyProps> = ({ user }) => {
    const fields = [
        { label: "Company", value: user.company },
        { label: "Company Code", value: user.company_code },
        { label: "Panel Key", value: user.panel_key },
        { label: "Reagents", value: user.reagents }
    ];

    return (
        <div>
            <h2 className="text-color" style={{ fontSize: '1.25rem', fontWeight: '500', marginBottom: '1rem' }}>Company Information</h2>
            <div className="grid formgrid p-fluid">
                {fields.map((field, idx) => (
                    <div key={idx} className="field col-12 md:col-6">
                        <span className="text-color-secondary" style={{ display: 'block', marginBottom: '0.5rem' }}>{field.label}</span>
                        <span className="text-color">{field.value || 'Not provided'}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserProfileCompany;
