// src/components/icons/SubscriptionsIcon.tsx
import React from 'react';
import { MdAutorenew } from 'react-icons/md'; // Importing Material Design subscription icon

interface SubscriptionsIconProps {
    className?: string;
}

const SubscriptionsIcon: React.FC<SubscriptionsIconProps> = ({ className }) => {
    return (
        <MdAutorenew
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24} // Default size
            aria-label="Subscription Management"
        />
    );
};

export default SubscriptionsIcon;
