// BatchTable.tsx

import React, { useRef } from "react";
import { DataTable, SortOrder } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { Batch } from "../../../api/labiq/labiqApi";
import Cookies from "js-cookie";

interface BatchTableProps {
	batches: Batch[];
	totalItems: number; // Total number of batches for pagination
	currentPage: number; // Current page index (zero-based)
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>; // Function to update current page
	selectedBatches: number[];
	setSelectedBatches: React.Dispatch<React.SetStateAction<number[]>>;
	onSort: (field: string, order: SortOrder) => void;
	sortBy: string;
	sortOrder: SortOrder;
	onEditBatch: (batchId: number) => void;
	itemsPerPage: number;
	onPageChange: (isShowAll: boolean) => void
}

const BatchTable: React.FC<BatchTableProps> = ({
	batches,
	totalItems,
	currentPage,
	setCurrentPage,
	selectedBatches,
	setSelectedBatches,
	onSort,
	sortBy,
	sortOrder,
	onEditBatch,
	itemsPerPage,
	onPageChange,
}) => {
	const toast = useRef<Toast>(null);
	const user = JSON.parse(Cookies.get("user") || "{}");

	// Handler for selection change via checkboxes
	const onSelectionChange = (e: any) => {
		const selected = e.value as Batch[];
		setSelectedBatches(selected.map((batch) => batch.batch_id));
	};

	// Handler for sorting
	const onSortHandler = (e: any) => {
		const field = e.sortField;
		const order = e.sortOrder === 1 ? 'asc' : 'desc';
		onSort(field, e.sortOrder);
	};



	// Helper function to determine if the click was on a checkbox
	const isClickOnCheckbox = (event: React.MouseEvent) => {
		const target = event.target as HTMLElement;
		return target.closest('.p-checkbox-box') !== null;
	};

	// Handler for row clicks
	const onRowClickHandler = (event: any) => {
		if (!isClickOnCheckbox(event.originalEvent)) {
			const selectedBatch: Batch = event.data;
			onEditBatch(selectedBatch.batch_id);
		}
	};

	return (
		<div className="card surface-0 border-round shadow-2 p-3">
			<Toast ref={toast} />

			<DataTable
				value={batches}
				selection={batches.filter((batch) =>
					selectedBatches.includes(batch.batch_id)
				)}
				onSelectionChange={onSelectionChange}
				selectionMode="checkbox"
				paginator
				lazy
				rows={30}
				totalRecords={totalItems}
				first={currentPage * 30}
				onPage={(e) => {
					setCurrentPage(e.page as number);
					onPageChange(false);
				}}
				sortField={sortBy}
				sortOrder={sortOrder}
				onSort={onSortHandler}
				onRowClick={onRowClickHandler}
				emptyMessage="No batches available."
				scrollable
				scrollHeight="70vh"
				className="p-datatable-gridlines p-datatable-sm cursor-pointer"
				globalFilter={null} 
				rowHover
			>
				<Column
					selectionMode="multiple"
					headerStyle={{ width: "3rem", textAlign: "center" }}
					bodyStyle={{ textAlign: "center" }}
					
				/>
				<Column field="batch_client_id" header="Client Batch ID" sortable />
				<Column
					field="batch_delivery_option"
					header="Delivery Option"
					sortable
					body={(rowData: Batch) =>
						rowData.batch_delivery_option === 0 ? "Internal" : "Courier"
					}
				/>
				<Column field="batch_create_date" header="Create Date" sortable />
				<Column
					field="batch_submission"
					header="Status"
					sortable
					body={(rowData: Batch) =>
						rowData.batch_submission === 0 ? "Draft" : "Submitted"
					}
				/>
				{(user.company === "DNAIQ" || user.company === "Mygene") && (

					<Column field="batch_user_id" header="User ID" sortable />
				)}
				{(user.company === "DNAIQ" || user.company === "Mygene") && (

					<Column field="batch_company" header="Company" sortable />
				)}
				
				<Column field="batch_sample_count" header="Sample Count" sortable />
			</DataTable>
		</div>
	);
};

export default BatchTable;
