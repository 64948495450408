// src/components/forms/BuilderCanvas.tsx
import React, { ChangeEvent } from 'react';
import { useDroppable, DndContext, DragEndEvent } from '@dnd-kit/core';
import {
	SortableContext,
	verticalListSortingStrategy,
	arrayMove,
	useSortable
} from '@dnd-kit/sortable';

// Api
import { FormElement } from '../../../../api/formApi';

// Prime react
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';

export const SortableItem: React.FC<{
	field: FormElement;
	children: React.ReactNode;
	onDeleteField: (fieldId: string | number) => void;
}> = ({ field, children, onDeleteField }) => {

	const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
		id: field.id,
		data: { from: 'canvas' },
	});

	const style: React.CSSProperties = {
		transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
		transition,
		opacity: isDragging ? 0.5 : 1,
	};

	return (
		// The outer div sets up the draggable item positioning & transformation
		<div ref={setNodeRef} style={style} {...attributes}>
			<Card
				title={field.label}
				// subTitle={field.type ? field.type.toUpperCase() : 'UNKNOWN'}
				className="mb-3"
				style={{ border: '1px solid #ddd', position: 'relative' }}
			>
				{/* Delete button */}
				<div style={{
					position: 'absolute',
					top: '-0.5rem',
					right: '2rem',
				}}>
					<Button
						//label="Remove"
						icon="pi pi-trash"
						className="p-button-danger p-button-text"
						onClick={() => onDeleteField(field.id)}
					/>
				</div>
				
				<div
					{...listeners}
					style={{
						position: 'absolute',
						top: '0.5rem',
						right: '0.5rem',
						cursor: 'grab'
					}}
				>
					<i className="pi pi-bars" />
				</div>

				{children}
			</Card>
		</div>
	);
};