// src/components/icons/GeneticSampleTrackingIcon.tsx
import React from 'react';
import { FaDna } from 'react-icons/fa'; // Importing the Font Awesome DNA icon

interface GeneticSampleTrackingIconProps {
    className?: string;
}

const GeneticSampleTrackingIcon: React.FC<GeneticSampleTrackingIconProps> = ({ className }) => {
    return (
        <FaDna
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24} // Default size
            aria-label="Genetic Sample Tracking"
        />
    );
};

export default GeneticSampleTrackingIcon;
