// src/components/icons/RoleManagementIcon.tsx
import React from 'react';
import { FaUserShield } from 'react-icons/fa'; // Importing Font Awesome role management icon

interface RoleManagementIconProps {
    className?: string;
}

const RoleManagementIcon: React.FC<RoleManagementIconProps> = ({ className }) => {
    return (
        <FaUserShield
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24} // Default size
            aria-label="Role Management"
        />
    );
};

export default RoleManagementIcon;
