import React from "react";
import { Company } from "../../../../api/admin/companyApi";

interface SidebarProps {
	activeTab: string;
	setActiveTab: (tab: string) => void;
	company: Company;
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab, company }) => {
	return (
		<div className="flex flex-column surface-100 text-color h-full" style={{ minWidth: '12rem' }}>
			{/* Profile Section */}
			<div className="flex flex-column align-items-center p-3">
				<div className="mt-2 font-medium text-center">
					{company.name || "New Company"}
				</div>
			</div>
			<hr className="my-2 mx-2 border-none border-top-1 surface-border" />

			{/* Navigation Links */}
			<div className="flex flex-column">
				<div
					className={
						"flex align-items-center p-2 text-color transition-colors transition-duration-200 border-round cursor-pointer" +
						(activeTab === "Details" ? " bg-primary-100" : " hover:surface-200")
					}
					style={{ gap: '0.5rem' }}
					onClick={() => setActiveTab("Details")}
				>
					<span className="ml-2">Company Info</span>
				</div>

				<div
					className={
						"flex align-items-center p-2 text-color transition-colors transition-duration-200 border-round cursor-pointer" +
						(activeTab === "Staff" ? " bg-primary-100" : " hover:surface-200")
					}
					style={{ gap: '0.5rem' }}
					onClick={() => setActiveTab("Staff")}
				>
					<span className="ml-2">Staff</span>
				</div>
			</div>
		</div>
	);
};

export default Sidebar;
