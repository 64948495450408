// src/components/forms/BuilderCanvas.tsx
import React, { ChangeEvent } from 'react';
import { useDroppable, DndContext, DragEndEvent } from '@dnd-kit/core';
import {
	SortableContext,
	verticalListSortingStrategy,
	arrayMove,
	useSortable
} from '@dnd-kit/sortable';

// Api
import { FormElement } from '../../../api/formApi';
import { SortableItem } from './elements/SortableItem';

// Prime react
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Chips } from 'primereact/chips';
import { Button } from 'primereact/button';


// Extend the props to accept a callback for updating a field
interface BuilderCanvasProps {
	formstructure: FormElement[];
	onUpdateField: (fieldId: string | number, newValues: Partial<FormElement>) => void;
	onDeleteField: (fieldId: string | number) => void;
	onReorderstructure: (newOrder: FormElement[]) => void;
}



export const BuilderCanvas: React.FC<BuilderCanvasProps> = ({
	formstructure,
	onUpdateField,
	onDeleteField,
	onReorderstructure
}) => {
	const { isOver, setNodeRef } = useDroppable({
		id: 'builder-canvas',
	});

	const canvasStyle: React.CSSProperties = {
		border: isOver ? '2px dashed #66bb6a' : '1px dashed #ccc',
		minHeight: '400px',
		padding: '1rem',
		transition: 'border 0.2s ease-in-out'
	};


	/**
	 * For text-like fields (text, email, phone, number, textarea), we
	 * allow the user to edit `placeholder` and `required`.
	 */
	const renderTextSettings = (field: FormElement) => {
		const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
			onUpdateField(field.id, { question: e.target.value });
		};

		const handlePlaceholderChange = (e: ChangeEvent<HTMLInputElement>) => {
			onUpdateField(field.id, { placeholder: e.target.value });
		};

		const handleRequiredChange = (e: any) => {
			onUpdateField(field.id, { required: e.checked });
		};

		return (
			<div className="p-fluid">
				<div className="field mb-2">
					<label htmlFor={`question-${field.id}`} className="block font-bold mb-1">
						Question
					</label>
					<InputText
						id={`question-${field.id}`}
						value={field.question || ''}
						onChange={handleQuestionChange}
						placeholder="Enter question"
						className="w-full"
					/>
				</div>

				<div className="field mb-2">
					<label htmlFor={`placeholder-${field.id}`} className="block font-bold mb-1">
						Placeholder
					</label>
					<InputText
						id={`placeholder-${field.id}`}
						value={field.placeholder || ''}
						onChange={handlePlaceholderChange}
						placeholder="Enter placeholder text"
						className="w-full"
					/>
				</div>

				<div className="field-checkbox">
					<Checkbox
						inputId={`required-${field.id}`}
						checked={field.required || false}
						onChange={handleRequiredChange}
					/>
					<label htmlFor={`required-${field.id}`}>Required?</label>
				</div>
			</div>
		);
	};

	/**
	 * For checkbox and radio fields, we allow editing their options and "required" property.
	 */
	const renderChoiceSettings = (field: FormElement) => {
		const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
			onUpdateField(field.id, { question: e.target.value });
		};

		const handleOptionsChange = (values: string[]) => {
			onUpdateField(field.id, { options: values });
		};

		const handleRequiredChange = (e: any) => {
			onUpdateField(field.id, { required: e.checked });
		};

		return (
			<div className="p-fluid">
				<div className="field mb-2">
					<label htmlFor={`question-${field.id}`} className="block font-bold mb-1">
						Question
					</label>
					<InputText
						id={`question-${field.id}`}
						value={field.question || ''}
						onChange={handleQuestionChange}
						placeholder="Enter question"
						className="w-full"
					/>
				</div>

				<div className="field mb-2">
					<label className="block font-bold mb-1">Options</label>
					<Chips
						value={field.options || []}
						onChange={(e) => handleOptionsChange(e.value as string[])}
						separator=","
						placeholder="Press enter after each option"
						className="w-full"
					/>
				</div>

				<div className="field-checkbox">
					<Checkbox
						inputId={`required-${field.id}`}
						checked={field.required || false}
						onChange={handleRequiredChange}
					/>
					<label htmlFor={`required-${field.id}`}>Required?</label>
				</div>
			</div>
		);
	};

	/**
	 * For checkbox and radio fields, we allow editing their options and "required" property.
	 */
	const renderCheckboxSettings = (field: FormElement) => {
		const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
			onUpdateField(field.id, { question: e.target.value });
		};


		const handleRequiredChange = (e: any) => {
			onUpdateField(field.id, { required: e.checked });
		};

		return (
			<div className="p-fluid">
				<div className="field mb-2">
					<label htmlFor={`question-${field.id}`} className="block font-bold mb-1">
						Question
					</label>
					<InputText
						id={`question-${field.id}`}
						value={field.question || ''}
						onChange={handleQuestionChange}
						placeholder="Enter question"
						className="w-full"
					/>
				</div>

				<div className="field-checkbox">
					<Checkbox
						inputId={`required-${field.id}`}
						checked={field.required || false}
						onChange={handleRequiredChange}
					/>
					<label htmlFor={`required-${field.id}`}>Required?</label>
				</div>
			</div>
		);
	};

	/**
	 * For select, we show "options", "placeholder", and "required".
	 */
	const renderSelectSettings = (field: FormElement) => {
		const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
			onUpdateField(field.id, { question: e.target.value });
		};

		const handleOptionsChange = (values: string[]) => {
			onUpdateField(field.id, { options: values });
		};

		const handlePlaceholderChange = (e: ChangeEvent<HTMLInputElement>) => {
			onUpdateField(field.id, { placeholder: e.target.value });
		};

		const handleRequiredChange = (e: any) => {
			onUpdateField(field.id, { required: e.checked });
		};

		return (
			<div className="p-fluid">
				<div className="field mb-2">
					<label htmlFor={`question-${field.id}`} className="block font-bold mb-1">
						Question
					</label>
					<InputText
						id={`question-${field.id}`}
						value={field.question || ''}
						onChange={handleQuestionChange}
						placeholder="Enter question"
						className="w-full"
					/>
				</div>

				<div className="field mb-2">
					<label className="block font-bold mb-1">Options</label>
					<Chips
						value={field.options || []}
						onChange={(e) => handleOptionsChange(e.value as string[])}
						separator=","
						placeholder="Press enter after each option"
						className="w-full"
					/>
				</div>

				<div className="field mb-2">
					<label htmlFor={`placeholder-${field.id}`} className="block font-bold mb-1">
						Placeholder
					</label>
					<InputText
						id={`placeholder-${field.id}`}
						value={field.placeholder || ''}
						onChange={handlePlaceholderChange}
						placeholder="Enter placeholder text"
						className="w-full"
					/>
				</div>

				<div className="field-checkbox">
					<Checkbox
						inputId={`required-${field.id}`}
						checked={field.required || false}
						onChange={handleRequiredChange}
					/>
					<label htmlFor={`required-${field.id}`}>Required?</label>
				</div>
			</div>
		);
	};

	/**
	 * For date/time/datetime, we can have placeholder + required.
	 * You could also add fields for minDate, maxDate, etc.
	 */
	const renderDateSettings = (field: FormElement) => {
		const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
			onUpdateField(field.id, { question: e.target.value });
		};

		const handlePlaceholderChange = (e: ChangeEvent<HTMLInputElement>) => {
			onUpdateField(field.id, { placeholder: e.target.value });
		};

		const handleRequiredChange = (e: any) => {
			onUpdateField(field.id, { required: e.checked });
		};

		return (
			<div className="p-fluid">
				<div className="field mb-2">
					<label htmlFor={`question-${field.id}`} className="block font-bold mb-1">
						Question
					</label>
					<InputText
						id={`question-${field.id}`}
						value={field.question || ''}
						onChange={handleQuestionChange}
						placeholder="Enter question"
						className="w-full"
					/>
				</div>

				<div className="field mb-2">
					<label htmlFor={`placeholder-${field.id}`} className="block font-bold mb-1">
						Placeholder
					</label>
					<InputText
						id={`placeholder-${field.id}`}
						value={field.placeholder || ''}
						onChange={handlePlaceholderChange}
						placeholder="Enter placeholder text"
						className="w-full"
					/>
				</div>

				<div className="field-checkbox">
					<Checkbox
						inputId={`required-${field.id}`}
						checked={field.required || false}
						onChange={handleRequiredChange}
					/>
					<label htmlFor={`required-${field.id}`}>Required?</label>
				</div>
			</div>
		);
	};

	/**
	 * For file, maybe we let user define allowed file types or a max file size, plus required.
	 */
	const renderFileSettings = (field: FormElement) => {
		const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
			onUpdateField(field.id, { question: e.target.value });
		};

		const handleOptionsChange = (values: string[]) => {
			// We can store allowed file types in .options
			onUpdateField(field.id, { options: values });
		};

		const handleRequiredChange = (e: any) => {
			onUpdateField(field.id, { required: e.checked });
		};

		return (
			<div className="p-fluid">
				<div className="field mb-2">
					<label htmlFor={`question-${field.id}`} className="block font-bold mb-1">
						Question
					</label>
					<InputText
						id={`question-${field.id}`}
						value={field.question || ''}
						onChange={handleQuestionChange}
						placeholder="Enter question"
						className="w-full"
					/>
				</div>

				<div className="field mb-2">
					<label className="block font-bold mb-1">Allowed File Types</label>
					<Chips
						value={field.options || []}
						onChange={(e) => handleOptionsChange(e.value as string[])}
						separator=","
						placeholder="e.g. pdf, jpg"
						className="w-full"
					/>
				</div>

				<div className="field-checkbox">
					<Checkbox
						inputId={`required-${field.id}`}
						checked={field.required || false}
						onChange={handleRequiredChange}
					/>
					<label htmlFor={`required-${field.id}`}>Required?</label>
				</div>
			</div>
		);
	};

	/**
	 * For signature field, just "required" for now. Could expand to color/stroke thickness, etc.
	 */
	const renderSignatureSettings = (field: FormElement) => {
		const handleQuestionChange = (e: ChangeEvent<HTMLInputElement>) => {
			onUpdateField(field.id, { question: e.target.value });
		};

		const handleRequiredChange = (e: any) => {
			onUpdateField(field.id, { required: e.checked });
		};

		return (
			<div className="p-fluid">
				<div className="field mb-2">
					<label htmlFor={`question-${field.id}`} className="block font-bold mb-1">
						Question
					</label>
					<InputText
						id={`question-${field.id}`}
						value={field.question || ''}
						onChange={handleQuestionChange}
						placeholder="Enter question"
						className="w-full"
					/>
				</div>

				<p className="mb-2">Signature Field</p>
				<div className="field-checkbox">
					<Checkbox
						inputId={`required-${field.id}`}
						checked={field.required || false}
						onChange={handleRequiredChange}
					/>
					<label htmlFor={`required-${field.id}`}>Required?</label>
				</div>
			</div>
		);
	};

	/**
	 * For heading/paragraph, you can store the text in `placeholder` or `label`.
	 */
	const renderHeadingParagraphSettings = (field: FormElement) => {


		const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => {
			// We'll update the placeholder for the text
			onUpdateField(field.id, { placeholder: e.target.value });
		};

		return (
			<div className="p-fluid">

				<div className="field mb-2">
					<label className="block font-bold mb-1">
						{field.type === 'heading' ? 'Heading Text' : 'Paragraph Text'}
					</label>
					<InputText
						value={field.placeholder || ''}
						onChange={handleTextChange}
						placeholder="Enter text"
						className="w-full"
					/>
				</div>
			</div>
		);
	};

	/**
	 * Decides which "render" function to call based on field.type
	 */
	const renderFieldSettings = (field: FormElement) => {
		switch (field.type) {
			case 'text':
			case 'email':
			case 'phone':
			case 'number':
			case 'textarea':
				return renderTextSettings(field);

			case 'checkbox':
				return renderCheckboxSettings(field);
			case 'radio':
				return renderChoiceSettings(field);

			case 'select':
				return renderSelectSettings(field);

			case 'date':
			case 'time':
			case 'datetime':
				return renderDateSettings(field);

			case 'file':
				return renderFileSettings(field);

			case 'signature':
				return renderSignatureSettings(field);

			case 'heading':
			case 'paragraph':
				return renderHeadingParagraphSettings(field);

			default:
				return <p>No additional settings for type "{field.type}".</p>;
		}
	};

	return (


		<SortableContext items={formstructure} strategy={verticalListSortingStrategy}>
			<div ref={setNodeRef} style={canvasStyle}>
				<h3 className="mb-3">Form Builder Canvas</h3>

				{formstructure.map((field) => (
					<SortableItem key={field.id} field={field} onDeleteField={onDeleteField}>

						{/* Render the field's editable settings */}
						{renderFieldSettings(field)}


					</SortableItem>
				))}
			</div>
		</SortableContext>

	);
};
