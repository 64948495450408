import React, { ChangeEvent, useState, useEffect } from "react";
import axios from "axios";
import { fetchReagentList, SampleFormData } from "../../../../api/labiq/labiqApi";
import { fetchCompanies, Company } from "../../../../api/admin/companyApi";

//Prime react
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Timeline } from "primereact/timeline"; 
import { Tooltip } from "primereact/tooltip";
import { SampleHistory } from "../../../../api/labiq/LabHistory";

interface SampleDetailsProps {
	formData: SampleFormData;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
	handleSelectChange: (e: ChangeEvent<HTMLSelectElement>) => void;
	setErrorMessages: React.Dispatch<React.SetStateAction<any[]>>;
	setFormData: React.Dispatch<React.SetStateAction<SampleFormData>>;
	companies: Company[];
	handleAutoSave: () => void;
	disabled: boolean;
}

const SampleDetails: React.FC<SampleDetailsProps> = ({
	formData,
	handleChange,
	handleSelectChange,
	setErrorMessages,
	setFormData,
	companies,
	handleAutoSave,
	disabled,
}) => {
	const [reagentList, setReagentList] = useState<string[]>([]);

	// Define options for Collection Type and Status
	const collectionTypes = [
		{ label: "Copan Swab", value: "copan swab" },
		{ label: "Buccal Swab", value: "buccal swab" },
		{ label: "DNAtape", value: "DNAtape" },
		{ label: "OraGene saliva", value: "OraGene saliva" },
	];

	const statusOptions = [
		{ label: "Dispatched To Client", value: "dispatched" },
		{ label: "Returning To Lab", value: "returning" },
		{ label: "Received At Lab", value: "received" },
		{ label: "Processing", value: "processing" },
		{ label: "Ready To Submit To AGRF", value: "submitAgrf" },
		{ label: "Process At AGRF", value: "processingAgrf" },
		{ label: "Data ready to upload", value: "uploadReady" },
		{ label: "On Hold", value: "hold" },
		{ label: "Complete", value: "complete" },
		{ label: "Destroy", value: "destroy" },
	];

	const deliveryVendorsOptions = [
		{ label: "FedEx", value: "fedex" },
		{ label: "UPS", value: "ups" },
		{ label: "Australia Post", value: "auspost" },
		{ label: "TNT", value: "tnt" },
	];

	// Initialize on the first render
	useEffect(() => {
		const fetchReagents = async () => {
			try {
				const reagents = await fetchReagentList();
				setReagentList(reagents);
			} catch (error: unknown) {
				if (axios.isAxiosError(error)) {
					if (error.response?.data?.errors) {
						const errorMessages = Object.entries(error.response.data.errors)
							.flatMap(([field, messages]) => messages);
						setErrorMessages(errorMessages); // State to store an array of error messages
					} else if (error.response?.data?.message) {
						setErrorMessages([error.response.data.message]); // Fallback to general error message
					} else {
						setErrorMessages(["An unknown error occurred."]);
					}
				} else if (error instanceof Error) {
					setErrorMessages([error.message || "An unknown error occurred."]);
				} else {
					setErrorMessages(["An unknown error occurred."]);
				}
			}
		};

		fetchReagents();
	}, [setErrorMessages]);

	// Prepare options for analysis_requested MultiSelect
	const analysisRequestedOptions = reagentList.map((reagent) => ({
		label: reagent,
		value: reagent,
	}));

	// Handler for Dropdown changes (single selection)
	const handleDropdownChange = (e: any) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	// Handler for MultiSelect changes (multiple selection)
	const handleMultiSelectChange = (e: any) => {
		const { value } = e.target;
		setFormData((prev) => ({
			...prev,
			analysis_requested: value,
		}));
	};



	return (
		<div className="grid card">
			<div className="col-12">
				<h4 className="m-0">Details</h4>
			</div>
			{/* Column 1 */}
			<div className="col-12 md:col-6 flex flex-column gap-3">
				{/* Client Sample ID */}
				<div className="p-2">

					<label htmlFor="sampleID" className="block text-sm mb-2">Sample ID</label>
					<InputText
						type="text"
						id="sampleID"
						name="sampleID"
						value={formData.sampleID?.toString() || ""}
						onChange={handleChange}
						onBlur={handleAutoSave}
						placeholder="Enter sample ID"
						className="w-full p-2 border rounded"
						required
						disabled={disabled}
					/>


				</div>

				{/* Client Selection using Dropdown */}
				{companies.length > 0 && (
					<div className="p-2">
						<label htmlFor="client" className="block text-sm mb-2">
							Client
						</label>
						<Dropdown
							id="client"
							name="client"
							value={formData.client || null}
							options={companies.map((company) => ({
								label: company.name,
								value: company.name,
							}))}
							onChange={handleDropdownChange}
							onBlur={handleAutoSave}
							placeholder="Select Client"
							className="w-full"
							required
							disabled={disabled}
						/>
					</div>
				)}

				{/* Analysis Requested using MultiSelect */}
				<div className="p-2">
					<label htmlFor="analysis_requested" className="block text-sm mb-2">
						Analysis Requested
					</label>
					<MultiSelect
						id="analysis_requested"
						name="analysis_requested"
						value={formData.analysis_requested || []}
						options={analysisRequestedOptions}
						onChange={handleMultiSelectChange}
						onBlur={handleAutoSave}
						placeholder="Select Reagents"
						className="w-full"
						display="chip"
						maxSelectedLabels={10}
						filter
						disabled={disabled}
					/>
				</div>

				<div className="p-2">

					<label htmlFor="tracking_number" className="block text-sm mb-2">
						Tracking Number
					</label>
					<InputText
						type="text"
						id="tracking_number"
						name="tracking_number"
						value={formData.tracking_number}
						onChange={handleChange}
						onBlur={handleAutoSave}
						placeholder="Enter tracking number"
						className="w-full"
						required
						disabled={disabled}
					/>

				</div>


			</div>

			{/* Column 2 */}
			<div className="col-12 md:col-6 flex flex-column gap-3">
				{/* Sample Type using Dropdown */}
				<div className="p-2">
					<label htmlFor="sampleType" className="block text-sm mb-2">
						Collection Type
					</label>
					<Dropdown
						id="sampleType"
						name="sampleType"
						value={formData.sampleType || null}
						options={collectionTypes}
						onChange={handleDropdownChange}
						onBlur={handleAutoSave}
						placeholder="Select Collection Type"
						className="w-full"
						required
						disabled={disabled}
					/>
				</div>

				{/* Status using Dropdown */}
				<div className="p-2">
					<label htmlFor="status" className="block text-sm mb-2">
						Status
					</label>
					<Dropdown
						id="status"
						name="status"
						value={(formData.status === undefined || null) ? null : formData.status}
						options={statusOptions}
						onChange={handleDropdownChange}
						onBlur={handleAutoSave}
						placeholder="Select Status"
						className="w-full"
						required
						disabled={disabled}
					/>
				</div>

				{/* Delivery Vendor using Dropdown */}
				<div className="p-2">
					<label htmlFor="status" className="block text-sm mb-2">
						Delivery Vendor
					</label>
					<Dropdown
						id="delivery_vendor"
						name="delivery_vendor"
						value={formData.delivery_vendor}
						options={deliveryVendorsOptions}
						onChange={handleDropdownChange}
						onBlur={handleAutoSave}
						className="w-full"
						required
						disabled={disabled}
						dropdownIcon={disabled}
						placeholder="Select Delivery Vendor"
					/>
				</div>
			</div>

		</div>
	);
};

export default SampleDetails;
