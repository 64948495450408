import React from "react";
import { Practitioner } from "../../../../api/healthcare/practitionerApi";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

interface EducationTabProps {
	updatedPractitioner: Practitioner;
	setUpdatedPractitioner: React.Dispatch<React.SetStateAction<Practitioner>>;
}

interface EducationEntry {
	degree: string;
	institution: string;
	year: string;
}

const EducationTab: React.FC<EducationTabProps> = ({
	updatedPractitioner,
	setUpdatedPractitioner,
}) => {

	const handleEducationChange = (index: number, field: keyof EducationEntry, value: string) => {
		setUpdatedPractitioner((prev) => {
			const prevEducation = prev.practitioner?.education_history || [];
			const updatedEducation = prevEducation.map((entry: EducationEntry, idx: number) => {
				if (idx === index) {
					return { ...entry, [field]: value };
				}
				return entry;
			});
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					education_history: updatedEducation,
				},
			};
		});
	};

	const addEducationEntry = () => {
		setUpdatedPractitioner((prev) => {
			const prevEducation = prev.practitioner?.education_history || [];
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					education_history: [...prevEducation, { degree: "", institution: "", year: "" }]
				},
			};
		});
	};

	const removeEducationEntry = (index: number) => {
		setUpdatedPractitioner((prev) => {
			const prevEducation = prev.practitioner?.education_history || [];
			// Ensure 'idx' is typed as number in the filter callback:
			const updatedEducation = prevEducation.filter((_: number, idx: number) => idx !== index);
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					education_history: updatedEducation,
				},
			};
		});
	};

	const educationHistory = updatedPractitioner.practitioner?.education_history || [];

	return (
		<div className="grid formgrid p-fluid">
			<div className="field col-12">
				<label>Education History</label>
				{educationHistory.map((entry: EducationEntry, index: number) => (
					<div key={index} className="border-round surface-100 p-3 mb-3">
						<div className="flex align-items-center justify-content-between mb-2">
							<span className="font-semibold">Education {index + 1}</span>
							<Button
								label="Remove"
								icon="pi pi-times"
								className="p-button-text p-button-danger"
								onClick={() => removeEducationEntry(index)}
							/>
						</div>
						<div className="field">
							<label htmlFor={`degree-${index}`}>Degree</label>
							<InputText
								id={`degree-${index}`}
								value={entry.degree}
								onChange={(e) => handleEducationChange(index, "degree", e.target.value)}
								className="w-full"
							/>
						</div>
						<div className="field">
							<label htmlFor={`institution-${index}`}>Institution</label>
							<InputText
								id={`institution-${index}`}
								value={entry.institution}
								onChange={(e) => handleEducationChange(index, "institution", e.target.value)}
								className="w-full"
							/>
						</div>
						<div className="field">
							<label htmlFor={`year-${index}`}>Year</label>
							<InputText
								id={`year-${index}`}
								value={entry.year}
								onChange={(e) => handleEducationChange(index, "year", e.target.value)}
								className="w-full"
							/>
						</div>
					</div>
				))}
				<Button
					label="Add Education Entry"
					icon="pi pi-plus"
					className="p-button-outlined p-button-success"
					onClick={addEducationEntry}
				/>
			</div>
		</div>
	);
};

export default EducationTab;
