import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import { getApplications, Application } from "../../api/admin/appApi";
import LoadingSpinner from '../../components/icons/LoadingSpinner';
import AppTable from "../../components/admin/application/AppTable";
import EditApplicationModal from "../../components/admin/application/EditAppModal";
import ApplicationButtons from "../../components/admin/application/AppButtons";

const ApplicationManagementPage: React.FC = () => {
	const [applications, setApplications] = useState<Application[]>([]);
	const [totalApplications, setTotalApplications] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedApplication, setSelectedApplication] = useState<Application | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const APPLICATIONS_PER_PAGE = 30;

	useEffect(() => {
		const loadApplications = async () => {
			setIsLoading(true);
			try {
				const { data, total } = await getApplications(currentPage, APPLICATIONS_PER_PAGE);
				setApplications(data);
				setTotalApplications(total);
				setError(null);
			} catch (err) {
				console.error("Error fetching applications:", err);
				setError("Failed to load applications.");
			} finally {
				setIsLoading(false);
			}
		};

		loadApplications();
	}, [currentPage]);

	const handleEditClick = (application: Application) => {
		setSelectedApplication(application);
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setSelectedApplication(null);
		setIsModalOpen(false);
	};

	const handleApplicationUpdate = async () => {
		try {
			setIsLoading(true);
			const { data, total } = await getApplications(currentPage, APPLICATIONS_PER_PAGE);
			setApplications(data);
			setTotalApplications(total);
			setError(null);
		} catch (err) {
			console.error("Error fetching updated applications:", err);
			setError("Failed to load updated applications.");
		} finally {
			setIsLoading(false);
			handleModalClose();
		}
	};

	// Convert currentPage (1-based) to first (0-based) for the DataTable
	const first = (currentPage - 1) * APPLICATIONS_PER_PAGE;

	const onPageChange = (event: any) => {
		const newPage = event.page !== undefined ? event.page + 1 : 1;
		setCurrentPage(newPage);
	};

	return (
		<Layout>
			<div className="flex flex-column gap-3">
				<h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: 0 }}>Application Management</h1>
				{isLoading ? (
					<div className="flex justify-content-center align-items-center" style={{ height: '200px' }}>
						<LoadingSpinner size={60} color="#354396" />
					</div>
				) : (
					<div className="flex flex-column gap-2">
						<ApplicationButtons />
						<AppTable
							apps={applications}
							onEditClick={handleEditClick}
							first={first}
							rows={APPLICATIONS_PER_PAGE}
							totalRecords={totalApplications}
							onPage={onPageChange}
						/>
					</div>
				)}
				{selectedApplication && (
					<EditApplicationModal
						isOpen={isModalOpen}
						onClose={handleModalClose}
						app={selectedApplication}
						onAppUpdated={handleApplicationUpdate}
					/>
				)}
			</div>
		</Layout>
	);
};

export default ApplicationManagementPage;
