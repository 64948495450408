import React, { useState } from "react";
import Cookies from "js-cookie";
import { Batch } from "../../../../api/labiq/labiqApi";
import { Company } from "../../../../api/admin/companyApi";

// Import PrimeReact components
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

interface BatchDetailsProps {
	companies: Company[];
	formData: Partial<Batch>;
	onChange: (field: string, value: any) => void;
	setErrorMessages: React.Dispatch<React.SetStateAction<any[]>>;
}

const BatchDetails: React.FC<BatchDetailsProps> = ({ formData, onChange, setErrorMessages, companies }) => {

	const deliverOptions = [
		{ label: "Internal", value: 0 },
		{ label: "Courier", value: 1 },
	];

	const deliveryVendorsOptions = [
		{ label: "FedEx", value: "fedex" },
		{ label: "UPS", value: "ups" },
		{ label: "Australia Post", value: "auspost" },
		{ label: "TNT", value: "tnt" },
	];

	// Generic handler for input changes
	const handleInputChange = (field: string, value: any) => {
		onChange(field, value);
	};


	return (
		<div className="grid p-fluid formgrid">
			{/* Batch Client ID */}
			<div className="col-4">
				<FloatLabel>
					<label htmlFor="batch_client_id" className="block text-sm">
						Batch ID
					</label>
					<InputText
						type="text"
						id="batch_client_id"
						name="batch_client_id"
						value={formData.batch_client_id || ""}
						onChange={(e) => handleInputChange('batch_client_id', e.target.value)}
						disabled={formData.batch_submission === 1}
						placeholder="Enter batch ID"
						className="w-full"
						required
					/>
				</FloatLabel>
			</div>

			{/* Company Selection */}
			{companies.length > 0 && (
				<div className="col-4">
					<FloatLabel>
						<label htmlFor="batch_company" className="block text-sm pl-2">
							Select Company
						</label>
						<Dropdown
							id="batch_company"
							name="batch_company"
							value={formData.batch_company || null}
							options={companies.map((company) => ({
								label: company.name,
								value: company.name,
							}))}
							onChange={(e) => handleInputChange('batch_company', e.value)}
							className="w-full"
							required
							disabled={formData.batch_submission === 1}
							placeholder="Select Company"
							dropdownIcon={formData.batch_submission === 1}
						/>
					</FloatLabel>
				</div>
			)}

			{/* Delivery Options */}
			<div className="col-4">
				<FloatLabel>
					
					<Dropdown
						id="batch_delivery_option"
						name="batch_delivery_option"
						value={formData.batch_delivery_option}
						options={deliverOptions}
						onChange={(e) => handleInputChange('batch_delivery_option', e.value)}
						className="w-full"
						required
						disabled={formData.batch_submission === 1}
						placeholder="Select Delivery Type"
						dropdownIcon={formData.batch_submission === 1}
					/>
					<label htmlFor="batch_delivery_option" className="block text-sm pl-2">
						Delivery Options
					</label>
				</FloatLabel>
			</div>

			{/* Tracking Number (Visible if Delivery Option is Courier) */}
			{formData.batch_delivery_option === 1 && (
				<div className="col-6 pt-4">
					<FloatLabel>
						<label htmlFor="batch_tracking_no" className="block text-sm">
							Tracking Number
						</label>
						<InputText
							type="text"
							id="batch_tracking_no"
							name="batch_tracking_no"
							value={formData.batch_tracking_no || ""}
							onChange={(e) => handleInputChange('batch_tracking_no', e.target.value)}
							placeholder="Enter tracking number"
							className="w-full"
							required
							disabled={formData.batch_submission === 1}
						/>
					</FloatLabel>
				</div>
			)}

			{/* Delivery Vendor (Visible if Delivery Option is Courier) */}
			{formData.batch_delivery_option === 1 && (
				<div className="col-6 pt-4">
					<FloatLabel>
						<label htmlFor="batch_vendor" className="block text-sm pl-2">
							Delivery Vendor
						</label>
						<Dropdown
							id="batch_vendor"
							name="batch_vendor"
							value={formData.batch_vendor || null}
							options={deliveryVendorsOptions}
							onChange={(e) => handleInputChange('batch_vendor', e.value)}
							className="w-full"
							required
							disabled={formData.batch_submission === 1}
							dropdownIcon={formData.batch_submission === 1}
							placeholder="Select Delivery Vendor"
						/>
					</FloatLabel>
				</div>
			)}

			
		</div>
	);
};

export default BatchDetails;
