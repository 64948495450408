import React, { useState, useEffect } from "react";
import debounce from 'lodash/debounce';

// prime reaact
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

// api and modal imports
import { Company, updateCompany, createCompany } from "../../../api/admin/companyApi";
import Sidebar from "./editCompanyModalComponents/Sidebar";
import DetailsTab from "./editCompanyModalComponents/DetailsTab";
import StaffTab from "./editCompanyModalComponents/StaffTab";

interface EditCompanyModalProps {
	isOpen: boolean;
	onClose: () => void;
	company: Company | null;
	onCompanyUpdated: () => void;
}

const EditCompanyModal: React.FC<EditCompanyModalProps> = ({
	isOpen,
	onClose,
	company,
	onCompanyUpdated
}) => {
	const [updatedCompany, setUpdatedCompany] = useState<Company>(
		company || {
			id: 0,
			name: "",
			address: "",
			email: "",
			phone: "",
			created_at: "",
			updated_at: ""
		}
	);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [error, setError] = useState<string>("");
	const [activeTab, setActiveTab] = useState("Details");

	useEffect(() => {
		if (company) {
			setUpdatedCompany({ ...company });
		}
	}, [company]);

	const updateCompanyInfo = async () => {
		if (!updatedCompany) return;
		setIsSubmitting(true);
		setError("");

		try {
			if (!updatedCompany.name || !updatedCompany.email) {
				setError("Name and email are required.");
				setIsSubmitting(false);
				return;
			}

			if (updatedCompany.id !== 0) {
				// Update existing company
				await updateCompany(updatedCompany.id, updatedCompany);
			} else {
				// Create a new company
				await createCompany(updatedCompany);
			}
			
		} catch (err) {
			console.error("Error updating company:", err);
			setError("Failed to update company.");
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleAutoSave = debounce(updateCompanyInfo, 500);

	const header = () => {
				return (
					<div className="flex justify-content-between">
						<div className="">{company?.id ? "Edit " + company?.name  : "Create New Company"}</div>
		
						<div>
							<Button icon="pi pi-save p-icon"
								rounded
								text aria-label="Save"
								loading={isSubmitting}
								disabled={isSubmitting}
								type="button"
								onClick={updateCompanyInfo}
								className="p-dialog-header-icon mr-2"
								style={{ color: 'var(--text-color-alternative)' }}
							/>
						</div>
		
					</div>
				)
		
			};

	return (
        <Dialog
            visible={isOpen}
            onHide={() => {onCompanyUpdated(); onClose();}}
            modal
            dismissableMask
            style={{ width: "80vw", height: "80vh" }}
            closable={true}
            draggable={true}
            maximizable={true}
            header={header}
            headerClassName="pb-0 h-auto bg-primary "
            contentStyle={{ padding: 0 }}
            className="custom-dialog"
        >
            <div className="flex w-full h-full" style={{ overflow: "hidden" }}>
                {/* Sidebar */}
                <div className="border-right-1 surface-border" style={{ overflowY: "auto" }}>
                    {updatedCompany && <Sidebar activeTab={activeTab} setActiveTab={setActiveTab} company={updatedCompany} />}
                </div>

                {/* Main Content */}
                <div className="flex flex-column p-4" style={{ flex: 1 }}>
                    <div className="flex-1 overflow-y-auto overflow-x-hidden">
                        {/* Render tabs based on activeTab */}
                        {activeTab === "Details" && updatedCompany && <DetailsTab company={updatedCompany} setUpdatedCompany={setUpdatedCompany} handleAutoSave={handleAutoSave}/>}
                        {activeTab === "Staff" && updatedCompany && <StaffTab company={updatedCompany} isSubmitting={isSubmitting} />}
                    </div>

                    {error && (
                        <p className="mt-3" style={{ color: "red" }}>
                            {error}
                        </p>
                    )}                    
                </div>
            </div>
        </Dialog>
    );
};

export default EditCompanyModal;
