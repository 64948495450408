import React, { useState } from 'react';
import { User } from "../../../api/admin/userApi";
import { Role } from "../../../api/admin/rolesApi";
import EditUserModal from "./EditUserModal";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';

const createEmptyUser = (): User => ({
	id: 0,
	uuid: "",
	username: "",
	email: "",
	password: "",
	password_confirmation: "",
	first_name: "",
	last_name: "",
	phone: "",
	address: "",
	city: "",
	state: "",
	country: "",
	postal_code: "",
	profile_picture: "",
	date_of_birth: new Date(),
	gender: "",
	sex: "",
	company: "",
	panel_key: "",
	company_code: "",
	reagents: "",
	preferences: "",
	is_active: false,
	created_at: new Date(),
	updated_at: new Date(),
	roles: []
});

interface UserButtonsProps {
	searchUsers: (search: string, role: string) => void;
	roles: Role[];
}

const UserButtons: React.FC<UserButtonsProps> = ({ searchUsers, roles }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [newUser, setSelectedUser] = useState<User>(createEmptyUser());
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [selectedRole, setSelectedRole] = useState('');

	const roleOptions = roles.map((role) => ({ label: role.name, value: role.name }));

	const handleRoleChange = (e: DropdownChangeEvent) => {
		const newRole = e.value;
		setSelectedRole(newRole);
		searchUsers(searchTerm, newRole);
	};

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newSearchTerm = e.target.value;
		setSearchTerm(newSearchTerm);
		searchUsers(newSearchTerm, selectedRole);
	};

	const reloadUsers = () => {
		setSearchTerm("");
		setSelectedRole("");
		searchUsers("", "");
	};

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	const handleCreateUser = () => {
		handleModalClose();
	};

	return (
		<div className="flex align-items-center justify-content-between gap-3">
			<Button label="Create User" onClick={handleOpenModal} className="p-button-outlined" />

			<div className="flex align-items-center gap-3">
				{/* Role dropdown */}
				<Dropdown
					value={selectedRole}
					options={roleOptions}
					onChange={handleRoleChange}
					placeholder="Select a role"
					className="w-12rem"
					showClear
				/>

				{/* Search input */}
				<span className="p-input-icon-left">

					<InputText
						type="text"
						
						placeholder="Search users..."
						value={searchTerm}
						onChange={handleSearchChange}
						className="w-12rem"
					/>
				</span>

				{/* Reset button */}
				<Button label="Reset" onClick={reloadUsers} className="p-button-outlined" />
			</div>

			{isModalOpen && (
				<EditUserModal
					isOpen={isModalOpen}
					onClose={handleModalClose}
					user={newUser}
					onUserUpdated={handleCreateUser}
				/>
			)}
		</div>
	);
};

export default UserButtons;
