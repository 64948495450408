import React, { useState, useEffect } from "react";
import axios from "axios";
import { Patient } from "../../../../api/healthcare/patientApi";
import { listUserFiles, downloadFile, deleteFile, FileMetadata } from "../../../../api/fileServiceApi";
import UploadFileModal from "./UploadFileModal";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProgressSpinner } from 'primereact/progressspinner';

interface FilesTabProps {
	patient: Patient;
	setErrorMessages: React.Dispatch<React.SetStateAction<any[]>>;
}

const FilesTab: React.FC<FilesTabProps> = ({ patient, setErrorMessages }) => {
	const [files, setFiles] = useState<FileMetadata[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

	useEffect(() => {
		const fetchFiles = async () => {
			setLoading(true);
			try {
				const response = await listUserFiles(patient.id);
				setFiles(response);
			} catch (error: unknown) {
				handleErrors(error);
			} finally {
				setLoading(false);
			}
		};
		fetchFiles();
		// eslint-disable-next-line
	}, [patient.id]);

	const handleErrors = (error: unknown) => {
		if (axios.isAxiosError(error) && error.response) {
			if (error.response.data?.errors) {
				const errorMessages = Object.entries(error.response.data.errors)
					.flatMap(([_, messages]) => messages as string);
				setErrorMessages(errorMessages);
			} else if (error.response.data?.message) {
				setErrorMessages([error.response.data.message]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} else if (error instanceof Error) {
			setErrorMessages([error.message]);
		} else {
			setErrorMessages(["An unknown error occurred."]);
		}
	};

	const handleOpenModal = () => {
		setShowUploadModal(true);
	};

	const handleCloseModal = () => {
		setShowUploadModal(false);
	};

	const handleFileUploaded = async (newFile: FileMetadata) => {
		try {
			const response = await listUserFiles(patient.id);
			setFiles(response);
		} catch (error: unknown) {
			handleErrors(error);
		} finally {
			handleCloseModal();
		}
	};

	const handleDeleteFile = async (fileId: string) => {
		try {
			await deleteFile(fileId, patient.id);
			const response = await listUserFiles(patient.id);
			setFiles(response);
		} catch (error: unknown) {
			handleErrors(error);
		}
	};

	const handleDownloadFile = async (fileId: string) => {
		try {
			await downloadFile(patient.id, fileId);
		} catch (error: unknown) {
			handleErrors(error);
		}
	};

	const formatFileSize = (bytes: number) => {
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes === 0) return '0 Bytes';
		const i = Math.floor(Math.log(bytes) / Math.log(1024));
		const fileSize = (bytes / Math.pow(1024, i)).toFixed(2);
		return `${fileSize} ${sizes[i]}`;
	};

	// Body templates for DataTable
	const fileNameBody = (rowData: FileMetadata) => rowData.file_name;
	const fileTypeBody = (rowData: FileMetadata) => rowData.file_type;
	const dateBody = (rowData: FileMetadata) => {
		if (rowData.created_at instanceof Date) {
			return rowData.created_at.toISOString().substring(0, 10);
		}
		if (typeof rowData.created_at === 'string') {
			return rowData.created_at.substring(0, 10);
		}
		return '';
	};

	const sizeBody = (rowData: FileMetadata) => formatFileSize(rowData.file_size);

	const actionBody = (rowData: FileMetadata) => (
		<>
			<Button
				label="Download"
				icon="pi pi-download"
				className="p-button-text p-button-success mr-2"
				onClick={() => handleDownloadFile(rowData.id)}
			/>
			<Button
				label="Delete"
				icon="pi pi-trash"
				className="p-button-text p-button-danger"
				onClick={() => handleDeleteFile(rowData.id)}
			/>
		</>
	);

	return (
		<div className="flex flex-column gap-3 p-3">
			<div className="flex align-items-center justify-content-between mb-3">
				<h2 style={{ fontSize: '1.25rem', margin: 0 }}>Patient Files</h2>
				<Button label="Upload File" icon="pi pi-upload" onClick={handleOpenModal} />
			</div>

			{loading ? (
				<div className="flex align-items-center justify-content-center" style={{ height: '200px' }}>
					<ProgressSpinner />
				</div>
			) : files && files.length > 0 ? (
				<div className="card surface-0 border-round shadow-2 p-3">
					<DataTable
						value={files}
						className="p-datatable-gridlines p-datatable-sm"
						responsiveLayout="scroll"
					>
						<Column field="file_name" header="File Name" body={fileNameBody} />
						<Column field="file_type" header="Type" body={fileTypeBody} />
						<Column header="Date" body={dateBody} />
						<Column header="Size" body={sizeBody} />
						<Column header="Actions" body={actionBody} style={{ minWidth: '8rem' }} />
					</DataTable>
				</div>
			) : (
				<p>No files found for this patient.</p>
			)}

			{showUploadModal && (
				<UploadFileModal
					patientId={patient.id}
					onClose={handleCloseModal}
					onFileUploaded={handleFileUploaded}
				/>
			)}
		</div>
	);
};

export default FilesTab;
