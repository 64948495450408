import axios, { AxiosInstance } from "axios";
import { UUID } from "crypto";
import Cookies from "js-cookie";
export const API_BASE_URL = process.env.REACT_APP_NOTIFICATION_BASE_URL;
const user = JSON.parse(Cookies.get("user") || "{}");

export interface SampleHistory {
	id: number,
	sample_id: string,
    company: string,
    user_uuid : UUID,
    sample_status : string,
    update_details : UpdateDetails,
	created_at: Date,
	updated_at: Date
}

interface DiffValue {
	new: any;
	old: any;
}

export interface UpdateDetails {
	sample?: Record<string, DiffValue>;
	sample_oa?: Record<string, DiffValue>;
	analysis_requested?: DiffValue;
	"0"?: any[]; 
	[key: string]: any;
}

export const fetchSampleHistory = async (id: string): Promise<SampleHistory[]> => {
    const response = await axios.get(`${API_BASE_URL}/api/audit/sample-history/get-sample`, {
		params: {
			sampleID: id,
		},
        headers: {
			
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data.data;
};