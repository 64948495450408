import React from "react";
import { User } from "../../../../api/admin/userApi";

interface SidebarProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
    user: User;
}

const tabs = [
    { label: 'Personal Info', value: 'Personal' },
    { label: 'Company Info', value: 'Company' },
    { label: 'Preferences', value: 'Preferences' },
    { label: 'Roles', value: 'Roles' },
    { label: 'Subscriptions', value: 'Subscriptions' },
	{ label: 'Samples', value: 'Samples' }
];

const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab, user }) => {
    return (
        <div className="flex flex-column justify-content-between surface-100 text-color" style={{ minWidth: '16rem', overflowY: 'auto' }}>
            <div className="mt-3">
                {/* Profile Section */}
                <div className="flex flex-column align-items-center p-3">
                    <img
                        className="border-circle"
                        src={user.profile_picture || "/assets/images/default-profile.png"}
                        alt={`${user.first_name} ${user.last_name}`}
                        style={{ width: '8rem', height: '8rem', objectFit: 'cover' }}
                    />
                    <div className="mt-2 font-medium text-center">
                        {user.first_name} {user.last_name}
                    </div>
                </div>
                <hr className="my-3 mx-2 border-none border-top-1 surface-border" />

                {/* Tabs Navigation */}
                <div className="flex flex-column">
                    {tabs.map((tab) => (
                        <div
                            key={tab.value}
                            className={
                                "flex align-items-center p-2 text-color transition-colors transition-duration-200 border-round cursor-pointer" +
                                (activeTab === tab.value ? " bg-primary-100" : " hover:surface-200")
                            }
                            style={{ textDecoration: 'none', gap: '0.5rem' }}
                            onClick={() => setActiveTab(tab.value)}
                        >
                            <span className="ml-3">{tab.label}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
