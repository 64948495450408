import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";
import { User } from "../admin/userApi";
const API_BASE_URL = process.env.REACT_APP_LABIQ_SERVER;
const user = JSON.parse(Cookies.get("user") || "{}");

//interfaces from labiq
export interface Sample {
    id: number;
    sampleID: string;
    client: string;
	is_active?: boolean;
	authSampleId?: number;
    created_at: string;
    updated_at: string;
    sampleOA: SampleOA | null;
    qsBases: QSBase[];
}

export interface SampleOA {
    sampleID: number;
    client: string;
    jobID: number | null;
    client_runID: string | null;
    client_sampleID: string;
    job_sequence: number | null;
    sampleType: string | null;
    QC_date: string | null;
    protocol: string | null;
    concentration: number | null;
    OD: number | null;
    OD2: number | null;
    eProtocol: string | null;
    eP_concentration: number | null;
    eP_OD: number | null;
    eP_OD2: number | null;
    dProtocol: string | null;
    gel_pic: string | null;
    gel_lane: string | null;
    OA_date: string | null;
    OA_plate: string | null;
    OA_call_rate: number | null;
    status: string | null;
    recollect_notice: string | null;
    reported: boolean | null;
    reported_date: string | null;
    plated_state: string | null;
    analysis_requested: string[];
    turnaround: string | null;
    date_expected: string | null;
    volume: number | null;
    storage: string | null;
    comments: string | null;
    QC_notice: string | null;
    create_date: string | null;
    lab_location: string | null;
    available_material: string | null;
    batch_id: number | null;
    delivery: string | null;
    tracking_number: string | null;
    delivery_vendor: string | null;
    batch: any | null;
}

export const defaultSampleOA: SampleOA = {
    sampleID: 0, // Assign a default or meaningful initial value
    client: '',
    jobID: null,
    client_runID: null,
    client_sampleID: '',
    job_sequence: null,
    sampleType: null,
    QC_date: null,
    protocol: null,
    concentration: null,
    OD: null,
    OD2: null,
    eProtocol: null,
    eP_concentration: null,
    eP_OD: null,
    eP_OD2: null,
    dProtocol: null,
    gel_pic: null,
    gel_lane: null,
    OA_date: null,
    OA_plate: null,
    OA_call_rate: null,
    status: null,
    recollect_notice: null,
    reported: null,
    reported_date: null,
    plated_state: null,
    analysis_requested: [],
    turnaround: null,
    date_expected: null,
    volume: null,
    storage: null,
    comments: null,
    QC_notice: null,
    create_date: null,
    lab_location: null,
    available_material: null,
    batch_id: null,
    delivery: null,
    tracking_number: null,
    delivery_vendor: null,
    batch: null,
};


export interface QSBase {
    QS_baseID: number;
    sampleID: string;
    PlateID: string | null;
    geneID: string | null;
    rs: string | null;
    taqmanID: string | null;
    A1: string | null;
    A2: string | null;
    created_on: string; 
}

export interface SampleFormData {
    // Sample fields
    id?: number;
    sampleID?: string | number;
    client?: string;

    // SampleOA fields
    jobID?: number | null;
    client_runID?: string | null;
    client_sampleID?: string;
    job_sequence?: number | null;
    sampleType?: string | null;
    QC_date?: string | null;
    protocol?: string | null;
    concentration?: number | null;
    OD?: number | null;
    OD2?: number | null;
    eProtocol?: string | null;
    eP_concentration?: number | null;
    eP_OD?: number | null;
    eP_OD2?: number | null;
    dProtocol?: string | null;
    gel_pic?: string | null;
    gel_lane?: string | null;
    OA_date?: string | null;
    OA_plate?: string | null;
    OA_call_rate?: number | null;
    status?: string | null;
    recollect_notice?: string | null;
    reported?: boolean | null;
    reported_date?: string | null;
    plated_state?: string | null;
    analysis_requested?: string[];
    turnaround?: string | null;
    date_expected?: string | null;
    volume?: number | null;
    storage?: string | null;
    comments?: string | null;
    QC_notice?: string | null;
    create_date?: string | null;
    lab_location?: string | null;
    available_material?: string | null;
    batch_id?: number | null;
    delivery?: string | null;
    tracking_number?: string | null;
    delivery_vendor?: string | null;
}

export interface Batch{
	batch_id: number;
	batch_client_id: string;
	batch_delivery_option: number;
	batch_tracking_no: string;
	batch_vendor: string;
	batch_create_date: string;
	batch_submission: number;
	batch_user_id: string;
	batch_company: string;
	batch_sample_count: number;
	samples: BatchSample[];
}

export interface BatchSample{
	bs_id : number,
	bs_sample_id : string,
	bs_sequence : string,
	bs_test_type : string[],
	bs_collection_type : string,
	bs_batch_id : number
}

////////////////////////// sample management api calls
export const fetchSamples = async (
    page: number,
    perPage: number,
    company?: string,
    sortBy: string = "sampleID",
    sortOrder: string = "desc",
    search?: string
): Promise<{
    data: Sample[];
    total: number;
}> => {
    const response = await axios.post(
        `${API_BASE_URL}/getSamples`,
        {},
        {
            params: {
                page,
                per_page: perPage,
                company,
                sort_by: sortBy,
                sort_order: sortOrder,
                search: search,
            },
            headers: {
                Authorization: `Bearer ${Cookies.get("apiKey")}`,
            },
        }
    );

    return {
        data: response.data.data,
        total: response.data.total,
    };
};

export const fetchSample = async (id: string): Promise<Sample> => {
    const response = await axios.get<Sample>(`${API_BASE_URL}/sample/${id}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};


// Update an existing sample
export const updateSample = async (id: number, sampleData: SampleFormData) => {
	
    await axios.put(
        `${API_BASE_URL}/sample/${id}`,
        {
            sample: sampleData,
            username: user.username,
			company: user.company,
			user_id: user.uuid,
        },
        {
            headers: {
                Authorization: `Bearer ${Cookies.get("apiKey")}`,
            },
        }
    );
};

// Request a new analysis on an existing sample
export const NewAnalysisRequest = async (id: number, analysis: string[]): Promise<Sample> => {
    const response = await axios.put(
        `${API_BASE_URL}/sample/analysis/${id}`,
        {
            analysis_requested: analysis,
            username: user.username,
			company: user.company,
			user_id: user.uuid,
        },
        {
            headers: {
                Authorization: `Bearer ${Cookies.get("apiKey")}`,
            },
        }
    );

	return response.data as Sample;
};

// Create a new sample
export const createSample = async (sampleData: SampleFormData) => {
    const response = await axios.post(
        `${API_BASE_URL}/sample`,
        {
            sample: sampleData,
            username: user.username,
			company: user.company,
			user_id: user.uuid,
        },
        {
            headers: {
                Authorization: `Bearer ${Cookies.get("apiKey")}`,
            },
        }
    );
    return response.data.sample;
};



export const deleteSample = async (sampleID: number) => {
    
        await axios.delete(`${API_BASE_URL}/sample/${sampleID}`, {
            headers: {
                Authorization: `Bearer ${Cookies.get("apiKey")}`,
            },
        });
    
};


//company and reagent list

export const fetchReagentList = async (company?: string): Promise<string[]> => {
    let url = `${API_BASE_URL}/getRegent`;
    if (company) {
        url += `?company=${encodeURIComponent(company)}`;
    } else {
        url += "?full=true";
    }
    const response = await axios.get<{ results: string[] }>(url, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data.results;
};

//  omnibead upload functions

export const uploadOmnibeadData = async (formData: FormData) => {
    const response = await axios.post(`${API_BASE_URL}/omnibead/upload`, formData, {
        headers: {
            // Remove 'Content-Type' header
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};

export const importOmnibeadData = async (data: { omnibead_batchID: string; }) => {
   
        const response = await axios.post(`${API_BASE_URL}/omnibead/import`, data, {
            headers: {
                Authorization: `Bearer ${Cookies.get("apiKey")}`,
            },
        });
        return response.data;
    
};

export const translateOmnibeadData = async (data: { omnibead_batchID: string; translation_mode: string; }) => {
    
        const response = await axios.post(`${API_BASE_URL}/omnibead/translate`, data, {
            headers: {
                Authorization: `Bearer ${Cookies.get("apiKey")}`,
            },
        });
        return response.data;
    
};



////////////////////////// batch management api calls
export const fetchBatches = async (
	page: number,
	perPage: number,
	company?: string,
	sortBy: string = "batch_id",
	sortOrder: string = "desc" 
): Promise<{
	data: Batch[];
	total: number;
}> => {
    const response = await axios.get(
        `${API_BASE_URL}/batches`,
        {
            params: {
                page,
                per_page: perPage,
                company,
                sort_by: sortBy,
                sort_order: sortOrder,
            },
            headers: {
                Authorization: `Bearer ${Cookies.get("apiKey")}`,
            },
        }
    );

	return {
		data: response.data.data,
		total: response.data.total,
	};
};

export const fetchBatch = async (batch_id: number): Promise<{Batch: Batch; Samples: Sample[]}> => {
	const response = await axios.get(`${API_BASE_URL}/batches/${batch_id}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	let Batch = response.data.batch;
	let Samples = response.data.samples
	return {
		Batch,
		Samples
	};
};

export const createBatch = async (batch: Batch) => {
	await axios.post(
		`${API_BASE_URL}/batches`,
		{
			batch_client_id: batch.batch_client_id,
			batch_delivery_option: batch.batch_delivery_option ,
			batch_tracking_no: batch.batch_tracking_no || "",
			batch_vendor: batch.batch_vendor || "",
			user_id: user.id,
			batch_company: batch.batch_company,
			samples: batch.samples || [],
		},
		{
			headers: {
				Authorization: `Bearer ${Cookies.get("apiKey")}`,
			},
		}
	);
	return batch;
};

export const updateBatch = async (batch_id: number, batch: Batch) => {
	await axios.put(
		`${API_BASE_URL}/batches/${batch_id}`,
		{
			batch_client_id: batch.batch_client_id,
			batch_delivery_option: batch.batch_delivery_option !== null ? batch.batch_delivery_option : "",
			batch_tracking_no: batch.batch_tracking_no || "",
			batch_vendor: batch.batch_vendor || "",
			user_id: user.id,
			batch_company: batch.batch_company,
			samples: batch.samples || [],
		},
		{
			headers: {
				Authorization: `Bearer ${Cookies.get("apiKey")}`,
			},
		}
	);
	return batch;
};

export const submitBatch = async (batch_id: number) => {
	await axios.post(
		`${API_BASE_URL}/batches/${batch_id}/submit`,
		{
			user_id: user.uuid,
		},
		{
			headers: {
				Authorization: `Bearer ${Cookies.get("apiKey")}`,
			},
		}
	);
};

export const deleteBatch = async (batch_id: number) => {
	await axios.delete(`${API_BASE_URL}/batches/${batch_id}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
};



//Get data for the dashboard
export const getDashboardData = async (
	company: string,
	start: Date,
	end: Date
) => {
	// Convert Date objects to strings (e.g., 'YYYY-MM-DD')
	const startDateStr = start.toISOString().split("T")[0];
	const endDateStr = end.toISOString().split("T")[0];
	const response = await axios.get(`${API_BASE_URL}/dashboard`, {
		params: {
			company,
			start: startDateStr,
			end: endDateStr,
		},
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});

	return {
		totals: [response.data.samples, response.data.batches],
	};
};