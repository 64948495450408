import React, { useState } from "react";
import { User, assignUserRole, removeUserRole } from "../../../../api/admin/userApi";
import { Role } from "../../../../api/admin/rolesApi";
import {
    updatePractitioner,
    Practitioner,
    defaultPractitionerInfo,
} from "../../../../api/healthcare/practitionerApi";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";

interface RolesTabProps {
    updatedUser: User;
    setUpdatedUser: React.Dispatch<React.SetStateAction<User>>;
    roles: Role[];
	handleAutoSave: () => void;
}

const RolesTab: React.FC<RolesTabProps> = ({ updatedUser, setUpdatedUser, roles, handleAutoSave }) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    const handleRoleChange = async (e: CheckboxChangeEvent, role: Role) => {
        setIsSubmitting(true);

        const isChecked = e.checked;

        try {
            let updatedUserData = { ...updatedUser };

            if (isChecked) {
                // Assign the role
                await assignUserRole(updatedUser.id, role.name);
                updatedUserData.roles = [...updatedUser.roles, role];

                // Handle Practitioner-specific logic
                if (role.name === "Practitioner") {
                    let updatedPractitioner: Practitioner = updatedUser as Practitioner;
                    updatedPractitioner = {
                        ...updatedUser,
                        practitioner: updatedPractitioner?.practitioner || defaultPractitionerInfo,
                    };
                    await updatePractitioner(updatedUser.id, updatedPractitioner);
                }
            } else {
                // Remove the role
                const updatedRoles = updatedUser.roles.filter((userRole) => userRole.name !== role.name);
                await removeUserRole(updatedUser.id, role.name);
                updatedUserData.roles = updatedRoles;
            }

            setUpdatedUser(updatedUserData);
        } catch (err) {
            console.error("Error updating user:", err);
            setError("Failed to update user.");
        } finally {
            setIsSubmitting(false);
        }
    };

	return (
		<div className="grid p-fluid justify-content-center">
			{roles?.map((role) => (
				<div key={role.id} className="field-checkbox col-12 w-6">
					<Checkbox
						inputId={role.id.toString()}
						name={role.name}
						onChange={(e) => handleRoleChange(e, role)}
						onBlur={handleAutoSave}
						checked={updatedUser.roles?.some(
							(userRole) => userRole.name === role.name
						) ?? false}
						disabled={isSubmitting}
					/>
					<label htmlFor={role.id.toString()}>
						{`${role.name} (${role.guard_name})`}
					</label>
				</div>
			))}
			{error && <p className="text-red-500 mt-2">{error}</p>}
		</div>
	);
	  
};

export default RolesTab;
