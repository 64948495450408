import React, { useState } from "react";
import Cookies from "js-cookie";
import { NavLink, Link, useLocation } from "react-router-dom";

//Utils
import {hasRole} from "../utils/validatePermissions";

// Icons
import UsersIcon from "./icons/mainSidebar/UsersIcon";
import ApplicationsIcon from "./icons/mainSidebar/ApplicationsIcon";
import SubscriptionsIcon from "./icons/mainSidebar/SubscriptionsIcon";
import PlansIcon from "./icons/mainSidebar/PlansIcon";
import SettingsIcon from "./icons/mainSidebar/SettingsIcon";
import DashboardIcon from "./icons/mainSidebar/DashboardIcon";
import LogoutIcon from "./icons/mainSidebar/LogoutIcon";
import RoleManagementIcon from "./icons/mainSidebar/RoleManagementIcon";
import PatientManagementIcon from "./icons/healthcare/PatientsIcon";
import GeneticSampleTrackingIcon from "./icons/labiq/SampleManagementIcon";
import BatchManagementIcon from "./icons/labiq/BatchManagementIcon";
import OrdersIcon from "./icons/inventory/OrdersIcon";
import SuppliersIcon from "./icons/inventory/SuppliersIcon";
import ProductsIcon from "./icons/inventory/ProductsIcon";
import CompanyIcon from "./icons/mainSidebar/CompanyIcon";
import HealthcareProfessionalIcon from "./icons/healthcare/HealthcareProfessionals";
import AppointmentIcon from "./icons/healthcare/AppointmentsIcon";
import FormIcon from "./icons/mainSidebar/FormIcon";

const Sidebar: React.FC = () => {
	const [dropdowns, setDropdowns] = useState<{ [key: string]: boolean }>({});
	const location = useLocation();

	// Retrieve the user object
	const user = JSON.parse(Cookies.get("user") || "{}");

	const toggleDropdown = (section: string) => {
		setDropdowns((prev) => ({
			...prev,
			[section]: !prev[section],
		}));
	};

	const sectionRoutes: { [key: string]: string[] } = {
		adminManagement: ["/users", "/roles", "/applications", "/subscriptions", "/plans", "/companies"],
		healthcare: ["/healthcare/patients", "/healthcare/professionals", "/healthcare/Appointments", "/healthcare/forms"],
		labiq: ["/labiq/sample-tracking", "/labiq/batch-management"],
		inventory: ["/inventory/products", "/inventory/ordering", "/inventory/suppliers", "/inventory/kits", "/inventory/stock/"],
	};

	const isDropdownOpen = (section: string) => {
		return dropdowns[section] || sectionRoutes[section]?.some((route) => location.pathname.startsWith(route));
	};


	return (
		<div className="flex flex-column justify-content-between surface-100 text-color min-h-screen"  >
			<div className="mt-3">
				{/* Profile Section */}
				<div className="flex flex-column align-items-center p-3">
					<Link to="/profile" className="text-color hover:text-primary transition-duration-200">
						<img
							className="border-circle"
							src={user.profile_picture || "/assets/images/default-profile.png"}
							alt={`${user.first_name} ${user.last_name}`}
							style={{ width: '8rem', height: '8rem', objectFit: 'cover' }}
						/>
						<div className="mt-2 font-medium text-center">
							{user.first_name} {user.last_name}
						</div>
					</Link>
				</div>
				<hr className="my-3 mx-2 border-none border-top-1 surface-border" />

				<NavItem to="/" icon={<DashboardIcon className="w-2 h-2" />} label="Dashboard" />

				{/* Admin Management */}
				{hasRole(["User Management"]) && (
					<DropdownSection
						label="Admin Management"
						isOpen={isDropdownOpen("adminManagement")}
						onToggle={() => toggleDropdown("adminManagement")}
					>
						<NavItem to="/users" icon={<UsersIcon className="w-2 h-2" />} label="User Management" />
						<NavItem to="/companies" icon={<CompanyIcon className="w-2 h-2" />} label="Company Management" />
						<NavItem to="/roles" icon={<RoleManagementIcon className="w-2 h-2" />} label="Role Management" />
						<NavItem to="/applications" icon={<ApplicationsIcon className="w-2 h-2" />} label="Application Management" />
						<NavItem to="/subscriptions" icon={<SubscriptionsIcon className="w-2 h-2" />} label="Subscription Management" />
						<NavItem to="/plans" icon={<PlansIcon className="w-2 h-2" />} label="Plan Management" />
					</DropdownSection>
				)}

				{/* Healthcare */}
				{hasRole(["Practitioner"]) && (
					<DropdownSection
						label="Healthcare"
						isOpen={isDropdownOpen("healthcare")}
						onToggle={() => toggleDropdown("healthcare")}
					>
						<NavItem to="/healthcare/patients" icon={<PatientManagementIcon className="w-2 h-2" />} label="Patients" />
						<NavItem to="/healthcare/professionals" icon={<HealthcareProfessionalIcon className="w-2 h-2" />} label="Healthcare Professionals" />

						{/* currently in dev so not showing to normal users */}
						{hasRole(["admin"]) && (
							<div>
								<NavItem to="/healthcare/Appointments" icon={<AppointmentIcon className="w-2 h-2" />} label="Appointments" />
								<NavItem to="/healthcare/forms" icon={<FormIcon className="w-2 h-2" />} label="Forms" />
							</div>
						)}

					</DropdownSection>
				)}

				{/* LabIQ */}
				{hasRole(["Lab Management", "Practitioner"]) && (
								

					<DropdownSection
						label="LabIQ"
						isOpen={isDropdownOpen("labiq")}
						onToggle={() => toggleDropdown("labiq")}
					>
						<NavItem to="/labiq/sample-tracking" icon={<GeneticSampleTrackingIcon className="w-2 h-2" />} label="Sample Tracking" />
						<NavItem to="/labiq/batch-management" icon={<BatchManagementIcon className="w-2 h-2" />} label="Batch Management" />
					</DropdownSection>
				)}

				{/* Inventory */}
				{hasRole(["Inventory Management"]) && (
					<DropdownSection
						label="Inventory"
						isOpen={isDropdownOpen("inventory")}
						onToggle={() => toggleDropdown("inventory")}
					>
						<NavItem to="/inventory/products" icon={<ProductsIcon className="w-2 h-2" />} label="Products" />
						{(user.company === "Mygene" || user.company === "DNAIQ") && (
							<NavItem to="/inventory/kits" icon={<ProductsIcon className="w-2 h-2" />} label="Kits" />
						)}
						<NavItem to="/inventory/ordering" icon={<OrdersIcon className="w-2 h-2" />} label="Ordering" />
						<NavItem to="/inventory/suppliers" icon={<SuppliersIcon className="w-2 h-2" />} label="Suppliers" />
					</DropdownSection>
				)}
			</div>

			<div className="mb-3">
				<NavItem to="/settings" icon={<SettingsIcon className="w-2 h-2" />} label="Settings" />
				<NavItem to="/logout" icon={<LogoutIcon className="w-2 h-2" />} label="Logout" />
			</div>
		</div>
	);
};

interface NavItemProps {
	to: string;
	icon: React.ReactNode;
	label: string;
}

const NavItem: React.FC<NavItemProps> = ({ to, icon, label }) => {
	return (
		<NavLink
			to={to}
			className={({ isActive }) =>
				"flex align-items-center p-2 text-color transition-colors transition-duration-200 border-round cursor-pointer" +
				(isActive ? " bg-primary-100" : " hover:surface-200")
			}
			style={{ textDecoration: 'none' }}
		>
			{icon}
			<span className="ml-3">{label}</span>
		</NavLink>
	);
};

interface DropdownSectionProps {
	label: string;
	isOpen: boolean;
	onToggle: () => void;
	children: React.ReactNode;
}

const DropdownSection: React.FC<DropdownSectionProps> = ({ label, isOpen, onToggle, children }) => {
	return (
		<div className="mt-3">
			<div
				className="flex align-items-center text-color-secondary text-xs uppercase font-bold ml-3 cursor-pointer hover:text-color transition-duration-200"
				onClick={onToggle}
				style={{ gap: '0.5rem' }}
			>
				{label}
				<i className={`pi pi-chevron-right transition-duration-200 ${isOpen ? 'rotate-90' : ''}`} />
			</div>
			<div
				className="overflow-hidden transition-all transition-duration-200 ml-3"
				style={{ maxHeight: isOpen ? '500px' : '0', opacity: isOpen ? 1 : 0 }}
			>
				{children}
			</div>
		</div>
	);
};

export default Sidebar;
