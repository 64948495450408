// src/components/forms/DraggableElement.tsx
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDraggable } from '@dnd-kit/core';
import { FormElement } from '../../../../api/formApi';

interface DraggableElementProps {
	element: Omit<FormElement, 'id'>;
	draggableId: string;
}

export const DraggableElement: React.FC<DraggableElementProps> = ({ element, draggableId }) => {
	const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
		id: draggableId, 
		data: {
			from: 'toolbox', 
			type: element.type,
			label: element.label,
			options: element.options || [],
		},
	});

	const style: React.CSSProperties = {
		transform: transform
			? `translate3d(${transform.x}px, ${transform.y}px, 0)`
			: undefined,
		opacity: isDragging ? 0.5 : 1,
		cursor: 'grab',
		marginBottom: '8px',
		padding: '8px',
		border: '1px solid #999',
		borderRadius: '4px',
		backgroundColor: '#f7f7f7',
		zIndex: 9999,
	};

	return (
		<div
			ref={setNodeRef}
			style={style}
			{...listeners}
			{...attributes}
		>
			{element.label}
		</div>
	);
};
