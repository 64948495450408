// src/api/purchaseOrderApi.ts

import axios from "axios";
import Cookies from "js-cookie";
const API_BASE_URL = process.env.REACT_APP_COMMERCE_SERVER;

export interface PurchaseOrderItem {
    purchase_order_item_id?: number;
    product_id: number;
    quantity: number;
    unit_cost: number;
    notes?: string;
}

export interface PurchaseOrder {
    purchase_order_id?: number;
    supplier_id: number;
    order_date?: string;
    status?: string;
    total_amount?: number;
    notes?: string;
    items?: PurchaseOrderItem[];
}

export const fetchPurchaseOrders = async (params?: any): Promise<{ data: PurchaseOrder[]; total: number }> => {
    const response = await axios.get(`${API_BASE_URL}/purchase-orders`, {
        params,
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return { data: response.data.data, total: response.data.total };
};

export const fetchPurchaseOrder = async (id: number): Promise<PurchaseOrder> => {
    const response = await axios.get(`${API_BASE_URL}/purchase-orders/${id}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};

export const createPurchaseOrder = async (orderData: PurchaseOrder): Promise<PurchaseOrder> => {
    const response = await axios.post(`${API_BASE_URL}/purchase-orders`, orderData, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};

export const updatePurchaseOrder = async (id: number, orderData: Partial<PurchaseOrder>): Promise<PurchaseOrder> => {
    const response = await axios.put(`${API_BASE_URL}/purchase-orders/${id}`, orderData, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};

export const deletePurchaseOrder = async (id: number): Promise<void> => {
    await axios.delete(`${API_BASE_URL}/purchase-orders/${id}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
};
