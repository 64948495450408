// src/pages/suppliers/SupplierManagementPage.tsx

import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import SupplierTable from "../../components/inventory/supplier/SupplierTable";
import Pagination from "../../components/Pagination";
import SupplierModal from "../../components/inventory/supplier/SupplierModal";
import { Supplier, fetchSuppliers, createSupplier, updateSupplier, deleteSupplier } from "../../api/inventory/supplierApi";

const SupplierManagementPage: React.FC = () => {
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const [totalSuppliers, setTotalSuppliers] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const SUPPLIERS_PER_PAGE = 20;

    useEffect(() => {
        loadSuppliers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, searchTerm]);

    const loadSuppliers = async () => {
        setIsLoading(true);
        try {
            const { data, total } = await fetchSuppliers(currentPage, SUPPLIERS_PER_PAGE, searchTerm);
            setSuppliers(data);
            setTotalSuppliers(total);
        } catch (error) {
            console.error("Error fetching suppliers:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddSupplier = () => {
        setSelectedSupplier(null);
        setIsModalOpen(true);
    };

    const handleEditSupplier = (supplier: Supplier) => {
        setSelectedSupplier(supplier);
        setIsModalOpen(true);
    };

    const handleDeleteSupplier = async (supplier: Supplier) => {
        if (window.confirm(`Are you sure you want to delete supplier "${supplier.name}"?`)) {
            try {
                await deleteSupplier(supplier.supplier_id!);
                loadSuppliers();
            } catch (error) {
                console.error("Error deleting supplier:", error);
            }
        }
    };

    const handleModalClose = () => {
        setSelectedSupplier(null);
        setIsModalOpen(false);
    };

    const handleSupplierSave = async (supplierData: Supplier) => {
        try {
            if (selectedSupplier) {
                await updateSupplier(selectedSupplier.supplier_id!, supplierData);
            } else {
                await createSupplier(supplierData);
            }
            loadSuppliers();
            handleModalClose();
        } catch (error) {
            console.error("Error saving supplier:", error);
        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    return (
        <Layout>
            <div className="flex justify-between items-center mb-4">
                <h1 className="text-2xl font-bold">Supplier Management</h1>
                <button onClick={handleAddSupplier} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    Add Supplier
                </button>
            </div>
            <div className="mb-4">
                <input type="text" placeholder="Search suppliers..." value={searchTerm} onChange={handleSearchChange} className="border px-3 py-2 rounded w-full" />
            </div>
            {isLoading ? (
                <p>Loading suppliers...</p>
            ) : (
                <>
                    <SupplierTable suppliers={suppliers} onEdit={handleEditSupplier} onDelete={handleDeleteSupplier} />
                    <Pagination currentPage={currentPage} totalItems={totalSuppliers} itemsPerPage={SUPPLIERS_PER_PAGE} onPageChange={setCurrentPage} />
                </>
            )}
            {isModalOpen && <SupplierModal isOpen={isModalOpen} onClose={handleModalClose} supplier={selectedSupplier} onSave={handleSupplierSave} />}
        </Layout>
    );
};

export default SupplierManagementPage;
