import React, { useState } from 'react';
import { Application } from '../../../api/admin/appApi';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

interface AppTableProps {
	apps: Application[];
	onEditClick: (app: Application) => void;
	first: number;
	rows: number;
	totalRecords: number;
	onPage: (event: any) => void;
}

const AppTable: React.FC<AppTableProps> = ({ apps, onEditClick, first, rows, totalRecords, onPage }) => {
	const [selectedApp, setSelectedApp] = useState<Application | null>(null);

	if (!apps || apps.length === 0) {
		return <p>No apps available.</p>;
	}

	// Render the logo in a column
	const logoBodyTemplate = (rowData: Application) => {
		return (
			<img
				src={rowData.logo}
				alt={`${rowData.name} Logo`}
				style={{ width: '50px', height: '50px', objectFit: 'contain' }}
			/>
		);
	};

	// Render the action button
	const actionBodyTemplate = (rowData: Application) => {
		return (
			<Button
				label="Edit"
				icon="pi pi-pencil"
				onClick={() => onEditClick(rowData)}
				className="p-button-text p-button-sm"
			/>
		);
	};

	return (
		<div className="card surface-0 border-round shadow-2 p-3">
			<DataTable
				value={apps}
				selectionMode="single"
				selection={selectedApp}
				onSelectionChange={(e) => setSelectedApp(e.value as Application)}
				paginator
				lazy
				first={first}
				rows={rows}
				totalRecords={totalRecords}
				onPage={onPage}
				className="p-datatable-gridlines p-datatable-sm"
				responsiveLayout="scroll"
			>
				<Column field="id" header="ID" style={{ minWidth: '3rem' }} />
				<Column header="Logo" body={logoBodyTemplate} style={{ minWidth: '6rem' }} />
				<Column field="name" header="Name" style={{ minWidth: '10rem' }} />
				<Column field="description" header="Description" style={{ minWidth: '12rem' }} />
				<Column header="Actions" body={actionBodyTemplate} style={{ minWidth: '6rem' }} />
			</DataTable>
		</div>
	);
};

export default AppTable;
