//get the env variables
import axios from 'axios';
import Cookies from "js-cookie";
import { User } from '../api/admin/userApi';
import { responsiveArray } from 'antd/es/_util/responsiveObserver';

export const API_BASE_URL = process.env.REACT_APP_NOTIFICATION_BASE_URL;

export interface Notification {
    id: string;
    user_id: string;
    type: 'alert' | 'info' | 'error';
    title: string;
    message: string;
    related_entity_id?: string;
    read_status: boolean;
	status: string;
    created_at: string;
    updated_at: string;
}

export interface NotificationEvent {
    notification: Notification;
}

export interface NotificationsResponse extends Array<Notification> { }

const user: User = JSON.parse(Cookies.get("user") || "{}")
	const userId = user.uuid;

export const fetchNotifications = async () => {
	try {
		const token = Cookies.get('apiKey');
		const response = await axios.get<NotificationsResponse>(`${API_BASE_URL}/api/notifications`, {
			params: { user_id: userId },
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return(response.data);
	} catch (error) {
		console.error('Error fetching notifications:', error);
	}
};

export const markAsRead = async (id: string) => {
	try {
		const token = Cookies.get('apiKey');
		const response = await axios.patch(`${API_BASE_URL}/api/notifications/${id}`, {
			read_status: true,
			status: "seen"
		}, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.data;
	} catch (error) {
		console.error('Error marking notification as read:', error);
	}
};

export const updateStatus = async (id: string, status: string) => {
	try {
		const token = Cookies.get('apiKey');
		const response = await axios.put(`${API_BASE_URL}/api/notifications/status`, {
			id,
			status
		}, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.data;
	} catch (error) {
		console.error('Error marking notification as read:', error);
	}
};

export const deleteNotification = async (id: string) => {
	try {
		const token = Cookies.get('apiKey');
		const response = await axios.delete(`${API_BASE_URL}/api/notifications/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.data;
	} catch (error) {
		console.error('Error marking notification as read:', error);
	}
};