// src/pages/inventory/StockAssignmentPage.tsx

import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import Layout from "../../components/Layout";
import StockAssignmentTable from "../../components/inventory/stockAssignment/StockAssignmentTable";
import Pagination from "../../components/Pagination";
import StockAssignmentModal from "../../components/inventory/stockAssignment/StockAssignmentModal";
import { StockAssignment, fetchStockAssignments, createStockAssignment, updateStockAssignment, deleteStockAssignment, fetchStockAssignment } from "../../api/inventory/stockAssignmentApi";
import { fetchStockEntry, Stock } from "../../api/inventory/stockApi";
import { fetchPatient } from "../../api/healthcare/patientApi";
import { fetchSingleCompany } from "../../api/admin/companyApi";
import {assignBatchToCompany, unassignBatchFromCompany} from "../../api/inventory/kitApi";
import {fetchProduct, Product} from "../../api/inventory/productApi";



const StockAssignmentPage: React.FC = () => {
	const { stockId } = useParams<{ stockId: string }>();
	const [assignments, setAssignments] = useState<StockAssignment[]>([]);
	const [totalAssignments, setTotalAssignments] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedAssignment, setSelectedAssignment] = useState<StockAssignment | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [stock, setStock] = useState<Stock | null>(null);
	const [isKit, setIsKit] = useState<boolean>(false);

	const ASSIGNMENTS_PER_PAGE = 20;
	const stockIdNumber = stockId ? parseInt(stockId, 10) : 0;
	const user = JSON.parse(sessionStorage.getItem('user') || '{}');

	useEffect(() => {

		loadStock();
		loadAssignments();
		
	}, [currentPage]);

	const loadStock = async () => {
		try {
			const stockData = await fetchStockEntry(stockIdNumber);
			let product:Product = await fetchProduct(stockData.product_id)
			setIsKit(product.is_kit);
			setStock(stockData);
		} catch (error) {
			console.error("Error fetching stock:", error);
		}
	};

	const loadAssignments = async () => {
		setIsLoading(true);
		try {
			const { data, total } = await fetchStockAssignments(currentPage, ASSIGNMENTS_PER_PAGE, {
				stock_id: stockIdNumber,
			});
			if (data) {
				for (const item of data) {
					if (item.assignee_type === "user") {
						try {
							const user = await fetchPatient(item.assignee_id);
							item.assignee_id = user.data.first_name + " " + user.data.last_name;
						} catch (error) {
							console.error("Error fetching user:", error);
						}
					}
					if (item.assignee_type === "company") {
						try {
							const company = await fetchSingleCompany(item.assignee_id);
							item.assignee_id = company.name;
						} catch (error) {
							console.error("Error fetching user:", error);
						}
					}
				}
			}

			setAssignments(data);
			setTotalAssignments(total);
		} catch (error) {
			console.error("Error fetching stock assignments:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleEditClick = (assignment: StockAssignment) => {
		setSelectedAssignment(assignment);
		setIsModalOpen(true);
	};

	const handleDeleteClick = async (assignment: StockAssignment) => {
		if (window.confirm(`Are you sure you want to delete the assignment with ID "${assignment.assignment_id}"?`)) {
			//if the assignment is on a kit then remove it from the kit assignment section
			if(isKit){
				//need to re get the assignment for asignnee id
				let ass:StockAssignment = await fetchStockAssignment(assignment.assignment_id)
				unassignBatchFromCompany(assignment.quantity_assigned, ass.assignee_id, assignment.stock_id)
			}
			
			try {
				await deleteStockAssignment(assignment.assignment_id);
				loadAssignments();
			} catch (error) {
				console.error("Error deleting assignment:", error);
			}
		}
	};

	const handleModalClose = () => {
		setSelectedAssignment(null);
		setIsModalOpen(false);
	};

	//add in if the save is for a kit or note to test saving the ids to a company
	const handleAssignmentSave = async (assignmentData: StockAssignment) => {
		try {
			if (selectedAssignment?.assignment_id !== 0 && selectedAssignment) {
				// Update assignment
				await updateStockAssignment(selectedAssignment.assignment_id, assignmentData);
			} else {
				let companyName = "";
				if (assignmentData.assignee_type === "company") {
					const company = await fetchSingleCompany(assignmentData.assignee_id);
					companyName = company.name;
				}
				const { newStock, assignment } = await createStockAssignment(assignmentData, companyName);
				//now assign the kit ids to the company if the stock assignment is for a kit
				if(isKit){
					assignBatchToCompany(assignmentData.quantity_assigned, assignmentData.assignee_id, assignment.stock_id, newStock.stock_id)
				}
			}
			loadAssignments();
			handleModalClose();
		} catch (error) {
			console.error("Error saving assignment:", error);
		}
	};

	const handleAddAssignment = () => {
		let newAssignment: StockAssignment = {
			stock_id : stockIdNumber,
			assignee_id: "0",
			assignee_type: "company",
			quantity_assigned: 0,
			assignment_id: 0,
		}
		setSelectedAssignment(newAssignment);
		setIsModalOpen(true);
	};

	return (

		<Layout>
			{isLoading ? (
				<p>Loading assignments...</p>
			) : (
				<div>
					<div className="flex justify-between items-center mb-4">
						<h1 className="text-2xl font-bold">Stock Assignments {stock ? `for Stock ID ${stock.stock_id}` : ""}</h1>
						<Link to={`/inventory/products/${stock?.product_id}/stock`} className="text-blue-500 hover:underline">
							Back to Stock Entries
						</Link>
					</div>
					<div className="mb-4">
						{(user.company === "Mygene" || user.company === "DNAIQ") &&(
							<button onClick={handleAddAssignment} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
								Add Assignment
							</button>
						)}
						
					</div>
				
					<StockAssignmentTable assignments={assignments} onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} />
					<Pagination currentPage={currentPage} totalItems={totalAssignments} itemsPerPage={ASSIGNMENTS_PER_PAGE} onPageChange={setCurrentPage} />
					
				</div>
			)}
			{(isModalOpen && stock) && (<StockAssignmentModal isOpen={isModalOpen} onClose={handleModalClose} assignment={selectedAssignment} setAssignment={setSelectedAssignment} onSave={handleAssignmentSave} stock={stock} />)}
		</Layout>
	);
};

export default StockAssignmentPage;
