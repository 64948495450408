// src/components/inventory/StockTable.tsx

import React from "react";
import { useNavigate } from 'react-router-dom';
import { Stock } from "../../../api/inventory/stockApi";

interface StockTableProps {
    stockEntries: Stock[];
    onEditClick: (stock: Stock) => void;
    onDeleteClick: (stock: Stock) => void;
}

const StockTable: React.FC<StockTableProps> = ({ stockEntries, onEditClick, onDeleteClick }) => {
    const navigate = useNavigate();

    const handleManageAssignments = (stockId: number) => {
        navigate(`/inventory/stock/${stockId}/assignments`);
    };

    if (!stockEntries || stockEntries.length === 0) {
        return <p>No stock entries available.</p>;
    }

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white border">
                <thead>
                    <tr>
                        <th className="py-2 px-3 border-b">Stock ID</th>
                        <th className="py-2 px-3 border-b">Product ID</th>
                        <th className="py-2 px-3 border-b">Quantity</th>
                        <th className="py-2 px-3 border-b">Location</th>
                        <th className="py-2 px-3 border-b">Batch Number</th>
                        <th className="py-2 px-3 border-b">Expiry Date</th>
                        <th className="py-2 px-3 border-b">Status</th>
                        <th className="py-2 px-3 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {stockEntries.map((stock) => (
                        <tr key={stock.stock_id} className="text-center">
                            <td className="py-2 px-3 border-b">{stock.stock_id}</td>
                            <td className="py-2 px-3 border-b">{stock.product_id}</td>
                            <td className="py-2 px-3 border-b">{stock.quantity}</td>
                            <td className="py-2 px-3 border-b">{stock.location || "-"}</td>
                            <td className="py-2 px-3 border-b">{stock.batch_number || "-"}</td>
                            <td className="py-2 px-3 border-b">{stock.expiry_date ? new Date(stock.expiry_date).toLocaleDateString() : "-"}</td>
                            <td className="py-2 px-3 border-b">{stock.status || "available"}</td>
                            <td className="py-2 px-3 border-b">
                                <button onClick={() => onEditClick(stock)} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 mr-2">
                                    Edit
                                </button>
                                <button onClick={() => onDeleteClick(stock)} className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600 mr-2">
                                    Delete
                                </button>
                                <button onClick={() => handleManageAssignments(stock.stock_id)} className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600">
                                    Manage Assignments
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default StockTable;
