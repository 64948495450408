// src/components/icons/OrdersIcon.tsx
import React from 'react';
import { FaClipboardList } from 'react-icons/fa'; // Font Awesome order list icon

interface OrdersIconProps {
    className?: string;
}

const OrdersIcon: React.FC<OrdersIconProps> = ({ className }) => {
    return (
        <FaClipboardList
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24}
            aria-label="Orders"
        />
    );
};

export default OrdersIcon;
