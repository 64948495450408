/**
 * @file This file contains the patient API functions.
 * @module api/patientApi
 * 
 * There are enpoints that get here from both the auth server and the healthcare server
 * most of these endpoint calls come out of the practitioner healthcare system but somce come from labiq sample management as well
 * 
 */

import axios from "axios";
import { User } from "../admin/userApi";
import Cookies from "js-cookie";

const API_BASE_URL = process.env.REACT_APP_AUTH_SERVER;
const HEALTHCARE_BASE_URL = process.env.REACT_APP_HEALTHCARE_SERVER;

export interface Patient {
	id: number;
	uuid: string;
	username: string;
	email: string;
	password: string;
	password_confirmation: string;
	first_name: string;
	middle_name: string;
	last_name: string;
	phone: string;
	address: string;
	city: string;
	state: string;
	country: string;
	postal_code: string;
	profile_picture: string;
	date_of_birth?: Date;
	gender: string;
	sex: string;
	preferences: string;
	is_active: boolean;
	created_at: Date;
	updated_at: Date;
	company: string;
	panel_key: string;
	company_code: string;
	reagents: string;
	total_gene_samples: number;
	total_blood_samples: number;
	patient_info?: PatientInfo;
	is_anonymous?: boolean;
}

export const defaultPatient: Patient = {
	id: 0,
	uuid: "",
	username: "",
	email: "",
	password: "",
	password_confirmation: "",
	first_name: "",
	middle_name: "", 
	last_name: "",
	phone: "",
	address: "",
	city: "",
	state: "",
	country: "",
	postal_code: "",
	profile_picture: "",
	date_of_birth: undefined, 
	gender: "",
	sex: "",
	preferences: "",
	is_active: false,
	created_at: new Date(), 
	updated_at: new Date(), 
	company: "",
	panel_key: "",
	company_code: "",
	reagents: "",
	total_gene_samples: 0, 
	total_blood_samples: 0, 
	patient_info: undefined,
	is_anonymous: false, 
};


export interface PatientInfo {
	medicare_number?: number,
    medicare_individual_ref_number?: number,
    medicare_valid_to?: Date,
	ihi_number?: number,
	ihi_status?: string,
	ihi_source?: string,
}

export interface MygeneProgramStatus {
	user_id: number;
	program_elected: string;
	swab_kit_sent: boolean;
	swab_kit_returned: boolean;
	questionnaire_sent: boolean;
	questionnaire_completed: boolean;
	questionnaire_sent_admin: boolean;
	pedigree_chart_filled: boolean;
	pathology_consult_completed: boolean;
	pathology_forms_sent: boolean;
	initial_blood_test_completed: boolean;
	genetic_results_ready: boolean;
	consult_90_minute_booked: boolean;
	consult_90_minute_completed: boolean;
	date_90_minute_consult: Date | null;
	initial_blood_consult_completed: boolean;
	date_initial_blood_consult: Date | null;
	follow_up_blood_test_due: Date | null;
	follow_up_blood_test_completed: boolean;
	follow_up_blood_consult_completed: boolean;
}


///////////////////////////////////////////////////////standard patient routes from the auth server
export const fetchPatients = async (
	page: number,
	perPage: number,
	search?: string,
	sortField?: string,
	sortDirection?: 'asc' | 'desc'
): Promise<{
	data: Patient[];
	total: number;
}> => {
	const response = await axios.post(
		`${API_BASE_URL}/practitioner/getPatient`,
		{
			company: JSON.parse(Cookies.get("user") || "{}").company,
		},
		{
			params: {
				page,
				per_page: perPage,
				...(search ? { search } : {}),
				...(sortField ? { sort_field: sortField } : {}),
				...(sortDirection ? { sort_direction: sortDirection } : {}),
			},
			headers: {
				Authorization: `Bearer ${Cookies.get("apiKey")}`,
			},
		}
	);

	return {
		data: response.data.data,
		total: response.data.pagination.total_items,
	};
};
  

export const fetchSinglePatientByUuid = async (
	uuid: string
): Promise<{
	data: Patient;
}> => {
	const response = await axios.get(`${API_BASE_URL}/practitioner/patientSimpleUuid/${uuid}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return {
		data: response.data,
	};
};

export const fetchPatient = async (
	id: string
): Promise<{
	data: Patient;
}> => {
	const response = await axios.get(`${API_BASE_URL}/practitioner/patient/${id}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return {
		data: response.data,
	};
};

export const updatePatient = async (patientId: number, patientData: Partial<Patient>) => {
	const user:User= JSON.parse(Cookies.get("user") || "{}")
	const response = await axios.put(`${API_BASE_URL}/practitioner/patient/${patientId}`, {
		patientData,
		user_id: user.uuid,
	}, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return response.data;
};

export const activatePatient = async (patientId: number) => {
	const response = await axios.put(`${API_BASE_URL}/practitioner/patient/${patientId}/activate`, {}, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return response.data;
};

export const deactivatePatient = async (patientId: number) => {
	const response = await axios.delete(`${API_BASE_URL}/practitioner/patient/${patientId}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return response.data;
};


export const createPatient = async (patientData: Partial<Patient>) => {
	const user:User= JSON.parse(Cookies.get("user") || "{}")
	const response = await axios.post(`${API_BASE_URL}/practitioner/patient`, {
		patientData, 
		user_id: user.uuid,
	}, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return response.data;
};

export const assignGeneCodes = async (patientId: number) => {
	const response = await axios.post(`${API_BASE_URL}/practitioner/patient/${patientId}/assign-role`, {
		"role": "gene.codes"
	}, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return response.data;
};



////////////////////////////////////////////// patient sample management
export const fetchPatientsSimple = async (
	page: number,
	perPage: number
): Promise<{
	data: Patient[];
	total: number;
	currentPage: number;
	totalPages: number;
}> => {
	const response = await axios.post(
		`${API_BASE_URL}/practitioner/patientSimple`,
		{
			company: JSON.parse(Cookies.get("user") || "{}").company,
		},
		{
			params: {
				page,
				per_page: perPage,
			},
			headers: {
				Authorization: `Bearer ${Cookies.get("apiKey")}`,
			},
		}
	);

	return {
		data: response.data.data,
		total: response.data.pagination.total_items,
		currentPage: response.data.pagination.current_page,
		totalPages: response.data.pagination.total_pages,
	};
};


export const fetchSinglePatientSimple = async (
	patientEmail: string
): Promise<{
	data: Patient[];
}> => {
	const response = await axios.get(`${API_BASE_URL}/practitioner/patientSimple/${patientEmail}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return {
		data: response.data,
	};
};

export const fetchPatientSamples = async (patientEmail: string) => {
	const response = await axios.get(
		`${HEALTHCARE_BASE_URL}/practitioner/patient/gene-samples-all/${patientEmail}`,
		{
			headers: {
				Authorization: `Bearer ${Cookies.get("apiKey")}`,
			},
		}
	);
	return response.data;
};

//count type is to be increase or decrease of sample count, sample type is blood or gene
export const sampleCountOnPatient = async (patientEmail: string, sampleType: string, countType: string) => {
	const response = await axios.post(
		`${API_BASE_URL}/practitioner/patient/sampleCount`,
		{
			"user_email": patientEmail,
			"sampleType":sampleType,
			"countType": countType
		},
		{
			headers: {
				Authorization: `Bearer ${Cookies.get("apiKey")}`,
			},
		}
	);
	return response.data;
}




////////////////////////////////////////////////Mygene Program status endpoints in healthcare server
export const fetchPatientProgramStatus = async (
	userId: number
): Promise<{
	data: MygeneProgramStatus;
}> => {
	const response = await axios.get(`${HEALTHCARE_BASE_URL}/mygene/healthcare-program-status/${userId}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return {
		data: response.data,
	};
};

export const updatePatientProgramStatus = async (userId: number, status: Partial<MygeneProgramStatus>) => {
    const response = await axios.put(`${HEALTHCARE_BASE_URL}/mygene/healthcare-program-status/${userId}`, status, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};

export const createPatientProgramStatus = async (status: Partial<MygeneProgramStatus>) => {
    const response = await axios.post(`${HEALTHCARE_BASE_URL}/mygene/healthcare-program-status`, status, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};