// src/components/forms/Toolbox.tsx
import React from 'react';
import { DraggableElement } from './elements/DraggableElement';
import { FormElement } from '../../../api/formApi';

interface ToolboxProps { }

export const Toolbox: React.FC<ToolboxProps> = () => {
	/**
	 * A comprehensive list of form elements for building forms
	 */
	const formElements: Omit<FormElement, 'id'>[] = [
		{ type: 'heading', label: 'Section Heading' },
		{ type: 'paragraph', label: 'Paragraph Text'},
		{ type: 'text', label: 'Text (Single line)' },
		{ type: 'textarea', label: 'Textarea (Multi-line)' },
		
		{ type: 'number', label: 'Number' },
		{ type: 'email', label: 'Email' },
		{ type: 'phone', label: 'Phone Number' },
		{ type: 'checkbox', label: 'Checkbox' },
		{
			type: 'radio',
			label: 'Radio Buttons (Multi Select)',
			options: ['Option 1', 'Option 2', 'Option 3']
		},
		{
			type: 'select',
			label: 'Dropdown (Single Select)',
			options: ['Option 1', 'Option 2', 'Option 3']
		},
		{ type: 'date', label: 'Date Picker' },
		{ type: 'time', label: 'Time Picker' },
		{ type: 'datetime', label: 'Date & Time Picker' },
		{ type: 'file', label: 'File Upload' },
		{ type: 'signature', label: 'Signature' }
	];

	return (
		<div
			className="surface-100 text-color"
			style={{ maxWidth: 'fit-content', height: '100%', overflowY: 'auto' , overflowX: 'hidden'}}
		>
			<div className="flex flex-column align-items-center p-3">
				<h3 className="mb-2">Toolbox</h3>
			</div>
			<hr className="my-2 mx-2 border-none border-top-1 surface-border" />
			<div className="flex flex-column gap-2 p-2">
				{formElements.map((element, index) => {
					const draggableId = `${element.type}-${index}`;
					return <DraggableElement key={draggableId} element={element} draggableId={draggableId} />;
				})}
			</div>
		</div>
	);
};
