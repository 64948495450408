import React, { useEffect, useState } from "react";

import LoadingSpinner from "../../../icons/LoadingSpinner";


//APi imports
import { Patient } from "../../../../api/healthcare/patientApi";
import { AssignedForm, fetchSingleFormById, Form, FormResponse, getAssignedForms, getFormResults, FieldResponse, FullFormResponse } from "../../../../api/formApi";

//Prime react
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { FloatLabel } from "primereact/floatlabel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

//Compoennts
import AssignFormModal from "./AssignFormModal";
import FormResultModal from "./FormResultModal";


interface FormsTabProps {
	updatedPatient: Patient;
	setUpdatedPatient: React.Dispatch<React.SetStateAction<Patient>>;
	handleAutoSave: () => void;
}

const FormsTab: React.FC<FormsTabProps> = ({ updatedPatient, setUpdatedPatient, handleAutoSave }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isAssignModalOpen, setIsAssignModalOpen] = useState<boolean>(false);
	const [assignedForms, setAssignedForms] = useState<AssignedForm[]>();
	const [forms, setForms] = useState<Form[]>();
	const [selectedForm, setSelectedForm] = useState<FullFormResponse>();
	const [isResultModalOpen, setIsResultModalOpen] = useState<boolean>(false);

	useEffect(() => {
		loadAssignedForms();
	}, []);

	const loadAssignedForms = async () => {
		setIsLoading(true);
		const af:AssignedForm[] = await getAssignedForms(updatedPatient.id);
		setAssignedForms(af);
		let fm:Form[] = [];
		for(let i = 0; i < af.length; i++){
			const {data} = await fetchSingleFormById(af[i].form_id)
			fm.push(data);
		};
		setForms(fm);
		setIsLoading(false);
	};

	// ASsign form modal open
	const openFormModal = async () => {
		setIsAssignModalOpen(true);
	};

	const closeFormModal = async () => {
		setIsAssignModalOpen(false);
		loadAssignedForms();
	};

	//Result Modal
	const openResultModal = async (form: Form) => {
		if (assignedForms) {
			for (let i = 0; i < assignedForms.length; i++) {
				if (assignedForms[i].form_id === form.id) {
					if (assignedForms[i].status === "Completed") {
						const data = await getFormResults(assignedForms[i].id);

						// Parse the stored JSON response into FieldResponse[]
						const parsedStructure: FieldResponse[] = data.response
							? JSON.parse(data.response)
							: [];

						const parsedData: FullFormResponse = {
							...data,
							structure: parsedStructure,
						};

						setSelectedForm(parsedData);
						setIsResultModalOpen(true);
						return;
					} else {
						console.log("Form not filled in");
					}
				}
			}
		} else {
			// handle an error here
			return;
		}
	};

	const closeResultModal = async () => {
		setSelectedForm(undefined);
		setIsResultModalOpen(false);
	};


	// DataTable body templates
	const titleBodyTemplate = (rowData: Form) => rowData.title;
	const statusBodyTemplate = (rowData: Form) => {
		if(assignedForms){
			for (let i = 0; i < assignedForms.length; i++){
				if(rowData.id === assignedForms[i].form_id){
					return (
						assignedForms[i].status
					);
				}
			};
		};
		return (
			null
		);
	};

	return (
		<div className="relative">
			{isLoading ? (
				<div className="flex align-items-center justify-content-center" style={{ height: '200px' }}>
					<LoadingSpinner size={60} color="#354396" />
				</div>
			) : (
				<div className="flex flex-column gap-3">
					<div className="flex align-items-center justify-content-end gap-3 mb-2">
						<Button
							label="Assign Form"
							icon="pi pi-plus"
							className="p-button-outlined p-button-success"
							onClick={() => openFormModal()}
						/>
					</div>

					{/* DataTable for assigned samples */}
					{assignedForms && assignedForms.length > 0 ? (
						<div className="card surface-0 border-round shadow-2 p-3">
							<DataTable
								value={forms}
								responsiveLayout="scroll"
								className="p-datatable-gridlines p-datatable-sm"
								selectionMode="single"
								onRowClick={(e) => openResultModal(e.data as Form)}
							>
								<Column field="title" header="title" body={titleBodyTemplate} />
								<Column field="status" header="status" body={statusBodyTemplate} />

							</DataTable>
						</div>
					) : (
						<p>No Forms found</p>
					)}


				</div>
			)}
			{isAssignModalOpen && (
				<AssignFormModal
					isOpen={isAssignModalOpen}
					onClose={closeFormModal}
					patient={updatedPatient}
				/>
			)}

			{(isResultModalOpen && selectedForm) && (
				<FormResultModal
					isOpen={isResultModalOpen}
					onClose={closeResultModal}
					patient={updatedPatient}
					selectedForm={selectedForm}
				/>
			)}
		</div>
	);
};

export default FormsTab;
