// 1. Import SortOrder from primeReact
import React from 'react';
import { format } from 'date-fns';

//Prime react
import { DataTable, SortOrder } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Form } from '../../api/formApi';


interface FormTableProps {
	forms: Form[],
	totalForms: number;
	first: number;
	rows: number;
	onEditClick: (form: Form) => void;
}

const FormTable: React.FC<FormTableProps> = ({
	forms,
	totalForms,
	first,
	rows,
	onEditClick,

}) => {
	// Determine sortField and sortOrder based on "sorting"
	let sortField: string | undefined;
	let sortOrder: SortOrder | undefined; 


	const createdAtBody = (rowData: Form) => {

		const date = new Date(rowData.created_at);

		return format(date, 'dd-MM-yyyy');
	};


	const onRowSelect = (event: { data: Form }) => {
		onEditClick(event.data);
	};

	return (
		<div className="card surface-0 border-round shadow-2 p-3">
			<DataTable
				value={forms}
				selectionMode="single"
				paginator
				lazy
				first={first}
				rows={rows}
				totalRecords={totalForms}
				sortField={sortField}
				sortOrder={sortOrder}

				onRowSelect={onRowSelect}
				scrollable
				scrollHeight="75vh"
				className="p-datatable-gridlines p-datatable-sm cursor-pointer"
				rowHover={true}
			>
				<Column field="title" header="Title" sortable />
				<Column field="department" header="Department" sortable />
				<Column field="created_at" header="created_at" sortable body={createdAtBody} />
			</DataTable>
		</div>
	);
};

export default FormTable;
