import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

interface ProfessionalsButtonsProps {
	searchProfessionals: (search: string) => void;
	reloadProfessionals: () => void;
	handleNewPractitioner: () => void;
}

const ProfessionalsButtons: React.FC<ProfessionalsButtonsProps> = ({
	searchProfessionals,
	reloadProfessionals,
	handleNewPractitioner
}) => {
	const [searchTerm, setSearchTerm] = useState("");

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchTerm(value);
		searchProfessionals(value);
	};

	const handleCreatePractitioner = () => {
		handleNewPractitioner();
	};

	return (
		<div className="flex align-items-center justify-content-between mb-3">
			<Button
				label="Create Practitioner"
				className="p-button-outlined"
				onClick={handleCreatePractitioner}
			/>
			<div className="flex align-items-center gap-2">
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						type="text"
						placeholder="Search practitioners..."
						value={searchTerm}
						onChange={handleSearchChange}
						style={{ width: '16rem' }}
					/>
				</span>
				<Button label="Reset" className="p-button-outlined" onClick={reloadProfessionals} />
			</div>
		</div>
	);
};

export default ProfessionalsButtons;
