export const validateIHI = (ihi: string): { isValid: boolean; error?: string } => {
    // Remove any non-digit characters
    const cleanedIHI = ihi.replace(/\D/g, '');

    // IHI length: Typically 16 digits (including check digit)
    if (cleanedIHI.length !== 16) {
        return { isValid: false, error: 'IHI must be exactly 16 digits.' };
    }

    const digits = cleanedIHI.split('').map(Number);

    // Validate all characters are digits
    if (digits.some(isNaN)) {
        return { isValid: false, error: 'IHI must contain only numeric characters.' };
    }

    // Implement the Luhn Algorithm
    let sum = 0;
    const len = digits.length;

    for (let i = 0; i < len - 1; i++) { // Exclude the check digit
        let digit = digits[len - 2 - i]; // Start from second last digit

        if (i % 2 === 0) { // Double every second digit
            digit *= 2;
            if (digit > 9) {
                digit = Math.floor(digit / 10) + (digit % 10);
            }
        }

        sum += digit;
    }

    // Calculate the check digit
    const checkDigit = (10 - (sum % 10)) % 10;

    if (digits[len - 1] !== checkDigit) {
        return { isValid: false, error: `Invalid check digit. Expected ${checkDigit}.` };
    }

    return { isValid: true };
};
