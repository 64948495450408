import React, { useState, useEffect } from "react";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import {
	Practitioner,
	updatePractitioner,
	createPractitioner,
	defaultPractitionerInfo
} from "../../../api/healthcare/practitionerApi";

// Components
import Sidebar from "./modalComponents/Sidebar";
import ProfileTab from "./modalComponents/ProfileTab";
import PractitionerInfoTab from "./modalComponents/PractitionerInfoTab";
import AvailabilityTab from "./modalComponents/AvailabilityTab";
import EducationTab from "./modalComponents/EducationTab";

interface EditProffesionalModalProps {
	isOpen: boolean;
	onClose: () => void;
	practitioner: Practitioner;
	onPractitionerUpdate: () => void;
}

const EditProffesionalModal: React.FC<EditProffesionalModalProps> = ({
	isOpen,
	onClose,
	practitioner,
	onPractitionerUpdate
}) => {
	const [updatedPractitioner, setUpdatedPractitioner] = useState<Practitioner>({ ...practitioner });
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const [activeTab, setActiveTab] = useState("Personal");

	useEffect(() => {
		setUpdatedPractitioner({
			...practitioner,
			practitioner: practitioner.practitioner || defaultPractitionerInfo
		});

		if (practitioner.company === "") {
			const userCompany = JSON.parse(sessionStorage.getItem("user") || "{}").company;
			setUpdatedPractitioner((prev) => ({
				...prev,
				company: userCompany,
				company_code: userCompany
			}));
		}
	}, [practitioner]);

	const updatePatientInfo = async () => {
		setIsSubmitting(true);
		try {
			if (
				!updatedPractitioner.first_name ||
				!updatedPractitioner.last_name ||
				!updatedPractitioner.email ||
				!updatedPractitioner.username
			) {
				setErrorMessages(["First name, last name, email, and username are required."]);
				setIsSubmitting(false);
				return;
			}
			if (updatedPractitioner.id !== 0) {
				await updatePractitioner(practitioner.id, updatedPractitioner);
			} else {
				await createPractitioner(updatedPractitioner);
			}
			onPractitionerUpdate();
		} catch (error: unknown) {
			handleErrors(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleErrors = (error: unknown) => {
		if (axios.isAxiosError(error) && error.response) {
			if (error.response.data?.errors) {
				const msgs = Object.entries(error.response.data.errors).flatMap(([_, val]) => val as string);
				setErrorMessages(msgs);
			} else if (error.response.data?.message) {
				setErrorMessages([error.response.data.message]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} else if (error instanceof Error) {
			setErrorMessages([error.message]);
		} else {
			setErrorMessages(["An unknown error occurred."]);
		}
	};

	
	const header = () => {
	    return (
	        <div className="flex justify-content-between">
	            <div className="">{practitioner ? "Edit " + practitioner.first_name + " " + practitioner.last_name : "Create New Practitioner"}</div>
		
	            <div>
	                <Button
	                    icon="pi pi-save p-icon"
	                    rounded
	                    text
	                    aria-label="Save"
	                    loading={isSubmitting}
	                    disabled={isSubmitting}
	                    type="button"
	                    onClick={updatePatientInfo}
	                    className="p-dialog-header-icon mr-2"
	                    style={{ color: "var(--text-color-alternative)" }}
	                />
	            </div>
	        </div>
	    );
	};


	return (
		<Dialog
			visible={isOpen}
			onHide={onClose}
			modal
			dismissableMask
			focusOnShow={false}
			maximizable
			header={header}
			headerClassName="pb-0 h-auto bg-primary "
			style={{ width: '80vw', height: '80vh' }}
			contentStyle={{ height: 'calc(100% - 5rem)', padding: 0 }}
			closable={true}
			className="custom-dialog"
		>
			<div className="flex w-full h-full">
				{/* Sidebar */}
				<div className="border-right-1 surface-border" style={{ overflowY: 'auto' }}>
					<Sidebar activeTab={activeTab} setActiveTab={setActiveTab} practitioner={updatedPractitioner} />
				</div>

				{/* Main Content */}
				<div className="flex flex-1 flex-column p-4 " >
					<div className="flex-1 overflow-y-auto overflow-x-hidden">
						{activeTab === "Personal" && (
							<ProfileTab
								updatedPractitioner={updatedPractitioner}
								setUpdatedPractitioner={setUpdatedPractitioner}
							/>
						)}
						{activeTab === "Practitioner" && (
							<PractitionerInfoTab
								updatedPractitioner={updatedPractitioner}
								setUpdatedPractitioner={setUpdatedPractitioner}
							/>
						)}
						{activeTab === "Availability" && (
							<AvailabilityTab
								updatedPractitioner={updatedPractitioner}
								setUpdatedPractitioner={setUpdatedPractitioner}
							/>
						)}
						{activeTab === "Education" && (
							<EducationTab
								updatedPractitioner={updatedPractitioner}
								setUpdatedPractitioner={setUpdatedPractitioner}
							/>
						)}
					</div>

					{errorMessages.length > 0 && (
						<div className="mt-3" style={{ color: 'red' }}>
							{errorMessages.map((msg, idx) => (
								<div key={idx}>{msg}</div>
							))}
						</div>
					)}

					
				</div>
			</div>
		</Dialog>
	);
};

export default EditProffesionalModal;
