import axios from "axios";
import Cookies from "js-cookie";
const API_BASE_URL = process.env.REACT_APP_FILE_SERVER;

export interface FileMetadata {
	id: string;
	user_id: number;
	file_name: string;
	storage_path: string;
	file_size: number;
	file_type: string;
	created_at: string | Date;
	updated_at: Date;
}

export const uploadFile = async (file: File, userId: number) => {
	try {
		// Create a FormData object to send the file, as you cant send a file in json
		const formData = new FormData();
		formData.append("file", file);
		formData.append("user_id", userId.toString());

		const response = await axios.post(`${API_BASE_URL}/files`, formData, {
			headers: {
				Authorization: `Bearer ${Cookies.get("apiKey")}`,
			},
		});

		return response.data;
	} catch (error) {
		throw new Error(`Error uploading file: ${error}`);
	}
};


export const listUserFiles = async (userId: number) => {
	try {
		const response = await axios.get(`${API_BASE_URL}/files/user/${userId}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("apiKey")}`,
			},
		});
		return response.data;
	} catch (error) {
		throw new Error(`Error creating application: ${error}`);
	}
};

export const downloadFile = async (userId: number, fileId: string) => {
	try {
		// Make the API request to download the file
		const response = await axios.post(
			`${API_BASE_URL}/files/download`,
			{
				user_id: userId,
				file_id: fileId,
			},
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("apiKey")}`,
				},
				responseType: "blob",
			}
		);

		// Access content type from headers
		const contentType = response.headers["content-type"];

		// Create a Blob with the correct MIME type
		const blob = new Blob([response.data], { type: contentType });
		const fileURL = window.URL.createObjectURL(blob);
		const fileLink = document.createElement("a");
		fileLink.href = fileURL;

		// Extract file name from headers if available, or set a default name
		const contentDisposition = response.headers["content-disposition"];
		const fileName = contentDisposition
			? contentDisposition.split("filename=")[1]?.replace(/['"]/g, "")
			: "downloaded-file";

		fileLink.setAttribute("download", fileName);
		document.body.appendChild(fileLink);
		fileLink.click();

		// Clean up
		fileLink.remove();
		window.URL.revokeObjectURL(fileURL);
	} catch (error) {
		throw new Error(`Error downloading file: ${error}`);
	}
};


export const deleteFile = async (fileId: string, userId: number) => {
	try {
		const response = await axios.delete(`${API_BASE_URL}/files`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("apiKey")}`,
			},
			data: {
				user_id: userId,
				file_id: fileId
			},
		});
		
		return response.data;
	} catch (error) {
		throw new Error(`Error creating application: ${error}`);
	}
};