
export const validateMedicareNumber = (medicareNumber: string): { isValid: boolean; error?: string } => {
	// Remove any non-digit characters
	const cleanedNumber = medicareNumber.replace(/\D/g, '');

	// Check length: 8 digits + check digit + issue number = 10 digits
	if (cleanedNumber.length !== 10) {
		return { isValid: false, error: 'Medicare number must be exactly 10 digits.' };
	}

	const digits = cleanedNumber.split('').map(Number);

	// Check first digit is between 2 and 6
	if (digits[0] < 2 || digits[0] > 6) {
		return { isValid: false, error: 'The first digit must be between 2 and 6.' };
	}

	// Extract parts
	const mainNumber = digits.slice(0, 8);
	const providedCheckDigit = digits[8];
	const issueNumber = digits[9]; // Not used in validation but could be validated if rules are known

	// Define weights as per the calculation rules
	const weights = [1, 3, 7, 9, 1, 3, 7, 9];

	// Calculate the sum
	const sum = mainNumber.reduce((acc, digit, index) => acc + digit * weights[index], 0);

	// Calculate the expected check digit
	const expectedCheckDigit = sum % 10;

	if (providedCheckDigit !== expectedCheckDigit) {
		return { isValid: false, error: `Invalid check digit. Expected ${expectedCheckDigit}.` };
	}

	return { isValid: true };
};
