// src/components/icons/UserIcon.tsx
import React from 'react';
import { FaUser } from 'react-icons/fa'; // Importing the Font Awesome user icon

interface UserIconProps {
    className?: string;
}

const UserIcon: React.FC<UserIconProps> = ({ className }) => {
    return (
        <FaUser
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24} // Set the default size to 24px
            aria-label="User Management"
        />
    );
};

export default UserIcon;
