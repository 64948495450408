// src/components/Navbar.tsx
import React from "react";
import Cookies from "js-cookie";
import { Menubar } from 'primereact/menubar';
import { InputText } from "primereact/inputtext";
import { Avatar } from "primereact/avatar";

import Notifications from "../notifications/Notifications";

const Navbar: React.FC = () => {
    const user = JSON.parse(Cookies.get("user") || "{}");

    const items:any = [
         //Add menu items if needed.
         //Example:
         
    	
    ];

    // The start element can be a logo or a title.
    const start = <div className="text-xl font-semibold">MyGene Management System</div>;
	const end = (
        <div className="flex align-items-center gap-2">
            <Notifications />
        </div>
    );

    return (
        <Menubar model={items} start={start} end={end}/>
    );
};

export default Navbar;
