import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";

// PrimeReact
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from "primereact/floatlabel";
import { Toast } from "primereact/toast";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

//API
import { FullFormResponse, FieldResponse } from "../../../../api/formApi";
import { Patient } from "../../../../api/healthcare/patientApi";
import { User } from "../../../../api/admin/userApi";
import { RadioButton } from "primereact/radiobutton";


interface FormResultModalProps {
	isOpen: boolean;
	onClose: () => void;
	patient: Patient;
	selectedForm: FullFormResponse;
}

const FormResultModal: React.FC<FormResultModalProps> = ({ isOpen, onClose, patient, selectedForm }) => {
	const user: User = JSON.parse(Cookies.get('user') || "{}");
	const toast = useRef<Toast>(null);

	useEffect(() => {
	}, []);


	const footer = (
		<div className="flex justify-content-end gap-2">
			<Button label="Close" className="p-button-outlined" onClick={onClose} />
		</div>
	);

	// render out the results of the form
	const fields = selectedForm.structure || [];
	return (
		<Dialog
			visible={isOpen}
			onHide={onClose}
			header="Form Results"
			footer={footer}
			modal
			dismissableMask
			style={{ width: '30rem' }}
		>
			<Toast ref={toast} />
			<div className="flex flex-column gap-3">

				{fields.map((field: FieldResponse) => {
					switch (field.type) {
						case 'heading':
							return (
								<div key={field.id}>
									<h3 className="mb-1">{field.question}</h3>
								</div>
							);

						case 'paragraph':
							return (
								<div key={field.id} className="mb-3">
									<p className="mb-1">{field.question}</p>
								</div>
							);

						case 'email':
							return (
								<div key={field.id} className="mb-3">
									<FloatLabel>
										<label className="block mb-1">{field.question}</label>
										<InputText
											type="email"
											keyfilter="email"
											value={field.response}
											className="w-full"
											disabled
										/>
									</FloatLabel>
								</div>
							);

						case 'number':
							return (
								<div key={field.id} className="mb-3">
									<FloatLabel>
										<label className="block mb-1">{field.question}</label>
										<InputText
											type="number"
											value={field.response}
											disabled
											className="w-full"
										/>
									</FloatLabel>
								</div>
							);

						case 'phone':
							return (
								<div key={field.id} className="mb-3">
									<FloatLabel>
										<label className="block mb-1">{field.question}</label>
										<InputText
											type="number"
											value={field.response}
											disabled
											className="w-full"
										/>
									</FloatLabel>
								</div>
							);

						case 'radio':
							return (
								<div key={field.id} className="mb-3">
									<label className="block mb-1">{field.question}</label>
									<RadioButton
										//inputId={field.id}
										name={`radio-${field.id}`}
										value={field.response}
										checked={field.response}
									/>
								</div>
							);

						case 'text':
							return (
								<div key={field.id} className="mb-3">
									<FloatLabel>
										<label className="block mb-1">{field.question}</label>
										<InputText
											value={field.response}
											disabled
											className="w-full"
										/>
									</FloatLabel>
								</div>
							);

						case 'textarea':
							return (
								<div key={field.id} className="mb-3">
									<FloatLabel>
										<label className="block mb-1">{field.question}</label>
										<InputTextarea
											value={field.response}
											disabled
											className="w-full"
										/>
									</FloatLabel>
								</div>
							);

						case 'checkbox':
							return (
								<div key={field.id} className="mb-3">
									<label className="block mb-1">{field.question}</label>
									<Checkbox
										checked={field.response}
										disabled
									/>
								</div>
							);

						case 'select':
							return (
								<div key={field.id} className="mb-3">
									<FloatLabel>
										<InputText
											value={field.response}
											disabled
											placeholder="Select an option"
											className="w-full"
										/>
										<label className="block mb-1">{field.question}</label>
									</FloatLabel>
								</div>
							);

						case 'date':
							return (
								<div key={field.id} className="mb-3">
									<FloatLabel>
										<Calendar
											dateFormat="dd/mm/yy"
											value={field.response}
											disabled
											className="w-full"
										/>
										<label className="block mb-1">{field.question}</label>
									</FloatLabel>
								</div>
							);

						case 'time':
							return (
								<div key={field.id} className="mb-3">
									<FloatLabel>
										<Calendar
											timeOnly
											value={field.response}
											disabled
											className="w-full"
										/>
										<label className="block mb-1">{field.question}</label>
									</FloatLabel>
								</div>
							);

						case 'datetime':
							return (
								<div key={field.id} className="mb-3">
									<FloatLabel>
										<Calendar
											dateFormat="dd/mm/yy"
											showTime
											hourFormat="12"
											value={field.response}
											disabled
											className="w-full"
										/>
										<label className="block mb-1">{field.question}</label>
									</FloatLabel>
								</div>
							);

						case 'file':
							return (
								<div key={field.id} className="mb-3">
									<label className="block mb-1">{field.question}</label>
									<p>will work on this</p>
								</div>
							);

						case 'signature':
							return (
								<div key={field.id} className="mb-3">
									<label className="block mb-1">{field.question}</label>
									<p>WIll have to show the filled in signature here</p>
								</div>
							);

						default:
							return (
								<div key={field.id} className="mb-3">
									<p>Unsupported field type: {field.type}</p>
								</div>
							);
					}
				})}

			</div>
		</Dialog>
	);
};

export default FormResultModal;
