// src/components/icons/LogoutIcon.tsx
import React from 'react';
import { FaSignOutAlt } from 'react-icons/fa'; // Importing the Font Awesome logout icon

interface LogoutIconProps {
    className?: string;
}

const LogoutIcon: React.FC<LogoutIconProps> = ({ className }) => {
    return (
        <FaSignOutAlt
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24} // Default size
            aria-label="Logout"
        />
    );
};

export default LogoutIcon;
