import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { fetchReagentList, createSample, Sample, SampleFormData, NewAnalysisRequest } from "../../../../api/labiq/labiqApi";


// PrimeReact
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from "primereact/floatlabel";
import LoadingSpinner from "../../../icons/LoadingSpinner";

interface AnalysisModalProps {
	isOpen: boolean;
	onClose: (sample?: Sample) => void;
	sample: SampleFormData;
}

const AnalysisModal: React.FC<AnalysisModalProps> = ({ isOpen, onClose, sample }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [formData, setFormData] = useState<SampleFormData>({
		analysis_requested: [],
	});


	const [reagentList, setReagentList] = useState<string[]>([]);

	useEffect(() => {
		const fetchReagents = async () => {
			try {
				const reagents = await fetchReagentList();
				setReagentList(reagents);
			} catch (err) {
				console.error("Error fetching reagents:", err);
			}
		};
		fetchReagents();
	}, []);

	const handleCheckboxChange = (reagent: string, checked: boolean) => {
		setFormData((prev) => {
			const analysisRequested = prev.analysis_requested ? [...prev.analysis_requested] : [];
			if (checked) {
				if (!analysisRequested.includes(reagent)) {
					analysisRequested.push(reagent);
				}
			} else {
				const index = analysisRequested.indexOf(reagent);
				if (index > -1) {
					analysisRequested.splice(index, 1);
				}
			}
			return { ...prev, analysis_requested: analysisRequested };
		});
	};


	const handleSubmit = async () => {
		setIsLoading(true)
		try {
			if (formData.analysis_requested) {
				const updatedSample = await NewAnalysisRequest(sample.id as number, formData.analysis_requested);
				onClose(updatedSample);
			}

		} catch (err) {
			console.error("Error requesting analysis:", err);
		} finally {
			setIsLoading(false);
		}
	};

	const footer = (
		<div className="flex justify-content-end gap-2">
			<Button label="Cancel" className="p-button-outlined" onClick={() => onClose()} />
			<Button label="Submit" onClick={handleSubmit} />
		</div>
	);

	return (
		<Dialog
			visible={isOpen}
			onHide={onClose}
			header="Select Analysis To Request"
			footer={footer}
			modal
			dismissableMask
			style={{ width: '30rem' }}
		>
			{isLoading ? (
				<div className="flex justify-content-center align-items-center" style={{ height: '200px' }}>
					<LoadingSpinner size={60} color="#354396" />
				</div>
			) : (
				<div className="flex flex-column gap-3">
				<div className="grid formgrid p-fluid">
					{reagentList.map((reagent) => (
						<div key={reagent} className="field-checkbox col-6">
							<input
								type="checkbox"
								id={reagent}
								value={reagent}
								checked={formData.analysis_requested?.includes(reagent) || false}
								onChange={(e) => handleCheckboxChange(reagent, e.target.checked)}
							/>
							<label htmlFor={reagent} style={{ marginLeft: '0.5rem' }}>{reagent}</label>
						</div>
					))}
				</div>

			</div>
			)}
			
		</Dialog>
	);
};

export default AnalysisModal;
