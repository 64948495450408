import React from "react";
import { User } from "../../api/admin/userApi";
import { InputText } from 'primereact/inputtext';

interface UserProfileHeaderProps {
	user: User;
	isEditing: boolean;
	setUserData: React.Dispatch<React.SetStateAction<User>>;
}

const UserProfileHeader: React.FC<UserProfileHeaderProps> = ({ user, isEditing, setUserData }) => {
	const handleChange = (name: keyof User, value: string) => {
		setUserData(prevUser => ({ ...prevUser, [name]: value }));
	};

	return (
		<div className="flex align-items-center gap-3 mb-3">
			<img
				className="border-circle"
				src={user.profile_picture || "/assets/images/default-profile.png"}
				alt="User avatar"
				style={{ width: '7rem', height: '7rem', objectFit: 'cover' }}
			/>
			<div className="flex flex-column gap-2">
				{isEditing ? (
					<>
						<InputText
							name="first_name"
							value={user.first_name || ''}
							onChange={(e) => handleChange('first_name', e.target.value)}
							style={{ fontWeight: 'bold', fontSize: '1.5rem' }}
							placeholder="First Name"
						/>
						<InputText
							name="last_name"
							value={user.last_name || ''}
							onChange={(e) => handleChange('last_name', e.target.value)}
							style={{ fontWeight: 'bold', fontSize: '1.5rem' }}
							placeholder="Last Name"
						/>
						<InputText
							name="email"
							value={user.email || ''}
							onChange={(e) => handleChange('email', e.target.value)}
							placeholder="Email"
						/>
						<InputText
							name="phone"
							value={user.phone || ''}
							onChange={(e) => handleChange('phone', e.target.value)}
							placeholder="Phone"
						/>
					</>
				) : (
					<>
						<span className="text-color" style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
							{user.first_name} {user.last_name}
						</span>
						<span className="text-color-secondary">{user.email}</span>
						<span className="text-color-secondary">{user.phone}</span>
					</>
				)}
			</div>
		</div>
	);
};

export default UserProfileHeader;
