import React from "react";
import Cookies from "js-cookie";
import { Appointment } from "../../../../api/healthcare/appointmentApi";

import { Button } from "primereact/button";

interface SidebarProps {
	activeTab: string;
	setActiveTab: (tab: string) => void;
	appointment: Appointment;
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab, appointment }) => {
	const user = JSON.parse(Cookies.get("user") || "{}");

	// A small utility for the tab items
	const TabItem = ({ label, value }: { label: string; value: string }) => {
		const isActive = activeTab === value;
		return (
			<div
				className={`flex align-items-center p-2 border-round cursor-pointer transition-duration-200 
                    ${isActive ? 'bg-primary-100' : 'hover:surface-200'}`}
				style={{ gap: '0.5rem' }}
				onClick={() => setActiveTab(value)}
			>
				<span>{label}</span>
			</div>
		);
	};

	return (
		<div className="flex flex-column surface-100 text-color" style={{ maxWidth: '16rem', height: '100%', overflowY: 'auto' }}>
			{/* Profile Section */}
			<div className="flex flex-column align-items-center p-3">
				<div className="mt-2 font-medium text-center">
					Appointment info for patient and doctor will go here
				</div>
			</div>
			<hr className="my-2 mx-2 border-none border-top-1 surface-border" />

			{/* Navigation Links */}
			<div className="flex flex-column gap-2 p-2">
				<TabItem label="Appointment Info" value="Appointment" />
				{appointment.id !== 0 && (
					<div>
						
						<TabItem label="Patient Profile" value="Patient" />
						<TabItem label="Practitioner Profile" value="Practitioner" />
						<TabItem label="Start Appointment" value="Start" />
					</div>
				)}
				
			</div>
		</div>
	);
};

export default Sidebar;
