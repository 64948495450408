import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import debounce from 'lodash/debounce';
import Cookies from "js-cookie";

// Import API
import { submitBatch, updateBatch, createBatch, fetchReagentList, fetchBatch, Batch, Sample } from "../../../api/labiq/labiqApi";
import { fetchCompanies, Company } from "../../../api/admin/companyApi";

// Import PrimeReact components
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Dialog } from "primereact/dialog";

// Components for the modal
import Sidebar from "./batchModalComponents/Sidebar";
import BatchDetails from "./batchModalComponents/BatchDetails";
import BatchSamples from "./batchModalComponents/BatchSamples";

interface BatchModalProps {
	isOpen: boolean;
	onClose: () => void;
	batch_id: number;
}

const BatchModal: React.FC<BatchModalProps> = ({ isOpen, onClose, batch_id }) => {
	const user = JSON.parse(Cookies.get('user') || "{}");
	const [formData, setFormData] = useState<Partial<Batch>>({});
	const [submittedSamples, setSubmittedSamples] = useState<Sample[]>();
	const [companies, setCompanies] = useState<Company[]>([]);
	const [reagentList, setReagentList] = useState<string[]>([]);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [errorMessages, setErrorMessages] = useState<any[]>([]);
	const [activeTab, setActiveTab] = useState("Details");

	const toast = useRef<Toast>(null);

	// Initialize data when modal opens
	useEffect(() => {
		if (!isOpen) return;

		const initializeData = async () => {
			try {
				if (batch_id !== 0) {
					await getBatchDetails();
				} else {
					const userCompany = JSON.parse(Cookies.get("user") || "{}").company;
					if (userCompany === "Mygene" || userCompany === "DNAIQ") {
						await getCompanies();
					} else {
						setFormData((prev) => ({ ...prev, batch_company: userCompany }));
					}
				}
				await fetchReagents();
			} catch (error: unknown) {
				handleError(error);
			}
		};

		initializeData();
	}, [isOpen, batch_id]);

	// Fetch companies
	const getCompanies = async () => {
		try {
			const data = await fetchCompanies(1, 100, "");
			setCompanies(data.data);
		} catch (error: unknown) {
			handleError(error);
		}
	};

	// Fetch reagent list
	const fetchReagents = async () => {
		try {
			const reagents = await fetchReagentList();
			setReagentList(reagents);
		} catch (error: unknown) {
			handleError(error);
		}
	};

	// Fetch batch details
	const getBatchDetails = async () => {
		try {
			const {Batch, Samples} = await fetchBatch(batch_id);
			const batchData: Partial<Batch> = {
				batch_id: Batch.batch_id,
				batch_client_id: Batch.batch_client_id,
				batch_delivery_option: Batch.batch_delivery_option,
				batch_tracking_no: Batch.batch_tracking_no,
				batch_vendor: Batch.batch_vendor,
				batch_create_date: Batch.batch_create_date,
				batch_submission: Batch.batch_submission,
				batch_user_id: Batch.batch_user_id,
				batch_company: Batch.batch_company,
				batch_sample_count: Batch.batch_sample_count,
				samples: Batch.samples,
			};
			setFormData(batchData);
			setSubmittedSamples(Samples);
		} catch (error: unknown) {
			handleError(error);
		}
	};

	// Handler for adding a new sample
	const handleAddSample = () => {
		if(formData.samples){
			let last = formData.samples?.length;
			const smpl = last ? formData.samples[last - 1] : null;

			if (smpl?.bs_sample_id === null || smpl?.bs_collection_type === null || smpl?.bs_sample_id === "" || smpl?.bs_collection_type === "") {
				toast.current?.show({
					severity: "warn",
					summary: "Warning",
					detail: "Please fill in Sample ID and Collection Type of last sample before adding another",
					life: 5000
				});
				return
			}
		}
		

		setFormData((prev) => ({
			...prev,
			samples: [
				...(prev.samples || []),
				{
					bs_id: Date.now(),
					bs_batch_id: batch_id,
					bs_sample_id: "",
					bs_sequence: "",
					bs_test_type: [],
					bs_collection_type: "",
				},
			],
		}));

		toast.current?.show({
			severity: "success",
			summary: "Added",
			detail: "Sample added",
			life: 3000
		});
	};

	// Handle input changes
	const handleChange = (field: string, value: any) => {
		setFormData((prev) => ({ ...prev, [field]: value }));
		handleAutoSave();
	};

	const handleSave = async () => {
		if(batch_id === 0){
			setIsSubmitting(true);
		try {
			const updatedData = { ...formData };
			if (updatedData.batch_delivery_option === 0) {
				delete updatedData.batch_tracking_no;
				delete updatedData.batch_vendor;
			}
			await createBatch(updatedData as Batch);

			toast.current?.show({
				severity: "success",
				summary: "Created",
				detail: "Batch Created",
				life: 3000
			});

		} catch (error: unknown) {
			handleError(error);
		} finally {
			setIsSubmitting(false);
		}
		}else{
			autoSave();
		}
	};

	// Handle auto-save with debounce
	const autoSave = useCallback(async () => {
		if (isSubmitting) return; // Prevent multiple auto-saves

		setIsSubmitting(true);
		try {
			if (batch_id === 0) {
				// No batch ID means creating a new batch
				return;
			} else {
				const updatedData = { ...formData };
				if (updatedData.batch_delivery_option === 0) {
					delete updatedData.batch_tracking_no;
					delete updatedData.batch_vendor;
				}
				await updateBatch(batch_id, updatedData as Batch);
			}
		} catch (error: unknown) {
			handleError(error);
		} finally {
			setIsSubmitting(false);
		}
	}, [batch_id, formData, isSubmitting]);

	const handleAutoSave = debounce(() => {
		if (batch_id === 0) {
			return;
		}
		if(formData.batch_submission === 1){
			return;
		}
		autoSave();
	}, 500);

	// Handle batch submission
	const submitBatchClick = async () => {
		setIsSubmitting(true);
		try {
			let currentBatchId = batch_id;
			if (batch_id === 0) {
				const createdBatch = await createBatch(formData as Batch);
				currentBatchId = createdBatch.batch_id;
				setFormData((prev) => ({ ...prev, batch_id: currentBatchId }));
			} else {
				const updatedData = { ...formData };
				if (updatedData.batch_delivery_option === 0) {
					delete updatedData.batch_tracking_no;
					delete updatedData.batch_vendor;
				}
				await updateBatch(batch_id, updatedData as Batch);
			}
			await submitBatch(currentBatchId);
			await getBatchDetails();
			onClose();
			toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Batch submitted successfully', life: 3000 });
		} catch (error: unknown) {
			handleError(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	// Confirmation popup before submitting the batch

	const confirmBatchSubmission = (event: React.MouseEvent<HTMLButtonElement>) => {
		confirmDialog({
			message: 'Are you sure you want to submit this batch? You will not be able to make any changes after submission.',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			defaultFocus: 'accept',
			accept: () => submitBatchClick(),
			reject: () => { /* Do nothing on reject */ },
			// Optional: Customize styles if needed
			style: { width: '25em' },
		});
	};


	// Handle errors
	const handleError = (error: unknown) => {
		if (axios.isAxiosError(error)) {
			if (error.response?.data?.errors) {
				const errorMessages = Object.entries(error.response.data.errors)
					.flatMap(([_, messages]) => messages);
				setErrorMessages(errorMessages);
			} else if (error.response?.data?.message) {
				setErrorMessages([error.response.data.message]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} else if (error instanceof Error) {
			setErrorMessages([error.message || "An unknown error occurred."]);
		} else {
			setErrorMessages(["An unknown error occurred."]);
		}
	};

	// Header for the dialog
	const header = () => (
		<div className="flex justify-content-between">
			<div className="">{formData ? `${formData.batch_submission === 1 ? "Viewing" : "Editing"} Batch ${formData.batch_client_id} - ${user.username}` : "Create Batch"}</div>
			<div>
				<Button
					icon="pi pi-save p-icon"
					rounded
					text
					aria-label="Save"
					loading={isSubmitting}
					disabled={isSubmitting}
					type="button"
					onClick={handleSave}
					className="p-dialog-header-icon mr-2"
					style={{ color: 'var(--text-color-alternative)' }}
				/>
			</div>
		</div>
	);

	return (
		<Dialog
			header={header}
			headerClassName="pb-0 h-auto bg-primary"
			visible={isOpen}
			style={{ width: '80vw', height: '80vh' }}
			modal
			onHide={() => {  onClose(); }}
			closable
			draggable
			maximizable
			focusOnShow={false}
			contentStyle={{ padding: 0 }}
			className="custom-dialog"
		>
			<Toast ref={toast} />
			<div className="flex w-full h-full" style={{ overflow: 'hidden' }}>
				{/* Uncomment and use Sidebar if needed
                <div className="border-right-1 surface-border" style={{ overflowY: 'auto' }}>
                    <Sidebar activeTab={activeTab} setActiveTab={setActiveTab} batch={formData as Batch} />
                </div>
                */}
				<div className="flex flex-column flex-1">
					<div className="flex-1 overflow-y-auto p-4">
						<div className="mb-5">
							<BatchDetails
								companies={companies}
								formData={formData}
								onChange={handleChange}
								setErrorMessages={setErrorMessages}
							/>
						</div>
						<BatchSamples
							batch_id={batch_id}
							formData={formData}
							reagentList={reagentList}
							setFormData={setFormData}
							handleAutoSave={handleAutoSave}
							submittedSamples={submittedSamples}
							setSubmittedSamples={setSubmittedSamples}
						/>

						{/* Error Handling */}
						{errorMessages.length > 0 && (
							<div className="error-container">
								{errorMessages.map((msg, idx) => (
									<div key={idx} className="text-red-500">
										{msg}
									</div>
								))}
							</div>
						)}
					</div>
					<div className="flex justify-content-end p-4 surface-border">
						{/* Add Sample Button */}
						{formData.batch_submission !== 1 && (

							<Button
								type="button"
								label="Add Sample"
								icon="pi pi-plus"
								className="px-4 py-2 mr-2 p-button-success"
								onClick={handleAddSample}
								disabled={formData.batch_submission === 1}
							/>

						)}
						<ConfirmDialog />
						{formData.batch_submission === 0 && (
							<Button
								type="button"
								onClick={confirmBatchSubmission}
								disabled={isSubmitting}
								icon="pi pi-check"
								label={isSubmitting ? "Submitting..." : "Submit Batch"}
								loading={isSubmitting}
								className="px-4 py-2 mr-2 p-button-success"
							/>
						)}
					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default BatchModal;
