import React from "react";
import { User } from "../../../../api/admin/userApi";
import { Company } from "../../../../api/admin/companyApi";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

interface CompanyTabProps {
	updatedUser: User;
	setUpdatedUser: React.Dispatch<React.SetStateAction<User>>;
	companies: Company[];
	handleAutoSave: () => void;
}

const CompanyTab: React.FC<CompanyTabProps> = ({ updatedUser, setUpdatedUser, companies, handleAutoSave }) => {
	const handleDropdownChange = (e: DropdownChangeEvent) => {
		setUpdatedUser((prev) => ({ ...prev, [e.target.name]: e.value }));
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUpdatedUser((prev) => ({ ...prev, [e.target.name]: e.target.value }));
	};

	const companyOptions = companies.map((company) => ({
		label: company.name,
		value: company.name,
	}));

	return (
		<div className="grid p-fluid">
			{/* Company Dropdown */}
			<div className="field col-12 md:col-6">
				<label htmlFor="company">Company</label>
				<Dropdown
					id="company"
					name="company"
					value={updatedUser.company || ""}
					options={companyOptions}
					onChange={handleDropdownChange}
					onBlur={handleAutoSave}
					className="w-full"
				/>
			</div>

			{/* Company Code Dropdown */}
			<div className="field col-12 md:col-6">
				<label htmlFor="company_code">Company Code</label>
				<Dropdown
					id="company_code"
					name="company_code"
					value={updatedUser.company_code || ""}
					options={companyOptions}
					onChange={handleDropdownChange}
					onBlur={handleAutoSave}
					className="w-full"
				/>
			</div>

			{/* Panel Key Input */}
			<div className="field col-12 md:col-6">
				<label htmlFor="panel_key">Panel Key</label>
				<InputText
					id="panel_key"
					name="panel_key"
					value={updatedUser.panel_key || ""}
					onChange={handleInputChange}
					onBlur={handleAutoSave}
					className="w-full"
				/>
			</div>

			{/* Reagents Input */}
			<div className="field col-12 md:col-6">
				<label htmlFor="reagents">Reagents</label>
				<InputText
					id="reagents"
					name="reagents"
					value={updatedUser.reagents || ""}
					onChange={handleInputChange}
					onBlur={handleAutoSave}
					className="w-full"
				/>
			</div>
		</div>
	);
};

export default CompanyTab;
