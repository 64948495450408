import React, { useState, useEffect } from "react";
import axios from "axios";
import debounce from 'lodash/debounce';

//prime react components
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

//import the api funcitons and interfaces
import { activateUser, deactivateUser, updateUser, assignUserRole, removeUserRole, User, createUser, } from "../../../api/admin/userApi";
import { getAllRoles, Role } from "../../../api/admin/rolesApi";
import { fetchCompanies, Company } from "../../../api/admin/companyApi";

// import the components for the modal
import Sidebar from "./editUserModalComponents/Sidebar";
import PersonalTab from "./editUserModalComponents/PersonalTab";
import CompanyTab from "./editUserModalComponents/CompanyTab";
import PreferencesTab from "./editUserModalComponents/PreferencesTab";
import RolesTab from "./editUserModalComponents/RolesTab";
import SampleTab from "./editUserModalComponents/SampleTab"

interface EditUserModalProps {
	isOpen: boolean;
	onClose: () => void;
	user: User;
	onUserUpdated: (user: User) => void;
}

const EditUserModal: React.FC<EditUserModalProps> = ({ isOpen, onClose, user, onUserUpdated }) => {
	const [updatedUser, setUpdatedUser] = useState<User>({ ...user });
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const [activeTab, setActiveTab] = useState("Personal");
	const [roles, setRoles] = useState<Role[]>([]);
	const [companies, setCompanies] = useState<Company[]>([]);

	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const rolesData = await getAllRoles();
				setRoles(rolesData);
			} catch (error) {
				console.error("Error fetching roles:", error);
			}
		};

		const fetchCompaniesList = async () => {
			try {
				const response = await fetchCompanies(1, 30, "");
				setCompanies(response.data || []);
			} catch (error) {
				console.error("Error fetching companies:", error);
				setCompanies([]);
			}
		};

		fetchRoles();
		fetchCompaniesList();
	}, []);

	const onActiveClick = async () => {
		setIsSubmitting(true);
		try {
			let updatedUserData = { ...updatedUser };
			if (!updatedUser.is_active) {
				await activateUser(updatedUser.id);
				updatedUserData.is_active = true;
			} else {
				await deactivateUser(updatedUser.id);
				updatedUserData.is_active = false;
			}
			setUpdatedUser(updatedUserData);
			onUserUpdated(updatedUserData);
		} catch (error: unknown) {
			handleErrors(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleSubmit = async () => {
		setIsSubmitting(true);
		try {
			let updatedUserData;
			if (updatedUser.id !== 0) {
				updatedUserData = await updateUser(updatedUser.id, updatedUser);
			} else {
				updatedUserData = await createUser(updatedUser);
			}
			setUpdatedUser(updatedUserData);
			//onUserUpdated(updatedUserData);
		} catch (error: unknown) {
			handleErrors(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	const handleAutoSave = async () => {
		if (updatedUser.id !== 0 ){
			handleSubmit();
		}
	}

	const handleErrors = (error: unknown) => {
		if (axios.isAxiosError(error)) {
			if (error.response?.data?.errors) {
				const errMsgs = Object.entries(error.response.data.errors)
					.flatMap(([_, messages]) => messages as string);
				setErrorMessages(errMsgs);
			} else if (error.response?.data?.message) {
				setErrorMessages([error.response.data.message]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} else if (error instanceof Error) {
			setErrorMessages([error.message || "An unknown error occurred."]);
		} else {
			setErrorMessages(["An unknown error occurred."]);
		}
	};

	const header = () => {
		return (
			<div className="flex justify-content-between">
				<div className="">{user.id ? "Edit " + user.first_name + " " + user.last_name : "Create New Patient"}</div>

				<div>
					<Button icon="pi pi-save p-icon"
						rounded
						text aria-label="Save"
						loading={isSubmitting}
						disabled={isSubmitting}
						type="button"
						onClick={handleSubmit}
						className="p-dialog-header-icon mr-2"
						style={{ color: 'var(--text-color-alternative)' }}
					/>
				</div>

			</div>
		)

	};

	return (
		<Dialog
			visible={isOpen}
			onHide={() => {onUserUpdated(updatedUser); onClose();}}
			modal
			dismissableMask
			style={{ width: '80vw', height: '80vh' }}
			closable={true}
			draggable={true}
			maximizable={true}
			header={header}
			headerClassName="pb-0 h-auto bg-primary "
			contentStyle={{ padding: 0 }}
			className="custom-dialog"
			focusOnShow={false}
		>
			<div className="flex w-full h-full" style={{ overflow: 'hidden' }}>
				{/* Sidebar */}
				<div className="border-right-1 surface-border" style={{ overflowY: 'auto' }}>
					<Sidebar activeTab={activeTab} setActiveTab={setActiveTab} user={user} />
				</div>

				{/* Main Content Area */}
				<div className="flex flex-column flex-1">
					<div className="flex-1 overflow-y-auto p-4">
						{/* Tabs */}
						{activeTab === "Personal" && (
							<PersonalTab updatedUser={updatedUser} setUpdatedUser={setUpdatedUser} handleAutoSave={handleAutoSave}/>
						)}

						{activeTab === "Company" && (
							<CompanyTab updatedUser={updatedUser} setUpdatedUser={setUpdatedUser} companies={companies} handleAutoSave={handleAutoSave}/>
						)}

						{activeTab === "Preferences" && (
							<PreferencesTab updatedUser={updatedUser} setUpdatedUser={setUpdatedUser} handleAutoSave={handleAutoSave}/>
						)}

						{activeTab === "Roles" && (
							<RolesTab updatedUser={updatedUser} setUpdatedUser={setUpdatedUser} roles={roles} handleAutoSave={handleAutoSave}/>
						)}

						{activeTab === "Subscriptions" && (
							<div>
								<p>Not active at the moment</p>
							</div>
						)}

						{activeTab === "Samples" && (
							<div>
								<SampleTab
									patient={updatedUser}
									isSubmitting={isSubmitting}
									setIsSubmitting={setIsSubmitting}
									setErrorMessages={setErrorMessages}
									handleAutoSave={handleAutoSave}
								/></div>
							
						)}
						

						{errorMessages.length > 0 && (
							<div className="mt-3">
								{errorMessages.map((msg, idx) => (
									<div key={idx} className="text-red-500">
										{msg}
									</div>
								))}
							</div>
						)}
					</div>

					{/* Footer */}
					<div className="flex justify-content-end p-4  surface-border">
						<Button
							label={updatedUser.is_active ? "Deactivate" : "Activate"}
							className="p-button-outlined mr-2"
							onClick={onActiveClick}
							disabled={isSubmitting}
						/>

					</div>
				</div>
			</div>
		</Dialog>

	);
};

export default EditUserModal;
