// src/api/supplierApi.ts

import axios from "axios";
import Cookies from "js-cookie";
const API_BASE_URL = process.env.REACT_APP_COMMERCE_SERVER;

export interface Supplier {
    supplier_id?: number;
    name: string;
    contact_name?: string;
    email?: string;
    phone?: string;
    address?: string;
}

export const fetchSuppliers = async (page: number = 1, perPage: number = 20, search?: string): Promise<{ data: Supplier[]; total: number }> => {
	const params: any = {
		page,
		per_page: perPage,
	};
	if (search) {
		params.search = search;
	}

	const response = await axios.get(`${API_BASE_URL}/suppliers`, {
		params,
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
    return {
        data: response.data.data,
        total: response.data.total,
    };
};

export const fetchSupplier = async (supplier_id: number): Promise<Supplier> => {
    const response = await axios.get(`${API_BASE_URL}/suppliers/${supplier_id}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};

export const createSupplier = async (supplierData: Supplier): Promise<Supplier> => {
    const response = await axios.post(`${API_BASE_URL}/suppliers`, supplierData, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};

export const updateSupplier = async (supplier_id: number, supplierData: Partial<Supplier>): Promise<Supplier> => {
    const response = await axios.put(`${API_BASE_URL}/suppliers/${supplier_id}`, supplierData, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};

export const deleteSupplier = async (supplier_id: number): Promise<void> => {
    await axios.delete(`${API_BASE_URL}/suppliers/${supplier_id}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
};
