import React, { useEffect } from "react";
import { MygeneProgramStatus } from "../../../../api/healthcare/patientApi";
import { ToggleButton } from 'primereact/togglebutton';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';

interface MygeneProgramStatusTabProps {
	status: MygeneProgramStatus;
	setStatus: React.Dispatch<React.SetStateAction<MygeneProgramStatus | undefined>>;
	handleAutoSave: () => void;
}

const MygeneProgramStatusTab: React.FC<MygeneProgramStatusTabProps> = ({ status, setStatus, handleAutoSave }) => {
	useEffect(() => {
		// Possibly fetch or re-check logic here if needed
	}, []);

	const handleToggle = (key: string, value: boolean) => {
		setStatus(prev => prev ? { ...prev, [key]: value } : prev);
	};

	const handleChange = (key: string, value: any) => {
		setStatus(prev => prev ? { ...prev, [key]: value } : prev);
	};

	const excludedKeys = ["id", "user_id", "created_at", "updated_at"];

	return (
		<div className="grid p-fluid">
			{Object.keys(status).map((key) => {
				if (excludedKeys.includes(key)) return null;

				const val = status[key as keyof MygeneProgramStatus];
				const isBoolean = typeof val === "boolean";
				const isDate = key.includes("date") || key.includes("test_due");

				return (
					<div key={key} className="field col-12 md:col-4 flex align-items-center gap-2">
						<label style={{ textTransform: 'capitalize', fontWeight: '500', width: '8rem' }}>
							{key.replace(/_/g, " ")}
						</label>
						{isBoolean ? (
							<ToggleButton
								checked={val as boolean}
								onLabel="Yes"
								offLabel="No"
								onIcon="pi pi-check"
								offIcon="pi pi-times"
								onChange={(e) => handleToggle(key, e.value)}
								onBlur={handleAutoSave}
							/>
						) : isDate ? (
							<Calendar
								value={
									typeof val === 'string' && val.length > 0 ? new Date(val) : null
								}
								onChange={(e) => handleChange(key, e.value)}
								onBlur={handleAutoSave}
								dateFormat="yy-mm-dd"
								showIcon
							/>
						) : (
							<InputText
								value={val ? val.toString() : ""}
								onChange={(e) => handleChange(key, e.target.value)}
								onBlur={handleAutoSave}
							/>
						)}
					</div>
				);
			})}
		</div>
	);
};

export default MygeneProgramStatusTab;
