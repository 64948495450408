// src/components/icons/CompanyManagementIcon.tsx
import React from 'react';
import { FaBuilding } from 'react-icons/fa'; // Importing the Font Awesome building icon

interface CompanyIconProps {
    className?: string;
}

const CompanyIcon: React.FC<CompanyIconProps> = ({ className }) => {
    return (
        <FaBuilding
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24} // Default size
            aria-label="Company Management"
        />
    );
};

export default CompanyIcon;
