import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { logout } from "../api/admin/userApi"; 

const Logout: React.FC = () => {
    useEffect(() => {
        const performLogout = async () => {
            try {
                await logout();
                sessionStorage.removeItem('apiKey');
				sessionStorage.removeItem('user');
            } catch (error) {
                console.error("Error during logout:", error);
            }
        };

        performLogout();
    }, []);

    // Redirect to the login page
    return <Navigate to="/login" replace />;
};

export default Logout;