import React, { useState, useEffect, useRef } from "react";
import Cookies from "js-cookie";

// PrimeReact
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from "primereact/floatlabel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

//API
import { AssignedForm, assignForm, fetchForms, Form, FormElement } from "../../../../api/formApi";
import { Patient } from "../../../../api/healthcare/patientApi";
import { User } from "../../../../api/admin/userApi";
import { Toast } from "primereact/toast";

interface AssignFormModalProps {
	isOpen: boolean;
	onClose: () => void;
	patient: Patient;
}

const AssignFormModal: React.FC<AssignFormModalProps> = ({ isOpen, onClose, patient }) => {
	const [availableForms, setAvailableForms] = useState<Form[]>();
	const [selectedForms, setSelectedForms] = useState<Form[]>([]);
	const user: User = JSON.parse(Cookies.get('user') || "{}");
	const toast = useRef<Toast>(null);

	useEffect(() => {
	loadForms();
		
	}, [isOpen]);

	const loadForms = async () => {
		try {

			const { data } = await fetchForms(user.company, "");

			// Convert `structure` from JSON string into the FormElement[] array
			const transformed = data.map((form: Form) => {
				if (typeof form.structure === 'string') {
					return {
						...form,
						structure: JSON.parse(form.structure) as FormElement[],
					};
				}
				return form;
			});
			setAvailableForms(transformed);
		} catch (error: unknown) {

		}
	};

	const handleSelectionChange = (e: any) => {
		setSelectedForms(e.value); // Update the selected forms
	};


	const handleSubmit = async () => {
		if (selectedForms.length <= 0) {
			toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'Select atleast one form to assign', life: 3000 });
			return
		};
		if (selectedForms) {
			try {
				for (let i = 0; i < selectedForms.length; i++) {
					//build the assignForm 
					const af: AssignedForm = {
						id: 0,
						company: user.company,
						form_id: selectedForms[i].id,
						patient_id: patient.id,
						practitioner_id: user.id,
						notes: "",
						status: "Assigned",
						created_at: new Date(),
						updated_at: new Date()

					};

					//assign it
					await assignForm(af);
				};
				toast.current?.show({ severity: 'success', summary: 'Success', detail: 'The forms have been assigned', life: 3000 });
				onClose();

			} catch (error: unknown) {
				console.log(error);
			}

		};

	};



	//bopdy temps
	const titleBodyTemplate = (rowData: Form) => rowData.title;

	const footer = (
		<div className="flex justify-content-end gap-2">
			<Button label="Cancel" className="p-button-outlined" onClick={onClose} />
			<Button label="Assign" onClick={handleSubmit} />
		</div>
	);

	// Map out the available forms to assign with checkboxes
	return (
		<Dialog
			visible={isOpen}
			onHide={onClose}
			header="Assign Form To Patient"
			footer={footer}
			modal
			dismissableMask
			style={{ width: '30rem' }}
		>
			<Toast ref={toast} />
			<div className="flex flex-column gap-3">

				{availableForms && (
					<DataTable
						value={availableForms}
						responsiveLayout="scroll"
						className="p-datatable-gridlines p-datatable-sm"
						selectionMode="checkbox"
						selection={selectedForms}
						onSelectionChange={handleSelectionChange}
					>
						<Column
							selectionMode="multiple"
							headerStyle={{ width: "3rem", textAlign: "center" }}
							bodyStyle={{ textAlign: "center" }}

						/>
						<Column field="title" header="title" body={titleBodyTemplate} />

					</DataTable>
				)}
			</div>
		</Dialog>
	);
};

export default AssignFormModal;
