import React, { useState } from 'react';
import { Practitioner } from '../../../api/healthcare/practitionerApi';
import { DataTable, DataTableStateEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface ProfessionalsTableProps {
	professionals: Practitioner[];
	totalProfessionals: number;
	currentPage: number;
	rows: number;
	onEditClick: (practitioner: Practitioner) => void;
	onPageChange: (page: number) => void;
}

const ProfessionalsTable: React.FC<ProfessionalsTableProps> = ({
	professionals,
	totalProfessionals,
	currentPage,
	rows,
	onEditClick,
	onPageChange
}) => {
	const [selectedProfessional, setSelectedProfessional] = useState<Practitioner | null>(null);

	if (!professionals || professionals.length === 0) {
		return <p>No professionals available.</p>;
	}

	// Convert 1-based `currentPage` to 0-based `first`
	const first = (currentPage - 1) * rows;

	const handlePageChange = (event: DataTableStateEvent) => {
		// event.page is 0-based, so we add 1
		const newPage = (event.page ?? 0) + 1;
		onPageChange(newPage);
	};

	const onRowSelect = (e: { data: Practitioner }) => {
		onEditClick(e.data);
	};

	const specialtiesBody = (rowData: Practitioner) => {
		const specialties = rowData.practitioner?.specialties || [];
		return specialties.join(", ");
	};

	return (
		<div className="card surface-0 border-round shadow-2 p-3">
			<DataTable
				value={professionals}
				selectionMode="single"
				selection={selectedProfessional}
				onSelectionChange={(e) => setSelectedProfessional(e.value as Practitioner)}
				onRowSelect={onRowSelect}
				paginator
				lazy
				first={first}
				rows={rows}
				totalRecords={totalProfessionals}
				onPage={handlePageChange}
				className="p-datatable-gridlines p-datatable-sm"
				responsiveLayout="scroll"
			>
				<Column field="first_name" header="First Name" style={{ minWidth: '150px' }} />
				<Column field="last_name" header="Last Name" style={{ minWidth: '150px' }} />
				<Column field="phone" header="Phone" style={{ minWidth: '120px' }} />
				<Column field="email" header="Email" style={{ minWidth: '200px' }} />
				<Column header="Specialties" body={specialtiesBody} style={{ minWidth: '200px' }} />
			</DataTable>
		</div>
	);
};

export default ProfessionalsTable;
