import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Layout from "../../components/Layout";
import LoadingSpinner from '../../components/icons/LoadingSpinner';

// Calnder components
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import { enAU } from 'date-fns/locale/en-AU';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './AppointmentPage.css';

// Component components
import NavButtons from "../../components/healthcare/appointments/NavButtons";
import AppointmentModal from "../../components/healthcare/appointments/AppointmentModal";

//API's
import { Appointment, fetchAppointments } from "../../api/healthcare/appointmentApi";
import { User } from "../../api/admin/userApi";


const locales = {
	'en-AU': enAU,
};

const localizer = dateFnsLocalizer({
	format,
	parse,
	startOfWeek,
	getDay,
	locales,
});

interface SlotInfo {
	action: string;
	end: Date;
	slots: Date[];
	start: Date;
}

interface Event {
	id: number;
	title: string;
	start: Date;
	end: Date;
	resource: {
		appointment: Appointment,
	};
}


const AppointmentManagementPage: React.FC = () => {
	const user: User = JSON.parse(Cookies.get("user") || "{}")
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const [events, setEvents] = useState<Event[]>([]);
	const [selectedAppointment, setSelectedAppointment] = useState<Appointment>();

	useEffect(() => {
		setIsLoading(true);
		loadAppointments();
		setIsLoading(false);
	}, []);

	const loadAppointments = async () => {
		const {data} = await fetchAppointments(user.company);
		const eventsLoading: Event[] = data.map((appointment) => ({
			id: appointment.id,
			title: appointment.title || 'No Title',
			start: appointment.start_time ? new Date(appointment.start_time) : new Date(),
			end: appointment.end_time ? new Date(appointment.end_time) : new Date(),
			resource: {
				appointment: {
					...appointment,
					start_time: new Date(appointment.start_time ? appointment.start_time : new Date()), 
					end_time: new Date(appointment.end_time ? appointment.end_time : new Date()),
					date: appointment.date ? new Date(appointment.date) : undefined,
				},
			}
		}));
		setEvents(eventsLoading)


	};

	

	const createNewAppointment = (slotinfo?: SlotInfo) => {
		setSelectedAppointment({
			created_at: new Date(),
			date: slotinfo?.slots[0],
			end_time: slotinfo?.end,
			start_time: slotinfo?.start,
			id: 0,
			patient_id: 0,
			practitioner_id: 0,
			status: "",
			updated_at: new Date(),
			company: user.company,
			title: "",
			category: "",
			location: "",
			room_number: ""
		})
		setIsModalOpen(true);
	};

	const editAppointment = (event: Event) => {
		setSelectedAppointment(event.resource.appointment);
		setIsModalOpen(true);
	};


	return (
		<Layout>
			<div className="p-3">
				<NavButtons createNewAppointment={createNewAppointment}/>


				{isLoading ? (
					<div className="flex justify-content-center align-items-center" style={{ height: '200px' }}>
						<LoadingSpinner size={60} color="#354396" />
					</div>
				) : (
						<div className="grid">
							<div className="col-12 ">
								<Calendar
									localizer={localizer}
									events={events}
									startAccessor="start"
									endAccessor="end"
									defaultView="day"
									style={{ height: '82vh' }}
									selectable={true}
									onSelectSlot={(slotInfo) => {
										createNewAppointment(slotInfo);
									  }}
									  onSelectEvent={(event) => {
										editAppointment(event)
									  }}
								/>
							</div>
						</div>
         
				)}
			</div>

			{isModalOpen && selectedAppointment && (
				<AppointmentModal
				user={user}
					isOpen={isModalOpen}
					onClose={() => {setIsModalOpen(false); loadAppointments();}}
					selectedAppointment={selectedAppointment}
				/>
			)}
		</Layout>
	);
};

export default AppointmentManagementPage;
