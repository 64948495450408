// ActionButtons.tsx
import React, { useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { deleteSample } from "../../../api/labiq/labiqApi";
import { fetchPatientAuthSamples } from "../../../api/labiq/geneCodesApi";
import { Toast } from "primereact/toast";

interface SampleTrackingButtonsProps {
    selectedSamples: number[];
    selectedSampleClientId: string[];
    refreshSamples: (searchTerm: string) => void;
    editClick: (sampleID: number) => void;
    newSample: () => void;
    omnibeadUpload: () => void;
    searchTerm: string;
    setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
}

const SampleTrackingButtons: React.FC<SampleTrackingButtonsProps> = ({ selectedSamples, selectedSampleClientId, refreshSamples, editClick, newSample, omnibeadUpload, searchTerm, setSearchTerm }) => {
    const toast = useRef<Toast>(null);

    const handleDeleteSamples = async () => {
        try {
            for (let i = 0; i < selectedSamples.length; i++) {
                const clientId = selectedSampleClientId[i];
                const id = selectedSamples[i];
                const samplesPatient = await fetchPatientAuthSamples(clientId.toString());

                if (samplesPatient.length === 0) {
                    await deleteSample(id);
                } else {
                    toast.current?.show({ severity: "warn", summary: "Deletion Failed", detail: `Sample ID ${id} is assigned to a patient and cannot be deleted.` });
                }
            }
            refreshSamples("");
            // Clear selections after deletion
            // Assuming you have a way to clear selections from the parent component
        } catch (err) {
            console.error("Error deleting samples:", err);
            toast.current?.show({ severity: "error", summary: "Error", detail: "Failed to delete samples." });
        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        refreshSamples(e.target.value);
    };

    const handleReset = () => {
        setSearchTerm("");
        refreshSamples("");
    };

    return (
		<div className="flex justify-content-between">
			<div className="flex flex-wrap pb-4">
				<div className="">
					<Button label="New Sample" icon="pi pi-plus" className="p-button-primary mr-2" onClick={newSample} />
					<Button label="Edit Sample" icon="pi pi-pencil" className="p-button-success mr-2" onClick={() => editClick(selectedSamples[0])} disabled={selectedSamples.length !== 1} />
					<Button label="Delete Sample" icon="pi pi-trash" className="p-button-danger" onClick={handleDeleteSamples} disabled={selectedSamples.length === 0} />



				</div>

				<Toast ref={toast} />
			</div>
			<div className="justify-content-end">
				<span className="mr-2">
					<InputText type="text" placeholder="Search by Sample ID..." value={searchTerm} onChange={handleSearchChange} className="p-inputtext-sm" />
				</span>
				<Button label="Reset" icon="pi pi-refresh" className="p-button-outlined" onClick={handleReset} />
			</div>
		</div>
		
    );
};

export default SampleTrackingButtons;
