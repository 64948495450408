import React, { useEffect, useState } from "react";
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { assignPractitioners, unassignPractitioner, getAssignedPractitioners, fetchPractitioners, Practitioner, getPractitionerTypes, PractitionerType } from "../../../../api/healthcare/practitionerApi";
import { Patient } from "../../../../api/healthcare/patientApi";

// If you prefer PrimeReact MultiSelect, import it. For now, we'll keep react-select.
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

interface AssignPractitionersTabProps {
	patientId: number;
}

interface OptionType {
	value: any;
	label: string;
}

const animatedComponents = makeAnimated();

const AssignPractitionersTab: React.FC<AssignPractitionersTabProps> = ({ patientId }) => {
	const [assignedPractitioners, setAssignedPractitioners] = useState<Practitioner[]>([]);
	const [availablePractitioners, setAvailablePractitioners] = useState<Practitioner[]>([]);
	const [selectedPractitioner, setSelectedPractitioner] = useState<OptionType | null>(null);
	const [selectedRole, setSelectedRole] = useState<OptionType[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [pracTypes, setPracTypes] = useState<PractitionerType[]>([]);

	useEffect(() => {
		fetchAssignedPractitioners();
		fetchAvailablePractitioners();
		fetchPractitionerTypes();
		// eslint-disable-next-line
	}, [patientId]);

	const fetchAssignedPractitioners = async () => {
		try {
			const data = await getAssignedPractitioners(patientId);
			setAssignedPractitioners(data);
		} catch (err: any) {
			setError('Failed to fetch assigned practitioners.');
			console.error(err);
		}
	};

	const fetchAvailablePractitioners = async () => {
		const user = JSON.parse(sessionStorage.getItem('user') || "{}");
		try {
			const data = await fetchPractitioners(1, 30, user.company, "");
			setAvailablePractitioners(data.data);
		} catch (err: any) {
			setError('Failed to fetch available practitioners.');
			console.error(err);
		}
	};

	const fetchPractitionerTypes = async () => {
		try {
			const result = await getPractitionerTypes();
			setPracTypes(result);
		} catch (err) {
			console.error(err);
		}
	};

	const handleAssign = async () => {
		if (!selectedPractitioner || !selectedRole) {
			setError('Please select both a practitioner and a role.');
			return;
		}

		setLoading(true);
		setError(null);
		try {
			let rolesAssigned: string[] = [];
			for (let i = 0; i < selectedRole.length; i++) {
				rolesAssigned.push(selectedRole[i].value);
			}

			await assignPractitioners(patientId, selectedPractitioner.value, rolesAssigned);
			await fetchAssignedPractitioners();
			await fetchAvailablePractitioners();
			setSelectedPractitioner(null);
			setSelectedRole([]);
		} catch (err: any) {
			setError(err.message || 'Failed to assign practitioners.');
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	const handleUnassign = async (practitionerId: number) => {
		setLoading(true);
		setError(null);

		try {
			await unassignPractitioner(patientId, practitionerId);
			await fetchAssignedPractitioners();
			await fetchAvailablePractitioners();
		} catch (err: any) {
			setError(err.message || 'Failed to unassign practitioner.');
			console.error(err);
		} finally {
			setLoading(false);
		}
	};

	// Prepare options for react-select
	const practitionerOptions: OptionType[] = availablePractitioners.map(prac => ({
		value: prac.practitioner.id,
		label: `${prac.first_name} ${prac.last_name}`,
	}));

	const practitionerTypesOptions: OptionType[] = pracTypes.map(type => ({
		value: type.title,
		label: type.title,
	}));

	// DataTable columns for assigned practitioners
	const columns = [
		{ field: 'first_name', header: 'Name', body: (rowData: Practitioner) => `${rowData.first_name} ${rowData.last_name}` },
		{ field: 'pivot?.role', header: 'Role', body: (rowData: Practitioner) => rowData.pivot?.role },
		{
			field: 'action', header: 'Actions', body: (rowData: Practitioner) => (
				rowData.id > 0 && (
					<Button
						label="Unassign"
						className="p-button-text p-button-danger"
						onClick={() => handleUnassign(rowData.practitioner.id)}
					/>
				)
			)
		}
	];

	return (
		<div className="flex flex-column gap-3 p-3">
			{/* Assigned Practitioners */}
			<div className="card surface-0 border-round shadow-2 p-3">
				<h2 className="mb-3" style={{ fontSize: '1.25rem' }}>Assigned Practitioners</h2>
				{assignedPractitioners.length === 0 ? (
					<p>No practitioners assigned.</p>
				) : (
					<DataTable value={assignedPractitioners} responsiveLayout="scroll" className="p-datatable-gridlines p-datatable-sm">
						{columns.map(col => (
							<Column
								key={col.field}
								field={typeof col.field === 'string' ? col.field : undefined}
								header={col.header}
								body={col.body}
							/>
						))}
					</DataTable>
				)}
			</div>

			{/* Assign Practitioners */}
			<div className="card surface-0 border-round shadow-2 p-3">
				<h2 className="mb-3" style={{ fontSize: '1.25rem' }}>Assign New Practitioners</h2>
				<div className="flex align-items-center gap-2 mb-2">
					{/* Practitioner Dropdown */}
					<div className="flex-1">
						<Select
							components={animatedComponents}
							options={practitionerOptions}
							value={selectedPractitioner}
							onChange={(selected) => setSelectedPractitioner(selected as OptionType)}
							placeholder="Select practitioner..."
						/>
					</div>
					{/* Role(s) Dropdown */}
					<div className="flex-1">
						<Select
							components={animatedComponents}
							options={practitionerTypesOptions}
							isMulti
							value={selectedRole}
							onChange={(selected) => setSelectedRole(selected as OptionType[])}
							placeholder="Select role..."
						/>
					</div>
					{/* Assign Button */}
					<Button
						label={loading ? 'Assigning...' : 'Assign'}
						className="p-button-outlined"
						onClick={handleAssign}
						disabled={loading || !selectedPractitioner || selectedRole.length === 0}
					/>
				</div>
				{error && <div style={{ color: 'red' }}>{error}</div>}
			</div>
		</div>
	);
};

export default AssignPractitionersTab;
