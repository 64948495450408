import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import debounce from 'lodash/debounce';
import Cookies from "js-cookie";

//Prime react
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

import { activatePatient, deactivatePatient, updatePatient, Patient, createPatient, MygeneProgramStatus, fetchPatientProgramStatus, updatePatientProgramStatus, createPatientProgramStatus } from "../../../api/healthcare/patientApi";

//import the modal components
import NavButtons from "./modalComponents/Sidebar";
import ProfileTab from "./modalComponents/ProfileTab";
import BillingTab from "./modalComponents/BillingTab";
import SampleTab from "./modalComponents/SampleTab";
import MygeneProgramStatusTab from "./modalComponents/MygeneProgramStatusTab";
import FilesTab from "./modalComponents/FilesTab";
import PractitionersTab from "./modalComponents/PractitionersTab";
import FormsTab from "./modalComponents/FormsTab";


interface EditPatientModalProps {
	isOpen: boolean;
	onClose: () => void;
	patient: Patient;
	onPatientUpdated: () => void;
	isAnon: boolean;
}

const EditPatientModal: React.FC<EditPatientModalProps> = ({ isOpen, onClose, patient, onPatientUpdated, isAnon }) => {
	const [updatedPatient, setUpdatedPatient] = useState<Patient>({ ...patient });
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const [activeTab, setActiveTab] = useState("Personal");
	const [status, setStatus] = useState<MygeneProgramStatus>();

	const toast = useRef<Toast>(null);

	useEffect(() => {
		setUpdatedPatient({ ...patient });
		if (patient.company === "") {
			const userCompany = JSON.parse(Cookies.get("user") || "{}").company;
			console.log(userCompany);
			setUpdatedPatient((prevPatient) => ({
				...prevPatient,
				company: userCompany,
				company_code: userCompany,
			}));
		}

		if (patient.id !== 0) {
			const fetchStatus = async () => {
				const response = await fetchPatientProgramStatus(patient.id);
				setStatus(response.data);
			};
			fetchStatus();
		} else {
			setStatus({
				user_id: 0,
				program_elected: "NA",
				swab_kit_sent: false,
				swab_kit_returned: false,
				questionnaire_sent: false,
				questionnaire_completed: false,
				questionnaire_sent_admin: false,
				pedigree_chart_filled: false,
				pathology_consult_completed: false,
				pathology_forms_sent: false,
				initial_blood_test_completed: false,
				genetic_results_ready: false,
				consult_90_minute_booked: false,
				consult_90_minute_completed: false,
				date_90_minute_consult: null,
				initial_blood_consult_completed: false,
				date_initial_blood_consult: null,
				follow_up_blood_test_due: null,
				follow_up_blood_test_completed: false,
				follow_up_blood_consult_completed: false,
			});
		}

		//if the patient info is null then open a empty object array to stup put errors from the backend when autosaving
		if (patient.patient_info === null) {
			setUpdatedPatient((prevPatient) => ({
				...prevPatient,
				patient_info: {},
			}));
		}

		if (isAnon) {
			setUpdatedPatient((prevPatient) => ({
				...prevPatient,
				is_anonymous: true,
			}));
		}
	}, [patient]);


	const onActiveClick = async () => {
		setIsSubmitting(true);
		try {
			const updatedPatientStatus = { ...updatedPatient };
			if (!updatedPatient.is_active) {
				await activatePatient(patient.id);
				updatedPatientStatus.is_active = true;
			} else {
				await deactivatePatient(patient.id);
				updatedPatientStatus.is_active = false;
			}
			setUpdatedPatient(updatedPatientStatus);
			onPatientUpdated();
		} catch (error: unknown) {
			handleErrors(error);
		} finally {
			setIsSubmitting(false);
		}
	};


	const updatePatientInfo = async () => {
		setIsSubmitting(true);
		try {
			if (isAnon) {
				if (
					!updatedPatient.email ||
					!updatedPatient.username ||
					!updatedPatient.password ||
					!updatedPatient.password_confirmation
				) {
					setErrorMessages(["Email, username and password are required."]);
					setIsSubmitting(false);
					return;
				}
			} else {
				if (
					!updatedPatient.first_name ||
					!updatedPatient.last_name ||
					!updatedPatient.email ||
					!updatedPatient.username
				) {
					setErrorMessages(["First name, last name, email, and username are required."]);
					setIsSubmitting(false);
					return;
				}
			}


			if (patient.id !== 0) {
				const response = await updatePatient(patient.id, updatedPatient);
				setUpdatedPatient(response);
				if (status) {
					await updatePatientProgramStatus(patient.id, status);
				}
			} else {
				if(updatedPatient.password !== updatedPatient.password_confirmation){
					setErrorMessages(["Passwords do not match"]);
					return;
				}else if (updatedPatient.password.length < 8){
					setErrorMessages(["Passwords must be atleast 8 characters"]);
					return;
				}

				const newPatient = await createPatient(updatedPatient);
				setUpdatedPatient(newPatient);
				if (status) {
					const updatedStatus = { ...status, user_id: newPatient.id };
					await createPatientProgramStatus(updatedStatus);
				}
			}
			//onPatientUpdated();
		} catch (error: unknown) {
			handleErrors(error);
		} finally {
			setIsSubmitting(false);
			toast.current?.show({
				severity: 'success',
				summary: 'Success',
				detail: 'Patient auto-saved successfully.',

			});
		}
	};

	const nothing = () => { };

	const handleAutoSave = debounce((patient.id ? updatePatientInfo : nothing), 500);

	const handleErrors = (error: unknown) => {
		if (axios.isAxiosError(error) && error.response) {
			if (error.response.data?.errors) {
				const messages = Object.entries(error.response.data.errors)
					.flatMap(([_, errs]) => errs as string);
				setErrorMessages(messages);
			} else if (error.response.data?.message) {
				setErrorMessages([error.response.data.message]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} else if (error instanceof Error) {
			setErrorMessages([error.message || "An unknown error occurred."]);
		} else {
			setErrorMessages(["An unknown error occurred."]);
		}
	};

	const header = () => {
		return (
			<div className="flex justify-content-between">
				<Toast ref={toast} />
				<div className="">{patient.id ? "Edit " + patient.first_name + " " + patient.last_name : (isAnon ? "Create Anonymous Patient" : "Create New Patient")}</div>

				<div>
					<Button icon="pi pi-save p-icon"
						rounded
						text aria-label="Save"
						loading={isSubmitting}
						disabled={isSubmitting}
						type="button"
						onClick={updatePatientInfo}
						className="p-dialog-header-icon mr-2"
						style={{ color: 'var(--text-color-alternative)' }}
					/>
				</div>

			</div>
		)

	};


	return (
		<Dialog

			visible={isOpen}
			onHide={() => {
				onPatientUpdated();
				onClose();
			}}
			style={{ width: '80vw', height: '80vh' }}
			modal
			dismissableMask
			focusOnShow={false}
			header={header}
			headerClassName="pb-0 h-auto bg-primary "
			contentStyle={{ padding: 0, height: '100%' }}
			closable={true}
			maximizable
			className="custom-dialog"
		>
			<div className="flex w-full h-full" style={{ overflow: 'hidden' }}>
				{/* Sidebar */}
				<div className="border-right-1 surface-border" style={{ overflowY: 'auto' }}>
					<NavButtons activeTab={activeTab} setActiveTab={setActiveTab} patient={updatedPatient} isAnon={updatedPatient.is_anonymous as boolean} />
				</div>

				{/* Main content area */}
				<div className="flex flex-column p-4" style={{ flex: 1, overflowY: 'auto' }}>
					<div className="flex-1 overflow-y-auto overflow-x-hidden">
						{activeTab === "Personal" && (
							<ProfileTab
								updatedPatient={updatedPatient}
								setUpdatedPatient={setUpdatedPatient}
								handleAutoSave={handleAutoSave}
								isAnon={updatedPatient.is_anonymous as boolean}
							/>
						)}
						{activeTab === "Billing" && (
							<BillingTab
								updatedPatient={updatedPatient}
								setUpdatedPatient={setUpdatedPatient}
								handleAutoSave={handleAutoSave}
							/>
						)}
						{activeTab === "Samples" && (
							<SampleTab
								patient={patient}
								isSubmitting={isSubmitting}
								setIsSubmitting={setIsSubmitting}
								setErrorMessages={setErrorMessages}
								handleAutoSave={handleAutoSave}
							/>
						)}
						{activeTab === "MygeneProgramStatus" && status && (
							<MygeneProgramStatusTab
								status={status}
								setStatus={setStatus}
								handleAutoSave={handleAutoSave}
							/>
						)}
						{activeTab === "Files" && (
							<FilesTab patient={patient} setErrorMessages={setErrorMessages} />
						)}
						{activeTab === "Practitioners" && (
							<PractitionersTab patientId={patient.id} />
						)}

						{activeTab === "Forms" && (
							<FormsTab updatedPatient={updatedPatient}
								setUpdatedPatient={setUpdatedPatient}
								handleAutoSave={handleAutoSave}
							/>
						)}
					</div>

					{errorMessages.length > 0 && (
						<div className="mt-3">
							{errorMessages.map((msg, idx) => (
								<div key={idx} style={{ color: 'red' }}>
									{msg}
								</div>
							))}
						</div>
					)}

					<div className="flex justify-content-end gap-2">
						{activeTab === "Personal" && !updatedPatient.is_anonymous as boolean && (
							<Button
								label={updatedPatient.is_active ? "Deactivate" : "Activate"}
								className="p-button-outlined"
								onClick={onActiveClick}
								disabled={isSubmitting}
							/>
						)}

					</div>
				</div>
			</div>
		</Dialog>
	);
};

export default EditPatientModal;
