import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { createAuthSamples } from "../../../../api/labiq/geneCodesApi";
import { fetchReagentList, createSample, Sample, SampleFormData } from "../../../../api/labiq/labiqApi";
import { Patient, sampleCountOnPatient } from "../../../../api/healthcare/patientApi";
import { fetchAvailableKitIds, Kit, assignKitToPatient } from "../../../../api/inventory/kitApi";
import { Company, fetchSingleCompanyByName } from "../../../../api/admin/companyApi";
import { createStockAssignment, StockAssignment } from "../../../../api/inventory/stockAssignmentApi";

// PrimeReact
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FloatLabel } from "primereact/floatlabel";

interface CreateSampleSimpleModalProps {
	isOpen: boolean;
	onClose: () => void;
	setSamples: React.Dispatch<React.SetStateAction<Sample[]>>;
	patient: Patient;
}

const CreateSampleSimpleModal: React.FC<CreateSampleSimpleModalProps> = ({ isOpen, onClose, setSamples, patient }) => {
	const [formData, setFormData] = useState<SampleFormData>({
		client_sampleID: "",
		analysis_requested: [],
		delivery: "",
		tracking_number: "",
		delivery_vendor: "",
	});

	const [reagentList, setReagentList] = useState<string[]>([]);
	const [kitsAvailable, setKitsAvailable] = useState<Kit[]>([]);

	useEffect(() => {
		const fetchReagents = async () => {
			try {
				const reagents = await fetchReagentList();
				setReagentList(reagents);
			} catch (err) {
				console.error("Error fetching reagents:", err);
			}
		};

		const fetchAvailableKits = async () => {
			const user = JSON.parse(Cookies.get("user") || "{}");
			let company: Company = await fetchSingleCompanyByName(user.company);
			let ids: Kit[] = await fetchAvailableKitIds(company.id);
			setKitsAvailable(ids);
		};

		if (isOpen) {
			fetchReagents();
			fetchAvailableKits();
		}
		// eslint-disable-next-line
	}, [isOpen]);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleCheckboxChange = (reagent: string, checked: boolean) => {
		setFormData((prev) => {
			const analysisRequested = prev.analysis_requested ? [...prev.analysis_requested] : [];
			if (checked) {
				if (!analysisRequested.includes(reagent)) {
					analysisRequested.push(reagent);
				}
			} else {
				const index = analysisRequested.indexOf(reagent);
				if (index > -1) {
					analysisRequested.splice(index, 1);
				}
			}
			return { ...prev, analysis_requested: analysisRequested };
		});
	};

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = async () => {
		try {
			const user = JSON.parse(Cookies.get("user") || "{}");
			const sampleData: SampleFormData = {
				client_sampleID: formData.client_sampleID,
				analysis_requested: formData.analysis_requested,
				delivery: formData.delivery,
				client: user.company,
				...(formData.delivery === "courier" && {
					tracking_number: formData.tracking_number,
					delivery_vendor: formData.delivery_vendor,
				}),
			};

			const newSample = await createSample(sampleData);
			setSamples((prevSamples) => [...prevSamples, newSample]);

			await createAuthSamples(patient.email, sampleData.client_sampleID || "");
			await sampleCountOnPatient(patient.email, "gene", "increment");

			// Assign kit to patient
			let kit = await assignKitToPatient(sampleData.client_sampleID || "", patient.id);

			// Create a stock assignment
			let stockAssignment: StockAssignment = {
				stock_id: kit.stock_id,
				assignment_id: 0,
				assignee_type: "user",
				assignee_id: patient.id.toString(),
				quantity_assigned: 1,
			};
			await createStockAssignment(stockAssignment, patient.company);

			onClose();
		} catch (err) {
			console.error("Error creating sample:", err);
		}
	};

	const footer = (
		<div className="flex justify-content-end gap-2">
			<Button label="Cancel" className="p-button-outlined" onClick={onClose} />
			<Button label="Create" onClick={handleSubmit} />
		</div>
	);

	return (
		<Dialog
			visible={isOpen}
			onHide={onClose}
			header="Assign Kit To Patient"
			footer={footer}
			modal
			dismissableMask
			style={{ width: '30rem' }}
		>
			<div className="flex flex-column gap-3">
				<div>
					<label className="font-medium mb-1">Kit ID</label>
					{/* <select
						name="client_sampleID"
						value={formData.client_sampleID || ""}
						onChange={handleSelectChange}
						className="w-full p-2"
					>
						<option value="">Select from an available kit</option>
						{kitsAvailable.map((kit) => (
							<option key={kit.kit_code} value={kit.kit_code}>
								{kit.kit_code}
							</option>
						))}
					</select> */}
					<InputText
						name="client_sampleID"
						value={formData.client_sampleID || ""}
						onChange={handleInputChange}
						className="w-full mt-2"
						placeholder="Or enter a new kit ID"
					/>
				</div>

				<div>
					<label className="font-medium mb-1">Test Types</label>
					<div className="grid formgrid p-fluid">
						{reagentList.map((reagent) => (
							<div key={reagent} className="field-checkbox col-6">
								<input
									type="checkbox"
									id={reagent}
									value={reagent}
									checked={formData.analysis_requested?.includes(reagent) || false}
									onChange={(e) => handleCheckboxChange(reagent, e.target.checked)}
								/>
								<label htmlFor={reagent} style={{ marginLeft: '0.5rem' }}>{reagent}</label>
							</div>
						))}
					</div>
				</div>

				<div>
					<label className="font-medium mb-1">Delivery Method</label>
					<select
						name="delivery"
						value={formData.delivery || ""}
						onChange={handleSelectChange}
						className="w-full p-2"
					>
						<option value="">Select an option</option>
						<option value="courier">Courier</option>
						<option value="internal">Internal</option>
					</select>
				</div>

				{formData.delivery === "courier" && (
					<div className="flex flex-column gap-2">
						<div>
							
								<label className="font-medium mb-1">Tracking Number</label>
								<InputText
									name="tracking_number"
									value={formData.tracking_number || ""}
									onChange={handleInputChange}
									className="w-full"
								/>
							
						</div>
						<div>
							
								<label className="font-medium mb-1">Delivery Vendor</label>
								<select
									name="delivery_vendor"
									value={formData.delivery_vendor || ""}
									onChange={handleSelectChange}
									className="w-full p-2"
								>
									<option value="">Select an option</option>
									<option value="fedex">FedEX</option>
									<option value="ups">UPS</option>
									<option value="auspost">Australia Post</option>
									<option value="tnt">TNT</option>
								</select>
							
						</div>
					</div>
				)}
			</div>
		</Dialog>
	);
};

export default CreateSampleSimpleModal;
