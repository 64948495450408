import React, { useCallback, useMemo, useRef } from "react";
import { DataTable, DataTableStateEvent, SortOrder } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Toast } from "primereact/toast";
import { Sample } from "../../../api/labiq/labiqApi";
import Cookies from "js-cookie";

interface SampleTableProps {
	samples: Sample[];
	totalSamples: number; // Total number of samples for pagination
	currentPage: number; // Current page index (zero-based)
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>; // Function to update current page
	selectedSamples: number[];
	setSelectedSamples: React.Dispatch<React.SetStateAction<number[]>>;
	setSelectedSampleClientId: React.Dispatch<React.SetStateAction<string[]>>;
	selectedSampleClientId: string[];
	onSort: (field: string) => void;
	sortBy: string;
	sortOrder: SortOrder;
	onEditSample: (sample: Sample) => void;
	onPageChange: (serchTerm: string) => void; // Pagination handler
}

const SampleTable: React.FC<SampleTableProps> = ({
	samples,
	totalSamples,
	currentPage,
	setCurrentPage,
	selectedSamples,
	setSelectedSamples,
	setSelectedSampleClientId,
	selectedSampleClientId,
	onSort,
	sortBy,
	sortOrder,
	onEditSample,
	onPageChange,
}) => {
	const user = JSON.parse(Cookies.get("user") || "{}");
	// Handler for selection change via checkboxes
	const onSelectionChange = (e: any) => {
		const selected = e.value;
		setSelectedSamples(selected.map((sample: Sample) => sample.id));
		setSelectedSampleClientId(
			selected.map((sample: Sample) => sample.sampleID || "")
		);
	};

	// Handler for sorting
	const onSortHandler = (e: DataTableStateEvent) => {
		const field = e.sortField;
		onSort(field);
	};

	// Helper function to determine if the click was on a checkbox
	const isClickOnCheckbox = (event: React.MouseEvent) => {
		const target = event.target as HTMLElement;
		return target.closest('.p-checkbox-box') !== null;
	};

	// Handler for row clicks
	const onRowClickHandler = (event: any) => {
		if (!isClickOnCheckbox(event.originalEvent)) {
			const selectedSample: Sample = event.data;
			onEditSample(selectedSample);
		}
	};


	return (
		<div className="card surface-0 border-round shadow-2 p-3">

			<DataTable
				value={samples}
				selection={samples.filter((sample) =>
					selectedSamples.includes(sample.id)
				)}
				onSelectionChange={onSelectionChange}
				selectionMode="checkbox"

				paginator
				lazy
				rows={30}
				totalRecords={totalSamples}
				first={currentPage * 30}
				onPage={(e) => {
					setCurrentPage(e.page as number);
					onPageChange("");
				}}

				globalFilter={null}
				sortField={sortBy}
				sortOrder={sortOrder}
				onSort={onSortHandler}
				sortMode="single"

				onRowClick={onRowClickHandler}
				emptyMessage="No samples available."
				scrollable
				scrollHeight="70vh"
				className="p-datatable-gridlines p-datatable-sm cursor-pointer"
				rowHover={true}

			>
				<Column
					selectionMode="multiple"
					headerStyle={{ width: "3rem", textAlign: "center" }}
					bodyStyle={{ textAlign: "center" }}
					//header={headerCheckboxTemplate}
				/>
				<Column field="id" header="ID" sortable style={{ minWidth: "5rem" }} />
				<Column
					field="sampleID"
					header="Sample ID"
					sortable
					style={{ minWidth: "10rem" }}
				/>
				<Column
					field="sampleOA.batch.batch_client_id"
					header="Batch ID"
					//sortable
					body={(rowData: Sample) =>
						rowData.sampleOA?.batch?.batch_client_id || ""
					}
					style={{ minWidth: "10rem" }}
				/>
				{(user.company === "DNAIQ" || user.company === "Mygene") && (
					<Column
						field="client"
						header="Client"
						//sortable
						style={{ minWidth: "10rem" }}
					/>
				)}

				<Column
					field="sampleOA.sampleType"
					header="Collection"
					//sortable
					body={(rowData: Sample) => rowData.sampleOA?.sampleType || ""}
					style={{ minWidth: "10rem" }}
				/>
				<Column
					field="sampleOA.QC_date"
					header="QC Date"
					//sortable
					body={(rowData: Sample) => rowData.sampleOA?.QC_date || ""}
					style={{ minWidth: "10rem" }}
				/>
				<Column
					field="sampleOA.concentration"
					header="ng/μl"
					//sortable
					body={(rowData: Sample) => rowData.sampleOA?.concentration || ""}
					style={{ minWidth: "8rem" }}
				/>
				<Column
					field="sampleOA.OD"
					header="260/280"
					//sortable
					body={(rowData: Sample) => rowData.sampleOA?.OD || ""}
					style={{ minWidth: "8rem" }}
				/>
				<Column
					field="sampleOA.OD2"
					header="260/230"
					//sortable
					body={(rowData: Sample) => rowData.sampleOA?.OD2 || ""}
					style={{ minWidth: "8rem" }}
				/>
				<Column
					field="sampleOA.eProtocol"
					header="eP"
					//sortable
					body={(rowData: Sample) => rowData.sampleOA?.eProtocol || ""}
					style={{ minWidth: "5rem" }}
				/>
				<Column
					field="sampleOA.status"
					header="Status"
					//sortable
					body={(rowData: Sample) => rowData.sampleOA?.status || ""}
					style={{ minWidth: "8rem" }}
				/>
				<Column
					field="sampleOA.comments"
					header="Comments"
					//sortable
					body={(rowData: Sample) => rowData.sampleOA?.comments || ""}
					style={{ minWidth: "15rem" }}
				/>

			</DataTable>
		</div>
	);
};

export default SampleTable;
