import React, { useRef } from "react";

//Prime react components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FloatLabel } from "primereact/floatlabel";

import { Batch, BatchSample, Sample, SampleFormData, updateSample } from "../../../../api/labiq/labiqApi";
import Cookies from "js-cookie";

interface BatchSamplesProps {
	formData: Partial<Batch>;
	setFormData: React.Dispatch<React.SetStateAction<Partial<Batch>>>;
	reagentList: string[];
	batch_id: number;
	handleAutoSave: () => void;
	submittedSamples: Sample[] | undefined;
	setSubmittedSamples: React.Dispatch<React.SetStateAction<Sample[] | undefined>>;
}

interface OptionType {
	label: string;
	value: string;
}

const statusOptions = [
	{ label: "Dispatched To Client", value: "dispatched" },
	{ label: "Returning To Lab", value: "returning" },
	{ label: "Received At Lab", value: "received" },
	{ label: "Processing", value: "processing" },
	{ label: "Ready To Submit To AGRF", value: "submitAgrf" },
	{ label: "Process At AGRF", value: "processingAgrf" },
	{ label: "Data ready to upload", value: "uploadReady" },
	{ label: "On Hold", value: "hold" },
	{ label: "Complete", value: "complete" },
	{ label: "Destroy", value: "destroy" },
];

const BatchSamples: React.FC<BatchSamplesProps> = ({
	formData,
	setFormData,
	reagentList,
	batch_id,
	handleAutoSave,
	submittedSamples,
	setSubmittedSamples
}) => {
	const toast = useRef<Toast>(null);
	const samples = formData.samples || [];
	const user = JSON.parse(Cookies.get("user") || "{}");

	// Prepare MultiSelect options for Test Type
	const reagentOptions: OptionType[] = reagentList.map((reagent) => ({
		label: reagent,
		value: reagent,
	}));

	// Prepare options for Collection Type Dropdown
	const collectionTypeOptions: OptionType[] = [
		{ label: "Copan Swab", value: "copan swab" },
		{ label: "Buccal Swab", value: "buccal swab" },
		{ label: "DNAtape", value: "DNAtape" },
		{ label: "OraGene saliva", value: "OraGene saliva" },
	];


	// Handler for editing Sample ID
	const handleSampleIDChange = (bs_id: number, value: string) => {
		setFormData((prev) => {
			const updatedSamples = [...(prev.samples || [])];
			const sampleIndex = updatedSamples.findIndex((sample) => sample.bs_id === bs_id);

			if (sampleIndex !== -1) {
				updatedSamples[sampleIndex] = {
					...updatedSamples[sampleIndex],
					bs_sample_id: value
				};
			}
			return { ...prev, samples: updatedSamples };
		});
	};

	// Handler for editing Test Types
	const handleTestTypeChange = (bs_id: number, value: string[]) => {
		// Create a sorted copy of the selected values
		const sortedValue = [...value].sort((a, b) => a.localeCompare(b));

		setFormData((prev) => {
			const updatedSamples = [...(prev.samples || [])];
			const sampleIndex = updatedSamples.findIndex((sample) => sample.bs_id === bs_id);

			if (sampleIndex !== -1) {
				updatedSamples[sampleIndex] = {
					...updatedSamples[sampleIndex],
					bs_test_type: sortedValue
				};
			}

			return { ...prev, samples: updatedSamples };
		});
	};

	// Handler for editing Collection Type
	const handleCollectionTypeChange = (bs_id: number, value: string) => {
		setFormData((prev) => {
			const updatedSamples = [...(prev.samples || [])];
			const sampleIndex = updatedSamples.findIndex((sample) => sample.bs_id === bs_id);

			if (sampleIndex !== -1) {
				updatedSamples[sampleIndex] = {
					...updatedSamples[sampleIndex],
					bs_collection_type: value
				};
			}
			return { ...prev, samples: updatedSamples };
		});
	};

	// Handler for editing Collection Type
	const handleStatusChange = (sampleID: string, value: string) => {
		if(submittedSamples){
			setSubmittedSamples((prev) => {
				const updatedSamples = [...(prev || [])];
				const sampleIndex = updatedSamples.findIndex((sample) => sample.sampleID === sampleID);
	
				if (sampleIndex !== -1) {
					const existingSampleOA = updatedSamples[sampleIndex].sampleOA;

					if (existingSampleOA) {
						updatedSamples[sampleIndex] = {
							...updatedSamples[sampleIndex],
							sampleOA: {
								...existingSampleOA,
								sampleID: existingSampleOA.sampleID ?? 0,
								status: value,
							},
						};
					}
				};

				let formData = updatedSamples[sampleIndex].sampleOA as SampleFormData;
				formData.id = updatedSamples[sampleIndex].id;
				formData.sampleID = updatedSamples[sampleIndex].sampleID;
				formData.client = updatedSamples[sampleIndex].client;
				//send the updated to the server	
				const update = updateSample(updatedSamples[sampleIndex].id, formData)

				return  updatedSamples;
			});
		};
		
	};

	const handleBulkStatusChange = (value: string) => {
		if(submittedSamples){
			for(let i = 0; i < submittedSamples?.length; i++){
				const smplId = submittedSamples[i].sampleID;
				handleStatusChange(smplId, value); 
			}
		}
		
	};

	// Handler for removing a sample
	const handleRemoveSample = (bs_id: number) => {
		setFormData((prev) => {
			const updatedSamples = (prev.samples || []).filter((sample) => sample.bs_id !== bs_id);
			return { ...prev, samples: updatedSamples };
		});

		toast.current?.show({
			severity: "info",
			summary: "Removed",
			detail: "Sample removed",
			life: 3000
		});
	};

	

	const sampleIDBodyTemplate = (rowData: BatchSample) => {
		return (
			<InputText
				value={rowData.bs_sample_id || ""}
				onChange={(e) => handleSampleIDChange(rowData.bs_id, e.target.value)}
				onBlur={handleAutoSave}
				className="w-full"
				disabled={formData.batch_submission === 1}
			/>
		);
	};

	const testTypeBodyTemplate = (rowData: BatchSample) => {
		if(formData.batch_submission === 1 && rowData.bs_test_type.length === 0){
			return (
				<Dropdown
				value={"Defered"}
				options={collectionTypeOptions}
				onChange={(e) => handleCollectionTypeChange(rowData.bs_id, e.value)}
				onBlur={handleAutoSave}
				className="w-full"
				placeholder="Defered"
				disabled={formData.batch_submission === 1}
				dropdownIcon={formData.batch_submission === 1}
			/>
			);
		}

		return (
			<MultiSelect
				value={rowData.bs_test_type || []}
				options={reagentOptions}
				onChange={(e) => handleTestTypeChange(rowData.bs_id, e.value)}
				onBlur={handleAutoSave}
				className="w-full"
				display="chip"
				placeholder="Select test types"
				disabled={formData.batch_submission === 1}
				dropdownIcon={formData.batch_submission === 1}
			/>
		);
	};

	const collectionTypeBodyTemplate = (rowData: BatchSample) => {
		return (
			<Dropdown
				value={rowData.bs_collection_type || null}
				options={collectionTypeOptions}
				onChange={(e) => handleCollectionTypeChange(rowData.bs_id, e.value)}
				onBlur={handleAutoSave}
				className="w-full"
				placeholder="Select Collection Type"
				disabled={formData.batch_submission === 1}
				dropdownIcon={formData.batch_submission === 1}
			/>
		);
	};

	const actionsBodyTemplate = (rowData: BatchSample) => {
		return (
			<Button
				icon="pi pi-minus"
				className="p-button-rounded p-button-danger"
				onClick={(e) => {
					e.stopPropagation(); // Prevent row click event
					handleRemoveSample(rowData.bs_id);
				}}
				onBlur={handleAutoSave}
				aria-label="Remove"
				disabled={formData.batch_submission === 1}
			/>
		);
	};

	const statusBodyTemplate = (rowData: BatchSample) => {
		if (submittedSamples) {
			for (let i = 0; i < submittedSamples.length; i++) {
				if(submittedSamples[i].sampleID === rowData.bs_sample_id){
					return (
						<Dropdown
							value={submittedSamples[i].sampleOA?.status}
							options={statusOptions}
							onChange={(e) => handleStatusChange(rowData.bs_sample_id, e.value)}
							//onBlur={handleAutoSave}
							className="w-full"
							placeholder="Select Status"
							//disabled={formData.batch_submission === 1}
							dropdownIcon={formData.batch_submission === 1}
						/>
					);
				}
			}
		}

		return (null);
	};

	const statusHeadingTemplate = () => {

		return (
			<div className = "" >


				<Dropdown
					id="statuses"
					name="statuses"
					value={statusOptions}
					options={statusOptions}
					onChange={(e) => handleBulkStatusChange(e.value)}
					className="w-full"
					required
					placeholder="Set All Status's"
				/>


			</div >
		);
	};

	return (
		<div className="p-fluid">
			<Toast ref={toast} />

			<DataTable
				value={samples}
				dataKey="bs_id"
				rowHover
				responsiveLayout="scroll"
				className="p-datatable-gridlines p-datatable-sm w-full"
				scrollable
				scrollHeight="50vh"
			>
				<Column
					header="Sample ID"
					body={sampleIDBodyTemplate}
					sortable
					style={{ width: "150px" }}
				/>
				<Column
					header="Test Type"
					body={testTypeBodyTemplate}
					sortable
					style={{ minWidth: "200px" }}
				/>
				<Column
					header="Collection Type"
					body={collectionTypeBodyTemplate}
					sortable
					style={{ width: "150px" }}
					
				/>
				{(submittedSamples !== undefined && (user.company === "Mygene" || user.company === "DNAIQ")) && (
					<Column
						header={statusHeadingTemplate}
						body={statusBodyTemplate}
						exportable={false}
						style={{ width: "100px", textAlign: "center" }}
					/>
				)}
				{formData.batch_submission !== 1 && (
					<Column
						header="Actions"
						body={actionsBodyTemplate}
						exportable={false}
						style={{ width: "100px", textAlign: "center" }}
					/>
				)}
				
			</DataTable>

			
		</div>
	);
};

export default BatchSamples;
