// src/components/inventory/product/ProductTable.tsx

import React from "react";
import { useNavigate } from "react-router-dom";
import { DataTable, SortOrder } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Product } from "../../../api/inventory/productApi";

interface ProductTableProps {
	products: Product[];
	totalProducts: number;
	currentPage: number;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
	onSort: (field: string, order: number) => void;
	sortBy: string;
	sortOrder: SortOrder;
	onEditClick: (product: Product) => void;
	onDeleteClick: (product: Product) => void;
	loadProducts: () => void; // Function to reload products, useful for actions
}

const ProductTable: React.FC<ProductTableProps> = ({
	products,
	totalProducts,
	currentPage,
	setCurrentPage,
	onSort,
	sortBy,
	sortOrder,
	onEditClick,
	onDeleteClick,
	loadProducts
}) => {
	const navigate = useNavigate(); // Move useNavigate to the top level

	// Handler for DataTable's sort event
	const handleSort = (e: any) => {
		const field = e.sortField;
		const order = e.sortOrder as number;
		onSort(field, order);
	};

	// Template for the Actions column
	const actionsBodyTemplate = (rowData: Product) => {
		return (
			<div className="flex justify-center">
				<Button
					icon="pi pi-pencil"
					className="p-button-rounded p-button-success p-mr-2"
					onClick={() => onEditClick(rowData)}
					aria-label="Edit"
				/>
				<Button
					icon="pi pi-trash"
					className="p-button-rounded p-button-danger p-mr-2"
					onClick={() => onDeleteClick(rowData)}
					aria-label="Delete"
				/>
				<Button
					icon="pi pi-box"
					className="p-button-rounded p-button-info"
					onClick={() => handleManageStock(rowData.product_id)}
					aria-label="Manage Stock"
				/>
			</div>
		);
	};

	// Handler for Manage Stock button
	const handleManageStock = (productId: number) => {
		navigate(`/inventory/products/${productId}/stock`); // Use backticks for template literal
	};

	return (
		<div className="card">
			<DataTable
				value={products}
				paginator
				lazy
				rows={20}
				totalRecords={totalProducts}
				first={currentPage * 20}
				onPage={(e) => {
					setCurrentPage(e.page as number);
					loadProducts();
				}}
				onSort={handleSort}
				sortField={sortBy}
				sortOrder={sortOrder}
				sortMode="single"
				className="p-datatable-gridlines p-datatable-sm"
				emptyMessage="No products available."
				loading={false} // Managed by the parent component's loading state
				stripedRows
				rowHover
			>
				<Column field="product_id" header="Product ID" sortable style={{ minWidth: "8rem" }} />
				<Column field="name" header="Name" sortable style={{ minWidth: "12rem" }} />
				<Column field="sku" header="SKU" sortable style={{ minWidth: "10rem" }} />
				<Column field="barcode" header="Barcode" sortable style={{ minWidth: "10rem" }} />
				<Column
					field="unit_price"
					header="Unit Price"
					sortable
					body={(rowData: Product) => rowData.unit_price !== undefined ? `$${rowData.unit_price}` : "-"}
					style={{ minWidth: "8rem" }}
				/>
				<Column
					field="unit_cost"
					header="Unit Cost"
					sortable
					body={(rowData: Product) => rowData.unit_cost !== undefined ? `$${rowData.unit_cost}` : "-"}
					style={{ minWidth: "8rem" }}
				/>
				<Column
					header="Actions"
					body={actionsBodyTemplate}
					exportable={false}
					style={{ minWidth: "12rem", textAlign: "center" }}
				/>
			</DataTable>
		</div>
	);
};

export default ProductTable;
