// src/components/icons/ApplicationIcon.tsx
import React from 'react';
import { MdApps } from 'react-icons/md'; // Importing the Material Design icon

interface ApplicationIconProps {
    className?: string;
}

const ApplicationIcon: React.FC<ApplicationIconProps> = ({ className }) => {
    return (
        <MdApps
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24} // Default size
            aria-label="Application Management"
        />
    );
};

export default ApplicationIcon;
