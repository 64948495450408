import React from 'react';
import { format } from 'date-fns';
import { DataTable, SortOrder } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Patient } from '../../../api/healthcare/patientApi';
import HelixIconWithNumber from '../../icons/healthcare/HelixWithNumber';
import BloodDropIconWithNumber from '../../icons/healthcare/BloodWithNumber';

interface PatientTableProps {
	patients: Patient[];
	totalPatients: number;
	first: number;
	rows: number;
	onEditClick: (patient: Patient) => void;
	onPage: (event: any) => void;
	onSort: (event: any) => void;
	// Replace old "sorting" prop with direct sortField/sortOrder
	sortField?: string;
	sortOrder?: SortOrder; // Typically 1 for asc, -1 for desc
}

const PatientTable: React.FC<PatientTableProps> = ({
	patients,
	totalPatients,
	first,
	rows,
	onEditClick,
	onPage,
	onSort,
	sortField,
	sortOrder
}) => {

	if (!patients || patients.length === 0) {
		return <p>No patients available.</p>;
	}

	const geneSamplesBody = (rowData: Patient) => {
		return <HelixIconWithNumber count={rowData.total_gene_samples} />;
	};

	const bloodSamplesBody = (rowData: Patient) => {
		return <BloodDropIconWithNumber count={rowData.total_blood_samples} />;
	};

	const createdAtBody = (rowData: Patient) => {
		const date = new Date(rowData.created_at);
		return format(date, 'dd-MM-yyyy');
	};

	const isActiveBody = (rowData: Patient) => {
		return rowData.is_active
			? <span className="text-green-500 font-bold">✔</span>
			: <span className="text-red-500 font-bold">✖</span>;
	};

	const onRowSelect = (event: { data: Patient }) => {
		onEditClick(event.data);
	};

	return (
		<div className="card surface-0 border-round shadow-2 p-3">
			<DataTable
				value={patients}
				selectionMode="single"
				paginator
				lazy
				first={first}
				rows={rows}
				totalRecords={totalPatients}
				onPage={onPage}
				sortField={sortField}
				sortOrder={sortOrder}
				onSort={onSort}
				onRowSelect={onRowSelect}
				scrollable
				scrollHeight="75vh"
				className="p-datatable-gridlines p-datatable-sm cursor-pointer"
				rowHover={true}
			>
				<Column field="first_name" header="First Name" sortable />
				<Column field="last_name" header="Last Name" sortable />
				<Column field="phone" header="Phone" />
				<Column field="email" header="Email" sortable />
				<Column
					header="Gene Samples"
					body={geneSamplesBody}
					sortable
					field="total_gene_samples"
					style={{ padding: '0' }}
				/>
				<Column
					header="Blood Samples"
					body={bloodSamplesBody}
					sortable
					field="total_blood_samples"
				/>
				<Column
					header="Is Active"
					body={isActiveBody}
					field="is_active"
				/>
				<Column
					field="created_at"
					header="Created At"
					sortable
					body={createdAtBody}
				/>
			</DataTable>
		</div>
	);
};

export default PatientTable;
