import React from "react";
import Cookies from "js-cookie";
import { Patient } from "../../../../api/healthcare/patientApi";
import { hasRole } from "../../../../utils/validatePermissions";

interface SidebarProps {
	activeTab: string;
	setActiveTab: (tab: string) => void;
	patient: Patient;
	isAnon: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab, patient, isAnon }) => {
	const user = JSON.parse(Cookies.get("user") || "{}");

	// A small utility for the tab items
	const TabItem = ({ label, value }: { label: string; value: string }) => {
		const isActive = activeTab === value;
		return (
			<div
				className={`flex align-items-center p-2 border-round cursor-pointer transition-duration-200 
                    ${isActive ? 'bg-primary-100' : 'hover:surface-200'}`}
				style={{ gap: '0.5rem' }}
				onClick={() => setActiveTab(value)}
			>
				<span>{label}</span>
			</div>
		);
	};

	return (
		<div className="flex flex-column surface-100 text-color" style={{ maxWidth: '16rem', height: '100%', overflowY: 'auto' }}>
			{/* Profile Section */}
			<div className="flex flex-column align-items-center p-3">
				<img
					className="border-circle"
					src={patient.profile_picture || "/assets/images/default-profile.png"}
					alt={`${patient.first_name} ${patient.last_name}`}
					style={{ width: '8rem', height: '8rem', objectFit: 'cover' }}
				/>
				<div className="mt-2 font-medium text-center">
					{patient.first_name} {patient.last_name}
				</div>
			</div>
			<hr className="my-2 mx-2 border-none border-top-1 surface-border" />

			{/* Navigation Links */}
			<div className="flex flex-column gap-2 p-2">
				{!isAnon && <TabItem label="Overview" value="Overview" />}
				<TabItem label="Personal Info" value="Personal" />
				{/* {!isAnon && <TabItem label="Company Details" value="Company" />}
				{!isAnon && <TabItem label="Medical Information" value="Medical" />} */}
				{(!isAnon && hasRole(["admin"])) && <TabItem label="Forms" value="Forms" />}
				{!isAnon && <TabItem label="Billing & Insurance" value="Billing" />}
				{patient.id !== 0 && <TabItem label="Genetic Samples" value="Samples" />}
				{!isAnon && <TabItem label="Healthcare Practitioners" value="Practitioners" />}
				{(!isAnon && hasRole(["admin"]))  && <TabItem label="Appointments" value="Appointments" />}
				{/* {!isAnon && <TabItem label="Medication And Prescriptions" value="Medication" />} */}
				{user.company === "Mygene" && !isAnon && (
					<TabItem label="Mygene Program Status" value="MygeneProgramStatus" />
				)}
				{!isAnon && <TabItem label="Files" value="Files" />}
				
			</div>
		</div>
	);
};

export default Sidebar;
