// src/components/icons/PatientManagementIcon.tsx
import React from 'react';
import { RiCalendarScheduleFill } from "react-icons/ri";

interface PatientManagementIconProps {
	className?: string;
}

const PatientManagementIcon: React.FC<PatientManagementIconProps> = ({ className }) => {
	return (
		<RiCalendarScheduleFill
			className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
			size={24} // Default size
			aria-label="Patient Management"
		/>
	);
};

export default PatientManagementIcon;
