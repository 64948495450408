// src/components/icons/SettingsIcon.tsx
import React from 'react';
import { FaCog } from 'react-icons/fa'; // Importing the Font Awesome settings icon

interface SettingsIconProps {
    className?: string;
}

const SettingsIcon: React.FC<SettingsIconProps> = ({ className }) => {
    return (
        <FaCog
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24} // Default size
            aria-label="Settings"
        />
    );
};

export default SettingsIcon;
