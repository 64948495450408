import React, { useState, useEffect } from "react";
import { Company, getStaff } from "../../../../api/admin/companyApi";
import { User } from "../../../../api/admin/userApi";
import { Button } from 'primereact/button';
import EditUserModal from "../../user/EditUserModal";

interface StaffTabProps {
	company: Company;
	isSubmitting: boolean;
}

const StaffTab: React.FC<StaffTabProps> = ({ company }) => {
	const [staffList, setStaffList] = useState<User[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [pageTotal, setPageTotal] = useState<number>(30);
	const [totalStaff, setTotalStaff] = useState<number>(0);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [selectedUser, setSelectedUser] = useState<User | null>(null);

	useEffect(() => {
		loadStaff();
		// eslint-disable-next-line
	}, [currentPage]);

	const loadStaff = async () => {
		try {
			const response = await getStaff(currentPage, pageTotal, company.name);
			setStaffList(response.data);
			setTotalStaff(response.total);
		} catch (err) {
			console.error(err);
		}
	};

	const editStaffMember = (staff: User) => {
		setSelectedUser(staff);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setSelectedUser(null);
		setIsModalOpen(false);
	};

	const handleStaffUpdate = (updatedStaff: User) => {
		setStaffList((prevStaff) => prevStaff.map((s) => (s.id === updatedStaff.id ? updatedStaff : s)));
		closeModal();
	};

	const newUser = () => {
		let user: User = {
			id: 0,
			uuid: '',
			username: '',
			email: '',
			password: '',
			password_confirmation: '',
			first_name: '',
			last_name: '',
			phone: '',
			address: '',
			city: '',
			state: '',
			country: 'Australia',
			postal_code: '',
			profile_picture: '',
			date_of_birth: new Date(),
			gender: '',
			sex: '',
			preferences: '',
			is_active: true,
			created_at: new Date(),
			updated_at: new Date(),
			company: company.name,
			panel_key: company.name,
			company_code: company.name,
			reagents: '',
			isAnonymous: false,
			roles: []
		};
		setSelectedUser(user);
		setIsModalOpen(true);
	};

	return (
		<div className="flex flex-column gap-3">
			<div className="flex justify-content-between align-items-center">
				<Button label="Create Staff Member" onClick={newUser} className="p-button-outlined" />
			</div>

			<div className="flex flex-column gap-2 mt-3">
				{staffList.length > 0 ? (
					staffList.map((staff) => (
						<div
							key={staff.id}
							className="flex align-items-start gap-2 p-2 border-bottom-1 surface-border hover:surface-200 cursor-pointer"
							onClick={() => editStaffMember(staff)}
						>
							<div className="flex flex-column">
								<span style={{ fontWeight: 600, fontSize: '0.9rem' }}>
									{staff.first_name} {staff.last_name}
								</span>
								<span className="text-color-secondary" style={{ fontSize: '0.8rem' }}>
									{staff.email}
								</span>
							</div>
						</div>
					))
				) : (
					<p>No staff found for this company</p>
				)}
			</div>

			{isModalOpen && selectedUser && (
				<EditUserModal
					isOpen={isModalOpen}
					onClose={closeModal}
					user={selectedUser}
					onUserUpdated={handleStaffUpdate}
				/>
			)}
		</div>
	);
};

export default StaffTab;
