import React, { useState } from 'react';
import { User } from '../../../api/admin/userApi';
import { DataTable, DataTableFilterMeta, DataTablePageEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

interface UserTableProps {
	users: User[];
	onEditClick: (user: User) => void;
	first: number;
	rows: number;
	totalRecords: number;
	onPage: (event: DataTablePageEvent) => void;
}

const UserTable: React.FC<UserTableProps> = ({ users, onEditClick, first, rows, totalRecords, onPage }) => {
	const [globalFilter, setGlobalFilter] = useState<string>('');
	const [selectedUser, setSelectedUser] = useState<User | null>(null);

	if (!users || users.length === 0) {
		return <p>No users available.</p>;
	}

	const filters: DataTableFilterMeta = {
		global: { value: globalFilter, matchMode: 'contains' }
	};

	const onRowSelect = (e: { data: User }) => {
		onEditClick(e.data);
	};

	const activeBodyTemplate = (rowData: User) => {
		return (
			<i
				className={rowData.is_active ? "pi pi-check-circle text-green-500" : "pi pi-times-circle text-red-500"}
				style={{ fontSize: '1rem' }}
			></i>
		);
	};

	const header = (
		<div className="flex justify-content-end">
			<span className="p-input-icon-left">
				<i className="pi pi-search" />
				<InputText
					type="search"
					placeholder="Global Search"
					value={globalFilter}
					onChange={(e) => setGlobalFilter(e.target.value)}
					style={{ width: '250px' }}
				/>
			</span>
		</div>
	);

	return (
		<div className="card surface-0 border-round shadow-2 p-3">
			<DataTable
				value={users}
				selection={selectedUser}
				selectionMode="single"
				onSelectionChange={(e) => setSelectedUser(e.value as User)}
				onRowSelect={onRowSelect}
				paginator
				lazy
				scrollable
				scrollHeight='75vh'
				first={first}
				rows={rows}
				totalRecords={totalRecords}
				onPage={(e) => onPage(e)}

				className="p-datatable-gridlines p-datatable-sm"
				sortMode="single"
			
				rowHover={true}
			>
				<Column field="id" header="ID" sortable filter filterPlaceholder="Search by ID" style={{ minWidth: '100px' }} />
				<Column field="username" header="Username" sortable filter filterPlaceholder="Search by Username" style={{ minWidth: '150px' }} />
				<Column field="email" header="Email" sortable filter filterPlaceholder="Search by Email" style={{ minWidth: '200px' }} />
				<Column field="first_name" header="First Name" sortable filter filterPlaceholder="Search by First Name" style={{ minWidth: '150px' }} />
				<Column field="last_name" header="Last Name" sortable filter filterPlaceholder="Search by Last Name" style={{ minWidth: '150px' }} />
				<Column header="Active" body={activeBodyTemplate} style={{ minWidth: '100px', textAlign: 'center' }} />
			</DataTable>
		</div>
	);
};

export default UserTable;
