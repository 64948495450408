import React from "react";
import { Practitioner } from "../../../../api/healthcare/practitionerApi";

interface SidebarProps {
	activeTab: string;
	setActiveTab: (tab: string) => void;
	practitioner: Practitioner;
}

const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab, practitioner }) => {
	const TabItem = ({ label, value }: { label: string; value: string }) => {
		const isActive = activeTab === value;
		return (
			<div
				className={`flex align-items-center p-2 border-round cursor-pointer transition-duration-200 ${isActive ? 'bg-primary-100' : 'hover:surface-200'
					}`}
				style={{ gap: '0.5rem' }}
				onClick={() => setActiveTab(value)}
			>
				<span>{label}</span>
			</div>
		);
	};

	return (
		<div className="flex flex-column surface-100 text-color overflow-x-hidden" style={{  height: '100%' }}>
			{/* Profile Section */}
			<div className="flex flex-column align-items-center p-3">
				<img
					src={practitioner.profile_picture || "/assets/images/default-profile.png"}
					alt={`${practitioner.first_name} ${practitioner.last_name}`}
					style={{ width: '8rem', height: '8rem', objectFit: 'cover', borderRadius: '50%' }}
				/>
				<div className="mt-2 font-medium text-center">
					{practitioner.first_name} {practitioner.last_name}
				</div>
			</div>
			<hr className="my-2 mx-2 border-none border-top-1 surface-border" />

			<div className="flex flex-column gap-2 p-2">
				<TabItem label="Personal Info" value="Personal" />
				<TabItem label="Practitioner Info" value="Practitioner" />
				<TabItem label="Education" value="Education" />
				<TabItem label="Availability & Billing" value="Availability" />
			</div>
		</div>
	);
};

export default Sidebar;
