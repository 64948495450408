import React from "react";
import { User } from "../../../../api/admin/userApi";

interface PreferencesTabProps {
    updatedUser: User;
    setUpdatedUser: React.Dispatch<React.SetStateAction<User>>;
	handleAutoSave: () => void;
}

const PreferencesTab: React.FC<PreferencesTabProps> = ({ updatedUser, setUpdatedUser, handleAutoSave }) => {
    return (
        <div className="grid grid-cols-1">
            <p>Not active at the moment</p>
        </div>
    );
};

export default PreferencesTab;
