// SampleModal.tsx
import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import debounce from 'lodash/debounce';
import Cookies from "js-cookie";

// PrimeReact Components
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

// API Imports
import { createSample, updateSample, Sample, SampleFormData } from "../../../api/labiq/labiqApi";
import { fetchCompanies, Company } from "../../../api/admin/companyApi";

// Import Modal Components
import Sidebar from "./sampleModalComponents/Sidebar";
import SampleDetails from "./sampleModalComponents/SampleDetails";
import SampleLab from "./sampleModalComponents/SampleLab";
import GeneCodesTab from "./sampleModalComponents/GeneCodesTab";
import { Patient } from "../../../api/healthcare/patientApi";
import AnalysisModal from "./sampleModalComponents/AnalysisModal";
import { fetchSampleHistory, SampleHistory } from "../../../api/labiq/LabHistory";
import HistoryTab from "./sampleModalComponents/HistoryTab";


interface SampleModalProps {
	isOpen: boolean;
	onClose: () => void;
	sample: Sample;
	patient: Patient | null;
	onFormSubmission: () => void;
}

const SampleModal: React.FC<SampleModalProps> = ({ isOpen, onClose, sample, patient, onFormSubmission }) => {
	const [formData, setFormData] = useState<SampleFormData>({});
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const [activeTab, setActiveTab] = useState<string>("Details");
	const [isDisabled, setIsDisabled] = useState<boolean>(false);
	const [history, setHistory] = useState<SampleHistory[]>([]);
	const [companies, setCompanies] = useState<Company[]>([]);

	const [analysisModalOpen, setAnalysisModalOpen] = useState<boolean>(false);

	const toast = useRef<Toast>(null);
	const userCompany = JSON.parse(Cookies.get("user") || "{}").company;

	// Initialize formData when modal opens
	useEffect(() => {
		const getCompanies = async () => {
			try {
				const response = await fetchCompanies(1, 100, "");
				setCompanies(response.data);
			} catch (error: unknown) {
				handleErrors(error);
			}
		};

		if (isOpen) {
			const { sampleID: _, ...sampleOAData } = sample.sampleOA || {};


			if (sample.client === "") {
				if (userCompany === "Mygene" || userCompany === "DNAIQ") {
					getCompanies().then(() => {
						setFormData((prev) => ({ ...prev, client: userCompany }));
					});
				} else {
					setFormData((prev) => ({ ...prev, client: userCompany }));
				}
			} else {
				setFormData((prev) => ({ ...prev, client: sample.client }));
			}

			setFormData((prev) => ({
				...prev,
				id: sample.id,
				sampleID: sample.sampleID,
				...sampleOAData,
			}));

			if (userCompany !== "DNAIQ" && userCompany !== "Mygene") {
				if (sample.sampleOA?.status === undefined) {
					setIsDisabled(false);
					return
				};
				if (sample.sampleOA?.status !== "dispatched" && sample.sampleOA?.status !== "returning") {
					setIsDisabled(true);
				};
			}
			if(sample.id !== 0){
				GetHistory();
			}
			
		}
	}, []);

	//get the sample history for the timeline
	const GetHistory = async () => {
		try{
			const sampleHistory = await fetchSampleHistory(sample.sampleID);
			setHistory(sampleHistory);
		}catch(error:unknown){
			handleErrors(error);
		}
	};


	// Event handlers
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value, type, checked } = e.target;
		const val = type === "checkbox" ? checked : value;
		setFormData((prev) => ({ ...prev, [name]: val }));
	};

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	// Auto-save function
	const autoSave = useCallback(async () => {
		setIsSubmitting(true);

		if (!formData) {
			setErrorMessages(["There is no from data"]);
			setIsSubmitting(false);
			return;
		}

		try {
			if (sample.id !== 0) {
				// Update existing sample
				await updateSample(sample.id, formData);
				toast.current?.show({
					severity: 'success',
					summary: 'Success',
					detail: 'Sample auto-saved successfully.'
				});
			}
		} catch (error: unknown) {
			handleErrors(error);
		} finally {
			setIsSubmitting(false);
			GetHistory();
		}
	}, [formData, sample.id, toast, GetHistory]);

	// Debounced auto-save function
	const handleAutoSave = useCallback(
		debounce(() => {
			sample.id !== 0 && autoSave();
		}, 500),
		[autoSave, sample.id]
	) as () => void;

	// Form submission
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setIsSubmitting(true);

		if (!formData.client) {
			setErrorMessages(["There is no client selected"]);
			setIsSubmitting(false);
			return;
		}

		try {
			if (sample.id !== 0) {
				// Update existing sample
				await updateSample(sample.id, formData);
				toast.current?.show({
					severity: 'success',
					summary: 'Success',
					detail: 'Sample updated successfully.'
				});
			} else {
				// Create new sample
				await createSample(formData);
				toast.current?.show({
					severity: 'success',
					summary: 'Success',
					detail: 'Sample created successfully.'
				});
			}

			onFormSubmission();
			onClose();
		} catch (error: unknown) {
			handleErrors(error);
		} finally {
			setIsSubmitting(false);
		}
	};

	// Error handling
	const handleErrors = (error: unknown) => {
		if (axios.isAxiosError(error) && error.response) {
			if (error.response.data?.errors) {
				const messages = Object.entries(error.response.data.errors)
					.flatMap(([_, errs]) => errs as string);
				setErrorMessages(messages);
			} else if (error.response.data?.message) {
				setErrorMessages([error.response.data.message]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} else if (error instanceof Error) {
			setErrorMessages([error.message || "An unknown error occurred."]);
		} else {
			setErrorMessages(["An unknown error occurred."]);
		}
	};

	const closeAnalysisModal = (sample?: Sample) => {
		if(sample){
			let sampleOA = sample.sampleOA;
			setFormData((prev) => ({
				...prev,
				sampleOA,
			}));
		};
		setAnalysisModalOpen(false);
	};

	const header = () => {
		return (
			<div className="flex justify-content-between">
				<div className="">{sample.id ? "Edit " + sample.sampleID : "Create Sample"}</div>

				<div className='flex'>
					<Button
						icon="pi pi-plus p-icon"
						rounded
						text
						aria-label="Add Analysis"
						loading={isSubmitting}
						disabled={isSubmitting}
						type="button"
						onClick={() => setAnalysisModalOpen(true)}
						className="p-dialog-header-icon mr-2"
						style={{ color: 'var(--text-color-alternative)' }}
						tooltip="Request Additional Analysis"
					/>
					<Button
						icon="pi pi-save p-icon"
						rounded
						text
						aria-label="Save"
						loading={isSubmitting}
						disabled={isSubmitting}
						type="button"
						onClick={handleSubmit}
						className="p-dialog-header-icon mr-2"
						style={{ color: 'var(--text-color-alternative)' }}
						tooltip="Save"
					/>
				</div>
			</div>
		)
	};

	return (
		<div>
			<Toast ref={toast} />
			<Dialog
				header={header}
				headerClassName="pb-0 h-auto bg-primary"
				visible={isOpen}
				style={{ width: '80vw', height: '80vh' }}
				modal
				onHide={() => { onFormSubmission(); onClose(); }}
				closable
				draggable
				maximizable
				focusOnShow={false}
				contentStyle={{ padding: 0 }}
				className="custom-dialog"


			>
				<div className="flex w-full h-full" style={{ overflow: "hidden" }}>
					{/* Sidebar for Navigation */}
					<div className="border-right-1 surface-border" style={{ overflowY: "auto" }}>
						<Sidebar activeTab={activeTab} setActiveTab={setActiveTab} sample={sample} patientId={patient ? patient.id : 0} />
					</div>

					{/* Main Content Area */}
					<div className="flex flex-column flex-1" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
						<form onSubmit={handleSubmit} className="">
							<div className="flex-1 overflow-y-auto p-4">
								{/* Active Tab Content */}
								{activeTab === "Details" && (

									<SampleDetails
										formData={formData}
										handleChange={handleChange}
										handleSelectChange={handleSelectChange}
										setErrorMessages={setErrorMessages}
										setFormData={setFormData}
										companies={companies}
										handleAutoSave={handleAutoSave}
										disabled={isDisabled}
										
									/>

								)}
								{activeTab === "QC" && (
									<SampleLab formData={formData} setFormData={setFormData} handleChange={handleChange} handleTextAreaChange={handleTextAreaChange} handleAutoSave={handleAutoSave} disabled={isDisabled} />
								)}

								{activeTab === "History" && (
									<HistoryTab history={history} />
								)}

								{patient && (
									<div>
										{activeTab === "Gene.Codes" && (
											<GeneCodesTab
												sampleId={formData.id as number}
												clientSampleId={sample.sampleID as string}
												patient={patient}
												sampleIsActive={sample.is_active as boolean}
												authSampleId={sample.authSampleId as number}

											/>
										)}
									</div>
								)}

								{/* Display errors to the user */}
								{errorMessages.length > 0 && (
									<div className="mt-3">
										{errorMessages.map((msg, idx) => (
											<div key={idx} className="p-error p-mb-2">
												{msg}
											</div>
										))}
									</div>
								)}
							</div>
						</form>
					</div>
				</div>
			</Dialog>

			{analysisModalOpen && (
				<AnalysisModal isOpen={analysisModalOpen} onClose={closeAnalysisModal} sample={formData} />
			)}
		</div>
	);

};

export default SampleModal;
