import React, { useState, useEffect } from "react";

import { fetchReagentList, createSample, Sample, SampleFormData, fetchSamples, fetchSample, } from "../../../../api/labiq/labiqApi";
import { Patient, sampleCountOnPatient } from "../../../../api/healthcare/patientApi";
import { createOrUpdateSamplesPanels, fetchPatientAuthSamples, createAuthSamples, removePatientAuthSamples } from "../../../../api/labiq/geneCodesApi";


// PrimeReact
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Cookies from "js-cookie";

interface AssignSampleModalProps {
	isOpen: boolean;
	onClose: () => void;
	setSamples: React.Dispatch<React.SetStateAction<Sample[]>>;
	patient: Patient;
	handleErrors: (error: unknown) => void;
	fetchPatientSample: () => void;
}

const AssignSampleModal: React.FC<AssignSampleModalProps> = ({ isOpen, onClose, setSamples, patient, handleErrors, fetchPatientSample }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [searchResults, setSearchResults] = useState<any[]>([]);
	useEffect(() => {


		if (isOpen) {
			searchSamples("");
		}
	}, [isOpen]);

	const assignSample = async (sampleClientId: string) => {
		try {
			await createAuthSamples(patient.email, sampleClientId);
			await sampleCountOnPatient(patient.email, "gene", "increment");
			await fetchPatientSample();
		} catch (error: unknown) {
			handleErrors(error);
		}
	};

	const searchSamples = async (term: string) => {
		try {
			const userInfo = JSON.parse(Cookies.get("user") || "{}");
			const company = userInfo.company;
			const { data } = await fetchSamples(1, 20, company, "sampleID", "desc", term);
			setSearchResults(data);
		} catch (error: unknown) {
			handleErrors(error);
		}
	};

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const term = e.target.value;
		setSearchTerm(term);
		if (term) {
			searchSamples(term);
		} else {
			setSearchResults([]);
		}
	};

	const footer = (
		<div className="flex justify-content-end gap-2">
			<Button label="Cancel" className="p-button-outlined" onClick={onClose} />
		</div>
	);

	return (
		<Dialog
			visible={isOpen}
			onHide={onClose}
			header="Assign Test To Patient"
			footer={footer}
			modal
			dismissableMask
			style={{ width: '60vw', height: '60vh' }}

		>
			<div className="flex flex-column gap-3">
				<span className="p-input-icon-left">

					<InputText
						type="text"
						placeholder="Search existing samples..."
						value={searchTerm}
						onChange={handleSearchChange}
						style={{ width: '16rem' }}
					/>
				</span>
				{searchResults.length > 0 && (
					<div

					>
						<DataTable
							value={searchResults}
							responsiveLayout="scroll"
							className="p-datatable-gridlines p-datatable-sm"
							selectionMode="single"
							scrollable
							scrollHeight="35vh"
							onRowClick={(e) => {
								// Same behavior as clicking the div in your original map
								setSearchTerm("");
								setSearchResults([]);
								assignSample(e.data.sampleID);
								onClose();
							}}
						>
							<Column field="sampleID" header="Sample ID" />
							<Column
								header="Date"
								body={(rowData) =>
									new Date(rowData.created_at).toLocaleDateString("en-GB")
								}
							/>
							<Column
								header="Status"
								body={(rowData) => rowData.sampleOA?.status || "N/A"}
							/>
						</DataTable>
					</div>
				)}
			</div>
		</Dialog>
	);
};

export default AssignSampleModal;
