// src/components/Layout.tsx
import React, { ReactNode } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

interface LayoutProps {
    children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    return (
        <div className="grid overflow-hidden m-0">
			<div className="col-4 md:col-3 lg:col-2 xl:col-2 p-0" >
            <Sidebar />
			</div>
            <div className="col-8 md:col-9 lg:col-10 xl:col-10 p-0">
                {/* Wrap Navbar in a surface and maybe add sticky positioning */}
                <div className="surface-0" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                    <Navbar />
                </div>
                <main className="">
                    {children}
                </main>
            </div>
        </div>
    );
};

export default Layout;
