// src/pages/inventory/ProductManagementPage.tsx

import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import Layout from "../../components/Layout";
import LoadingSpinner from '../../components/icons/LoadingSpinner';
import ProductTable from "../../components/inventory/product/ProductTable";
import Buttons from "../../components/inventory/product/Buttons";
import ProductModal from "../../components/inventory/product/ProductModal";
import { Product, fetchProducts, createProduct, updateProduct, deleteProduct } from "../../api/inventory/productApi";

// PrimeReact components
import { Toast } from 'primereact/toast';
import { SortOrder } from 'primereact/api';

const ProductManagementPage: React.FC = () => {
	const [products, setProducts] = useState<Product[]>([]);
	const [totalProducts, setTotalProducts] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(0); // PrimeReact uses zero-based indexing
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [sortBy, setSortBy] = useState<string>(""); // Initialize as per default sorting
	const [sortOrder, setSortOrder] = useState<SortOrder>(0); // 1 for ascending, -1 for descending, 0 for no sorting

	const toast = useRef<Toast>(null);

	const user = JSON.parse(Cookies.get("user") || "{}");
	const PRODUCTS_PER_PAGE = 20;

	useEffect(() => {
		loadProducts();
	}, [currentPage, searchTerm, sortBy, sortOrder]);

	const loadProducts = async () => {
		setIsLoading(true);
		try {
			const company = user.company;
			const { data, total } = await fetchProducts(currentPage + 1, PRODUCTS_PER_PAGE, company, sortBy, sortOrder, searchTerm);
			setProducts(data);
			setTotalProducts(total);
		} catch (error) {
			console.error("Error fetching products:", error);
			toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to load products.', life: 3000 });
		} finally {
			setIsLoading(false);
		}
	};

	const handleEditClick = (product: Product) => {
		setSelectedProduct(product);
		setIsModalOpen(true);
	};

	const handleDeleteClick = async (product: Product) => {
		if (window.confirm(`Are you sure you want to delete the product "${product.name}"?`)) {
			try {
				await deleteProduct(product.product_id);
				toast.current?.show({ severity: 'success', summary: 'Deleted', detail: 'Product deleted successfully.', life: 3000 });
				loadProducts();
			} catch (error) {
				console.error("Error deleting product:", error);
				toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete product.', life: 3000 });
			}
		}
	};

	const handleModalClose = () => {
		setSelectedProduct(null);
		setIsModalOpen(false);
	};

	const handleProductSave = async (productData: Partial<Product>) => {
		try {
			if (selectedProduct) {
				// Update product
				await updateProduct(selectedProduct.product_id, productData);
				toast.current?.show({ severity: 'success', summary: 'Updated', detail: 'Product updated successfully.', life: 3000 });
			} else {
				// Create new product
				productData.is_kit = false;
				await createProduct(productData);
				toast.current?.show({ severity: 'success', summary: 'Created', detail: 'Product created successfully.', life: 3000 });
			}
			loadProducts();
			handleModalClose();
		} catch (error) {
			console.error("Error saving product:", error);
			toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to save product.', life: 3000 });
		}
	};

	const handleAddProduct = () => {
		setSelectedProduct(null);
		setIsModalOpen(true);
	};

	const handleSearchChange = (search: string) => {
		setSearchTerm(search);
		setCurrentPage(0); // Reset to first page on search
	};

	const handleSort = (field: string, order: number) => {
		setSortBy(field);
		setSortOrder(order);
	};

	return (
		<Layout>
			<div className="p-3">
				<Toast ref={toast} />

				<div className="p-mb-3">
					<Buttons handleAddProduct={handleAddProduct} onSearchChange={handleSearchChange} searchTerm={searchTerm} />
				</div>

				{isLoading ? (
					<LoadingSpinner size={60} color="#354396" />
				) : (
					<ProductTable
						products={products}
						totalProducts={totalProducts}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						onSort={handleSort}
						sortBy={sortBy}
						sortOrder={sortOrder}
						onEditClick={handleEditClick}
						onDeleteClick={handleDeleteClick}
						loadProducts={loadProducts}
					/>
				)}

				{isModalOpen && (
					<ProductModal
						isOpen={isModalOpen}
						onClose={handleModalClose}
						product={selectedProduct}
						onSave={handleProductSave}
					/>
				)}
			</div>
		</Layout>
	);
};

export default ProductManagementPage;
