// src/api/productApi.ts
import axios from "axios";
import Cookies from "js-cookie";
const API_BASE_URL = process.env.REACT_APP_COMMERCE_SERVER;

export interface Product {
    product_id: number;
    product_shopify_id?: number;
    name: string;
    description?: string;
    sku: string;
    barcode?: string;
    unit_price?: number;
    unit_cost?: number;
    created_at?: string;
    updated_at?: string;
	is_kit: boolean;
	kit_prefix?: string;
}

// Fetch a list of products with pagination and optional search
export const fetchProducts = async (page: number = 1, perPage: number = 20, company: string, sortBy: string, sortOrder: number, search?: string,): Promise<{ data: Product[]; total: number }> => {
	const response = await axios.get(`${API_BASE_URL}/products`, {
        params: {
            page,
            per_page: perPage,
			sortBy,
			"sortOrder" : sortOrder === 1 ? 'asc' : 'desc',
			company,
            ...(search ? { search } : {}),
			
            
        },
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return {
        data: response.data.data,
        total: response.data.total,
    };
};



// Fetch details of a specific product
export const fetchProduct = async (product_id: number): Promise<Product> => {
    const response = await axios.get(`${API_BASE_URL}/products/${product_id}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return response.data;
};



// Create a new product
export const createProduct = async (productData: Partial<Product>): Promise<Product> => {
    const company = JSON.parse(sessionStorage.getItem("user") || "{}").company;
    const response = await axios.post( `${API_BASE_URL}/products`,
        {
            ...productData,
            company,
        },
        {
            headers: {
                Authorization: `Bearer ${Cookies.get("apiKey")}`,
            },
        }
    );
    return response.data;
};

// Update an existing product
export const updateProduct = async (product_id: number, productData: Partial<Product>): Promise<Product> => {
    const company = JSON.parse(sessionStorage.getItem("user") || "{}").company;
    const response = await axios.put(
        `${API_BASE_URL}/products/${product_id}`,
        {
            ...productData,
            company,
        },
        {
            headers: {
                Authorization: `Bearer ${Cookies.get("apiKey")}`,
            },
        }
    );
    return response.data;
};

// Delete a product
export const deleteProduct = async (product_id: number): Promise<void> => {
    await axios.delete(`${API_BASE_URL}/products/${product_id}`, {
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
};











