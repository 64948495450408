import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import { fetchSample, fetchSamples, Sample } from "../../api/labiq/labiqApi";
import LoadingSpinner from '../../components/icons/LoadingSpinner';

import SampleTable from "../../components/labiq/sampleManagement/SampleTable";
import ActionButtons from "../../components/labiq/sampleManagement/SampleTrackingButtons";
import SampleModal from "../../components/labiq/sampleManagement/SampleModal";
import OmnibeadModal from "../../components/labiq/batchManagement/omnibeadUploadForm";
import { Toast } from 'primereact/toast';
import { SortOrder } from "primereact/datatable";
import Cookies from "js-cookie";

const SampleTrackingPage: React.FC = () => {
	const [samples, setSamples] = useState<Sample[]>([]);
	const [totalSamples, setTotalSamples] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(0); 
	const [sortBy, setSortBy] = useState<string>("id");
	const [sortOrder, setSortOrder] = useState<SortOrder>(-1); 
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
	const [selectedSamples, setSelectedSamples] = useState<number[]>([]);
	const [selectedSampleClientId, setSelectedSampleClientId] = useState<string[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [searchTerm, setSearchTerm] = useState<string>("");

	const { id } = useParams<{ id: string }>();

	const SAMPLES_PER_PAGE = 30;
	const toast = useRef<Toast>(null);

	// Fetch sample data and handle modal open logic
	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				if (id) {
					const sample = await fetchSample(id);
					setSamples([sample]);
					handleEditSample(sample);
				} else {
					await fetchSampleData(searchTerm);
				}
			} catch (err) {
				console.error("Error fetching data:", err);
				setError("Failed to load samples.");
				toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to load samples.' });
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [id, currentPage, sortBy, sortOrder]);

	const fetchSampleData = async (searchTerm: string) => {
		setError(null);
		try {
			const userInfo = JSON.parse(Cookies.get("user") || "{}");
			const company = userInfo.company;
			const { data, total } = await fetchSamples(
				currentPage + 1,
				SAMPLES_PER_PAGE,
				company,
				sortBy,
				sortOrder === 1 ? "asc" : "desc",
				searchTerm
			);
			setSamples(data);
			setTotalSamples(total);
		} catch (err) {
			console.error("Error fetching samples:", err);
			setError("Failed to load samples.");
			toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Failed to load samples.' });
		}
	};

	// Handle sorting of the page data
	const handleSort = async (field: string) => {
		
		if (field === sortBy) {

			await setSortOrder(prevOrder => prevOrder === 1 ? -1 : 1);
			fetchSampleData("");
		} else {
			await setSortBy(field);
			await setSortOrder(1); // default to ascending
			fetchSampleData("");
		}
	};

	// Handle editing a sample when a row is clicked
	const handleEditSample = (sample: Sample) => {
		setSelectedSamples([sample.id]);
		setIsModalOpen(true);
	};

	// Handle opening the modal from action buttons or other sources
	const handleEditClick = (sampleId: number) => {
		setSelectedSamples([sampleId]);
		setIsModalOpen(true);
	};

	const handleNewSampleClick = () => {
		setSelectedSamples([0]); // Use 0 to indicate a new sample
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setSelectedSamples([]);
		setSelectedSampleClientId([]);
		setIsModalOpen(false);
		setIsUploadModalOpen(false);
	};

	const handleFormSubmission = () => {
		fetchSampleData("");
		handleModalClose();
	};

	const handleOmnibeadModalOpen = () => {
		setIsModalOpen(false);
		setIsUploadModalOpen(true);
	};


	return (
		<Layout>
			<div className="p-3">
				<Toast ref={toast} />
				<ActionButtons
					selectedSamples={selectedSamples}
					selectedSampleClientId={selectedSampleClientId}
					refreshSamples={fetchSampleData}
					editClick={handleEditClick}
					newSample={handleNewSampleClick}
					omnibeadUpload={handleOmnibeadModalOpen}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
				/>
				{isLoading ? (
					<div className="flex justify-content-center align-items-center" style={{ height: '200px' }}>
						<LoadingSpinner size={60} color="#354396" />
					</div>
				) : error ? (
					<div className="p-text-center">
						<span className="p-error">{error}</span>
					</div>
				) : (
					<SampleTable
						samples={samples}
						totalSamples={totalSamples}
						currentPage={currentPage}
						setCurrentPage={setCurrentPage}
						selectedSamples={selectedSamples}
						setSelectedSamples={setSelectedSamples}
						setSelectedSampleClientId={setSelectedSampleClientId}
						selectedSampleClientId={selectedSampleClientId}
						onSort={handleSort}
						sortBy={sortBy}
						sortOrder={sortOrder}
						onEditSample={handleEditSample}
						onPageChange={fetchSampleData}
					/>
				)}

				{isModalOpen && selectedSamples.length === 1 && (
					<SampleModal
						isOpen={isModalOpen}
						onClose={handleModalClose}
						sample={
							selectedSamples[0] === 0
								? {
									id: 0,
									sampleID: "",
									client: "",
									created_at: new Date().toISOString(),
									updated_at: new Date().toISOString(),
									sampleOA: null,
									qsBases: [],
								}
								: samples.find((s) => s.id === selectedSamples[0])!
						}
						patient={null}
						onFormSubmission={handleFormSubmission}
					/>
				)}

				{isUploadModalOpen && <OmnibeadModal isOpen={isUploadModalOpen} onClose={handleModalClose} onFormSubmission={handleFormSubmission} />}
			</div>
		</Layout>
	);
};

export default SampleTrackingPage;
