import React, { useState } from "react";
import axios from "axios";
import Cookies from 'js-cookie';


import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FloatLabel } from "primereact/floatlabel";

const LoginPage: React.FC = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const authServerBaseUrl = process.env.REACT_APP_AUTH_SERVER;

	const handleLogin = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			// Send the login request to the api
			const response = await axios.post(`${authServerBaseUrl}/login`, {
				email,
				password,
			});
			// Extract the token and user from the response and verify the user’s roles
			const { token, user } = response.data;

			const hasAdminRole = user.roles.some((role: any) =>
				role.name === "admin" ||
				role.name === "Practitioner" ||
				role.name === "Lab Management"
			);

			if (!hasAdminRole) {
				setError("Unauthorized: You do not have permissions");
				return;
			}

			// Store the token in sessionStorage
			Cookies.set('apiKey', token, { expires: 7 });
			Cookies.set('user', JSON.stringify(user), { expires: 7 });
			// sessionStorage.setItem("apiKey", token);
			// sessionStorage.setItem("user", JSON.stringify(user));

			// Redirect to the dashboard after successful login
			window.location.href = "/";
		} catch (err: any) {
			if (axios.isAxiosError(err) && err.response) {
				setError(err.response.data.message);
			} else {
				setError("Something has gone wrong, please try again");
			}
		}
	};

	return (
		<div
			className="flex align-items-center justify-content-center"
			style={{ minHeight: '100vh', background: '#F3F4F6' }}
		>
			<div
				className="surface-0 p-4 border-round shadow-2"
				style={{ width: '100%', maxWidth: '400px' }}
			>
				<div className="flex justify-content-center mb-4">
					<img src="/assets/images/logo/logoBlack.png" alt="The mygene logo" style={{ maxWidth: '250px' }} />
				</div>

				<form onSubmit={handleLogin} className="flex flex-column gap-3">
					<div className="flex flex-column gap-1">
						<FloatLabel>
							<label
								htmlFor="email"
							>
								Email
							</label>
							<InputText
								id="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="Enter your email"
								className="w-full"
							/>
						</FloatLabel>
					</div>

					<div className="flex flex-column gap-1 mt-2">
						<FloatLabel>

							<Password
								id="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								toggleMask
								className="w-full"
								feedback={false}
							/>
							<label
								htmlFor="password"
							>
								Password
							</label>
						</FloatLabel>
					</div>

					{error && (
						<p style={{ color: 'red', fontSize: '0.875rem' }}>
							{error}
						</p>
					)}

					<Button
						type="submit"
						label="Login"
						className="w-full"
					//severity="primary"
					/>
				</form>
			</div>
		</div>
	);
};

export default LoginPage;
