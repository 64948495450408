import React, { useState } from 'react';
import Cookies from "js-cookie";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';



interface FormButtonsProps {
	searchForms: (search: string) => void;
	handleCreateForm: () => void;
}

const FormButtons: React.FC<FormButtonsProps> = ({ searchForms, handleCreateForm }) => {
	const [searchTerm, setSearchTerm] = useState("");


	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchTerm(value);
		searchForms(value);
	};


	return (
		<div className="flex align-items-center justify-content-between mb-3 gap-3">
			<div>
				<Button
					label="Create Form"
					className="p-button-outlined"
					onClick={handleCreateForm}
				/>
			</div>
			
			

			<div className="flex align-items-center gap-2">
				<span className="p-input-icon-left">
					<InputText
						type="text"
						placeholder="Search forms..."
						value={searchTerm}
						onChange={handleSearchChange}
						className="w-12rem"
					/>
				</span>
				<Button
					label="Reset"
					className="p-button-outlined"
					onClick={() => {searchForms("")}}
				/>
			</div>

		</div>
	);
};

export default FormButtons;
