// src/components/icons/SuppliersIcon.tsx
import React from 'react';
import { FaTruck } from 'react-icons/fa'; // Font Awesome truck icon

interface SuppliersIconProps {
    className?: string;
}

const SuppliersIcon: React.FC<SuppliersIconProps> = ({ className }) => {
    return (
        <FaTruck
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24}
            aria-label="Suppliers"
        />
    );
};

export default SuppliersIcon;
