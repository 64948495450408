// src/components/admin/company/ActionButtons.tsx
import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

interface ActionButtonsProps {
	createCompany: () => void;
	searchCompanies: (search: string) => void;
	reloadCompanies: () => void;
}

const ActionButtons: React.FC<ActionButtonsProps> = ({ createCompany, searchCompanies, reloadCompanies }) => {
	const [searchTerm, setSearchTerm] = useState("");

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchTerm(value);
		searchCompanies(value);
	};

	return (
		<div className="flex align-items-center justify-content-between mb-3 gap-3">
			<Button label="Create Company" onClick={createCompany} className="p-button-outlined" />

			<div className="flex align-items-center gap-2">
				<span className="p-input-icon-left">
					<InputText
						type="text"
						placeholder="Search companies..."
						value={searchTerm}
						onChange={handleSearchChange}
						className="w-12rem"
					/>
				</span>
				<Button label="Reset" onClick={reloadCompanies} className="p-button-outlined" />
			</div>
		</div>
	);
};

export default ActionButtons;
