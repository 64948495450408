// src/components/orders/SalesOrderTable.tsx

import React from "react";
import { SalesOrder } from "../../../api/inventory/salesOrderApi";

interface SalesOrderTableProps {
    orders: SalesOrder[];
    onEdit: (order: SalesOrder) => void;
    onDelete: (order: SalesOrder) => void;
}

const SalesOrderTable: React.FC<SalesOrderTableProps> = ({ orders, onEdit, onDelete }) => {
    if (!orders || orders.length === 0) {
        return <p>No sales orders available.</p>;
    }

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white border">
                <thead>
                    <tr>
                        <th className="py-2 px-3 border-b">Order ID</th>
                        <th className="py-2 px-3 border-b">Assignee Type</th>
                        <th className="py-2 px-3 border-b">Assignee ID</th>
                        <th className="py-2 px-3 border-b">Order Date</th>
                        <th className="py-2 px-3 border-b">Status</th>
                        <th className="py-2 px-3 border-b">Total Amount</th>
                        <th className="py-2 px-3 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order) => (
                        <tr key={order.sales_order_id} className="text-center">
                            <td className="py-2 px-3 border-b">{order.sales_order_id}</td>
                            <td className="py-2 px-3 border-b">{order.assignee_type}</td>
                            <td className="py-2 px-3 border-b">{order.assignee_id}</td>
                            <td className="py-2 px-3 border-b">{order.order_date ? new Date(order.order_date).toLocaleDateString() : "-"}</td>
                            <td className="py-2 px-3 border-b">{order.status || "pending"}</td>
                            <td className="py-2 px-3 border-b">{order.total_amount !== undefined ? `$${order.total_amount}` : "-"}</td>
                            <td className="py-2 px-3 border-b">
                                <button onClick={() => onEdit(order)} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 mr-2">
                                    Edit
                                </button>
                                <button onClick={() => onDelete(order)} className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SalesOrderTable;
