// src/components/inventory/ProductModal.tsx

import React, { useState, useEffect } from "react";
import { Product } from "../../../api/inventory/productApi";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

interface ProductModalProps {
	isOpen: boolean;
	onClose: () => void;
	product: Product | null;
	onSave: (productData: Partial<Product>) => void;
}

const ProductModal: React.FC<ProductModalProps> = ({ isOpen, onClose, product, onSave }) => {
	const [formData, setFormData] = useState<Partial<Product>>({});

	useEffect(() => {
		setFormData(product || {});
	}, [product]);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const numberValue = value === "" ? undefined : parseFloat(value);
		setFormData((prevData) => ({ ...prevData, [name]: numberValue }));
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		onSave(formData);
	};

	if (!isOpen) return null;

	return (
		<Dialog
			header={product?.product_id !== 0 ? "Creat Product" : "Edit Product"}
			visible={isOpen}
			//style={{ width: '80vw', height: '80vh' }}
			modal
			onHide={onClose}
			closable={true}
			draggable={true}
			maximizable={true}

			contentStyle={{ padding: 0 }}
		>
			<div className="flex w-full h-full p-2" style={{ overflow: 'hidden' }}>
				<form onSubmit={handleSubmit}>
					{/* Name */}
					<div className="p-2 mt-2">
						<FloatLabel>
							<label className="block text-sm mb-2">Name</label>
							<InputText name="name" type="text" value={formData.name || ""} onChange={handleChange} required className="w-full border px-3 py-2 rounded" />
						</FloatLabel>
					</div>
					{/* Description */}
					<div className="p-2 mt-2">
						<FloatLabel>
							<label className="block text-sm mb-2">Description</label>
							<InputTextarea name="description" value={formData.description || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded" />
						</FloatLabel>
					</div>
					{/* SKU */}
					<div className="p-2 mt-2">
					<FloatLabel>
						<label className="block text-sm mb-2">SKU</label>
						<InputText name="sku" type="text" value={formData.sku || ""} onChange={handleChange} required className="w-full border px-3 py-2 rounded" />
					</FloatLabel>
					</div>
					{/* Barcode */}
					<div className="p-2 mt-2">
						<FloatLabel>
							<label className="block text-sm mb-2">Barcode</label>
							<InputText name="barcode" type="text" value={formData.barcode || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded" />
						</FloatLabel>
					</div>
					{/* Unit Price and Unit Cost */}
					<div className="p-2 flex space-x-4 mt-2">
						<div className="flex-1 mr-1">
							<FloatLabel>
								<label className="block text-sm mb-2">Unit Price</label>
								<InputText name="unit_price" type="number" step="0.01" value={formData.unit_price !== undefined ? formData.unit_price.toString() : ""} onChange={handleNumberChange} className="w-full border px-3 py-2 rounded" />
							</FloatLabel>
						</div>
						<div className="flex-1">
							<FloatLabel>
								<label className="block text-sm mb-2">Unit Cost</label>
								<InputText name="unit_cost" type="number" step="0.01" value={formData.unit_cost !== undefined ? formData.unit_cost.toString() : ""} onChange={handleNumberChange} className="w-full border px-3 py-2 rounded" />
							</FloatLabel>
						</div>
					</div>
					{/* Product Shopify ID */}
					<div className="p-2 mt-2">
						<FloatLabel>
							<label className="block text-sm mb-2">Shopify Product ID</label>
							<InputText name="product_shopify_id" type="number" value={formData.product_shopify_id !== undefined ? formData.product_shopify_id.toString() : ""} onChange={handleNumberChange} className="w-full border px-3 py-2 rounded" />
						</FloatLabel>
					</div>
					{/* Buttons */}
					<div className="flex justify-end">

						<Button
							label={product ? "Update" : "Create"}
							icon="pi pi-check"
							className="p-button"
							type="submit"

						/>
					</div>
				</form>
			</div>
		</Dialog>
	);
};

export default ProductModal;
