import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Layout from "../../components/Layout";
import PatientTable from "../../components/healthcare/patient/PatientTable";
import { fetchPatients, Patient } from "../../api/healthcare/patientApi";
import LoadingSpinner from '../../components/icons/LoadingSpinner';
import EditPatientModal from "../../components/healthcare/patient/EditPatientModal";
import PatientButtons from "../../components/healthcare/patient/PatientButtons";
import { SortOrder } from 'primereact/datatable';

const PatientManagementPage: React.FC = () => {
	const [patients, setPatients] = useState<Patient[]>([]);
	const [totalPatients, setTotalPatients] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	// Replace SortingState with two separate pieces of state for PrimeReact
	const [sortField, setSortField] = useState<string | undefined>("created_at");
	// PrimeReact uses 1 for ascending and -1 for descending
	const [sortOrder, setSortOrder] = useState<SortOrder>(-1);

	const PATIENTS_PER_PAGE = 30;

	useEffect(() => {
		loadPatients();
		// eslint-disable-next-line
	}, [currentPage, sortField, sortOrder]);

	const loadPatients = async (search?: string) => {
		setIsLoading(true);
		try {
			const sortDirection = sortOrder === 1 ? 'asc' : 'desc';

			const { data, total } = await fetchPatients(
				currentPage,
				PATIENTS_PER_PAGE,
				search,
				sortField,   
				sortDirection 
			);
			setPatients(data);
			setTotalPatients(total);
			setError(null);
		} catch (err) {
			console.error("Error fetching patients:", err);
			setError("Failed to load patients.");
		} finally {
			setIsLoading(false);
		}
	};

	const SearchPatients = async (search: string) => {
		try {
			setCurrentPage(1); 
			const sortDirection = sortOrder === 1 ? 'asc' : 'desc';

			const { data, total } = await fetchPatients(
				1,
				PATIENTS_PER_PAGE,
				search,
				sortField,
				sortDirection
			);
			setPatients(data);
			setTotalPatients(total);
			setError(null);
		} catch (err) {
			console.error("Error searching patients:", err);
			setError("Failed to search patients.");
		}
	};

	const handleEditClick = (patient: Patient) => {
		setSelectedPatient(patient);
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setSelectedPatient(null);
		setIsModalOpen(false);
	};

	const handlePatientUpdate = async () => {
		await loadPatients();
	};

	// Calculate the first record index for the DataTable
	const first = (currentPage - 1) * PATIENTS_PER_PAGE;

	// Handle paginator event from DataTable
	const onPageChange = (event: any) => {
		const newPage = event.page !== undefined ? event.page + 1 : 1;
		setCurrentPage(newPage);
	};

	// Handle sorting event from DataTable
	const onSort = (event: any) => {
		// event.sortField = name of the field
		// event.sortOrder = 1 (asc) or -1 (desc)
		setSortField(event.sortField);
		setSortOrder(event.sortOrder);
		setCurrentPage(1);
	};

	return (
		<Layout>
			<div className="p-3">
				{isLoading ? (
					<div className="flex justify-content-center align-items-center" style={{ height: '200px' }}>
						<LoadingSpinner size={60} color="#354396" />
					</div>
				) : (
					<div className="flex flex-column gap-2">
						<PatientButtons
							searchPatients={SearchPatients}
							reloadPatients={() => loadPatients()}
						/>
						<PatientTable
							patients={patients}
							totalPatients={totalPatients}
							first={first}
							rows={PATIENTS_PER_PAGE}
							onEditClick={handleEditClick}
							onPage={onPageChange}
							onSort={onSort}
							// Pass down the sort state to the table
							sortField={sortField}
							sortOrder={sortOrder}
						/>
					</div>
				)}
				{selectedPatient && (
					<EditPatientModal
						isOpen={isModalOpen}
						onClose={handleModalClose}
						patient={selectedPatient}
						onPatientUpdated={handlePatientUpdate}
						isAnon={false}
					/>
				)}
			</div>
		</Layout>
	);
};

export default PatientManagementPage;
