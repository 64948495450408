import React, { useEffect, useState } from "react";
import debounce from 'lodash/debounce';

//APi imports
import { InputText } from "primereact/inputtext";
import { Patient } from "../../../../api/healthcare/patientApi";
import { FloatLabel } from "primereact/floatlabel";
import { Calendar } from 'primereact/calendar';

import { validateMedicareNumber } from '../../../../utils/ValidateMedicareNumber';
import { validateIHI } from '../../../../utils/ValidateIhiNumber';
import { InputNumberChangeEvent, InputNumber } from "primereact/inputnumber";

interface BillingTabProps {
	updatedPatient: Patient;
	setUpdatedPatient: React.Dispatch<React.SetStateAction<Patient>>;
	handleAutoSave: () => void;
}

const BillingTabTab: React.FC<BillingTabProps> = ({ updatedPatient, setUpdatedPatient, handleAutoSave }) => {
	const [medicareNumberError, setMedicareNumberError] = useState<string | null>(null);
	const [ihiNumberError, setIhiNumberError] = useState<string | null>(null);
	const [medicareNumber, setMedicareNumber] = useState<number | null>(null);
	const [ihiNumber, setihiNumber] = useState<number | null>(null);
	const [medicareValidTo, setMedicareValidTo] = useState<Date | null>(null);


	useEffect(() => {
		if (updatedPatient.patient_info?.medicare_number) {
			setMedicareNumber(updatedPatient.patient_info?.medicare_number)
		}
		if (updatedPatient.patient_info?.ihi_number) {
			setihiNumber(updatedPatient.patient_info?.ihi_number)
		}
		if (updatedPatient.patient_info?.medicare_valid_to) {
			setMedicareValidTo(new Date(updatedPatient.patient_info.medicare_valid_to));
		}
	}, []);

	const handleRefChange = (e: InputNumberChangeEvent) => {
		const value = e.value as number;
		const name = 'medicare_individual_ref_number';
		setUpdatedPatient((prev) => ({
			...prev,
			patient_info: {
				...prev.patient_info,
				[name]: value,
			},
		}));

	};

	const handleMedicareChange = (e: InputNumberChangeEvent) => {
		const value = e.value;
		const name = "medicare_number"
		if (value) {
			setMedicareNumber(value);
			const validation = validateMedicareNumber(value.toString());
			if (!validation.isValid) {
				setMedicareNumberError(validation.error || "Invalid Medicare number.");
				return;
			} else {
				setMedicareNumberError(null);

			}

			setUpdatedPatient((prev) => ({
				...prev,
				patient_info: {
					...prev.patient_info,
					[name]: value,
				},
			}));
			handleAutoSave();
		}
	};

	const handleIhiChange = async (e: InputNumberChangeEvent) => {
		const value = e.value;
		const name = "ihi_number"
		if (value) {
			setihiNumber(value);
			const validation = await validateIHI(value.toString());
			if (!validation.isValid) {
				setIhiNumberError(validation.error || "Invalid IHI number.");
				return;
			} else {
				setIhiNumberError(null);

			}

			setUpdatedPatient((prev) => ({
				...prev,
				patient_info: {
					...prev.patient_info,
					[name]: value,
				},
			}));
			//Autosaving here was posting the data without the ihi number every time
		}
	};
	
	const saveIfIhiValid = () => {
		if(validateIHI(updatedPatient.patient_info?.ihi_number?.toString() as string)){
			handleAutoSave();
		}
	}


	return (
		<div className=" p-fluid mt-5">
			<div className="grid p-fluid w-full">

				<label htmlFor="" className="col-12">Medicare Card Information</label>
				{/* Medicare Card number */}
				<div className="field col-12 md:col-4 mt-2">
					<label htmlFor="medicare_number">Card Number</label>
					<InputNumber
						format={false}
						inputMode="numeric"
						id="medicare_number"
						name="medicare_number"
						allowEmpty={true}
						value={medicareNumber}
						onChange={handleMedicareChange}

						className="w-full"
					/>
					{medicareNumberError && <small className="p-error">{medicareNumberError}</small>}
				</div>
				{/* Medicare individual ref number */}
				<div className="field col-12 md:col-4 mt-2">

					<label htmlFor="medicare_individual_ref_number">Reference Number</label>
					<InputNumber
						id="medicare_individual_ref_number"
						name="medicare_individual_ref_number"
						value={updatedPatient.patient_info?.medicare_individual_ref_number}
						onChange={handleRefChange}
						onBlur={handleAutoSave}
						placeholder="#"
					/>

				</div>
				{/* Medicare card valid to */}
				<div className="field col-12 md:col-4 mt-2">

					<label htmlFor="medicare_valid_to">Expiration Date</label>
					<Calendar
						id="medicare_valid_to"
						name="medicare_valid_to"
						value={medicareValidTo}
						onChange={(e) => {
							const selectedDate = e.value as Date;
							setMedicareValidTo(selectedDate);
							setUpdatedPatient((prev) => ({
								...prev,
								patient_info: {
									...prev.patient_info,
									medicare_valid_to: selectedDate ? selectedDate : undefined,
								},
							}));
							
						}}
						onHide={handleAutoSave}
						dateFormat="yy-mm-dd"
						showIcon
						className="w-full"
					/>

				</div>
			</div>
			<div className="grid">
				<label htmlFor="" className="col-12">IHI Number Information</label>
				{/* IHI Number */}
				<div className="field col-12 md:col-4 mt-2">

					<label htmlFor="ihi_number">IHI Number</label>
					<InputNumber
						format={false}
						id="ihi_number"
						name="ihi_number"
						value={ihiNumber}
						onChange={handleIhiChange}
						onBlur={saveIfIhiValid}
					/>
					{ihiNumberError && <small className="p-error">{ihiNumberError}</small>}
				</div>
				<div className="field col-12 md:col-4 mt-2">

					<label htmlFor="ihi_status">IHI Status</label>
					<InputText
						//format={false}
						id="ihi_status"
						name="ihi_status"
						value={updatedPatient.patient_info?.ihi_status}
						disabled
						//onChange={handleIhiChange}
					/>

				</div>
				<div className="field col-12 md:col-4 mt-2">

					<label htmlFor="ihi_source">IHI Source</label>
					<InputText
						//format={false}
						id="ihi_source"
						name="ihi_source"
						value={updatedPatient.patient_info?.ihi_source}
						disabled
						//onChange={handleIhiChange}
					/>

				</div>
			</div>
		</div>
	);
};

export default BillingTabTab;
