import React, { useState, useEffect } from "react";

import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { ToggleButton } from "primereact/togglebutton";
import { Calendar } from "primereact/calendar";

// Import api info
import { getSamplesPanels, getPanels, Panel, createOrUpdateSamplesPanels, createAuthSamples, activatePatientAuthSamples, deactivatePatientAuthSamples, updateAuthSampleDeactivationDate, getAuthSampleDeactivationDate } from "../../../../api/labiq/geneCodesApi";
import { assignGeneCodes, Patient } from "../../../../api/healthcare/patientApi";

interface GeneCodesTabProps {
    sampleId: number;
    patient: Patient;
    clientSampleId: string;
    sampleIsActive: boolean;
    authSampleId: number;
}

const GeneCodesTab: React.FC<GeneCodesTabProps> = ({ sampleId, patient, clientSampleId, sampleIsActive, authSampleId }) => {
    const [panelList, setPanelList] = useState<Panel[]>([]);
    const [isActive, setIsActive] = useState<boolean>(false);
    const [selectedPanels, setSelectedPanels] = useState<number[]>([]);
	const [deactivationDate, setDeactivationDate] = useState<Date | null>(null);

    // We will use this to skip the first effect call
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const panels = await getPanels();
                setPanelList(panels);

                const samplePanels = await getSamplesPanels(authSampleId);
                // Make sure samplePanels is an array; if it’s nested, flatten it as needed
                setSelectedPanels(samplePanels.flat());

				const dDate:Date = await getAuthSampleDeactivationDate(authSampleId);
				setDeactivationDate(new Date(dDate));

                if (clientSampleId) {
                    await createAuthSamples(patient.email, clientSampleId);
                }


                // After data has been loaded, flip the flag
                setIsInitialLoad(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
        setIsActive(sampleIsActive);
    }, [sampleId, authSampleId, clientSampleId, patient.email, sampleIsActive]);

	const updateDeactivationDate = async (date: Date | null) => {
		if (date) {
			try {
				setDeactivationDate(date); 
				await updateAuthSampleDeactivationDate(authSampleId, date);
				
				console.log(`Deactivation date for sample ${authSampleId} updated to ${date}`);
			} catch (error) {
				console.error("Failed to update deactivation date:", error);
			}
		} else {
			console.warn("No date selected");
		}
	};
	

    // Only call createOrUpdateSamplesPanels if not on initial load
    useEffect(() => {
        if (!isInitialLoad) {
            createOrUpdateSamplesPanels(authSampleId, selectedPanels);
        }
    }, [selectedPanels, isInitialLoad, authSampleId]);

    const handleCheckboxChange = (panelId: number, checked: boolean) => {
        setSelectedPanels((prev) => (checked ? [...prev, panelId] : prev.filter((id) => id !== panelId)));
    };

    const handleSelectAll = () => {
        if (selectedPanels.length === panelList.length) {
            setSelectedPanels([]);
        } else {
            setSelectedPanels(panelList.map((panel) => panel.id_panels));
        }
        // Optionally, you could call createOrUpdateSamplesPanels here directly
    };

    const sampleStatus = async (isActive: boolean) => {
        if (isActive) {
            await assignGeneCodes(patient.id);
            activatePatientAuthSamples(authSampleId);
        } else {
            deactivatePatientAuthSamples(authSampleId);
        }
    };

	

    return (
        <div className="flex flex-column gap-3 grid">
			<div className="col flex">
            <div className="flex align-items-center gap-2 mb-2">
                <label>Activation Status</label>
                <ToggleButton
                    onLabel="Active"
                    offLabel="Inactive"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={isActive}
                    onChange={(e) => {
                        setIsActive(e.value);
                        sampleStatus(e.value);
                    }}
                />
            </div>

			<div className="flex align-items-center gap-2 mb-2 ml-5">
                <label>Deactivation Date</label>
					<Calendar
						id="sample_deactivation_date"
						name="sample_deactivation_date"
						value={deactivationDate}
						onChange={(e) => updateDeactivationDate(e.value || null)}
						dateFormat="dd/mm/yy"
						showIcon
						className="w-full hide-placeholder"
					/>
            </div>
			</div>

            <div className="flex align-items-center gap-2 mb-2 col">
                <label>Available Categories</label>
                <Button label={selectedPanels.length === panelList.length ? "Deselect All" : "Select All"} className="p-button-outlined ml-2" onClick={handleSelectAll} type="button" />
            </div>

            <div className="grid formgrid p-fluid">
                {panelList.map((panel) => (
                    <div key={panel.id_panels} className="field-checkbox col-12 md:col-4">
                        <Checkbox inputId={panel.name_panel} checked={selectedPanels.includes(panel.id_panels)} onChange={(e) => handleCheckboxChange(panel.id_panels, e.checked!)} />
                        <label htmlFor={panel.name_panel} style={{ marginLeft: "0.5rem" }}>
                            {panel.name_panel}
                        </label>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GeneCodesTab;
