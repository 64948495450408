import React, { useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Role } from '../../../api/admin/rolesApi';

interface RoleTableProps {
	roles: Role[];
	onEditClick: (role: Role) => void;
}

const RoleTable: React.FC<RoleTableProps> = ({ roles, onEditClick }) => {
	const [selectedRole, setSelectedRole] = useState<Role | null>(null);

	if (!roles || roles.length === 0) {
		return <p>No roles available.</p>;
	}

	const handleRowSelect = (e: { data: Role }) => {
		onEditClick(e.data);
	};

	return (
		<div className="card surface-0 border-round shadow-2 p-3">
			<DataTable
				value={roles}
				selectionMode="single"
				selection={selectedRole}
				onSelectionChange={(e) => setSelectedRole(e.value as Role)}
				onRowSelect={handleRowSelect}
				paginator={false} // Add pagination if you want
				className="p-datatable-gridlines p-datatable-sm"
				responsiveLayout="scroll"
			>
				<Column field="id" header="ID" style={{ width: '8rem' }} />
				<Column field="name" header="Name" />
				<Column field="guard_name" header="Guard" />
			</DataTable>
		</div>
	);
};

export default RoleTable;
