// src/api/companyApi.ts

import axios from "axios";
import {User} from "./userApi";
import Cookies from "js-cookie";

const API_BASE_URL = process.env.REACT_APP_AUTH_SERVER;

export interface Company {
	id: number;
	name: string;
	address: string | null;
	email: string;
	phone: string | null;
	created_at: string | null;
	updated_at: string | null;
}

export const fetchCompanies = async (
	page: number,
	perPage: number,
	search?: string,
): Promise<{
	data: Company[];
	total: number;
}> => {
	try {
		const response = await axios.get(`${API_BASE_URL}/admin/companies`, {
			params: {
				page,
				per_page: perPage,
				...(search ? { search } : {}),

			},
			headers: {
				Authorization: `Bearer ${Cookies.get("apiKey")}`,
			},
		});

		return {
			data: response.data.data,
			total: response.data.pagination.total_items,
		};
	} catch (error) {
		console.error('Error fetching companies:', error);
		throw error;
	}
};

export const fetchSingleCompany = async (companyId: string): Promise<Company> => {
	const response = await axios.get(`${API_BASE_URL}/admin/companies/${companyId}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return response.data;

};

export const fetchSingleCompanyByName = async (companyname: string) => {
	const response = await axios.post(`${API_BASE_URL}/admin/companies/name`, {
		'companyName': companyname,
	},{
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return response.data;
}

export const updateCompany = async (
    id: number,
    updatedData: Company
): Promise<{message: string , data : Company}> => {
    try {
        const response = await axios.put(`${API_BASE_URL}/admin/companies/${id}`, updatedData, {
            headers: {
                Authorization: `Bearer ${Cookies.get("apiKey")}`,
                'Content-Type': 'application/json',
            },
        });

        return response.data;
    } catch (error: any) {
        // Handle errors appropriately
        console.error('Error updating company:', error);
        // Optionally, extract and throw a more specific error message
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message || 'Failed to update company.');
        } else {
            throw new Error('Failed to update company.');
        }
    }
};

export const createCompany = async (
    newCompany: Company
): Promise<Company> => {
    try {
        const response = await axios.post(`${API_BASE_URL}/admin/companies`, newCompany, {
            headers: {
                Authorization: `Bearer ${Cookies.get("apiKey")}`,
                'Content-Type': 'application/json',
            },
        });

        return response.data.data;
    } catch (error: any) {
        // Handle errors appropriately
        console.error('Error creating company:', error);
        // Optionally, extract and throw a more specific error message
        if (error.response && error.response.data) {
            // If there are validation errors
            if (error.response.status === 422) {
                throw new Error('Validation failed. Please check your input.');
            }
            throw new Error(error.response.data.message || 'Failed to create company.');
        } else {
            throw new Error('Failed to create company.');
        }
    }
};




////////////////////////////////////////////////  Staff Management Functionality

// Get staff for a company
export const getStaff = async (
    page: number,
    perPage: number,
	company: string,
): Promise<{
    data: User[];
    total: number;
}> => {
    const response = await axios.get(`${API_BASE_URL}/admin/staff`, {
        params: {
            page,
            per_page: perPage,
			company : company,
        },
        headers: {
            Authorization: `Bearer ${Cookies.get("apiKey")}`,
        },
    });
    return {
        data: response.data.data,
        total: response.data.pagination.total_items,
    };
};