// src/components/admin/company/CompanyTable.tsx
import React, { useState } from 'react';
import { Company } from '../../../api/admin/companyApi';
import { DataTable, DataTableFilterMeta } from 'primereact/datatable';
import { Column } from 'primereact/column';

interface CompanyTableProps {
	companies: Company[];
	onEditClick: (company: Company) => void;
	first: number;
	rows: number;
	totalRecords: number;
	onPage: (event: any) => void;
}

const CompanyTable: React.FC<CompanyTableProps> = ({
	companies,
	onEditClick,
	first,
	rows,
	totalRecords,
	onPage
}) => {
	const [globalFilter, setGlobalFilter] = useState<string>('');

	if (!companies || companies.length === 0) {
		return <p>No companies available.</p>;
	}

	const filters: DataTableFilterMeta = {
		global: { value: globalFilter, matchMode: 'contains' }
	};

	const handleRowSelect = (e: { data: Company }) => {
		onEditClick(e.data);
	};

	const header = (
		<div className="flex justify-content-end">
			{/* If you want an inline global search: */}
			{/* <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText
                    type="search"
                    placeholder="Global Search"
                    value={globalFilter}
                    onChange={(e) => setGlobalFilter(e.target.value)}
                    style={{ width: '250px' }}
                />
            </span> */}
		</div>
	);

	return (
		<div className="card surface-0 border-round shadow-2 p-3">
			<DataTable
				value={companies}
				header={header}
				paginator
				lazy
				first={first}
				rows={rows}
				totalRecords={totalRecords}
				onPage={onPage}
				selectionMode="single"
				onRowSelect={handleRowSelect}
				filterDisplay="menu"
				filters={filters}
				globalFilterFields={['name', 'address', 'phone', 'email']}
				responsiveLayout="scroll"
				className="p-datatable-gridlines p-datatable-sm"
			>
				<Column field="name" header="Name" sortable filter filterPlaceholder="Search by Name" />
				<Column field="address" header="Address" sortable filter filterPlaceholder="Search by Address" />
				<Column field="phone" header="Phone" sortable filter filterPlaceholder="Search by Phone" />
				<Column field="email" header="Email" sortable filter filterPlaceholder="Search by Email" />
			</DataTable>
		</div>
	);
};

export default CompanyTable;
