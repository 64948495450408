// Sidebar.tsx
import React from "react";
import Cookies from "js-cookie";
import { Button } from "primereact/button";
import { Sample } from "../../../../api/labiq/labiqApi";

interface SidebarProps {
    activeTab: string;
    setActiveTab: (tab: string) => void;
    sample: Sample;
	patientId: number;
}


const Sidebar: React.FC<SidebarProps> = ({ activeTab, setActiveTab, sample, patientId }) => {
    const user = JSON.parse(Cookies.get("user") || "{}");

    // A small utility for the tab items
    const TabItem = ({ label, value }: { label: string; value: string }) => {
        const isActive = activeTab === value;
        return (

			<div
				key={value}
				className={
					"flex align-items-center p-2 text-color transition-colors transition-duration-200 border-round cursor-pointer" +
					(activeTab === value ? " bg-primary-100" : " hover:surface-200")
				}
				style={{ textDecoration: 'none', gap: '0.5rem' }}
				onClick={() => setActiveTab(value)}
			>
				<span className="ml-3">{label}</span>
			</div>
        );
    };

    return (
        <div className="flex flex-column justify-content-between surface-100 text-color" style={{ minWidth: '16rem', overflowY: 'auto' }}>
            {/* Top Section */}
            <div className="flex flex-column align-items-center p-3">
                <span className="p-text-bold">{sample.sampleID}</span>
            </div>
            <hr />

            {/* Navigation Links */}
            <div className="flex flex-column">
                <TabItem label="Sample Details" value="Details" />
				<TabItem label="Quality Control" value="QC" />
				
				{sample.id !== 0 && (
					<TabItem label="History" value="History" />
				)}
				{patientId !== 0 && (
					<TabItem label="Gene.Codes" value="Gene.Codes" />
				)}

            </div>
        </div>
    );
};

export default Sidebar;
