import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import debounce from 'lodash/debounce';
import Cookies from "js-cookie";

//Prime react
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

//import the modal components
import NavButtons from "./modalComponents/Sidebar";
import GeneralTab from "./modalComponents/GeneralTab";

// import the other modals
import EditPatientModal from "../patient/EditPatientModal";
import EditProffesionalModal from "../professionals/EditProfessionalsModal";

// Import the api
import { Appointment, setNewAppointment, updateAppointment } from "../../../api/healthcare/appointmentApi";
import { User } from "../../../api/admin/userApi";
import { update } from "lodash";
import { fetchPatient, Patient, defaultPatient } from "../../../api/healthcare/patientApi";
import { fetchPractitioner, Practitioner, defaultPractitioner} from "../../../api/healthcare/practitionerApi";


interface AppointmentModalProps {
	user: User
	isOpen: boolean;
	onClose: () => void;
	selectedAppointment: Appointment;
}

const AppointmentModal: React.FC<AppointmentModalProps> = ({ user, isOpen, onClose, selectedAppointment }) => {
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const [activeTab, setActiveTab] = useState("Appointment");
	const [isLock, setIsLock] = useState<boolean>(false);

	const [updatedAppointment, setUpdatedAppointment] = useState<Appointment>(selectedAppointment);
	const [selectedPractitioner, setSelectedPractitioner] = useState<Practitioner >(defaultPractitioner);
	const [selectedPatient, setSelectedPatient] = useState<Patient >(defaultPatient);

	

	const toast = useRef<Toast>(null);

	useEffect(() => {
			const getPatient = async () => {
				const {data} = await fetchPatient(selectedAppointment.patient_id.toString());
				setSelectedPatient(data)
			};
			const getPrac = async () => {
				const {data} = await fetchPractitioner(selectedAppointment.practitioner_id);
				setSelectedPractitioner(data)
			};

			if(selectedAppointment){
				setUpdatedAppointment(selectedAppointment)

				if(selectedAppointment.id !== 0){
					setIsLock(true);
					getPatient();
					getPrac();
				}
			}
	}, [selectedAppointment]);


	const handleSaveClick = async () => {
		//validate the appointment information
		if (updatedAppointment.end_time == null || updatedAppointment.start_time == null || updatedAppointment.patient_id === 0 || updatedAppointment.practitioner_id === 0){
			setErrorMessages(["You must fill in start and end date as well as the patient and practitioner"]);
			return;
		}

		try{
			if(updatedAppointment.id === 0){
				const {data, message} = await setNewAppointment(updatedAppointment);
				//Set toast for the new appointment
				toast.current?.show({ severity: 'success', summary: 'Appointment Created', detail: `${message}` });
			}else{
				const {data, message} = await updateAppointment(updatedAppointment);
				toast.current?.show({ severity: 'success', summary: 'Appointment Updated', detail: `${message}` });
			}

			onClose();
			
		} catch (error: any){
			handleErrors(error);
		}


	};

	const handleErrors = (error: unknown) => {
		if (axios.isAxiosError(error) && error.response) {
			if (error.response.data?.errors) {
				const messages = Object.entries(error.response.data.errors)
					.flatMap(([_, errs]) => errs as string);
				setErrorMessages(messages);
			} else if (error.response.data?.message) {
				setErrorMessages([error.response.data.message]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} else if (error instanceof Error) {
			setErrorMessages([error.message || "An unknown error occurred."]);
		} else {
			setErrorMessages(["An unknown error occurred."]);
		}
	};

	const modalClosed = async () => {
		setActiveTab("Appointment")
	};

	const header = () => {
		return (
			<div className="flex justify-content-between">
				<Toast ref={toast} />
				<div className="">Make a new appointment</div>

				<div>
					<Button icon="pi pi-save p-icon"
						rounded
						text aria-label="Save"
						loading={isSubmitting}
						disabled={isSubmitting}
						type="button"
						onClick={handleSaveClick}
						className="p-dialog-header-icon mr-2"
						style={{ color: 'var(--text-color-alternative)' }}
					/>
				</div>
			</div>
		)
	};


	return (
		<Dialog

			visible={isOpen}
			onHide={() => {
				onClose();
			}}
			style={{ width: '80vw', height: '80vh' }}
			modal
			dismissableMask
			focusOnShow={false}
			header={header}
			headerClassName="pb-0 h-auto bg-primary "
			contentStyle={{ padding: 0, height: '100%' }}
			closable={true}
			maximizable
			className="custom-dialog"
		>
			
			<div className="flex w-full h-full" style={{ overflow: 'hidden' }}>
				{/* Sidebar */}
				<div className="border-right-1 surface-border" style={{ overflowY: 'auto' }}>
					<NavButtons activeTab={activeTab} setActiveTab={setActiveTab} appointment={updatedAppointment as Appointment} />
				</div>

				{/* Main content area */}
				<div className="flex flex-column p-4" style={{ flex: 1, overflowY: 'auto' }}>
					<div className="flex-1 overflow-y-auto overflow-x-hidden">
						{/* Appointment Info */}
						{activeTab === "Appointment" && (
							<GeneralTab user={user} handleErrors={handleErrors} isLock={isLock} appointment={updatedAppointment} setAppointment={setUpdatedAppointment} selectedPatient={selectedPatient} selectedPractitioner={selectedPractitioner} setSelectedPatient={setSelectedPatient} setSelectedPractitioner={setSelectedPractitioner} />
						)}
						{activeTab === "Patient" && (
							<EditPatientModal isAnon={false} isOpen={true} onClose={modalClosed} patient={selectedPatient} onPatientUpdated={modalClosed}  />
						)}
						{activeTab === "Practitioner" && (
							<EditProffesionalModal  isOpen={true} onClose={modalClosed} practitioner={selectedPractitioner} onPractitionerUpdate={modalClosed}  />
						)}
					</div>

					{errorMessages.length > 0 && (
						<div className="mt-3">
							{errorMessages.map((msg, idx) => (
								<div key={idx} style={{ color: 'red' }}>
									{msg}
								</div>
							))}
						</div>
					)}

				</div>
			</div>
		</Dialog>
	);
};

export default AppointmentModal;
