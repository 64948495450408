// src/components/icons/PatientManagementIcon.tsx
import React from 'react';
import { FaHospitalUser } from 'react-icons/fa'; // Importing the Font Awesome icon

interface PatientManagementIconProps {
    className?: string;
}

const PatientManagementIcon: React.FC<PatientManagementIconProps> = ({ className }) => {
    return (
        <FaHospitalUser
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24} // Default size
            aria-label="Patient Management"
        />
    );
};

export default PatientManagementIcon;
