import React from "react";
import { User } from "../../api/admin/userApi";
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

interface UserProfileDetailsProps {
	user: User;
	isEditing: boolean;
	setUserData: React.Dispatch<React.SetStateAction<User>>;
}

const UserProfileDetails: React.FC<UserProfileDetailsProps> = ({ user, isEditing, setUserData }) => {
	const genders = [
		{ label: 'Male', value: 'Male' },
		{ label: 'Female', value: 'Female' },
		{ label: 'Other', value: 'Other' }
	];

	const handleChange = (name: keyof User, value: any) => {
		setUserData(prevUser => ({ ...prevUser, [name]: value }));
	};

	return (
		<div>
			<h2 className="text-color" style={{ fontSize: '1.25rem', fontWeight: '500', marginBottom: '1rem' }}>Personal Information</h2>
			<div className="grid formgrid p-fluid">
				{/* Username */}
				<div className="field col-12 md:col-6">
					<span className="text-color-secondary" style={{ display: 'block', marginBottom: '0.5rem' }}>Username</span>
					{isEditing ? (
						<InputText
							name="username"
							value={user.username || ''}
							onChange={(e) => handleChange('username', e.target.value)}
						/>
					) : (
						<span className="text-color">{user.username || 'Not provided'}</span>
					)}
				</div>

				{/* Date of Birth */}
				<div className="field col-12 md:col-6">
					<span className="text-color-secondary" style={{ display: 'block', marginBottom: '0.5rem' }}>Date of Birth</span>
					{isEditing ? (
						<Calendar
							name="date_of_birth"
							value={user.date_of_birth ? new Date(user.date_of_birth) : null}
							onChange={(e) => handleChange('date_of_birth', e.value)}
							dateFormat="yy-mm-dd"
							showIcon
						/>
					) : (
						<span className="text-color">{user.date_of_birth ? new Date(user.date_of_birth).toLocaleDateString() : "Not provided"}</span>
					)}
				</div>

				{/* Gender */}
				<div className="field col-12 md:col-6">
					<span className="text-color-secondary" style={{ display: 'block', marginBottom: '0.5rem' }}>Gender</span>
					{isEditing ? (
						<Dropdown
							name="gender"
							value={user.gender || ''}
							options={genders}
							onChange={(e) => handleChange('gender', e.value)}
							placeholder="Select Gender"
						/>
					) : (
						<span className="text-color">{user.gender || "Not provided"}</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default UserProfileDetails;
