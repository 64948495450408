// src/components/FormBuilder/FormBuilder.tsx
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { SortingState } from "@tanstack/react-table";

//Layout and generic compoennts
import Layout from "../components/Layout";
import LoadingSpinner from '../components/icons/LoadingSpinner';

//Api
import { Form, FormElement,defaultForm, fetchForms} from "../api/formApi";
import { User } from '../api/admin/userApi';

//COmponents
import FormButtons from "../components/forms/FormPageButtons";
import FormTable from '../components/forms/FormTable';
import FormBuilderModal  from '../components/forms/FormBuilderModal';

// Prime react
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';



export const FormBuilder: React.FC = () => {
	const user:User = JSON.parse(Cookies.get('user') || "{}");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [forms, setForms] = useState<Form[]>();
	const [form, setForm] = useState<Form | null>();

	//Table data
	const Forms_PER_PAGE = 30;
	const [totalForms, setTotalForms] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const first = currentPage * Forms_PER_PAGE;
	
	//Modal
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	useEffect(() => {
		loadForms("");
	}, []);

	const loadForms = async (search: string) => {
		setIsLoading(true);
		try {

			const { data } = await fetchForms(user.company, search);

			// Convert `structure` from JSON string into the FormElement[] array
			const transformed = data.map((form: Form) => {
				if (typeof form.structure === 'string') {
					return {
						...form,
						structure: JSON.parse(form.structure) as FormElement[],
					};
				}
				return form;
			});
			setForms(transformed);

			const length = transformed.length;
			setTotalForms(length);
		} finally {
			setIsLoading(false);
		}
	};
	const handleCreateForm = async () => {
		let newForm = defaultForm;
		newForm.company = user.company;
		newForm.practitioner_id = user.id;
		setForm(newForm);
		setIsModalOpen(true);

	};

	const onEditForm = async (form: Form) => {
		setForm(form);
		setIsModalOpen(true);
	};

	const onModalClose = async () => {
		setForm(null);
		setIsModalOpen(false);
		loadForms("");
	};



	return (
		<Layout>
			<div className="p-3">
				<FormButtons handleCreateForm={handleCreateForm} searchForms={loadForms} />

				{isLoading ? (
					<div className="flex justify-content-center align-items-center" style={{ height: '200px' }}>
						<LoadingSpinner size={60} color="#354396" />
					</div>
				) : (forms && (
					<FormTable first={first} forms={forms} onEditClick={onEditForm} rows={Forms_PER_PAGE} totalForms={totalForms} />
				))}
			</div>

			{(isModalOpen && form) && (
				<FormBuilderModal form={form} isOpen={isModalOpen} isSubmitting={isLoading} setIsSubmitting={setIsLoading} onClose={onModalClose} />
			)}
		</Layout>
	);
};
