// ActionButtons.tsx
import React, { useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { Toast } from "primereact/toast";

interface ButtonsProps {
	onSearchChange: (searchTerm: string) => void;
	handleAddProduct: () => void;
	
	searchTerm: string;

}

const Buttons: React.FC<ButtonsProps> = ({  onSearchChange, handleAddProduct,  searchTerm }) => {
	const toast = useRef<Toast>(null);


	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onSearchChange(e.target.value);
	};


	return (
		<div className="flex justify-content-between">
			<Toast ref={toast} />
			<div className="flex flex-wrap pb-4">
				<div className="">
					<Button label="Add Product" icon="pi pi-plus" className="p-button-primary mr-2" onClick={handleAddProduct} />
				</div>
			</div>
			<div className="justify-content-end">
				<InputText type="text" placeholder="Search products..." value={searchTerm} onChange={handleSearchChange} className="border px-4 py-2 rounded" />
			</div>
		</div>

	);
};

export default Buttons;
