import React from "react";
import debounce from 'lodash/debounce';

//APi imports
import { InputText } from "primereact/inputtext";
import { Patient } from "../../../../api/healthcare/patientApi";
import { FloatLabel } from "primereact/floatlabel";

interface ProfileTabProps {
	updatedPatient: Patient;
	setUpdatedPatient: React.Dispatch<React.SetStateAction<Patient>>;
	handleAutoSave: () => void;
	isAnon: boolean;
}

const ProfileTab: React.FC<ProfileTabProps> = ({ updatedPatient, setUpdatedPatient, handleAutoSave, isAnon }) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUpdatedPatient(prev => ({ ...prev, [e.target.name]: e.target.value }));

	};

	const formatDate = (date: string | Date | undefined): string => {
		if (!date) return "";
		if (date instanceof Date) {
			return date.toISOString().substring(0, 10);
		}
		// If it's a string, extract the first 10 characters
		return date.substring(0, 10);
	};

	return (
		<div >
			{!isAnon ? (
				<div className="grid formgrid p-fluid">
					{/* Column 1 */}
					<div className="grid col-12">
						<div className="col-12">
							<h4 className="">Basic Information</h4>
						</div>
						<div className="field col-12 md:col-6 lg:col-4 mt-2">
							<FloatLabel>
								<label htmlFor="first_name">First Name</label>
								<InputText
									id="first_name"
									name="first_name"
									value={updatedPatient.first_name || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									placeholder="Enter first name"
								/>
							</FloatLabel>
						</div>
						<div className="field col-12 md:col-6 lg:col-4 mt-2">
							<FloatLabel>
								<label htmlFor="middle_name">Middle Name</label>
								<InputText
									id="middle_name"
									name="middle_name"
									value={updatedPatient.middle_name || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									placeholder="Enter middle name"
								/>
							</FloatLabel>
						</div>
						<div className="field col-12 md:col-6 lg:col-4 mt-2">
							<FloatLabel>
								<label htmlFor="last_name">Last Name</label>
								<InputText
									id="last_name"
									name="last_name"
									value={updatedPatient.last_name || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									placeholder="Enter last name"
								/>
							</FloatLabel>
						</div>
						<div className="field col-12 md:col-6 lg:col-4 mt-2">
							<FloatLabel>
								<label htmlFor="email">Email</label>
								<InputText
									type="email"
									id="email"
									name="email"
									value={updatedPatient.email || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									placeholder="Enter email"
								/>
							</FloatLabel>
						</div>
						<div className="field col-12 md:col-6 lg:col-4 mt-2">
							<FloatLabel>
								<label htmlFor="username">Username</label>
								<InputText
									id="username"
									name="username"
									value={updatedPatient.username || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									placeholder="Enter username"
								/>
							</FloatLabel>
						</div>
						<div className="field col-12 md:col-6 lg:col-4 mt-2">
							<FloatLabel>
								<label htmlFor="date_of_birth">Date of Birth</label>
								<InputText
									type="date"
									id="date_of_birth"
									name="date_of_birth"
									value={formatDate(updatedPatient.date_of_birth)}
									onChange={handleChange}
									onBlur={handleAutoSave}
								/>
							</FloatLabel>
						</div>
						<div className="field col-12 md:col-6 lg:col-4 mt-2">
							<FloatLabel>
								<label htmlFor="gender">Gender</label>
								<InputText
									id="gender"
									name="gender"
									value={updatedPatient.gender || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									placeholder="Enter gender"
								/>
							</FloatLabel>
						</div>
						<div className="field col-12 md:col-6 lg:col-4 mt-2">
							<FloatLabel>
								<label htmlFor="sex">Sex</label>
								<InputText
									id="sex"
									name="sex"
									value={updatedPatient.sex || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									placeholder="Enter sex (Male, Female)"
								/>
							</FloatLabel>
						</div>
					</div>

					{/* Password fields only if new patient (id === 0) */}
					{updatedPatient.id === 0 && (
						<>
							<div className="field col-12 md:col-6 mt-2">
								<FloatLabel>
									<label htmlFor="password">Password</label>
									<InputText
										type="password"
										id="password"
										name="password"
										value={updatedPatient.password || ""}
										onChange={handleChange}
										onBlur={handleAutoSave}
										placeholder="Enter password"
									/>
								</FloatLabel>
							</div>
							<div className="field col-12 md:col-6 mt-2">
								<FloatLabel>
									<label htmlFor="password_confirmation">Password Confirmation</label>
									<InputText
										type="password"
										id="password_confirmation"
										name="password_confirmation"
										value={updatedPatient.password_confirmation || ""}
										onChange={handleChange}
										onBlur={handleAutoSave}
										placeholder="Confirm password"
									/>
								</FloatLabel>
							</div>
						</>
					)}

					{/* Column 2 */}
					<div className="grid col-12">
						<div className="col-12">
							<h4 className="">Address Information</h4>
						</div>
						<div className="field col-12 md:col-6 mt-2">
							<FloatLabel>
								<label htmlFor="address">Address</label>
								<InputText
									id="address"
									name="address"
									value={updatedPatient.address || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									placeholder="Enter address"
								/>
							</FloatLabel>
						</div>
						<div className="field col-12 md:col-6 mt-2">
							<FloatLabel>
								<label htmlFor="city">City</label>
								<InputText
									id="city"
									name="city"
									value={updatedPatient.city || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									placeholder="Enter city"
								/>
							</FloatLabel>
						</div>
						<div className="field col-12 md:col-6 mt-2">
							<FloatLabel>
								<label htmlFor="state">State</label>
								<InputText
									id="state"
									name="state"
									value={updatedPatient.state || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									placeholder="Enter state"
								/>
							</FloatLabel>
						</div>
						<div className="field col-12 md:col-6 mt-2">
							<FloatLabel>
								<label htmlFor="country">Country</label>
								<InputText
									id="country"
									name="country"
									value={updatedPatient.country || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									placeholder="Enter country"
								/>
							</FloatLabel>
						</div>
						<div className="field col-12 md:col-6 mt-2">
							<FloatLabel>
								<label htmlFor="postal_code">Post Code</label>
								<InputText
									id="postal_code"
									name="postal_code"
									value={updatedPatient.postal_code || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									placeholder="Enter post code"
								/>
							</FloatLabel>
						</div>
					</div>

					{/* Column 3 */}
					<div className="grid col-12">
						<div className="col-12">
							<h4 className="">Contact Information</h4>
						</div>
						<div className="field col-12 md:col-6 mt-2">
							<FloatLabel>
								<label htmlFor="phone">Phone Number</label>
								<InputText
									id="phone"
									name="phone"
									value={updatedPatient.phone || ""}
									onChange={handleChange}
									onBlur={handleAutoSave}
									placeholder="Enter phone number"
								/>
							</FloatLabel>
						</div>

					</div>
				</div>
			) : (
				<div className="grid formgrid p-fluid mt-5">
					<div className="field col-12 md:col-6 mt-2">
						<FloatLabel>
							<label htmlFor="email">Email</label>
							<InputText
								type="email"
								id="email"
								name="email"
								value={updatedPatient.email || ""}
								onChange={handleChange}
								onBlur={handleAutoSave}
								placeholder="Enter email"
							/>
						</FloatLabel>
					</div>
					<div className="field col-12 md:col-6 mt-2">
						<FloatLabel>
							<label htmlFor="username">Username</label>
							<InputText
								id="username"
								name="username"
								value={updatedPatient.username || ""}
								onChange={handleChange}
								onBlur={handleAutoSave}
								placeholder="Enter username"
							/>
						</FloatLabel>
					</div>

					{/* Password fields only if new patient (id === 0) */}
					{updatedPatient.id === 0 && (
						<>
							<div className="field col-12 md:col-6 mt-2">
								<FloatLabel>
									<label htmlFor="password">Password</label>
									<InputText
										type="password"
										id="password"
										name="password"
										value={updatedPatient.password || ""}
										onChange={handleChange}
										onBlur={handleAutoSave}
										placeholder="Enter password"
									/>
								</FloatLabel>
							</div>
							<div className="field col-12 md:col-6 mt-2">
								<FloatLabel>
									<label htmlFor="password_confirmation">Password Confirmation</label>
									<InputText
										type="password"
										id="password_confirmation"
										name="password_confirmation"
										value={updatedPatient.password_confirmation || ""}
										onChange={handleChange}
										onBlur={handleAutoSave}
										placeholder="Confirm password"
									/>
								</FloatLabel>
							</div>
						</>
					)}
				</div>
			)}

		</div>
	);
};

export default ProfileTab;
