import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Layout from "../../components/Layout";
import { fetchPractitioners, Practitioner } from "../../api/healthcare/practitionerApi";
import LoadingSpinner from '../../components/icons/LoadingSpinner';
import ProfessionalsButtons from "../../components/healthcare/professionals/ProfessionalsButtons";
import ProfessionalsTable from "../../components/healthcare/professionals/ProfessionalsTable";
import EditProfessionalsModal from "../../components/healthcare/professionals/EditProfessionalsModal";

const createNewPractitioner = (): Practitioner => ({
	id: 0,
	uuid: "",
	username: "",
	email: "",
	password: "",
	password_confirmation: "",
	first_name: "",
	last_name: "",
	phone: "",
	address: "",
	city: "",
	state: "",
	country: "",
	postal_code: "",
	profile_picture: "",
	date_of_birth: new Date(),
	gender: "",
	sex: "",
	company: "",
	panel_key: "",
	company_code: "",
	reagents: "",
	preferences: "",
	is_active: false,
	created_at: new Date(),
	updated_at: new Date(),
	roles: [],
	practitioner: {
		id: 0,
		user_id: 0,
		medical_license_number: "",
		npi_number: "",
		dea_number: "",
		specialties: [],
		years_of_experience: 0,
		qualifications: [],
		certifications: [],
		professional_memberships: [],
		practicing_locations: [],
		availability_schedule: null,
		consultation_fee: 0,
		biography: "",
		languages_spoken: [],
		education_history: null,
		malpractice_insurance_details: null,
		billing_information: null,
		supervisor_id: 0,
		taxonomy_code: "",
		emergency_contact: null,
		created_at: new Date().toISOString(),
		updated_at: new Date().toISOString(),
	},
});

const ProfessionalPage: React.FC = () => {
	const [professionals, setProfessionals] = useState<Practitioner[]>([]);
	const [totalProfessionals, setTotalProfessionals] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedProfessional, setSelectedProfessional] = useState<Practitioner | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const PROFESSIONALS_PER_PAGE = 30;

	useEffect(() => {
		loadProfessionals();
		// eslint-disable-next-line
	}, [currentPage]);

	const loadProfessionals = async (searchTerm: string = "") => {
		setIsLoading(true);
		const user = JSON.parse(Cookies.get("user") || "{}");
		try {
			const { data, total } = await fetchPractitioners(currentPage, PROFESSIONALS_PER_PAGE, user.company, searchTerm);
			setProfessionals(data);
			setTotalProfessionals(total);
			setError(null);
		} catch (err) {
			console.error("Error fetching professionals:", err);
			setError("Failed to load professionals.");
		} finally {
			setIsLoading(false);
		}
	};

	const searchProfessionals = async (search: string) => {
		setCurrentPage(1); // reset to first page if searching
		await loadProfessionals(search);
	};

	const handleEditClick = (professional: Practitioner) => {
		setSelectedProfessional(professional);
		setIsModalOpen(true);
	};

	const handleNewPractitioner = () => {
		setSelectedProfessional(createNewPractitioner());
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setSelectedProfessional(null);
		setIsModalOpen(false);
	};

	const handleProfessionalUpdate = async () => {
		await loadProfessionals();
	};

	return (
		<Layout>
			<div className="flex flex-column gap-3">
				<h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: 0 }}>Professional Management</h1>
				{isLoading ? (
					<div className="flex justify-content-center align-items-center" style={{ height: '200px' }}>
						<LoadingSpinner size={60} color="#354396" />
					</div>
				) : (
					<div className="flex flex-column gap-3">
						<ProfessionalsButtons
							searchProfessionals={searchProfessionals}
							reloadProfessionals={() => loadProfessionals()}
							handleNewPractitioner={handleNewPractitioner}
						/>
						<ProfessionalsTable
							professionals={professionals}
							totalProfessionals={totalProfessionals}
							currentPage={currentPage}
							rows={PROFESSIONALS_PER_PAGE}
							onEditClick={handleEditClick}
							onPageChange={setCurrentPage}
						/>
					</div>
				)}
				{selectedProfessional && (
					<EditProfessionalsModal
						isOpen={isModalOpen}
						onClose={handleModalClose}
						practitioner={selectedProfessional}
						onPractitionerUpdate={handleProfessionalUpdate}
					/>
				)}
				{error && <p style={{ color: 'red' }}>{error}</p>}
			</div>
		</Layout>
	);
};

export default ProfessionalPage;
