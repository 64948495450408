// src/components/icons/ProductsIcon.tsx
import React from 'react';
import { FaBox } from 'react-icons/fa'; // Font Awesome box icon

interface ProductsIconProps {
    className?: string;
}

const ProductsIcon: React.FC<ProductsIconProps> = ({ className }) => {
    return (
        <FaBox
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24}
            aria-label="Products"
        />
    );
};

export default ProductsIcon;
