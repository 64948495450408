// src/components/orders/PurchaseOrderTable.tsx

import React from "react";
import { PurchaseOrder } from "../../../api/inventory/purchaseOrderApi";

interface PurchaseOrderTableProps {
    orders: PurchaseOrder[];
    onEdit: (order: PurchaseOrder) => void;
    onDelete: (order: PurchaseOrder) => void;
}

const PurchaseOrderTable: React.FC<PurchaseOrderTableProps> = ({ orders, onEdit, onDelete }) => {
    if (!orders || orders.length === 0) {
        return <p>No purchase orders available.</p>;
    }

    return (
        <table className="min-w-full bg-white border">
            <thead>
                <tr>
                    <th className="py-2 px-3 border-b">Order ID</th>
                    <th className="py-2 px-3 border-b">Supplier ID</th>
                    <th className="py-2 px-3 border-b">Order Date</th>
                    <th className="py-2 px-3 border-b">Status</th>
                    <th className="py-2 px-3 border-b">Total Amount</th>
                    <th className="py-2 px-3 border-b">Actions</th>
                </tr>
            </thead>
            <tbody>
                {orders.map((order) => (
                    <tr key={order.purchase_order_id} className="text-center">
                        <td className="py-2 px-3 border-b">{order.purchase_order_id}</td>
                        <td className="py-2 px-3 border-b">{order.supplier_id}</td>
                        <td className="py-2 px-3 border-b">{order.order_date}</td>
                        <td className="py-2 px-3 border-b">{order.status}</td>
                        <td className="py-2 px-3 border-b">${order.total_amount}</td>
                        <td className="py-2 px-3 border-b">
                            <button onClick={() => onEdit(order)} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 mr-2">
                                Edit
                            </button>
                            <button onClick={() => onDelete(order)} className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600">
                                Delete
                            </button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default PurchaseOrderTable;
