// src/components/Notifications.tsx

import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { Badge } from 'primereact/badge';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Toast } from 'primereact/toast';
import { Notification, fetchNotifications, markAsRead, NotificationsResponse, updateStatus, deleteNotification } from './NotificationsInterface';

import './Notifications.css'; 

const Notifications: React.FC = () => {
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const [error, setError] = useState<string>("");
	const [prevNotifications, setPrevNotifications] = useState<Notification[]>([]);
	const POLLING_INTERVAL = 10000; // 10 seconds

	const op = useRef<OverlayPanel>(null);
	const toast = useRef<Toast>(null);

	useEffect(() => {
		let isMounted = true; // To prevent state updates if the component is unmounted

		const getNotifications = async () => {
			try {
				const data = await fetchNotifications();
				if (isMounted) {
					const fetchedNotifications = data as NotificationsResponse;

				
					const newNotifications = fetchedNotifications.filter(
						notif => !prevNotifications.some(prev => prev.id === notif.id) && notif.status === "new"
					);
				
					// Show toast notifications only for genuinely new notifications
					newNotifications.forEach(notif => {
						toast.current?.show({
							severity: 'info',
							summary: 'New Notification',
							detail: notif.title,
							life: 5000,
						});

						updateStatus(notif.id, "alerted");
					});

					//Foreach new notification i need to change the status in the backend as not new to stop repeat alerts of the notification


					setNotifications(fetchedNotifications);
					setPrevNotifications(fetchedNotifications);
				}
			} catch (err) {
				if (isMounted) {
					setError('Failed to fetch notifications.');
				}
			}
		};

		// Initial fetch
		getNotifications();

		// Set up polling
		const intervalId = setInterval(getNotifications, POLLING_INTERVAL);

		// Clean up on unmount
		return () => {
			isMounted = false;
			clearInterval(intervalId);
		};
	}, []);

	const handleMarkAsRead = async (id: string) => {
		try {
			await markAsRead(id);
			setNotifications(prev =>
				prev.map(notif =>
					notif.id === id ? { ...notif, read_status: true } : notif
				)
			);
		} catch (err) {
			setError('Failed to mark notification as read.');
		}
	};

	const handleDelete = async (id: string) => {
		try {
			await deleteNotification(id);
			setNotifications(prev => prev.filter(notif => notif.id !== id));
		}catch (err) {
			setError('Failed to delete notification.');
		}
	};

	const unreadCount = notifications.filter(notif => !notif.read_status).length;

	return (
		<div className="notifications-component">
			{/* Toast for new notifications */}
			<Toast ref={toast} style={{zIndex: '99999'}}/>

			{/* Notification Icon with Badge */}
			<Button
				icon="pi pi-bell"
				className="p-button-text"
				onClick={(e) => op.current?.toggle(e)}
			>
				{unreadCount > 0 && (
					<Badge value={unreadCount} severity="danger" className="notification-badge" />
				)}
			</Button>

			{/* OverlayPanel as Dropdown */}
			<OverlayPanel ref={op} dismissable>
				<div className="notification-dropdown">
					<h4>Notifications</h4>
					{error && <div className="error-message">{error}</div>}
					{notifications.length === 0 && <p>No notifications.</p>}
					<ul className="notification-list">
						{notifications.map(notification => (
							<li
								key={notification.id}
								className={`notification-item ${notification.read_status ? 'read' : 'unread'}`}
							>
								<div className="notification-content">
									<h5>{notification.title}</h5>
									<p>{notification.message}</p>
									<Button
										label="Delete"
										className="p-button-text p-button-sm "
										severity="danger"
										onClick={() => handleDelete(notification.id)}
									/>
								</div>
								
								{!notification.read_status && (
									<Button
										label="Mark as Read"
										className="p-button-text p-button-sm"
										onClick={() => handleMarkAsRead(notification.id)}
									/>
								)}
							</li>
						))}
					</ul>
				</div>
			</OverlayPanel>
		</div>
	);
};

export default Notifications;
