import axios from "axios";
import Cookies from "js-cookie";
import { Role } from "../admin/rolesApi";
const API_BASE_URL = process.env.REACT_APP_AUTH_SERVER;
const API_HEALTH_URL = process.env.REACT_APP_HEALTHCARE_SERVER;


export interface Practitioner {
	id: number;
	uuid: string;
	username: string;
	email: string;
	password: string;
	password_confirmation: string;
	first_name: string;
	middle_name?: string;
	last_name: string;
	phone: string;
	address: string;
	city: string;
	state: string;
	country: string;
	postal_code: string;
	profile_picture: string;
	date_of_birth: Date;
	gender: string;
	sex: string;
	preferences: string;
	is_active: boolean;
	created_at: Date;
	updated_at: Date;
	company: string;
	panel_key: string;
	company_code: string;
	reagents: string;
	roles: Role[];
	practitioner: PractitionerInfo;
	pivot?: Pivot;
}

//this is extended by the user interface
export interface PractitionerInfo {
	id: number;
	user_id: number;
	medical_license_number: string;
	npi_number?: string;
	dea_number?: string;
	specialties?: string[];
	years_of_experience?: number;
	qualifications?: string[];
	certifications?: string[];
	professional_memberships?: string[];
	practicing_locations?: string[];
	availability_schedule?: any;
	consultation_fee?: number;
	biography?: string;
	languages_spoken?: string[];
	education_history?: any;
	linkage?: string;
	malpractice_insurance_details?: any;
	billing_information?: any;
	supervisor_id?: number;
	taxonomy_code?: string;
	emergency_contact?: any;
	created_at: string;
	updated_at: string;
}

export interface Pivot {
	user_id: number;
	practitioner_id: number;
	role: string;
}

export interface PractitionerType {
	id: number,
	title: string
}


export const defaultPractitionerInfo: PractitionerInfo = {
	id: 0,
	user_id: 0,
	medical_license_number: "",
	npi_number: "",
	dea_number: "",
	specialties: [],
	years_of_experience: 0,
	qualifications: [],
	certifications: [],
	professional_memberships: [],
	practicing_locations: [],
	availability_schedule: null,
	consultation_fee: 0,
	biography: "",
	languages_spoken: [],
	education_history: null,
	malpractice_insurance_details: null,
	billing_information: null,
	supervisor_id: 0,
	taxonomy_code: "",
	emergency_contact: null,
	created_at: new Date().toISOString(),
	updated_at: new Date().toISOString(),
}

export const defaultPractitioner: Practitioner = {
	id: 0,
	uuid: "",
	username: "",
	email: "",
	password: "",
	password_confirmation: "",
	first_name: "",
	middle_name: "",
	last_name: "",
	phone: "",
	address: "",
	city: "",
	state: "",
	country: "",
	postal_code: "",
	profile_picture: "",
	date_of_birth: new Date(), 
	gender: "",
	sex: "",
	preferences: "",
	is_active: false,
	created_at: new Date(), 
	updated_at: new Date(), 
	company: "",
	panel_key: "",
	company_code: "",
	reagents: "",
	roles: [], 
	practitioner: defaultPractitionerInfo,
	pivot: undefined, 
};

export const fetchPractitioners = async (
	page: number,
	perPage: number,
	company: string,
	search?: string,
): Promise<{
	data: Practitioner[];
	total: number;
}> => {
	const response = await axios.get(`${API_BASE_URL}/admin/staff/practitioner`, {
		params: {
			page,
			per_page: perPage,
			company: company,
			...(search ? { search } : {}),
		},
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return {
		data: response.data.data,
		total: response.data.pagination.total_items,
	};
};

export const fetchPractitioner = async (
	id: number,

): Promise<{
	data: Practitioner;

}> => {
	const response = await axios.get(`${API_BASE_URL}/admin/staff/practitioner/${id}`, {

		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return {
		data: response.data,
	};
};

export const updatePractitioner = async (
	id: number,
	practitionerData: Practitioner
): Promise<Practitioner> => {
	const response = await axios.put(`${API_BASE_URL}/admin/staff/practitioner/${id}`, practitionerData, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
			"Content-Type": "application/json",
		},
	});
	return response.data; // Assumes the backend returns the updated user with practitioner data
};

export const createPractitioner = async (practitionerData: any): Promise<Practitioner> => {
	const response = await axios.post(`${API_BASE_URL}/admin/staff/practitioner`, practitionerData, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
			"Content-Type": "application/json",
		},
	});
	return response.data;
};




// Assign practitioners to a user
export const assignPractitioners = async (userId: number, practitionerId: number, roles: string[]) => {
	try {
		const response = await axios.post(`${API_BASE_URL}/users/${userId}/practitioners/assign`, {
			practitioner_id: practitionerId,
			role: roles
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${Cookies.get("apiKey")}`, // Adjust based on your auth setup
			},
		});
		return response.data;
	} catch (error: any) {
		throw error.response?.data || 'Error assigning practitioners';
	}
};

// Unassign a practitioner from a user
export const unassignPractitioner = async (userId: number, practitionerId: number) => {
	try {
		const response = await axios.post(`${API_BASE_URL}/users/${userId}/practitioners/unassign`, {
			practitioner_id: practitionerId
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${Cookies.get("apiKey")}`, // Adjust based on your auth setup
			},
		});
		return response.data;
	} catch (error: any) {
		throw error.response?.data || 'Error unassigning practitioner';
	}
};

// Get all practitioners assigned to a user
export const getAssignedPractitioners = async (userId: number) => {
	try {
		const response = await axios.get(`${API_BASE_URL}/users/${userId}/practitioners`, {
			headers: {
				'Authorization': `Bearer ${Cookies.get("apiKey")}`,
			},
		});
		return response.data.practitioners as Practitioner[];
	} catch (error: any) {
		throw error.response?.data || 'Error fetching assigned practitioners';
	}
};

//Get practitioner types
export const getPractitionerTypes = async () => {
	try {
		const response = await axios.get(`${API_HEALTH_URL}/practitioner/types`, {
			headers: {
				'Authorization': `Bearer ${Cookies.get("apiKey")}`,
			},
		});
		return response.data;
	} catch (error: any) {
		throw error.response?.data || 'Error fetching practitioner types';
	}
};