// src/components/icons/FormIcon.tsx
import React from 'react';
import { FaWpforms } from "react-icons/fa6";

interface FormIconProps {
	className?: string;
}

const FormIcon: React.FC<FormIconProps> = ({ className }) => {
	return (
		<FaWpforms
			className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
			size={24} // Default size
			aria-label="Application Management"
		/>
	);
};

export default FormIcon;
