import React from "react";
import { Company } from "../../../../api/admin/companyApi";
import { InputText } from 'primereact/inputtext';

interface DetailsTabProps {
	company: Company;
	setUpdatedCompany: React.Dispatch<React.SetStateAction<Company>>;
	handleAutoSave: () => void;
}

const DetailsTab: React.FC<DetailsTabProps> = ({ company, setUpdatedCompany, handleAutoSave }) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUpdatedCompany(prev => ({ ...prev, [e.target.name]: e.target.value }));
	};

	return (
		<div className="grid p-fluid formgrid">
			<div className="field col-12 md:col-6">
				<label htmlFor="name">Name</label>
				<InputText
					id="name"
					name="name"
					value={company.name || ""}
					onChange={handleChange}
					onBlur={handleAutoSave}
					placeholder="Enter company name"
					className="w-full"
				/>
			</div>
			<div className="field col-12 md:col-6">
				<label htmlFor="address">Address</label>
				<InputText
					id="address"
					name="address"
					value={company.address || ""}
					onChange={handleChange}
					onBlur={handleAutoSave}
					placeholder="Enter address"
					className="w-full"
				/>
			</div>
			<div className="field col-12 md:col-6">
				<label htmlFor="email">Email</label>
				<InputText
					id="email"
					name="email"
					value={company.email || ""}
					onChange={handleChange}
					onBlur={handleAutoSave}
					placeholder="Enter email"
					className="w-full"
				/>
			</div>
			<div className="field col-12 md:col-6">
				<label htmlFor="phone">Phone</label>
				<InputText
					id="phone"
					name="phone"
					value={company.phone || ""}
					onChange={handleChange}
					onBlur={handleAutoSave}
					placeholder="Enter phone number"
					className="w-full"
				/>
			</div>
		</div>
	);
};

export default DetailsTab;
