import React, { useState } from "react";
import debounce from 'lodash/debounce';

//Prime react
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog } from "primereact/confirmdialog";
import { FileUpload } from "primereact/fileupload";

//API imports
import { updateApplication, createApplication, deleteApplication, Application } from "../../../api/admin/appApi";

interface EditAppModalProps {
    isOpen: boolean;
    onClose: () => void;
    app: Application;
    onAppUpdated: () => void;
}

const EditAppModal: React.FC<EditAppModalProps> = ({ isOpen, onClose, app, onAppUpdated }) => {
    const [formData, setFormData] = useState<Partial<Application>>({
        name: app.name,
        description: app.description,
        redirect_uris: app.redirect_uris,
        client_id: app.client_id,
        client_secret: app.client_secret,
        logo: app.logo,
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [error, setError] = useState<string>("");
    const [activeTab, setActiveTab] = useState<"Details" | "Secrets">("Details");

    // Confirm deletion
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    // If you want to handle file uploads with PrimeReact’s FileUpload, you’d do something like:
    const handleLogoUpload = (event: any) => {
        // `event.files[0]` contains the uploaded file
        // Convert file to base64 or handle it as needed
        // setFormData((prev) => ({ ...prev, logo: base64OfFile }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const { name, description, redirect_uris, logo } = formData;
            const updateData = { name, description, redirect_uris, logo };

            if (app.id !== 0) {
                // Update existing application
                await updateApplication(app.id.toString(), updateData);
            } else {
                // Create new application
                await createApplication(updateData);
            }

        } catch (err) {
            console.error("Error updating app:", err);
            setError("Failed to update app.");
        } finally {
            setIsSubmitting(false);
        }
    };

	const autoSave = async () => {
		setIsSubmitting(true);
        try {
            const { name, description, redirect_uris, logo } = formData;
            const updateData = { name, description, redirect_uris, logo };

            if (app.id !== 0) {
                // Update existing application
                await updateApplication(app.id.toString(), updateData);
            } else {
                // Create new application
                await createApplication(updateData);
            }
            onAppUpdated();
            onClose();
        } catch (err) {
            console.error("Error updating app:", err);
            setError("Failed to update app.");
        } finally {
            setIsSubmitting(false);
        }
		};
	
		const handleAutoSave = debounce(autoSave, 500);

    // Confirm delete handlers
    const handleDeleteClick = () => {
        setShowConfirmDelete(true);
    };

    const handleConfirmDelete = async () => {
        setShowConfirmDelete(false);
        try {
            await deleteApplication(app.id.toString());
            onAppUpdated();
            onClose();
        } catch (err) {
            console.error("Error deleting application:", err);
            setError("Failed to delete app.");
        }
    };

    const handleCancelDelete = () => {
        setShowConfirmDelete(false);
    };

    // Dialog Footer
    const footer = (
        <div className="flex justify-content-end gap-2">
            <Button label="Cancel" className="p-button-outlined" onClick={onClose} />
            {app.id !== 0 && <Button label="Delete" className="p-button-danger p-button-outlined" onClick={handleDeleteClick} />}
            <Button label={isSubmitting ? (app.id !== 0 ? "Saving..." : "Creating...") : app.id !== 0 ? "Save Changes" : "Create App"} onClick={handleSubmit} disabled={isSubmitting} />
        </div>
    );

    const header = () => {
        return (
            <div className="flex justify-content-between">
                <div className="">{app.id ? "Edit " + app.name : "Add New Application"}</div>

                <div>
                    <Button
                        icon="pi pi-save p-icon"
                        rounded
                        text
                        aria-label="Save"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        type="button"
                        onClick={handleSubmit}
                        className="p-dialog-header-icon mr-2"
                        style={{ color: "var(--text-color-alternative)" }}
                    />
                </div>
            </div>
        );
    };

    // Tab Navigation
    const tabHeader = (
        <div className="flex gap-3 border-bottom-1 surface-border pb-3 mb-3">
            <Button label="App Details" className={"p-button-text " + (activeTab === "Details" ? "text-primary" : "text-color-secondary")} onClick={() => setActiveTab("Details")} />
            <Button label="Secrets" className={"p-button-text " + (activeTab === "Secrets" ? "text-primary" : "text-color-secondary")} onClick={() => setActiveTab("Secrets")} />
        </div>
    );

    return (
        <>
            <Dialog
                visible={isOpen}
                onHide={onClose}
                modal
                dismissableMask
                style={{ width: "80vw", height: "80vh" }}
                closable={true}
                draggable={true}
                maximizable={true}
                header={header}
                headerClassName="pb-0 h-auto bg-primary "
                contentStyle={{ padding: 0 }}
                className="custom-dialog"
                focusOnShow={false}
                footer={footer}
            >
                {tabHeader}

                <form onSubmit={handleSubmit} className="flex flex-column gap-3">
                    {activeTab === "Details" && (
                        <div className="grid formgrid p-fluid">
                            <div className="field col-12 md:col-6">
                                <label htmlFor="name">App Name</label>
                                <InputText id="name" name="name" value={formData.name || ""} onChange={handleChange} onBlur={handleAutoSave} placeholder="Enter app name" />
                            </div>
                            <div className="field col-12 md:col-6">
                                <label htmlFor="logo">Logo</label>
                                {/* PrimeReact FileUpload usage example */}
                                <FileUpload name="logo" chooseLabel="Upload Logo" customUpload auto onUpload={handleLogoUpload} />
                            </div>
                            <div className="field col-12">
                                <label htmlFor="description">Description</label>
                                <InputText id="description" name="description" value={formData.description || ""} onChange={handleChange} onBlur={handleAutoSave} placeholder="Enter description" />
                            </div>
                        </div>
                    )}

                    {activeTab === "Secrets" && (
                        <div className="grid formgrid p-fluid">
                            <div className="field col-12 md:col-6">
                                <label htmlFor="client_id">Client ID</label>
                                <InputText
                                    id="client_id"
                                    name="client_id"
                                    value={formData.client_id || ""}
                                    disabled // read-only
                                />
                            </div>
                            <div className="field col-12 md:col-6">
                                <label htmlFor="client_secret">Client Secret</label>
                                <InputText
                                    id="client_secret"
                                    name="client_secret"
                                    value={formData.client_secret || ""}
                                    disabled // read-only
                                />
                            </div>
                            <div className="field col-12">
                                <label htmlFor="redirect_uris">Redirect URIs (one per line)</label>
                                <textarea
                                    id="redirect_uris"
                                    name="redirect_uris"
                                    rows={5}
                                    className="w-full p-2 border-round"
                                    value={formData.redirect_uris?.join("\n") || ""}
                                    onChange={(e) => {
                                        const uris = e.target.value.split("\n");
                                        setFormData((prevData) => ({
                                            ...prevData,
                                            redirect_uris: uris,
                                        }));
                                    }}
									onBlur={handleAutoSave}
                                />
                            </div>
                        </div>
                    )}

                    {error && <p style={{ color: "red" }}>{error}</p>}
                </form>
            </Dialog>

            {/* ConfirmDialog for deletion */}
            <ConfirmDialog
                visible={showConfirmDelete}
                onHide={() => setShowConfirmDelete(false)}
                message="Are you sure you want to delete this application? This action cannot be undone."
                header="Confirm Delete"
                icon="pi pi-exclamation-triangle"
                acceptLabel="Confirm"
                rejectLabel="Cancel"
                acceptClassName="p-button-danger"
                rejectClassName="p-button-outlined"
                accept={handleConfirmDelete}
                reject={handleCancelDelete}
            />
        </>
    );
};

export default EditAppModal;
