import React, { useEffect, useState } from "react";
import { Timeline } from "primereact/timeline";
import { Dialog } from "primereact/dialog";
import { SampleHistory } from "../../../../api/labiq/LabHistory";
import { fetchSingleUserByUuid, User } from "../../../../api/admin/userApi";

interface HistoryTabProps {
	history: SampleHistory[];
}

const HistoryTab: React.FC<HistoryTabProps> = ({ history }) => {
	const [selectedEntry, setSelectedEntry] = useState<SampleHistory | null>(null);
	const [dialogVisible, setDialogVisible] = useState<boolean>(false);
	const [fetchedUser, setFetchedUser] = useState<User | null>(null);

	useEffect(() => {
		if (dialogVisible && selectedEntry) {
		  (async () => {
			try {
			  const { data } = await fetchSingleUserByUuid(selectedEntry.user_uuid);
			  setFetchedUser(data);
			} catch (error) {
			  console.error(error);
			  setFetchedUser(null);
			}
		  })();
		}
	  }, [dialogVisible, selectedEntry]);

	// Prepare timeline data
	const events = [...history]
		.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
		.map((entry) => ({
			status: entry.sample_status,
			date: new Date(entry.created_at).toLocaleString(),
			data: entry
		}));

	const customMarker = (item: any) => {
		return (
			<span className="custom-marker p-shadow-2">
				<i className="pi pi-circle-fill"></i>
			</span>
		);
	};

	// Content renderer for each timeline event
	const content = (item: any) => {
		const entry: SampleHistory = item.data;

		const handleClick = () => {
			// Set the selected entry, show the dialog
			setSelectedEntry(entry);
			setDialogVisible(true);
		};

		return (
			<div>
				<div>
					<b>Status:</b> {item.status}
				</div>
				<div
					onClick={handleClick}
					style={{ cursor: "pointer", textDecoration: "underline", marginTop: '0.5rem' }}
				>
					{item.date}
				</div>
			</div>
		);
	};

	

	// Render the dialog body
	const renderDialogContent = () => {
		if (!selectedEntry) return null;

		return (
			<div>
				<p><b>User:</b> {fetchedUser?.first_name} {fetchedUser?.last_name}</p>
				<p><b>Company:</b> {selectedEntry.company}</p>
				<p><b>Change Made At:</b> {new Date(selectedEntry.created_at).toLocaleString()}</p>
				<hr />
				<p><b>Change Details:</b></p>
				{renderChanges(selectedEntry.update_details)}
			</div>
		);
	};
  
	function renderChanges(updateDetails: any): JSX.Element {
		// Convert the top-level object into an array of [key, value] pairs
		const topLevelEntries = Object.entries(updateDetails);

		return (
			<div style={{ lineHeight: 1.5 }}>
				{topLevelEntries.map(([topKey, topValue]) => {
					if (typeof topValue === 'object' && topValue !== null) {
						// Turn each field in `topValue` into lines
						const fieldEntries = Object.entries(topValue);

						return (
							<div key={topKey} style={{ marginBottom: '0.5rem' }}>
								{/* bug in the analysis submitting system means i need this at the moment */}
								{topKey === "0" ? (
									<div></div>
								) : (
									<strong>{topKey}:</strong>
								)}
								
								<div style={{ marginLeft: '1rem' }}>
									{fieldEntries.map(([fieldName, fieldValue]) => {
										// Check if fieldValue is { new, old }
										if (
											fieldValue &&
											typeof fieldValue === 'object' &&
											'new' in fieldValue &&
											'old' in fieldValue
										) {
											const oldVal = formatValue(fieldValue.old);
											const newVal = formatValue(fieldValue.new);
											if(newVal === "null"){
												return (
													<div></div>
												);
											}

											if(oldVal === "(empty)"){
												return(
													<div key={fieldName}>
													<strong>{fieldName}</strong>: set to <em>{newVal}</em>
												</div>
												);
											}
											return (
												<div key={fieldName}>
													<strong>{fieldName}</strong>: changed from{" "}
													<em>{oldVal}</em> to <em>{newVal}</em>
												</div>
											);
										} else {
											// If not {new, old}, just show the JSON
											return (
												<div key={fieldName}>
													<strong>{fieldName}</strong>: {formatValue(fieldValue)}
												</div>
											);
										}
									})}
								</div>
							</div>
						);
					}

					// Fallback: topValue is primitive
					return (
						<div key={topKey}>
							<strong>{topKey}</strong>: {formatValue(topValue)}
						</div>
					);
				})}
			</div>
		);
	}

	/**
	 * Helper to remove extra quotes, handle nulls, or empty strings, etc.
	 */
	function formatValue(value: any): string {
		if (value === null) return "null";
		if (value === "") return "(empty)";

		if (typeof value === "string") {
			const trimmed = value.trim();
			if (
				trimmed.startsWith('"') &&
				trimmed.endsWith('"') &&
				trimmed.length >= 2
			) {
				return trimmed.slice(1, -1);
			}
			return trimmed;
		}

		if (typeof value === "object") {
			return JSON.stringify(value);
		}

		// fallback
		return String(value);
	}


	return (
		<div className="grid card">

			<Timeline
				value={events}
				align="alternate"
				marker={customMarker}
				content={content}
			/>

			<Dialog
				header="History Details"
				visible={dialogVisible}
				style={{ width: "50vw" }}
				onHide={() => setDialogVisible(false)}
			>
				{renderDialogContent()}
			</Dialog>
		</div>
	);
};

export default HistoryTab;
