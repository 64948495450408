// src/components/icons/HealthcareProfessionalIcon.tsx
import React from 'react';
import { FaUserMd } from 'react-icons/fa'; // Importing the Font Awesome Medical Doctor icon

interface HealthcareProfessionalIconProps {
    className?: string;
}

const HealthcareProfessionalIcon: React.FC<HealthcareProfessionalIconProps> = ({ className }) => {
    return (
        <FaUserMd
            className={`transform transition-transform duration-300 ease-in-out hover:scale-110 ${className}`}
            size={24} // Set the default size to 24px
            aria-label="Healthcare Professionals"
        />
    );
};

export default HealthcareProfessionalIcon;
