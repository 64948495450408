import React, { ChangeEvent } from "react";
import Cookies from "js-cookie";

import {hasRole} from '../../../../utils/validatePermissions';

//Prime react
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from 'primereact/inputtextarea';

//API's
import { SampleFormData } from "../../../../api/labiq/labiqApi";


interface SampleLabProps {
	formData: SampleFormData;
	setFormData: React.Dispatch<React.SetStateAction<SampleFormData>>;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
	handleTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
	handleAutoSave: () => void;
	disabled: boolean;
}

const SampleLab: React.FC<SampleLabProps> = ({ formData, handleChange, handleTextAreaChange, setFormData, handleAutoSave, disabled }) => {

	const platedOptions = [
		{ label: "Plated", value: "plated" },
		{ label: "Next Run", value: "next_run" },
	];

	const protocolOptions = [
		{ label: "Concentration", value: "concentration" },
		{ label: "Dilution", value: "dilution" },
		{ label: "Purification", value: "purification" },
	];

	// Handler for Dropdown changes (single selection)
	const handleDropdownChange = (e: any) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};



	return (
		<div className="grid grid-cols-2 card">
			{/* Column 1 */}
			<div className="col-12 md:col-6 flex flex-column gap-3">
				{/* Plated Status */}
				<div className="p-2">
					<FloatLabel>
						
						<Dropdown
							id="plated_state"
							name="plated_state"
							value={formData.plated_state === "" ? null : formData.plated_state}
							options={platedOptions}
							onChange={handleDropdownChange}
							onBlur={handleAutoSave}
							className="w-full"
							disabled={!hasRole(["Lab Management"])}
						/>
						<label htmlFor="plated_state" className="block text-sm">
							Plated Status
						</label>
					</FloatLabel>
				</div>
				{/* QC Date */}
				<div className="p-2">
					<FloatLabel>


						<Calendar
							id="QC_date"
							name="QC_date"
							value={formData.QC_date ? new Date(formData.QC_date) : null}
							onChange={(e) => {
								const dateVal = e.value; // a JS Date or null
								if (!dateVal) {
									setFormData(prev => ({ ...prev, QC_date: null }));
									return;
								}

								// Build YYYY-MM-DD in local time
								const year = dateVal.getFullYear();
								const month = String(dateVal.getMonth() + 1).padStart(2, '0');
								const day = String(dateVal.getDate()).padStart(2, '0');
								const mysqlDate = `${year}-${month}-${day}`; // e.g. 2025-01-08

								setFormData((prev) => ({
									...prev,
									QC_date: mysqlDate,
								}));
							}}
							onBlur={handleAutoSave}
							dateFormat="dd/mm/yy"
							showIcon
							disabled={!hasRole(["Lab Management"])}
							className="w-full hide-placeholder" // if you still want custom styling
						/>
						<label htmlFor="QC_date" className="block text-sm">
							QC Date
						</label>

					</FloatLabel>
				</div>
				{/* Concentration */}
				<div className="p-2">
					<FloatLabel>
						<label htmlFor="concentration" className="block text-sm">
							Concentration
						</label>
						<InputText
							type="text"
							id="concentration"
							name="concentration"
							value={formData.concentration?.toString() || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							placeholder="Enter concentration"
							className="w-full p-2 border rounded"
							disabled={!hasRole(["Lab Management"])}
						/>
					</FloatLabel></div>
				{/* OD */}
				<div className="p-2">
					<FloatLabel>
						<label htmlFor="OD" className="block text-sm">
							Absorbance 260/280
						</label>
						<InputText
							type="text"
							id="OD"
							name="OD"
							value={formData.OD?.toString() || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							placeholder="Enter Absorbance 260/280"
							className="w-full p-2 border rounded"
							disabled={!hasRole(["Lab Management"])} />
					</FloatLabel>
				</div>
				{/* OD2 */}
				<div className="p-2">
					<FloatLabel>
						<label htmlFor="OD2" className="block text-sm">
							Absorbance 260/230
						</label>
						<InputText
							type="text"
							id="OD2" name="OD2"
							value={formData.OD2?.toString() || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							placeholder="Enter Absorbance 260/230"
							className="w-full p-2 border rounded"
							disabled={!hasRole(["Lab Management"])} />
					</FloatLabel>
				</div>

				<div className="p-2">
					<FloatLabel>
						<label htmlFor="lab_location" className="block text-sm">
							Lab Location
						</label>
						<InputText type="text" id="lab_location" name="lab_location" value={formData.lab_location || ""} onChange={handleChange} onBlur={handleAutoSave} placeholder="Enter lab location" className="w-full p-2 border rounded" disabled={!hasRole(["Lab Management"])} />
					</FloatLabel>
				</div>

			</div>

			{/* Column 2 */}
			<div className="col-12 md:col-6 flex flex-column gap-3">
				{/* Enhanced Protocol */}
				<div className="p-2">
					<FloatLabel>

						<Dropdown
							id="eProtocol"
							name="eProtocol"
							value={formData.plated_state || null}
							options={protocolOptions}
							onChange={handleDropdownChange}
							onBlur={handleAutoSave}
							className="w-full"
							disabled={!hasRole(["Lab Management"])}
						/>
						<label htmlFor="eProtocol" className="block text-sm">
							Enhanced Protocol
						</label>
					</FloatLabel>
				</div>
				{/* Job Sequence */}
				<div className="p-2">
					<FloatLabel>
						<label htmlFor="job_sequence" className="block text-sm">
							Sequence
						</label>
						<InputText
							type="text"
							id="job_sequence"
							name="job_sequence"
							value={formData.job_sequence?.toString() || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							placeholder="Enter sequence"
							className="w-full p-2 border rounded"
							disabled={!hasRole(["Lab Management"])}
						/>
					</FloatLabel>
				</div>

				{/* New Concentration */}
				<div className="p-2">
					<FloatLabel>
						<label htmlFor="eP_concentration" className="block text-sm">
							New Concentration
						</label>

						<InputText type="text"
							id="eP_concentration"
							name="eP_concentration"
							value={formData.eP_concentration?.toString() || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							placeholder="Enter New Concentration"
							className="w-full p-2 border rounded"
							disabled={!hasRole(["Lab Management"])} />
					</FloatLabel>
				</div>
				{/* New OD */}
				<div className="p-2">
					<FloatLabel>
						<label htmlFor="eP_OD" className="block text-sm">
							New Absorbance 260/280
						</label>
						<InputText type="text"
							id="eP_OD"
							name="eP_OD"
							value={formData.eP_OD?.toString() || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							placeholder="Enter New Absorbance 260/280"
							className="w-full p-2 border rounded"
							disabled={!hasRole(["Lab Management"])} />
					</FloatLabel>
				</div>
				{/* New OD2 */}
				<div className="p-2">
					<FloatLabel>
						<label htmlFor="eP_OD2" className="block text-sm">
							New Absorbance 260/230
						</label>
						<InputText
							type="text"
							id="eP_OD2"
							name="eP_OD2"
							value={formData.eP_OD2?.toString() || ""}
							onChange={handleChange}
							onBlur={handleAutoSave}
							placeholder="Enter New Absorbance 260/230"
							className="w-full p-2 border rounded"
							disabled={!hasRole(["Lab Management"])} />
					</FloatLabel>
				</div>
				

				<div className="p-2">
					<FloatLabel>
						<label htmlFor="available_material" className="block text-sm">
							Available Material
						</label>
						<InputText type="text" id="available_material" name="available_material" value={formData.available_material || ""} onChange={handleChange} onBlur={handleAutoSave} placeholder="Enter available material" className="w-full p-2 border rounded" disabled={!hasRole(["Lab Management"])} />

					</FloatLabel>
				</div>
			</div>

			{/* col-3 */}
			<div className="col-12 flex flex-column gap-3">
				<div className="field col-12">
					<FloatLabel>
						<label htmlFor="comments" className="block text-sm">
							Comments
						</label>
						<InputTextarea id="comments" name="comments" value={formData.comments || ""} onChange={handleTextAreaChange} onBlur={handleAutoSave} placeholder="Enter comments" className="w-full p-2 border rounded h-32" disabled={!hasRole(["Lab Management"])} />
					</FloatLabel>
				</div>
			</div>
		</div>
	);
};

export default SampleLab;
