import React, { useState, useEffect } from "react";
import { StockAssignment } from "../../../api/inventory/stockAssignmentApi";
import { fetchCompanies, Company } from "../../../api/admin/companyApi";
import { Stock } from "../../../api/inventory/stockApi";

interface StockAssignmentModalProps {
	isOpen: boolean;
	onClose: () => void;
	assignment: StockAssignment | null;
	setAssignment: React.Dispatch<React.SetStateAction<StockAssignment | null>>;
	onSave: (assignmentData: StockAssignment) => void;
	stock: Stock;
}

const StockAssignmentModal: React.FC<StockAssignmentModalProps> = ({ isOpen, onClose, assignment, setAssignment, onSave, stock }) => {
	const [companies, setCompanies] = useState<Company[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([]);


	useEffect(() => {
		
		fetchCompaniesList();
		setSearchTerm("");
		
		

	}, [stock]);

	const fetchCompaniesList = async () => {
		try {
			const data = await fetchCompanies(1, 100, "");
			setCompanies(data.data || []);
			setFilteredCompanies(data.data || []);
		} catch (error) {
			console.error("Error fetching companies:", error);
			setCompanies([]);
			setFilteredCompanies([]);
		}
	};

	const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchTerm(value);
		const filtered = companies.filter((company) => company.name.toLowerCase().includes(value.toLowerCase()));
		setFilteredCompanies(filtered);

	};


	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setAssignment((prev) => {
			if (!prev) {
				return {
					assignee_id: value,
					assignment_id: 0,
					stock_id: stock.stock_id, 
					quantity_assigned: 0,
					assignee_type: "",
					status: "active",
					notes: "",
					created_at: new Date().toISOString(),
					updated_at: new Date().toISOString(),
				} as StockAssignment;
			}
			return { ...prev, [name]: value };
		});
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		onSave(assignment as StockAssignment);
	};

	if (!isOpen) return null;

	return (

		<div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
			{assignment && (


				<div className="bg-white p-6 rounded shadow-md w-full max-w-lg">
					<h2 className="text-xl font-bold mb-4">{assignment ? "Edit Assignment" : "Add Assignment"}</h2>
					<div>
						{/* Assignee ID */}
						<div className="mb-4">
							<label className="block text-gray-700 font-medium mb-1">Assignee</label>
							<input type="text" placeholder="Search..." value={searchTerm} onChange={handleSearchChange} className="w-full border px-3 py-2 rounded mb-2" />
							<select name="assignee_id" value={assignment.assignee_id || ""} onChange={handleChange} required className="w-full border px-3 py-2 rounded" size={5}>
								{filteredCompanies.map((company) => (
									<option key={company.id} value={company.id} >
										{company.name}
									</option>
								))}
							</select>
						</div>
						{/* Quantity Assigned */}
						<div className="mb-4">
							<label className="block text-gray-700 font-medium mb-1">Quantity Assigned</label>
							<input name="quantity_assigned" type="number" value={assignment.quantity_assigned !== undefined ? assignment.quantity_assigned : ""} onChange={handleChange} required className="w-full border px-3 py-2 rounded" />
						</div>
						{/* Status */}
						<div className="mb-4">
							<label className="block text-gray-700 font-medium mb-1">Status</label>
							<select name="status" value={assignment.status || "active"} onChange={handleChange} className="w-full border px-3 py-2 rounded">
								<option value="active">Active</option>
								<option value="used">Used</option>
							</select>
						</div>
						{/* Notes */}
						<div className="mb-4">
							<label className="block text-gray-700 font-medium mb-1">Notes</label>
							<textarea name="notes" value={assignment.notes || ""} onChange={handleChange} className="w-full border px-3 py-2 rounded" />
						</div>
						{/* Buttons */}
						<div className="flex justify-end">
							<button type="button" onClick={onClose} className="mr-2 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">
								Cancel
							</button>
							<button type="button" onClick={handleSubmit} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
								{assignment ? "Update" : "Create"}
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default StockAssignmentModal;
