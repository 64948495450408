import React from "react";
import { Practitioner } from "../../../../api/healthcare/practitionerApi";
import { InputText } from 'primereact/inputtext';
import { Password } from "primereact/password";
import { FloatLabel } from "primereact/floatlabel";

interface ProfileTabProps {
	updatedPractitioner: Practitioner;
	setUpdatedPractitioner: React.Dispatch<React.SetStateAction<Practitioner>>;
}

interface EmergencyContact {
	name: string;
	relationship: string;
	phone: string;
}

const ProfileTab: React.FC<ProfileTabProps> = ({
	updatedPractitioner,
	setUpdatedPractitioner
}) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setUpdatedPractitioner((prev) => ({ ...prev, [name]: value }));
	};

	const handleEmergencyContactChange = (field: keyof EmergencyContact, value: string) => {
		setUpdatedPractitioner((prev) => {
			const prevContact = prev.practitioner?.emergency_contact || {};
			return {
				...prev,
				practitioner: {
					...prev.practitioner,
					emergency_contact: { ...prevContact, [field]: value },
				},
			};
		});
	};

	return (
		<div className="grid formgrid p-fluid">
			{/* Personal Information */}
			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
					<InputText
						id="first_name"
						name="first_name"
						value={updatedPractitioner.first_name || ""}
						onChange={handleChange}
					/>
					<label htmlFor="first_name">First Name</label>
				</FloatLabel>
			</div>
			<div className="field col-12 md:col-4 mt-2">
				<FloatLabel>
					<label htmlFor="middle_name">Middle Name</label>
					<InputText
						id="middle_name"
						name="middle_name"
						value={updatedPractitioner.middle_name || ""}
						onChange={handleChange}
						placeholder="Enter middle name"
					/>
				</FloatLabel>
			</div>
			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
					<InputText
						id="last_name"
						name="last_name"
						value={updatedPractitioner.last_name || ""}
						onChange={handleChange}
					/>
					<label htmlFor="last_name">Last Name</label>
				</FloatLabel>
			</div>
			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
					<InputText
						id="email"
						name="email"
						value={updatedPractitioner.email || ""}
						onChange={handleChange}
					/>
					<label htmlFor="email">Email</label>
				</FloatLabel>
			</div>
			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
					<InputText
						id="username"
						name="username"
						value={updatedPractitioner.username || ""}
						onChange={handleChange}
					/>
					<label htmlFor="username">Username</label>
				</FloatLabel>
			</div>

			{/* If new practitioner, show password fields */}
			{updatedPractitioner.id === 0 && (
				<>
					<div className="field col-12 md:col-6 mt-2">
						<FloatLabel>
							<Password
								id="password"
								name="password"
								value={updatedPractitioner.password || ""}
								onChange={handleChange}
							/>
							<label htmlFor="password">Password</label>
						</FloatLabel>
					</div>
					<div className="field col-12 md:col-6 mt-2">
						<FloatLabel>
							<Password
								id="password_confirmation"
								name="password_confirmation"
								value={updatedPractitioner.password_confirmation || ""}
								onChange={handleChange}
							/>
							<label htmlFor="password_confirmation">Confirm Password</label>
						</FloatLabel>
					</div>
				</>
			)}

			{/* Address Information */}
			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
					<InputText
						id="address"
						name="address"
						value={updatedPractitioner.address || ""}
						onChange={handleChange}
					/>
					<label htmlFor="address">Address</label>
				</FloatLabel>
			</div>
			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
					<InputText
						id="city"
						name="city"
						value={updatedPractitioner.city || ""}
						onChange={handleChange}
					/>
					<label htmlFor="city">City</label>
				</FloatLabel>
			</div>
			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
					<InputText
						id="state"
						name="state"
						value={updatedPractitioner.state || ""}
						onChange={handleChange}
					/>
					<label htmlFor="state">State</label>
				</FloatLabel>
			</div>
			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
					<InputText
						id="country"
						name="country"
						value={updatedPractitioner.country || ""}
						onChange={handleChange}
					/>
					<label htmlFor="country">Country</label>
				</FloatLabel>
			</div>
			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
					<InputText
						id="postal_code"
						name="postal_code"
						value={updatedPractitioner.postal_code || ""}
						onChange={handleChange}
					/>
					<label htmlFor="postal_code">Post Code</label>
				</FloatLabel>
			</div>

			{/* Emergency Contact */}
			<div className="field col-12">
				<label className="block">Emergency Contact</label>
				<div className="grid formgrid p-fluid mt-1">
					<div className="field col-12 md:col-4 mt-2">
						<FloatLabel>
							<InputText
								id="emergency_name"
								value={updatedPractitioner.practitioner?.emergency_contact?.name || ""}
								onChange={(e) => handleEmergencyContactChange("name", e.target.value)}
							/>
							<label htmlFor="emergency_name">Name</label>
						</FloatLabel>
					</div>
					<div className="field col-12 md:col-4 mt-2">
						<FloatLabel>
							<InputText
								id="emergency_relationship"
								value={updatedPractitioner.practitioner?.emergency_contact?.relationship || ""}
								onChange={(e) => handleEmergencyContactChange("relationship", e.target.value)}
							/>
							<label htmlFor="emergency_relationship">Relationship</label>
						</FloatLabel>
					</div>
					<div className="field col-12 md:col-4 mt-2">
						<FloatLabel>
							<InputText
								id="emergency_phone"
								value={updatedPractitioner.practitioner?.emergency_contact?.phone || ""}
								onChange={(e) => handleEmergencyContactChange("phone", e.target.value)}
							/>
							<label htmlFor="emergency_phone">Phone</label>
						</FloatLabel>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileTab;
