import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactSignatureCanvas from 'react-signature-canvas';

import { getFormToRender, Form, FormElement, AssignedForm, uploadFormResults, FieldResponse, FileResponse } from '../../api/formApi';

// PrimeReact components
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { FloatLabel } from 'primereact/floatlabel';
import { RadioButton } from 'primereact/radiobutton';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { uploadFile } from '../../api/fileServiceApi';
import LoadingSpinner from '../../components/icons/LoadingSpinner';



const useQuery = (): URLSearchParams => {
	return new URLSearchParams(useLocation().search);
};

const FormRenderer: React.FC = () => {
	const [formData, setFormData] = useState<Form | null>(null);
	const [assigned, setAssigned] = useState<AssignedForm>();
	const [responses, setResponses] = useState<Record<string, any>>({});
	const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

	// checks for loading and success
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isComplete, setIsComplete] = useState<boolean>(false);

	// Holds signature pad reference
	const sigCanvasRef = useRef<ReactSignatureCanvas>(null);

	const query = useQuery();
	const id = query.get('id');


	useEffect(() => {
		const fetchFormData = async () => {
			setIsLoading(true);
			try {
				if (id) {
					const { form, assigned } = await getFormToRender(parseInt(id, 10));
					if(assigned.status === "Completed"){
						setIsComplete(true);
					}else {
						const parsedStructure = form.structure ? JSON.parse(form.structure) : [];
						const parsedData: Form = {
							...form,
							structure: parsedStructure,
						};
						setFormData(parsedData);
						setAssigned(assigned)
					}
					
					
				}
			} catch (error) {
				console.error('Error fetching form:', error);
			} finally {
				setIsLoading(false);
			}
		};

		fetchFormData();
	}, [id]);


	const handleFieldChange = (fieldId: string | number, value: any) => {
		setResponses((prev) => ({ ...prev, [fieldId]: value }));
	};

	// Submit the form
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();

		if (!formData) return;

		try {
			// Build a JSON structure of question/response pairs
			const finalResponses: FieldResponse[] = await Promise.all(
				formData.structure.map(async (field: FormElement) => {
					const questionText = field.question || field.placeholder || `Field ${field.id}`;
					let files: FileResponse[] = [];
					if (field.type === 'file') {
						for (let i = 0; i < uploadedFiles.length; i++) {

							//upload the file
							const response = await uploadFile(uploadedFiles[i], assigned?.patient_id as number);

							let tmp: FileResponse = {
								id: response.file_id,
								type: uploadedFiles[i].type,
								name: uploadedFiles[i].name,
								size: uploadedFiles[i].size,
								loaction: `${assigned?.patient_id}/files/${response.file_id}`,
							};
							files.push(tmp);
						}
					}
					return {
						id: field.id,
						type: field.type,
						question: questionText,
						response: field.type !== "file" ? (responses[field.id] || null) : (files),
					};
				}));

			//now post the json response to the backend
			const data = await uploadFormResults(assigned?.id as number, JSON.stringify(finalResponses));

			//if succesfull show a success message
			if (data) {
				setIsComplete(true);
			}

		} catch (error: unknown) {
			console.error(error);
		}

	};

	const fields = formData?.structure || [];

	return (
		<div
			className="flex align-items-center justify-content-center"
			style={{ minHeight: '100vh', background: '#F3F4F6' }}
		>
			<div
				className="surface-0 p-4 border-round shadow-2"
				style={{ width: '100%', maxWidth: '600px' }}
			>
				{isLoading ? (
					<div className="flex align-items-center justify-content-center" style={{ height: '200px' }}>
						<LoadingSpinner size={60} color="#354396" />
					</div>
				) : (
					isComplete ? (
						<div className="flex align-items-center justify-content-center" style={{ height: '200px', textAlign: 'center' }}>
							<h2>This form has been completed succesfully. <br/> Thank you</h2>
						</div>
					) : (
						formData && (
						<form onSubmit={handleSubmit} className="flex flex-column gap-3">
							<h2>{formData.title}</h2>

							{fields.map((field: FormElement) => {
								switch (field.type) {
									case 'heading':
										return (
											<div key={field.id}>
												<h3 className="mb-1">{field.placeholder}</h3>
											</div>
										);

									case 'paragraph':
										return (
											<div key={field.id} className="mb-3">
												<p className="mb-1">{field.placeholder}</p>
											</div>
										);

									case 'email':
										return (
											<div key={field.id} className="mb-3">
												<FloatLabel>
													<label className="block mb-1">{field.question}</label>
													<InputText
														type="email"
														keyfilter="email"
														value={responses[field.id] || ''}
														placeholder={field.placeholder || ''}
														onChange={(e) => handleFieldChange(field.id, e.target.value)}
														className="w-full"
													/>
												</FloatLabel>
											</div>
										);

									case 'number':
										return (
											<div key={field.id} className="mb-3">
												<FloatLabel>
													<label className="block mb-1">{field.question}</label>
													<InputText
														type="number"
														value={responses[field.id] || ''}
														placeholder={field.placeholder || ''}
														onChange={(e) => handleFieldChange(field.id, e.target.value)}
														className="w-full"
													/>
												</FloatLabel>
											</div>
										);

									case 'phone':
										return (
											<div key={field.id} className="mb-3">
												<FloatLabel>
													<label className="block mb-1">{field.question}</label>
													<InputText
														type="number"
														value={responses[field.id] || ''}
														placeholder={field.placeholder || ''}
														onChange={(e) => handleFieldChange(field.id, e.target.value)}
														className="w-full"
													/>
												</FloatLabel>
											</div>
										);

									case 'radio':
										return (
											<div key={field.id} className="mb-3">
												<label className="block mb-1">{field.question}</label>
												{field.options?.map((option) => (
													<div key={option} className="flex items-center mb-1">
														<RadioButton
															inputId={option}
															name={`radio-${field.id}`}
															value={option}
															onChange={() => handleFieldChange(field.id, option)}
															checked={responses[field.id] === option}
														/>
														<label htmlFor={option} className="ml-2">
															{option}
														</label>
													</div>
												))}
											</div>
										);

									case 'text':
										return (
											<div key={field.id} className="mb-3">
												<FloatLabel>
													<label className="block mb-1">{field.question}</label>
													<InputText
														value={responses[field.id] || ''}
														placeholder={field.placeholder || ''}
														onChange={(e) => handleFieldChange(field.id, e.target.value)}
														className="w-full"
													/>
												</FloatLabel>
											</div>
										);

									case 'textarea':
										return (
											<div key={field.id} className="mb-3">
												<FloatLabel>
													<label className="block mb-1">{field.question}</label>
													<InputTextarea
														value={responses[field.id] || ''}
														placeholder={field.placeholder || ''}
														onChange={(e) => handleFieldChange(field.id, e.target.value)}
														className="w-full"
													/>
												</FloatLabel>
											</div>
										);

									case 'checkbox':
										return (
											<div key={field.id} className="mb-3">
												<label className="block mb-1">{field.question}</label>
												<Checkbox
													checked={responses[field.id] || false}
													onChange={(e) => handleFieldChange(field.id, e.checked)}
												/>
											</div>
										);

									case 'select':
										return (
											<div key={field.id} className="mb-3">
												<FloatLabel>
													<Dropdown
														value={responses[field.id]}
														options={field.options?.map((o) => ({ label: o, value: o })) || []}
														onChange={(e) => handleFieldChange(field.id, e.value)}
														placeholder="Select an option"
														className="w-full"
													/>
													<label className="block mb-1">{field.question}</label>
												</FloatLabel>
											</div>
										);

									case 'date':
										return (
											<div key={field.id} className="mb-3">
												<FloatLabel>
													<Calendar
														dateFormat="dd/mm/yy"
														value={responses[field.id] || ''}
														placeholder={field.placeholder || ''}
														onChange={(e) => handleFieldChange(field.id, e.target.value)}
														className="w-full"
													/>
													<label className="block mb-1">{field.question}</label>
												</FloatLabel>
											</div>
										);

									case 'time':
										return (
											<div key={field.id} className="mb-3">
												<FloatLabel>
													<Calendar
														timeOnly
														value={responses[field.id] || ''}
														placeholder={field.placeholder || ''}
														onChange={(e) => handleFieldChange(field.id, e.target.value)}
														className="w-full"
													/>
													<label className="block mb-1">{field.question}</label>
												</FloatLabel>
											</div>
										);

									case 'datetime':
										return (
											<div key={field.id} className="mb-3">
												<FloatLabel>
													<Calendar
														dateFormat="dd/mm/yy"
														showTime
														hourFormat="12"
														value={responses[field.id] || ''}
														placeholder={field.placeholder || ''}
														onChange={(e) => handleFieldChange(field.id, e.target.value)}
														className="w-full"
													/>
													<label className="block mb-1">{field.question}</label>
												</FloatLabel>
											</div>
										);

									case 'file':
										return (
											<div key={field.id} className="mb-3">
												<label className="block mb-1">{field.question}</label>
												<FileUpload
													mode="advanced"
													name="files"
													multiple
													accept=".doc,.docx,application/pdf,image/*"
													maxFileSize={10_000_000}
													customUpload
													uploadOptions={{
														// Hide the default upload button
														className: 'p-d-none',
														style: { display: 'none' },
													}}
													onSelect={(e) => {
														// e.files is an array of File objects
														setUploadedFiles(e.files);
													}}
													chooseLabel="Choose File(s)"
													emptyTemplate={field.placeholder || 'Drag files here to upload'}
												/>
											</div>
										);

									case 'signature':
										return (
											<div key={field.id} className="mb-3">
												<label className="block mb-1">{field.question}</label>
												<ReactSignatureCanvas
													ref={sigCanvasRef}
													penColor="black"
													canvasProps={{
														width: 500,
														height: 200,
														style: { border: '1px solid #ddd' },
													}}
													onEnd={() => {
														// Convert canvas to base64 and store
														const signatureAsDataUrl =
															sigCanvasRef.current?.toDataURL('image/png');
														handleFieldChange(field.id, signatureAsDataUrl);
													}}
												/>
												<Button
													type="button"
													label="Clear"
													onClick={() => {
														sigCanvasRef.current?.clear();
														handleFieldChange(field.id, null);
													}}
													className="mt-2"
												/>
											</div>
										);

									default:
										return (
											<div key={field.id} className="mb-3">
												<p>Unsupported field type: {field.type}</p>
											</div>
										);
								}
							})}

							<Button type="submit" label="Submit" icon="pi pi-check" className="mt-3" />
						</form>
					))
				)}


			</div>
		</div>
	);
};

export default FormRenderer;
