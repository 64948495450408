
import React from "react";
import { User } from "../../api/admin/userApi";
import { InputText } from 'primereact/inputtext';

interface UserProfileAddressProps {
    user: User;
    isEditing: boolean;
    setUserData: React.Dispatch<React.SetStateAction<User>>;
}

const UserProfileAddress: React.FC<UserProfileAddressProps> = ({ user, isEditing, setUserData }) => {
    const fields: { name: keyof User; label: string }[] = [
        { name: "address", label: "ADDRESS" },
        { name: "city", label: "CITY" },
        { name: "state", label: "STATE" },
        { name: "country", label: "COUNTRY" },
        { name: "postal_code", label: "POSTAL CODE" }
    ];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData(prevUser => ({ ...prevUser, [name]: value }));
    };

    return (
        <div>
            <h2 className="text-color" style={{ fontSize: '1.25rem', fontWeight: '500', marginBottom: '1rem' }}>Address Information</h2>
            <div className="grid formgrid p-fluid">
                {fields.map(field => (
                    <div key={field.name} className="field col-12 md:col-6">
                        <span className="text-color-secondary" style={{ display: 'block', marginBottom: '0.5rem' }}>{field.label}</span>
                        {isEditing ? (
                            <InputText 
                                name={field.name} 
                                value={user[field.name] as string || ''} 
                                onChange={handleChange} 
                            />
                        ) : (
                            <span className="text-color">{user[field.name as keyof User] as string || ''}</span>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserProfileAddress;
