// src/pages/orders/PurchaseOrderManagementPage.tsx

import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import PurchaseOrderTable from "../../components/inventory/ordering/PurchaseOrderTable";
import Pagination from "../../components/Pagination";
import PurchaseOrderModal from "../../components/inventory/ordering/PurchaseOrderModal";
import { fetchPurchaseOrders, createPurchaseOrder, updatePurchaseOrder, deletePurchaseOrder, PurchaseOrder } from "../../api/inventory/purchaseOrderApi";

const PurchaseOrderManagementPage: React.FC = () => {
    const [orders, setOrders] = useState<PurchaseOrder[]>([]);
    const [totalOrders, setTotalOrders] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedOrder, setSelectedOrder] = useState<PurchaseOrder | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const ORDERS_PER_PAGE = 20;

    useEffect(() => {
        loadOrders();
    }, [currentPage]);

    const loadOrders = async () => {
        setIsLoading(true);
        try {
            const { data, total } = await fetchPurchaseOrders({ page: currentPage, per_page: ORDERS_PER_PAGE });
            setOrders(data);
            setTotalOrders(total);
        } catch (error) {
            console.error("Error fetching purchase orders:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleAddOrder = () => {
        setSelectedOrder(null);
        setIsModalOpen(true);
    };

    const handleEditOrder = (order: PurchaseOrder) => {
        setSelectedOrder(order);
        setIsModalOpen(true);
    };

    const handleDeleteOrder = async (order: PurchaseOrder) => {
        if (window.confirm(`Are you sure you want to delete Purchase Order #${order.purchase_order_id}?`)) {
            try {
                await deletePurchaseOrder(order.purchase_order_id!);
                loadOrders();
            } catch (error) {
                console.error("Error deleting purchase order:", error);
            }
        }
    };

    const handleModalClose = () => {
        setSelectedOrder(null);
        setIsModalOpen(false);
    };

    const handleOrderSave = async (orderData: PurchaseOrder) => {
        try {
            if (selectedOrder) {
                await updatePurchaseOrder(selectedOrder.purchase_order_id!, orderData);
            } else {
                await createPurchaseOrder(orderData);
            }
            loadOrders();
            handleModalClose();
        } catch (error) {
            console.error("Error saving purchase order:", error);
        }
    };

    return (
        <Layout>
            <h1 className="text-2xl font-bold mb-4">Purchase Order Management</h1>
            <div className="mb-4">
                <button onClick={handleAddOrder} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    Add Purchase Order
                </button>
            </div>
            {isLoading ? (
                <p>Loading purchase orders...</p>
            ) : (
                <>
                    <PurchaseOrderTable orders={orders} onEdit={handleEditOrder} onDelete={handleDeleteOrder} />
                    <Pagination currentPage={currentPage} totalItems={totalOrders} itemsPerPage={ORDERS_PER_PAGE} onPageChange={setCurrentPage} />
                </>
            )}
            {isModalOpen && <PurchaseOrderModal isOpen={isModalOpen} onClose={handleModalClose} order={selectedOrder} onSave={handleOrderSave} />}
        </Layout>
    );
};

export default PurchaseOrderManagementPage;
