// src/pages/inventory/ProductManagementPage.tsx

import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Layout from "../../components/Layout";
import { Product, createProduct, updateProduct, deleteProduct } from "../../api/inventory/productApi";
import { fetchKits } from "../../api/inventory/kitApi";

import LoadingSpinner from '../../components/icons/LoadingSpinner';
import Pagination from "../../components/Pagination";

import KitModal from "../../components/inventory/product/kit/KitModal";
import KitTable from "../../components/inventory/product/kit/KitTable";

const ProductManagementPage: React.FC = () => {
    const [kits, setKits] = useState<Product[]>([]);
    const [totalKits, setTotalKits] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedKit, setSelectedKit] = useState<Product | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");

	const user = JSON.parse(Cookies.get("user") || "{}");
    const PRODUCTS_PER_PAGE = 20;
	const [currentPage, setCurrentPage] = useState<number>(1);


    useEffect(() => {
        loadKits();
    }, [currentPage, searchTerm]);

    const loadKits = async () => {
        setIsLoading(true);
        try {
			const user = JSON.parse(Cookies.get("user") || "{}");
			const company = user.company;
            const { data, total } = await fetchKits(currentPage, PRODUCTS_PER_PAGE, company, searchTerm);
            setKits(data);
            setTotalKits(total);
        } catch (error) {
            console.error("Error fetching kits:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditClick = (kit: Product) => {
        setSelectedKit(kit);
        setIsModalOpen(true);
    };

    const handleDeleteClick = async (kit: Product) => {
        if (window.confirm(`Are you sure you want to delete the kit "${kit.name}"?`)) {
            try {
                await deleteProduct(kit.product_id);
                loadKits();
            } catch (error) {
                console.error("Error deleting product:", error);
            }
        }
    };

    const handleModalClose = () => {
        setSelectedKit(null);
        setIsModalOpen(false);
    };

    const handleKitSave = async (kitData: Partial<Product>) => {
        try {
            if (selectedKit) {
                // Update product
                await updateProduct(selectedKit.product_id, kitData);
            } else {
                // Create new product
				kitData.is_kit = true;
                await createProduct(kitData);
            }
            loadKits();
            handleModalClose();
        } catch (error) {
            console.error("Error saving product:", error);
        }
    };

    const handleAddKit = () => {
        setSelectedKit(null);
        setIsModalOpen(true);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    return (
        <Layout>
            <h1 className="text-2xl font-bold mb-4">Kit Management</h1>
            <div className="mb-4 flex justify-between">
				<div>
				<button onClick={handleAddKit} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                    Add Kit Type
                </button>
				
				</div>
                
                <input type="text" placeholder="Search kits..." value={searchTerm} onChange={handleSearchChange} className="border px-4 py-2 rounded" />
            </div>
            {isLoading ? (
                <LoadingSpinner size={60} color="#354396" />
            ) : (
                <>
                    <KitTable kits={kits} onEditClick={handleEditClick} onDeleteClick={handleDeleteClick} />
                    <Pagination currentPage={currentPage} totalItems={totalKits} itemsPerPage={PRODUCTS_PER_PAGE} onPageChange={setCurrentPage} />
                </>
            )}
            {isModalOpen && <KitModal isOpen={isModalOpen} onClose={handleModalClose} kit={selectedKit} onSave={handleKitSave} />}
        </Layout>
    );
};

export default ProductManagementPage;
