import React from "react";
import { Practitioner } from "../../../../api/healthcare/practitionerApi";
import { InputText } from 'primereact/inputtext';
// prime does not have Textarea but we can use InputTextarea
import { InputTextarea } from 'primereact/inputtextarea';
import { FloatLabel } from "primereact/floatlabel";

interface PractitionerInfoTabProps {
	updatedPractitioner: Practitioner;
	setUpdatedPractitioner: React.Dispatch<React.SetStateAction<Practitioner>>;
}

const PractitionerInfoTab: React.FC<PractitionerInfoTabProps> = ({
	updatedPractitioner,
	setUpdatedPractitioner,
}) => {

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setUpdatedPractitioner((prev) => ({
			...prev,
			practitioner: {
				...prev.practitioner,
				[name]: value,
			},
		}));
	};

	const handleArrayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const arrayValues = value.split(",").map((item) => item.trim());
		setUpdatedPractitioner((prev) => ({
			...prev,
			practitioner: {
				...prev.practitioner,
				[name]: arrayValues,
			},
		}));
	};

	return (
		<div className="grid formgrid p-fluid">
			{/* Column 1 */}
			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
				<label htmlFor="medical_license_number">Medical License Number</label>
				<InputText
					id="medical_license_number"
					name="medical_license_number"
					value={updatedPractitioner.practitioner?.medical_license_number || ""}
					onChange={handleInputChange}
				/>
				</FloatLabel>
			</div>

			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
				<label htmlFor="npi_number">NPI Number</label>
				<InputText
					id="npi_number"
					name="npi_number"
					value={updatedPractitioner.practitioner?.npi_number || ""}
					onChange={handleInputChange}
				/>
				</FloatLabel>
			</div>

			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
				<label htmlFor="dea_number">DEA Number</label>
				<InputText
					id="dea_number"
					name="dea_number"
					value={updatedPractitioner.practitioner?.dea_number || ""}
					onChange={handleInputChange}
				/>
				</FloatLabel>
			</div>

			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
				<label htmlFor="supervisor_id">Supervisor ID</label>
				<InputText
					id="supervisor_id"
					name="supervisor_id"
					value={String(updatedPractitioner.practitioner?.supervisor_id ?? "")}
					onChange={handleInputChange}
				/>
				</FloatLabel>
			</div>

			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
				<label htmlFor="taxonomy_code">Taxonomy Code</label>
				<InputText
					id="taxonomy_code"
					name="taxonomy_code"
					value={updatedPractitioner.practitioner?.taxonomy_code || ""}
					onChange={handleInputChange}
				/>
				</FloatLabel>
			</div>

			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
				<label htmlFor="specialties">Specialties</label>
				<InputText
					id="specialties"
					name="specialties"
					value={updatedPractitioner.practitioner?.specialties?.join(", ") || ""}
					onChange={handleArrayChange}
				/>
				</FloatLabel>
			</div>

			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
				<label htmlFor="years_of_experience">Years of Experience</label>
				<InputText
					id="years_of_experience"
					name="years_of_experience"
					value={String(updatedPractitioner.practitioner?.years_of_experience ?? 0)}
					onChange={handleInputChange}
				/>
				</FloatLabel>
			</div>

			{/* Column 2 */}
			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
				<label htmlFor="linkage">Linkage</label>
				<InputText
					id="linkage"
					name="linkage"
					value={(updatedPractitioner.practitioner as any)?.linkage || ""}
					onChange={handleInputChange}
				/>
				</FloatLabel>
			</div>

			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
				<label htmlFor="qualifications">Qualifications</label>
				<InputText
					id="qualifications"
					name="qualifications"
					value={updatedPractitioner.practitioner?.qualifications?.join(", ") || ""}
					onChange={handleArrayChange}
				/>
				</FloatLabel>
			</div>

			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
				<label htmlFor="certifications">Certifications</label>
				<InputText
					id="certifications"
					name="certifications"
					value={updatedPractitioner.practitioner?.certifications?.join(", ") || ""}
					onChange={handleArrayChange}
				/>
				</FloatLabel>
			</div>

			<div className="field col-12 md:col-6 mt-2">
				<FloatLabel>
				<label htmlFor="professional_memberships">Professional Memberships</label>
				<InputText
					id="professional_memberships"
					name="professional_memberships"
					value={updatedPractitioner.practitioner?.professional_memberships?.join(", ") || ""}
					onChange={handleArrayChange}
				/>
				</FloatLabel>
			</div>

			<div className="field col-12 mt-2">
				<FloatLabel>
				<label htmlFor="biography">Biography</label>
				<InputTextarea
					id="biography"
					name="biography"
					value={updatedPractitioner.practitioner?.biography || ""}
					onChange={handleInputChange}
					rows={4}
				/>
				</FloatLabel>
			</div>

			<div className="field col-12 mt-2">
				<FloatLabel>
				<label htmlFor="languages_spoken">Languages Spoken</label>
				<InputText
					id="languages_spoken"
					name="languages_spoken"
					value={updatedPractitioner.practitioner?.languages_spoken?.join(", ") || ""}
					onChange={handleArrayChange}
				/>
				</FloatLabel>
			</div>
		</div>
	);
};

export default PractitionerInfoTab;
