// src/components/suppliers/SupplierTable.tsx

import React from "react";
import { Supplier } from "../../../api/inventory/supplierApi";

interface SupplierTableProps {
    suppliers: Supplier[];
    onEdit: (supplier: Supplier) => void;
    onDelete: (supplier: Supplier) => void;
}

const SupplierTable: React.FC<SupplierTableProps> = ({ suppliers, onEdit, onDelete }) => {
    if (!suppliers || suppliers.length === 0) {
        return <p>No suppliers available.</p>;
    }

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white border">
                <thead>
                    <tr>
                        <th className="py-2 px-3 border-b">Supplier ID</th>
                        <th className="py-2 px-3 border-b">Name</th>
                        <th className="py-2 px-3 border-b">Contact Name</th>
                        <th className="py-2 px-3 border-b">Email</th>
                        <th className="py-2 px-3 border-b">Phone</th>
                        <th className="py-2 px-3 border-b">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {suppliers.map((supplier) => (
                        <tr key={supplier.supplier_id} className="text-center">
                            <td className="py-2 px-3 border-b">{supplier.supplier_id}</td>
                            <td className="py-2 px-3 border-b">{supplier.name}</td>
                            <td className="py-2 px-3 border-b">{supplier.contact_name || "-"}</td>
                            <td className="py-2 px-3 border-b">{supplier.email || "-"}</td>
                            <td className="py-2 px-3 border-b">{supplier.phone || "-"}</td>
                            <td className="py-2 px-3 border-b">
                                <button onClick={() => onEdit(supplier)} className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 mr-2">
                                    Edit
                                </button>
                                <button onClick={() => onDelete(supplier)} className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600">
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SupplierTable;
