import React, { useEffect, useState } from "react";
import debounce from 'lodash/debounce';

//APi imports
import { Appointment } from "../../../../api/healthcare/appointmentApi";
import { Patient, activatePatient, defaultPatient, fetchPatients } from "../../../../api/healthcare/patientApi";
import { User } from "../../../../api/admin/userApi";
import { fetchPractitioners, Practitioner } from "../../../../api/healthcare/practitionerApi";

//COmponents
import EditPatientModal from "../../patient/EditPatientModal";

//Prime react
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Calendar } from "primereact/calendar";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";



interface GeneralTabProps {
	user: User;
	handleErrors: any;
	isLock: boolean;
	appointment: Appointment;
	setAppointment: React.Dispatch<React.SetStateAction<Appointment>>;
	selectedPatient: Patient ;
	setSelectedPatient: React.Dispatch<React.SetStateAction<Patient >>;
	selectedPractitioner: Practitioner;
	setSelectedPractitioner: React.Dispatch<React.SetStateAction<Practitioner>>;
}

const categories = [
	"Office Visit",
	"Established Patient",
	"New Patient",
	"Health and Behaviour Assesment",
	"Preventitive Care Services",
	"No Show"
]

const statuses = [
	'Pending',
	'Reminder done',
	'EMAIL Confirmed',
	'SMS Confirmed',
	'Arrived',
	'Arrived late',
	'Canceled',
	'Canceled within 24h',
	'No show',
	'Left w/o visit',
	'In exam room',
	'Chart pulled',
	'Checked out',
	'Transferred Provider',
	'Callback requested',
]

const GeneralTab: React.FC<GeneralTabProps> = ({ user, handleErrors, isLock, appointment, setAppointment, selectedPatient, selectedPractitioner, setSelectedPatient, setSelectedPractitioner }) => {
	const [patients, setPatients] = useState<Patient[]>([]);
	const [Practitioners, setPractitioners] = useState<Practitioner[]>([]);
	const [filterText, setFilterText] = useState<string>("");

	const [newPatient, setNewPatient] = useState<Patient | null>(null);
	const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
	

	const PATIENTS_PER_PAGE = 30;

	//Load the page
	useEffect(() => {
		if (appointment.patient_id === 0) {
			getPatients();
		} else{
			if( selectedPatient && selectedPractitioner){
				setPatients([selectedPatient as Patient]);

			}
		}

		if (appointment.practitioner_id === 0) {
			getPractitioners();
		} else{
			if( selectedPatient && selectedPractitioner){
				setPractitioners([selectedPractitioner as Practitioner]);
			}
		}
		
	}, [appointment, selectedPatient, selectedPractitioner]);


	//Patient Functions
	const getPatients = async (search?: string) => {

		try {
			// Extract the first sorting rule
			const sortField = "first_name";
			const sortDirection = 'asc';

			const { data, total } = await fetchPatients(
				1,
				PATIENTS_PER_PAGE,
				search,
				sortField,
				sortDirection
			);
			setPatients(data);


		} catch (err) {
			console.error("Error fetching patients:", err);

		}
	};

	const handleFilterPatient = async (e: any) => {
		setFilterText(e.filter);
	
		try {
			// search for the filtered patinet
			const sortField = "first_name";
			const sortDirection = "asc";
			const { data } = await fetchPatients(
				1,
				PATIENTS_PER_PAGE,
				e.filter,
				sortField,
				sortDirection
			);
	
			// If no results found add a create patient item
			let updatedPatients = [...data];
			if (data.length === 0 && e.filter.trim() !== "") {
				updatedPatients.push({
					id: -1,
					first_name: `Create a new patient: "${e.filter}"`,
					last_name: "",
					isNewItem: true,  // custom flag
				} as unknown as Patient);
			}
	
			setPatients(updatedPatients);
		} catch (err) {
			console.error("Error fetching patients:", err);
		}
	};

	const handlePatientChange = (e: DropdownChangeEvent) => {
		
		if ((e.value as any)?.isNewItem) {
			//open the patient modal to create a new patient

			//Extract the name
			let fullName = filterText.toString();
			let parts = fullName.split(" ");
			const firstName = parts[0];
			const lastName = parts.slice(1).join(" ");

			//set the patient
			let patient:Patient = defaultPatient;
			patient.first_name = firstName;
			patient.last_name = lastName;
			
			setNewPatient(patient);
			setCreateModalOpen(true);

			return;
		}

		setSelectedPatient(e.value);
		setAppointment(prev => ({
			...prev,
			patient_id: e.value.id
		}));
	}

	const patientOptionTemplate = (option: Patient) => {
		if ((option as any).isNewItem) {
			// This is the button for creating a new patient
			return (
				<div className="flex align-items-center" style={{ fontStyle: "italic", color: "var(--primary-color)" }}>
					<i className="pi pi-plus-circle mr-2" />
					<span>Create a new patient: "{filterText}"</span>
				</div>
			);
		}
	
		// Normal rendering of existing patient
		return (
			<div className="flex align-items-center">
				<div>{option.first_name || null} {option.last_name || null}</div>
			</div>
		);
	};
	

	const selectedPatientTemplate = (option: Patient, props: any) => {
		if (option) {
			return (
				<div className="flex align-items-center">
					<div>{option.first_name} {option.last_name}</div>
				</div>
			);
		}

		return <span className="flex align-items-center m-2">{props.placeholder}</span>;
	};


	//Providor Funciton
	const getPractitioners = async (search?: string) => {
		try {
			const { data, total } = await fetchPractitioners(1, 30, user.company, search);
			setPractitioners(data);

		} catch (err) {
			console.error("Error fetching professionals:", err);
			handleErrors("Failed to load professionals.");
		}
	};

	const handleFilterPractitioners = (e: any) => {
		getPractitioners(e.filter);
	};

	const handlePractitionerChange = (e: DropdownChangeEvent) => {
		setSelectedPractitioner(e.value);
		setAppointment(prev => ({
			...prev,
			practitioner_id: e.value.id
		}));
	}

	const PractitionerOptionTemplate = (option: Practitioner) => {
		return (
			<div className="flex align-items-center">
				<div>{option.first_name || null} {option.last_name || null}</div>
			</div>
		);
	};

	const selectedPractitionerTemplate = (option: Practitioner, props: any) => {
		if (option) {
			return (
				<div className="flex align-items-center">
					<div>{option.first_name} {option.last_name}</div>
				</div>
			);
		}

		return <span className="flex align-items-center m-2">{props.placeholder}</span>;
	};

	// OTher functions
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAppointment(prev => ({ ...prev, [e.target.name]: e.target.value }));

	};

	const handleCalendarChange = (e: any) => {
		const fieldName = e.target?.name;
		setAppointment(prev => ({
			...prev,
			[fieldName]: e.value
		}));
	};

	const handleDropdownChange = (e: any) => {
		const fieldName = e.target?.name;
		setAppointment(prev => ({
			...prev,
			[fieldName]: e.value
		}));
	};

	//Creating a new patient 
	const handleCreatePatientClose = async () => {
		setCreateModalOpen(false);
		setNewPatient(null);

		// set the patient that was just created
		const sortField = "first_name";
		const sortDirection = "asc";
		const { data } = await fetchPatients(
			1,
			PATIENTS_PER_PAGE,
			filterText,
			sortField,
			sortDirection
		);

		setPatients(data);

		//reset the dropdown
		setFilterText("");
	};


	return (
		<div >
			<div className="grid formgrid p-fluid">

				{/* Col 1 */}
				<div className="grid formgrid p-fluid col-12">
					<div className="col-12">
						<h4 className="mt-0">Appointment Type</h4>
					</div>

					<div className="field col-12 md:col-4 mt-2">
						<FloatLabel>

							<InputText
								id="title"
								name="title"
								value={appointment.title || ""}
								onChange={handleChange}
							/>
							<label htmlFor="title">Title</label>
						</FloatLabel>
					</div>
					<div className="field col-12 md:col-4 mt-2">
						<FloatLabel>

							<Dropdown
							name="category"
								value={appointment.category}
								//valueTemplate={selectedPatientTemplate}
								onChange={handleDropdownChange}
								options={categories}
								//itemTemplate={patientOptionTemplate}
								optionLabel="name"
								showClear
								className="w-full"
							/>
							<label htmlFor="category">Select Category</label>
						</FloatLabel>
					</div>
					<div className="field col-12 md:col-4 mt-2">
						<FloatLabel>

							<Dropdown
							name="status"
								value={appointment.status}
								//valueTemplate={selectedPatientTemplate}
								onChange={handleDropdownChange}
								options={statuses}
								//itemTemplate={patientOptionTemplate}
								optionLabel="status"
								showClear
								className="w-full"
							/>
							<label htmlFor="status">Select Status</label>
						</FloatLabel>
					</div>
				</div>


				{/* Column 2 */}
				<div className="grid formgrid p-fluid col-12">
					<div className="col-12">
						<h4 className="mt-0">Appointment Time</h4>
					</div>
					<div className="field col-12 md:col-4 mt-2">
						<FloatLabel>

							<Calendar
								id="start_time"
								name="start_time"
								value={appointment.start_time || null}
								onChange={handleCalendarChange}
								// onBlur={handleAutoSave}
								showTime
								hourFormat="24"
								showButtonBar
							/>
							<label htmlFor="start_time">Start Time</label>
						</FloatLabel>
					</div>
					<div className="field col-12 md:col-4 mt-2">

						<FloatLabel>
							<Calendar
								id="end_time"
								name="end_time"
								value={appointment.end_time || null}
								onChange={handleCalendarChange}
								// onBlur={handleAutoSave}
								showTime
								hourFormat="24"
								showButtonBar
							/>
							<label htmlFor="end_time">End Time</label>
						</FloatLabel>
					</div>

					<div className="field col-12 md:col-4 mt-2">
						<p>Will build repeating system here</p>
						{/* <FloatLabel>
							<Calendar
								id="end_time"
								name="end_time"
								value={appointment.end_time || null}
								onChange={handleCalendarChange}
								// onBlur={handleAutoSave}
								showTime
								hourFormat="12"
								showButtonBar
							/>
							<label htmlFor="end_time">Repeating</label>
						</FloatLabel> */}
					</div>
				</div>

				{/* Col 3 */}
				<div className="grid formgrid p-fluid col-12">
					<div className="grid col-6">
						<div className="col-12">
							<h4 className="mt-0">Patient</h4>
						</div>
						<div className="field col-12 mt-2">
							<FloatLabel>

								<Dropdown
									value={selectedPatient || null}
									valueTemplate={selectedPatientTemplate}
									onChange={handlePatientChange}
									options={patients}
									itemTemplate={patientOptionTemplate}
									optionLabel="first_name"
									filter
									onFilter={handleFilterPatient}
									filterBy="first_name,last_name,email"
									showClear
									className="w-full"
									disabled={isLock ? true : false}
								/>
								<label htmlFor="start_time">Select Patient</label>
							</FloatLabel>
						</div>
					</div>
					<div className="grid col-6"><div className="col-12">
						<h4 className="mt-0">Providor</h4>
					</div>


						<div className="field col-12 mt-2">
							<FloatLabel>

								<Dropdown
									value={selectedPractitioner || null}
									valueTemplate={selectedPractitionerTemplate}
									onChange={handlePractitionerChange}
									options={Practitioners}
									itemTemplate={PractitionerOptionTemplate}
									optionLabel="name"
									filter
									onFilter={handleFilterPractitioners}
									filterBy="first_name,last_name,email"
									showClear
									className="w-full"
									disabled={isLock ? true : false}
								/>
								<label htmlFor="start_time">Select Providor</label>
							</FloatLabel>
						</div></div>
				</div>

				{/* Col 4 */}
				<div className="grid formgrid p-fluid col-12">
					<div className="col-12">
						<h4 className="mt-0">Location</h4>
					</div>
					<div className="field col-12 md:col-6 mt-2">
						<FloatLabel>

							<InputText
								id="location"
								name="location"
								value={appointment.location || ""}
								onChange={handleChange}
							/>
							<label htmlFor="location">Location</label>
						</FloatLabel>
					</div>
					<div className="field col-12 md:col-6 mt-2">
						<FloatLabel>

							<InputText
								id="room_number"
								name="room_number"
								value={appointment.room_number || ""}
								onChange={handleChange}
							/>
							<label htmlFor="room_number">Room Number (If Applicable)</label>
						</FloatLabel>
					</div>
				</div>

			</div>

			{(newPatient && createModalOpen) && (
				<EditPatientModal isAnon={false} isOpen={createModalOpen} onClose={handleCreatePatientClose} onPatientUpdated={handleCreatePatientClose} patient={newPatient} />
			)}

		</div>
	);
};

export default GeneralTab;
