import React, { useState } from 'react';
import Cookies from "js-cookie";
import Layout from "../components/Layout";
import { User, updateProfile } from "../api/admin/userApi";
import UserProfileHeader from "../components/profile/UserProfileHeader";
import UserProfileDetails from "../components/profile/UserProfileDetails";
import UserProfileAddress from "../components/profile/UserProfileAddress";
import UserProfileCompany from "../components/profile/UserProfileCompany";
import { Button } from 'primereact/button';

const ProfilePage: React.FC = () => {
	const user = JSON.parse(Cookies.get("user") || "{}");
	const [userData, setUserData] = useState<User>(user);
	const [isEditing, setIsEditing] = useState<boolean>(false);

	const handleEditToggle = () => {
		setIsEditing(!isEditing);
	};

	const handleSave = async () => {
		try {
			const updatedUser = await updateProfile(userData);
			sessionStorage.setItem("user", JSON.stringify(updatedUser));
		} catch (err) {
			console.log(err);
		}
		setIsEditing(false);
	};

	return (
		<Layout>
			<div className="flex justify-content-center p-4">
				<div className="surface-0 shadow-2 border-round p-4 w-full md:w-8 lg:w-8">
					<div className="flex align-items-center justify-content-between mb-3">
						<h1 className="text-color" style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: 0 }}>
							Profile
						</h1>
						{isEditing ? (
							<Button
								label="Save"
								severity="success"
								onClick={handleSave}
							/>
						) : (
							<Button
								label="Edit"
								severity="info"
								onClick={handleEditToggle}
							/>
						)}
					</div>

					<UserProfileHeader user={userData} isEditing={isEditing} setUserData={setUserData} />

					<hr className="my-3 surface-border" />

					<UserProfileDetails user={userData} isEditing={isEditing} setUserData={setUserData} />

					<hr className="my-3 surface-border" />

					<UserProfileAddress user={userData} isEditing={isEditing} setUserData={setUserData} />

					<hr className="my-3 surface-border" />

					<UserProfileCompany user={userData} isEditing={isEditing} setUserData={setUserData} />
				</div>
			</div>
		</Layout>
	);
};

export default ProfilePage;
