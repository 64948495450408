// src/components/FormBuilder/FormBuilder.tsx (or FormBuilderModal.tsx)
import React, { useState, useRef, ChangeEvent } from 'react';
import axios from 'axios';
import {
	DndContext,
	DragStartEvent,
	DragOverEvent,
	DragEndEvent,
} from '@dnd-kit/core';

import { arrayMove, SortableContext } from '@dnd-kit/sortable';

import { DragOverlay } from '@dnd-kit/core';

// Components
import { Toolbox } from './builderModal/Toolbox';
import { BuilderCanvas } from './builderModal/BuilderCanvas';
import { deleteForm, Form, FormElement, setNewForm, updateForm } from '../../api/formApi';

// PrimeReact
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { FloatLabel } from 'primereact/floatlabel';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';

interface FormBuilderProps {
	isOpen: boolean;
	onClose: () => void;
	form: Form;
	isSubmitting: boolean;
	setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormBuilderModal: React.FC<FormBuilderProps> = ({
	isOpen,
	onClose,
	form,
	isSubmitting,
	setIsSubmitting
}) => {
	const [updatedForm, setUpdatedForm] = useState<Form>(form);
	const [activeId, setActiveId] = useState<string | null>(null);
	const [errorMessages, setErrorMessages] = useState<string[]>([]);

	const toast = useRef<Toast>(null);

	// This is where you'd call your API to save the form
	const handleSave = async () => {
		setIsSubmitting(true);
		//habdle saving a new form
		if (updatedForm.id === 0) {
			//Creating
			try {
				const newForm = await setNewForm(updatedForm);
				onClose();
			} catch (error: unknown) {
				handleErrors(error);
			}
		} else {
			//Updating
			try {
				const update = await updateForm(updatedForm);
			} catch (error: unknown) {
				handleErrors(error);
			}
		}
		setIsSubmitting(false);
	};

	//delete the form
	const handleDelete = async () => {
		setIsSubmitting(true);
		try {
			const del = await deleteForm(updatedForm.id);
			onClose();
		} catch (error: unknown) {
			handleErrors(error);
		}
		setIsSubmitting(false);
	};

	const confirmDeleteForm = (event: React.MouseEvent<HTMLButtonElement>) => {
		confirmDialog({
			message: 'Are you sure you want to delete this fomr? This can not be undone.',
			header: 'Confirmation',
			icon: 'pi pi-exclamation-triangle',
			defaultFocus: 'accept',
			accept: () => handleDelete(),
			reject: () => { /* Do nothing on reject */ },
			style: { width: '25em' },
		});
	};

	//Handle form changes
	const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const newTitle = e.target.value;
		setUpdatedForm((prev) => ({
			...prev,
			title: newTitle,
		}));
	};

	const handleUpdateField = (id: string | number, newValues: Partial<FormElement>) => {
		setUpdatedForm((prev) => {
			const updatedstructure = prev.structure.map((f) =>
				f.id === id ? { ...f, ...newValues } : f
			);
			return { ...prev, structure: updatedstructure };
		});
	};

	const handleDeleteField = (id: string | number) => {
		setUpdatedForm((prev) => {
			const updatedstructure = prev.structure.filter((f) => f.id !== id);
			return { ...prev, structure: updatedstructure };
		});
	};

	const handleReorderstructure = (newOrder: FormElement[]) => {
		setUpdatedForm((prev) => ({
			...prev,
			structure: newOrder
		}));
	};


	/**
	 * Handle the events when the user is dragging an item out of the toolbox
	 */
	const handleDragStart = (event: DragStartEvent) => {
		setActiveId(event.active.id as string);
	};

	const handleDragOver = (event: DragOverEvent) => {
		// optional cross-droppable logic
	};

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		if (!over) return;

		// Case 1: Dropping from Toolbox into the Canvas
		if (over.id === 'builder-canvas' && active.data.current?.from === 'toolbox') {
			const draggedData = active.data.current;
			const newField: FormElement = {
				id: String(new Date().getTime()),
				type: draggedData.type,
				label: draggedData.label,
				options: draggedData.options || [],
			};
			setUpdatedForm((prev) => ({
				...prev,
				structure: [...prev.structure, newField],
			}));
		}
		// Case 2: Reordering items within the Canvas
		else if (active.data.current?.from === 'canvas' && over.data.current?.from === 'canvas') {
			if (active.id !== over.id) {
				setUpdatedForm((prev) => {
					const oldIndex = prev.structure.findIndex((f) => f.id === active.id);
					const newIndex = prev.structure.findIndex((f) => f.id === over.id);
					if (oldIndex === -1 || newIndex === -1) return prev;

					const reordered = arrayMove(prev.structure, oldIndex, newIndex);
					return { ...prev, structure: reordered };
				});
			}
		}
	};


	const handleErrors = (error: unknown) => {
		if (axios.isAxiosError(error) && error.response) {
			if (error.response.data?.errors) {
				const messages = Object.entries(error.response.data.errors)
					.flatMap(([_, errs]) => errs as string);
				setErrorMessages(messages);
			} else if (error.response.data?.message) {
				setErrorMessages([error.response.data.message]);
			} else {
				setErrorMessages(["An unknown error occurred."]);
			}
		} else if (error instanceof Error) {
			setErrorMessages([error.message || "An unknown error occurred."]);
		} else {
			setErrorMessages(["An unknown error occurred."]);
		}
	};

	// dialog header
	const header = () => {
		return (
			<div className="flex justify-content-between">
				<Toast ref={toast} />
				<div>{form.id ? 'Edit ' + form.title : 'Create New Form'}</div>

				<div className='flex'>
					<ConfirmDialog />
					<Button
						icon="pi pi-trash p-icon"
						rounded
						text
						aria-label="Delete"
						loading={isSubmitting}
						disabled={isSubmitting}
						type="button"
						onClick={confirmDeleteForm}
						className="p-dialog-header-icon mr-2"
						style={{ color: 'var(--text-color-alternative)' }}
					/>
					<Button
						icon="pi pi-save p-icon"
						rounded
						text
						aria-label="Save"
						loading={isSubmitting}
						disabled={isSubmitting}
						type="button"
						onClick={handleSave}
						className="p-dialog-header-icon mr-2"
						style={{ color: 'var(--text-color-alternative)' }}
					/>
				</div>
			</div>
		);
	};

	return (
		<Dialog
			visible={isOpen}
			onHide={onClose}
			style={{ width: '80vw', height: '80vh' }}
			modal
			dismissableMask
			focusOnShow={false}
			header={header}
			headerClassName="pb-0 h-auto bg-primary"
			contentStyle={{ padding: 0, height: '100%' }}
			closable
			maximizable
			className="custom-dialog"
		>

			<DndContext
				onDragStart={handleDragStart}
				onDragOver={handleDragOver}
				onDragEnd={handleDragEnd}
			>
				<div className="flex w-full h-full" style={{ overflow: 'hidden' }}>
					{/* Left column: Toolbox with draggable items */}

					<Toolbox />


					{/* Right column: The droppable Builder Canvas + form info */}
					<div className="p-3 flex-column" style={{ flex: 1, overflowY: 'auto' }}>
						<div className="mt-4 mb-4">
							<FloatLabel>
								<InputText
									id="formTitle"
									value={updatedForm.title}
									onChange={handleTitleChange}
									className="w-full"
									placeholder="Enter your form title"
								/>
								<label htmlFor="formTitle">
									Form Title
								</label>
							</FloatLabel>
						</div>

						<BuilderCanvas
							// Pass updatedForm.structure to your canvas
							formstructure={updatedForm.structure}
							onUpdateField={handleUpdateField}
							onDeleteField={handleDeleteField}
							onReorderstructure={handleReorderstructure}
						/>
					</div>
					{/* Render a DragOverlay that shows the item currently being dragged */}
					<DragOverlay>
						{activeId ? (
							<div
								style={{
									padding: '8px',
									border: '1px solid #999',
									borderRadius: '4px',
									backgroundColor: '#f7f7f7',
									cursor: 'grabbing',
									position: 'absolute',
									zIndex: 9999,
									pointerEvents: 'none',
								}}
							>
								{renderActiveElementLabel(activeId)}
							</div>
						) : null}
					</DragOverlay>
				</div>
			</DndContext>
			{errorMessages.length > 0 && (
				<div className="mt-3">
					{errorMessages.map((msg, idx) => (
						<div key={idx} style={{ color: 'red' }}>
							{msg}
						</div>
					))}
				</div>
			)}
		</Dialog>
	);
};

// Helper to figure out how to display the dragged item
function renderActiveElementLabel(activeId: string) {
	return <>{activeId}</>;
}

export default FormBuilderModal;
