/**
 * @file This file contains the form API functions.
 * @module api/formApi
 * 
 * 
 */

import { message } from "antd";
import axios from "axios";
import Cookies from "js-cookie";
const API_BASE_URL = process.env.REACT_APP_HEALTHCARE_SERVER;

export interface FormElement {
	id: string | number;
	type: string;
	label: string;
	question?: string;
	placeholder?: string;
	options?: string[];
	required?: boolean;
}

export interface Form{
	id: number;
	practitioner_id: number;
	company: string;
	department: string;
	title: string;
	structure: FormElement[];
	created_at: Date;
	updated_at: Date;
}

export const defaultForm:Form = {
	id: 0,
	practitioner_id : 0,
	company: "",
	department: "",
	title: "",
	structure: [],
	created_at: new Date(),
	updated_at: new Date(),
};


//assigning form interfaces
export interface AssignedForm {
	id: number;
	practitioner_id: number;
	patient_id: number;
	form_id: number;
	company: string;
	notes: string;
	status: string;
	created_at: Date;
	updated_at: Date;
}

//assigning form interfaces
export interface FormResponse {
	id: number;
	assigned_form_id: number;
	response: string;
	created_at: Date;
	updated_at: Date;
}

export interface FullFormResponse extends FormResponse {
	structure: FieldResponse[];
  }

export interface FieldResponse {
	id: string | number;
	type: string;
	question: string;
	response: any;
}

export interface FileResponse {
	id: string | number;
	name: string;
	type: string;
	loaction: string;
	size: number;
}


export const fetchForms = async (company: string, search?: string): Promise<{
	data: Form[];
}> => {
	const response = await axios.get(
		`${API_BASE_URL}/practitioner/forms/${company}`,
		{
			params: {
				search
			},
			headers: {
				Authorization: `Bearer ${Cookies.get("apiKey")}`,
			},
		}
	);

	return {
		data: response.data.data,
	};
};

export const fetchSingleFormById = async (
	id: number
): Promise<{
	data: Form;
}> => {
	const response = await axios.get(`${API_BASE_URL}/practitioner/forms/single/${id}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return {
		data: response.data,
	};
};

export const setNewForm = async (formData: Partial<Form>): Promise<{
	data: Form;
	message: string;
}> => {
	const user = JSON.parse(Cookies.get("user") || "{}");

	// TUrn the form structure into a json element to send to the db
	const payload = {
		...formData,
		structure: formData.structure
		  ? JSON.stringify(formData.structure)
		  : undefined,
	  };

	const response = await axios.post(`${API_BASE_URL}/practitioner/forms`, {
		formData: payload,
		user_id: user.uuid,
	}, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return {
		data: response.data.data,
		message: response.data.message,
	};
};

export const updateForm = async (formData: Partial<Form>): Promise<{
	data: Form;
	message: string;
}> => {
	const user = JSON.parse(Cookies.get("user") || "{}");
	const payload = {
		...formData,
		structure: formData.structure
		  ? JSON.stringify(formData.structure)
		  : undefined,
	  };
	const response = await axios.put(`${API_BASE_URL}/practitioner/forms/${formData.id}`, {
		formData: payload,
		user_id: user.uuid,
	}, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return {
		data: response.data.data,
		message: response.data.message,
	};
};

export const deleteForm = async (formId: number) => {
	const response = await axios.delete(`${API_BASE_URL}/practitioner/forms/${formId}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return response.data;
};


export const assignForm = async (assignedForm: AssignedForm) => {
	const response = await axios.post(`${API_BASE_URL}/practitioner/forms/assign`, {
		assignedForm
	},{
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return response.data;
};

export const getAssignedForms = async (patientId: number) => {
	const response = await axios.get(`${API_BASE_URL}/practitioner/forms/assign/${patientId}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return response.data;
};

export const uploadFormResults = async (assignedFormId: number, results: string) => {
	const response = await axios.post(`${API_BASE_URL}/practitioner/forms/results/`, {
		"assigned_form_id": assignedFormId,
		"response": results
	},{
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return response.data;
};

export const getFormResults = async (assignedFormId: number) => {
	const response = await axios.get(`${API_BASE_URL}/practitioner/forms/results/${assignedFormId}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return response.data;
};

export const getFormToRender = async (assignedFormId: number): Promise<{form: any, assigned: AssignedForm}> => {
	const response = await axios.get(`${API_BASE_URL}/practitioner/forms/render/${assignedFormId}`, {
		headers: {
			Authorization: `Bearer ${Cookies.get("apiKey")}`,
		},
	});
	return {
		form: response.data.form,
		assigned: response.data.assignment
		
	};
};