import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

// Layout and API
import Layout from "../../components/Layout";
import LoadingSpinner from '../../components/icons/LoadingSpinner';
import { fetchBatch, fetchBatches, Batch } from '../../api/labiq/labiqApi';

// Import the batch page components
import BatchTable from '../../components/labiq/batchManagement/BatchTable';
import ActionButtons from '../../components/labiq/batchManagement/BatchManagementButtons';
import NewBatchModal from '../../components/labiq/batchManagement/BatchModal';

// Prime react
import { Toast } from 'primereact/toast';
import { SortOrder } from 'primereact/datatable';


// Custom hook to parse query parameters
const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const BatchManagementPage: React.FC = () => {
	const [batches, setBatches] = useState<Batch[]>([]);
	const [totalBatches, setTotalBatches] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(0);
	const [sortBy, setSortBy] = useState<string>('batch_create_date');
	const [sortOrder, setSortOrder] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isShowAll, setIsShowAll] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [selectedBatches, setSelectedBatches] = useState<number[]>([]);
	const [error, setError] = useState<string | null>(null);

	const query = useQuery();
	const id = query.get('id'); 

	const BATCHES_PER_PAGE = 30;
	const toast = useRef<Toast>(null);

	// Fetch batch data and handle modal open logic
	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);
			try {
				if (id) {
					const batchId = parseInt(id, 10);
					if (!isNaN(batchId)) {
						const {Batch, Samples} = await fetchBatch(batchId);
						if (Batch) {
							setBatches([Batch]);
							handleEditClick(batchId);
						} else {
							setError(`Batch with ID ${batchId} not found.`);
						}
					} else {
						setError(`Invalid batch ID: ${id}`);
					}
				} else {
					await fetchBatchData(false);
				}
			} catch (err: any) {
				console.error("Error fetching data:", err);
				setError(err.message || "Failed to load batches.");
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();
	}, [id, currentPage, sortBy, sortOrder]); // Removed 'batches' from the dependency array to prevent re-render loops

	const fetchBatchData = async (showAll: boolean) => {
		setError(null);
		try {
			let company: string | "";
			if (showAll) {
				company = "";
				setIsShowAll(true);
			} else {
				const userInfo = JSON.parse(Cookies.get('user') || '{}');
				company = userInfo.company;
				setIsShowAll(false);
			}

			const { data, total } = await fetchBatches(
				currentPage + 1,
				BATCHES_PER_PAGE,
				company,
				sortBy,
				sortOrder === 1 ? 'asc' : 'desc'
			);
			setBatches(data);
			setTotalBatches(total);
		} catch (err: any) {
			console.error("Error fetching batches:", err);
			setError(err.message || "Failed to load batches.");
		}
	};

	// Handle sorting of the page data
	const handleSort = (column: string) => {
		if (column === sortBy) {
			setSortOrder(prevOrder => (prevOrder === 1 ? -1 : 1));
		} else {
			setSortBy(column);
			setSortOrder(1);
		}
	};

	// Page functions
	const handleEditClick = (batchID: number) => {
		setSelectedBatches([batchID]);
		setIsModalOpen(true);
	};

	const handleNewBatchClick = () => {
		setSelectedBatches([0]);
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		fetchBatchData(isShowAll);
		setSelectedBatches([]);
		setIsModalOpen(false);
	};

	return (
		<Layout>
			<div className="p-3">
				<Toast ref={toast} />

				{/* Action Buttons */}
				<ActionButtons
					selectedBatches={selectedBatches}
					refreshBatches={fetchBatchData}
					isShowAll={isShowAll}
					editClick={handleEditClick}
					newBatch={handleNewBatchClick}
				/>

				{/* Batch Table */}
				{isLoading ? (
					<div className="flex justify-content-center align-items-center" style={{ height: '200px' }}>
						<LoadingSpinner size={60} color="#354396" />
					</div>
				) : error ? (
					<p className="text-red-500">{error}</p>
				) : (
					<>
						<BatchTable
							onPageChange={fetchBatchData}
							batches={batches}
							selectedBatches={selectedBatches}
							setSelectedBatches={setSelectedBatches}
							onSort={handleSort}
							sortBy={sortBy}
							onEditBatch={handleEditClick}
							currentPage={currentPage}
							totalItems={totalBatches}
							itemsPerPage={BATCHES_PER_PAGE}
							setCurrentPage={setCurrentPage}
							sortOrder={sortOrder as SortOrder}
						/>
					</>
				)}

				{/* Show the modal to edit or create a batch */}
				{isModalOpen && selectedBatches.length === 1 && (
					<NewBatchModal
						isOpen={isModalOpen}
						onClose={handleModalClose}
						batch_id={selectedBatches[0] === 0 ? 0 : selectedBatches[0]}
					/>
				)}
			</div>
		</Layout>
	);
};

export default BatchManagementPage;
