import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import UserTable from "../../components/admin/user/UserTable";
import LoadingSpinner from '../../components/icons/LoadingSpinner';
import { fetchUsers, User } from "../../api/admin/userApi";
import { getAllRoles, Role } from "../../api/admin/rolesApi";
import EditUserModal from "../../components/admin/user/EditUserModal";
import UserButtons from "../../components/admin/user/UserButtons";
import { Divider } from 'primereact/divider';
import { Panel } from 'primereact/panel';

type DataTableStateEvent = {
	first: number;
	rows: number;
	page?: number;
	pageCount?: number;
};

const UserManagementPage: React.FC = () => {
	const [users, setUsers] = useState<User[]>([]);
	const [totalUsers, setTotalUsers] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [selectedUser, setSelectedUser] = useState<User | null>(null);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);
	const [roles, setRoles] = useState<Role[]>([]);

	const USERS_PER_PAGE = 30;

	useEffect(() => {
		const loadUsers = async () => {
			setIsLoading(true);
			try {
				// Fetch the current page of users from the server
				const { data, total } = await fetchUsers(currentPage, USERS_PER_PAGE);
				setUsers(data);
				setTotalUsers(total); // e.g., 500
				setError(null);
			} catch (err) {
				console.error("Error fetching users:", err);
				setError("Failed to load users.");
			} finally {
				setIsLoading(false);
			}
		};

		const loadRoles = async () => {
			setIsLoading(true);
			try {
				const rolesData = await getAllRoles();
				setRoles(rolesData);
				setError(null);
			} catch (err) {
				console.error("Error fetching roles:", err);
				setError("Failed to load roles.");
			} finally {
				setIsLoading(false);
			}
		};

		loadRoles();
		loadUsers();
	}, [currentPage]);

	const SearchUsers = async (search: string, role: string) => {
		try {
			const { data, total } = await fetchUsers(currentPage, USERS_PER_PAGE, search, role);
			setUsers(data);
			setTotalUsers(total);
			setError(null);
		} catch (err) {
			console.error("Error searching users:", err);
			setError("Failed to search users.");
		}
	};

	const handleEditClick = (user: User) => {
		setSelectedUser(user);
		setIsModalOpen(true);
	};

	const handleModalClose = () => {
		setSelectedUser(null);
		setIsModalOpen(false);
	};

	const handleUserUpdate = (updatedUser: User) => {
		setUsers((prevUsers) => prevUsers.map((usr) => (usr.id === updatedUser.id ? updatedUser : usr)));
		handleModalClose();
	};

	// Convert currentPage (1-based) to first (0-based index)
	const first = (currentPage - 1) * USERS_PER_PAGE;

	// Handler for paginator event from DataTable
	const handlePageChange = (event: DataTableStateEvent) => {
		const newPage = event.page !== undefined ? event.page + 1 : 1;
		setCurrentPage(newPage);
	};

	return (
		<Layout>
			<div className="flex flex-column gap-3">
				<h1 className="text-color" style={{ fontSize: '1.5rem', fontWeight: 'bold', margin: 0 }}>User Management</h1>
				{isLoading ? (
					<div className="flex justify-content-center align-items-center" style={{ height: '200px' }}>
						<LoadingSpinner size={60} color="#354396" />
					</div>
				) : (
					<div className="flex flex-column gap-2">
						<UserButtons searchUsers={SearchUsers} roles={roles} />
						<UserTable
							users={users}
							onEditClick={handleEditClick}
							first={first}
							rows={USERS_PER_PAGE}
							totalRecords={totalUsers}
							onPage={handlePageChange}
						/>
					</div>
				)}
				{selectedUser && (
					<EditUserModal
						isOpen={isModalOpen}
						onClose={handleModalClose}
						user={selectedUser}
						onUserUpdated={handleUserUpdate}
					/>
				)}
			</div>
		</Layout>
	);
};

export default UserManagementPage;
